<CardBase {element}>
<header style="height:auto;">
	<a class="primary-logo" href="/cards/Home?round=previous">
		<img src="https://resources-us.yinzcam.com/csf/logos/conmebol-logo_{languageValue}.png" alt="{$t("CONMEBOL Libertadores")}">
		<span>CONMEBOL Libertadores</span>
	</a>

	<nav class="primary">
		<ul class="navigation-links">
			<li>
				<a href="/cards/Home?round=previous">
					<img class="icon" src="https://resources-us.yinzcam.com/csf/shared/icons/icon_schedule.svg" alt="">
					<span>{$t('Inicio')}</span>
				</a>
			</li>
			<li>
				{#if teamId}
				<a href="/cards/Team{teamQueryParam}">
					<img class="icon" src="https://resources-us.yinzcam.com/csf/shared/icons/icon_photos.svg" alt="Equipo">
					<span>{$t('Equipo')}</span>
				</a>
				{:else}
				<a class="popup-open" data-popup=".team-select">
					<img class="icon" src="https://resources-us.yinzcam.com/csf/shared/icons/icon_photos.svg" alt="Equipo">
					<span>{$t('Equipo')}</span>
				</a>
				{/if}
			</li>
			<li class="has-sub-nav">
				<a href="/cards/Specials?titleText=Especiales&taxonomy=Goles">
					<img class="icon" src="https://resources-us.yinzcam.com/csf/shared/icons/icon_schedule.svg" alt="">
					<span>{$t("Especiales")}</span>
				</a>

				<ul class="sub-nav">
					<li>
						<a href="/cards/Specials?titleText={encodeURIComponent('Goles de la Semana')}&taxonomy=Goles">{$t('Goles de la Semana')}</a>
					</li>
					<li>
						<a href="/cards/Specials?titleText=Highlights&taxonomy=Highlights">{$t('Highlights')}</a>
					</li>
					<li>
						<a href="/cards/Specials?titleText={encodeURIComponent('Best of Awards')}&taxonomy=Best">{$t('Best of Awards')}</a>
					</li>
					<li>
						<a href="/cards/Specials?titleText={encodeURIComponent('Héroe Anónimo')}&taxonomy=Heroe%20Anonimo">{$t('Héroe Anónimo')}</a>
					</li>
				</ul>
			</li>
			<li>
				<a href="/cards/Calendar?round=ALL&teamId=">
					<img class="icon" src="https://resources-us.yinzcam.com/csf/shared/icons/icon_schedule.svg" alt="">
					<span>{$t('Calendario')}</span>
				</a>
			</li>
			<li>
				<a href="/cards/News?titleText=Noticias&mediaType=N">
					<img class="icon" src="https://resources-us.yinzcam.com/csf/shared/icons/icon_news.svg" alt="">
					<span>{$t('Noticias')}</span>
				</a>
			</li>
			<li>
				<a href="/cards/Stats{teamQueryParam}">
					<img class="icon" src="https://resources-us.yinzcam.com/csf/shared/icons/icon_statistics.svg" alt="">
					<span>{$t('Estadísticas')}</span>
				</a>
			</li>
			<li class="more-menu">
				<a href="/cards/Home?round=previous">
					<img class="icon" src="https://resources-us.yinzcam.com/csf/shared/icons/icon_more.svg" alt="More">
					<span>More</span>
				</a>
			</li>
		</ul>
	</nav>

	<div class="popup team-select" style="border:solid 1px #fcd069"><View url="/cards/ModalTeamSelect" /></div>
	
	<div class="social">
		<a href="{$t('https://www.facebook.com/CopaLibertadores/')}" target="_blank" class="link external social-icon">
			<img src="https://resources-us.yinzcam.com/csf/shared/icons/icon_facebook-gold.png" alt="Facebook">
		</a>
		<a href="{$t('https://twitter.com/Libertadores')}" target="_blank" class="link external social-icon">
			<img src="https://resources-us.yinzcam.com/csf/shared/icons/icon_twitter-gold.png" alt="Twitter">
		</a>
		<a href="{$t('https://www.instagram.com/libertadores')}" target="_blank" class="link external social-icon">
			<img src="https://resources-us.yinzcam.com/csf/shared/icons/icon_instagram-gold.png" alt="Instagram">
		</a>
		<a href="{$t('https://www.youtube.com/channel/UClgFf9mS1mZI2D_vo-ZTpmQ')}" target="_blank" class="link external social-icon">
			<img src="https://resources-us.yinzcam.com/csf/shared/icons/icon_youtube-gold.png" alt="YouTube">
		</a>
	</div>
	<!-- <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="right" /> -->
	<Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelToggle="right" />
	

	<!-- <a class="panel-toggle hamburger-menu-icon">
		<img src="/static/icons/icon_menu.svg" alt="Menu">
	</a> -->
</header>	
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

:root {
  --nav-background-color:#000;
}

header {
  width:100%;
  height:auto;
  min-height:110px;
  padding:0 5%;
  color:#FFF;
  font-family:var(--f7-font-family);
  text-decoration:none;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items:center;
  overflow:visible;
  /*position:relative;*/
  z-index:1000;
}

header .primary-logo {
  width:10%;
  padding:12px;
}

header .primary-logo img {
  width:auto;
  height:80px;
}

header .navigation-links {
  width:100%;
  margin:0;
  padding:0;
  list-style:none;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-around;
  align-items:center;
  overflow:visible;
}

header .navigation-links li {
  overflow:visible;
}

header .navigation-links li.has-sub-nav {
  position:relative;
  overflow:visible;
  display:block;
}

header .navigation-links li.has-sub-nav:hover > a {
  background:var(--f7-theme-primary);
}

header .navigation-links li.has-sub-nav ul.sub-nav {
  width:350px;
  margin:0;
  padding:0;
  background:rgba(0,0,0,0.9);
  list-style:none;
  display:block;
  position:absolute;
  top:100%;
  left:0;
  z-index:100;
  opacity:0;
  visibility:hidden;
  transform:translateY(25%);
  transition:all 300ms ease;
}

header .navigation-links li.has-sub-nav ul.sub-nav li {
  width:100%;
  padding:6px;
  list-style:none;
}

header .navigation-links li.has-sub-nav:hover ul.sub-nav, header .navigation-links li.has-sub-nav ul.sub-nav:hover {
  opacity:1;
  visibility:visible;
  transform:translateY(0);
}

header .navigation-links li.has-sub-nav ul.sub-nav li a:hover {
  color:var(--f7-theme-primary);
}

header .navigation-links li a {
  padding:8px 12px;
  font-size:1vw;
  font-weight:700;
  text-transform:uppercase;
  transition:all 250ms ease;
}

header .navigation-links li a:hover {
  opacity:0.8;
}

header .hamburger-menu-icon img {
  width:48px;
  height:intrinsic;
  height:auto;
  padding:6px;
}

header .social {
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-around;
  align-items:center;
}

header .social a {
  width:52px;
  padding:4px;
}

header .social a img {
  width:100%;
  height:auto;
  /**/
  -webkit-filter:grayscale(100%) brightness(2);
  filter:grayscale(100%) brightness(2);
  overflow:hidden;
  transition:all 300ms ease
}

header .social a:hover img {
  -webkit-filter:none;
  filter:none;
}

@media screen and (min-width:768px) and (max-width:1199px){
  header {
    padding:0 12px;
  }

  header nav {
    width:65%;
  }

  header .navigation-links a {
    font-size:1.75vw;
  }

  header .navigation-links li a {
    font-size:1.25vw;
  }
}

@media screen and (min-width:768px){
  header .more-menu {
    display:none;
  }

  header .hamburger-menu-icon {
    display:block;
    position:relative;
    z-index:1001;
  }

  header .primary-logo span {
    display:none;
  }

  header nav.primary {
    /*width:40%;*/
  }

  header nav.primary .icon {
    display:none;
  }

  header .social {
  }
}

@media screen and (max-width:767px){
  header {
    padding:0 12px;
    justify-content:flex-start;
    align-items:flex-start;
    position:fixed;
    bottom:0;
    left:0;
    z-index:100;
  }

  header .hamburger-menu-icon {
    display:none;
  }

  header .more-menu, header .primary-logo {
    display:inline-flex;
    position:relative;
    z-index:100;
  }

  header .social {
    display:none;
  }

  header .primary-logo {
    width:20%;
    margin:0 5% 0 0;
    padding:3px;
    font-size:9px;
    text-align:center;
    text-transform:uppercase;
    opacity:0.75;
    display:block;
  }

  header > .primary-logo img {
    width:48px;
    height:auto;
    margin:4px auto 0 auto;
    padding:0 8px;
    display:block;
  }

  header .primary-logo span {
    width:100%;
    display:block;
  }

  header nav.primary {
    width:75%;
    padding:0;
    display:flex;
    flex-wrap:nowrap;
    transform:none;
  }

  header nav.primary .navigation-links li {
    width:25%;
  }

  header nav.primary .navigation-links li:nth-child(n+4){
    display:none;
  }

  header nav.primary .navigation-links li.more-menu {
    display:block;
  }

  header nav.primary .navigation-links li a {
    width:100%;
    padding:3px;
    font-size:9px;
    text-align:center;
    opacity:0.75;
    display:block;
  }

  header nav.primary .navigation-links li a .icon {
    width:48px;
    margin:4px auto;
    padding:0 8px;
    display:block;
  }
}</style>

<script lang="ts">import { Link, f7, f7ready, View } from 'framework7-svelte';
import { onMount } from 'svelte';
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import logo from "./images/logo.png";
import { t } from "../../../../js/i18n";
import { getToken } from 'inversify-token';
import { windowMetrics as wm } from '../../../../js/stores.js';
import { YinzCamAppServer, YinzCamAppServerToken } from 'yinzcam-appserver';
import { JanusModeContextManagerToken } from "../../../../js/mode";
import ROOT from '../../../../inversify.config';
const appsrv = getToken(ROOT, YinzCamAppServerToken);
const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const langStore = contextManager.getLanguageComponent().store;
let teamId, teamName, teamShortName, teamLogoSuffix, teamsListData;
let teamStatsURL, firstTeam, teamQueryParam;
teamsListData = appsrv.getRequest({ path: 'Team/TeamsList' }).store;
$: languageValue = $langStore.toLowerCase();
$: if ($teamsListData) {
    var teams = $teamsListData.data.TeamsList.Section.Team;
    teams.forEach(function (value, index, array) {
        if (value._attributes.Id === teamId) {
            teamName = value._attributes.Name;
            teamShortName = value._attributes.TeamShortName;
            teamLogoSuffix = value._attributes.LogoId;
            teamId = value._attributes.Id;
        }
    });
}
contextManager.teamIdInput.store.subscribe(function (val) {
    teamId = val;
});
$: if (teamId) {
    teamQueryParam = `?teamId=${encodeURIComponent(teamId)}&titleText=${encodeURIComponent(teamName)}&teamTricode=${encodeURIComponent(teamLogoSuffix.replace('CSF_', ''))}`;
}
else {
    teamQueryParam = '';
}
var updateNavTopParentStyles = function () {
    var header = document.querySelectorAll('header');
    header.forEach(function (headerEl) {
        var cardElementParent = headerEl.parentNode.parentNode;
        var headerParent = headerEl.parentNode;
        var conflictingZIndex = false;
        headerParent.style.overflow = 'visible';
        for (var i = 0; i < 20; i++) {
            headerParent = headerParent.parentNode;
            if (headerParent) {
                if (headerParent.classList.contains('page-content')) {
                    return;
                }
                else {
                    //more general regions on interior pages
                    if (headerParent.classList.contains('yinzcam-cards-region')) {
                        conflictingZIndex = i + 1;
                    }
                    //homepage sections for navtop & upcoming games
                    if (headerParent.classList.contains('yinzcam-cards-section')) {
                        conflictingZIndex = i;
                    }
                    var styles = window.getComputedStyle(headerParent);
                    headerParent.style.overflow = 'visible';
                    headerParent.style.position = 'static';
                    if (i === conflictingZIndex) {
                        headerParent.style.position = 'relative';
                        headerParent.style.zIndex = '2';
                    }
                }
            }
        }
    });
};
onMount(() => {
    f7ready(() => {
        updateNavTopParentStyles();
        f7.on('routeChange', function () {
            window.setTimeout(function () { updateNavTopParentStyles(); }, 1000);
        });
    });
});
export let element;
</script>