<div class="waistband" style="flex-direction:{flowDirection};">
  {#each expandedElements as element, i}
    <div style="width: 100%;" class:display-none={!childContentAvailable[i]}>
      <Link class="waistband-tab" tabLink="#{element.data.targetTabID}" tabLinkActive={i === defaultTabIndex}>
        <CardsElement sequenceId={generateSequenceId(parentSequenceId, i)} {element} bind:contentAvailable={childContentAvailable[i]}/>
      </Link>
    </div>
  {/each}
</div>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.waistband {
  width: 100%;
  min-height: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

:global(.waistband-tab > div) {
  width: 100%;
}</style>

<script lang="ts">import { generateSequenceId } from "../utilities";
import { YinzCamCardsServiceArrayLayout, YinzCamCardsServiceElement } from "yinzcam-cards";
import { expandRepeats } from '../utilities';
import CardsElement from "./CardsElement.svelte";
import { Link, Dom7, Utils } from 'framework7-svelte';
let flowDirection = 'row';
let findDefaultTab = function (elementArray) {
    for (let i = 0; i < elementArray.length; i++) {
        if (elementArray[i].data.defaultTab) {
            return i;
        }
    }
    return 0;
};
export let layout;
let expandedElements;
let defaultTabIndex = 0;
let foundDefaultTab = false;
$: if (layout) {
    expandedElements = expandRepeats(layout.elements);
    if (layout.direction.toLowerCase() === 'vertical') {
        flowDirection = 'column';
    }
    else if (layout.direction.toLowerCase() === 'horizontal') {
        flowDirection = 'row';
    }
    if (!foundDefaultTab) {
        defaultTabIndex = findDefaultTab(expandedElements);
        foundDefaultTab = true;
    }
}
export let parentSequenceId = "";
let childContentAvailable = [];
</script>
