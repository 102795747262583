<CardBase {element}>
<div style="width:100%;display:block;overflow:hidden;" on:click={cardClick(this,gameId, state)}>
	<div class="feature-matchup" class:state-pregame={isPregame} class:state-live={isCurrent} class:state-final={isFinal}>
		<div class="date">
			{$tdate(gameTime, { year: 'numeric', month: 'short', day: 'numeric' })}
		</div>
		<!-- Home Team -->
		<div class="team team-2" on:click={teamClick.bind(this,homeTeam)}>
			<span class="logo"><TeamLogo teamObject={homeTeam} blankIfNil={true} /></span>
			<span class="team-name">{homeName || ''}</span>
			<div class="score">{(_.isNil(homeScore))? '' : homeScore}</div>
		</div>
		<div class="detail">
			<div class="game-start">{$ttime(gameTime, { hour: 'numeric', minute: 'numeric' })}</div>
			<div class="clock">{clock || ''}</div>
			<div class="state">{stateFmt || ''}</div>
			<div class="location">{venue || ''}</div>
			<div class="leg-type">{legType || ''}</div>
			<div class="btn-wrapper">
				{#each buttons as button (button.title) }
					<YCLink resolved={button.url}>{button.title || ''}</YCLink>
				{/each}
			</div>
		</div>
		<div class="team team-1" on:click={teamClick.bind(this,awayTeam)}>
			<span class="logo"><TeamLogo teamObject={awayTeam} blankIfNil={true} /></span>
			<span class="team-name">{awayName || ''}</span>
			<div class="score">{(_.isNil(awayScore))? '' : awayScore}</div>
		</div>
	</div>
</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.feature-matchup {
  width:100%;
  font-family:var(--f7-font-family);
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  overflow:hidden;
}

.feature-matchup .date {
  width:100%;
  padding:6px 12px;
  color:#FFF;
  font-weight:bold;
  text-transform:uppercase;
}

.feature-matchup .team {
  width:30%;
}

.feature-matchup .team .logo {
  width:95%;
  max-width:350px;
  height:auto;
  margin:0 auto;
  padding:6px 12px;
  display:block;
}

.feature-matchup .team .team-name {
  width:100%;
  padding:2px;
  color:#FFF;
  font-weight:500;
  font-size:1.25vw;
  display:none;
}

.feature-matchup .team .score {
  width:100%;
  padding:3px 12px;
  font-weight:bold;
  font-size:6.5vw;
  color:#FFF;
}

.feature-matchup .detail {
  width:40%;
}

.feature-matchup .detail > div {
  color:#FFF;
}

.feature-matchup .detail > div:empty {
  display:none;
}

.feature-matchup .game-start, .feature-matchup .state, .feature-matchup .clock {
  margin:12px 0;
  font-weight:500;
  font-size:4vw;
  text-transform:uppercase;
  display:none;
}

.feature-matchup .detail .location {
  margin:24px 0 0 0;
  color:#FFF;
}

.feature-matchup .btn-wrapper {
  width:100%;
  margin:8px 0 0 0;
  padding:12px;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
}

.feature-matchup .btn-wrapper:empty {
  display:none;
}

.feature-matchup .btn-wrapper :global(a) {
  width:45%;
  margin:12px auto 0 auto;
  padding:8px 16px;
  background-color:var(--f7-theme-color-bg-color);
  border:solid 0.5px var(--f7-theme-color-border-color);
  border-radius:8px;
  color:var(--f7-theme-color);
  font-weight:bold;
  font-size:1vw;
  text-decoration:none;
  text-transform:uppercase;
  /*display:none;*/
}

/*pregame state*/

.feature-matchup.state-pregame .pregame {
  display:block;
}

.feature-matchup.state-pregame .score {
  visibility:hidden;
}

.feature-matchup.state-pregame .game-start {
  display:block;
}

/*live game state*/

.feature-matchup.state-live .clock {
  display:block;
}

.feature-matchup.state-live .live {
  display:block;
}

/*final game state*/

.feature-matchup.state-final .state {
  display:block;
}

.feature-matchup.state-final .final {
  display:block;
}

.feature-matchup .detail .game-start:after, .feature-matchup .detail .clock:after, .feature-matchup .detail .state:after {
  width:100%;
  height:1px;
  margin:0 auto;
  background:linear-gradient(45deg, transparent,#fcd069,transparent);
  display:block;
  content:"";
}

@media screen and (min-width:1025px){
  .feature-matchup {
    padding:48px 150px;
  }

  .feature-matchup .date {
    font-size:1.25vw;
    font-weight:normal;
    display:block;
  }

  .feature-matchup .detail .game-start:after, .feature-matchup .detail .clock:after, .feature-matchup .detail .state:after {
    display:none;
  }

  .feature-matchup .team .team-name {
    display:block;
  }

  .feature-matchup .team .logo {
    width:75%;
  }

  .feature-matchup .btn-wrapper {
    margin-top:32px;
  }

  .feature-matchup .btn-wrapper :global(a) {
    padding:12px 8px;
    background-color:#FFF;
    border:0;
    color:#000;
    transition:all 250ms ease;
  }

  .feature-matchup .btn-wrapper :global(a):hover {
    transform:translateY(-3px);
  }

  .feature-matchup .game-start, .feature-matchup .state, .feature-matchup .clock {
    line-height:1;
    font-weight:bold;
  }

  .feature-matchup .matchup, .feature-matchup .location {
    font-size:1.75vw;
    font-weight:bold;
  }

  .feature-matchup .matchup, .feature-matchup .leg-type {
    font-size:1.15vw;
  }
}

@media screen and (min-width:1400px){
  .feature-matchup {
    padding:48px 210px;
  }
}

@media screen and (min-width:1625px){
  .feature-matchup {
    padding:48px 245px;
  }

  .feature-matchup .team {
    max-width:300px;
  }
}

@media screen and (max-width:1000px){
  .feature-matchup .team .score {
    font-size:5vw;
  }

  .feature-matchup .date, .feature-matchup .game-start, .feature-matchup .state, .feature-matchup .clock, .feature-matchup .btn-wrapper :global(a) {
    font-size:2vw;
  }
}

@media screen and (max-width:767px){
  .feature-matchup .team .score {
    font-size:7vw;
  }

  .feature-matchup .matchup, .feature-matchup .location, .feature-matchup .matchup, .feature-matchup .leg-type {
    font-size:2vw;
  }

  .feature-matchup .date, .feature-matchup .team .team-name {
    font-size:3vw;
  }

  .feature-matchup .game-start, .feature-matchup .state, .feature-matchup .clock {
    font-size:5vw;
  }

  .feature-matchup .btn-wrapper :global(a) {
    width:100%;
    padding:8px;
    font-size:14px;
  }
}

@media screen and (max-width:500px){
  .feature-matchup .team .score {
    font-size:11vw;
  }

  .feature-matchup .matchup, .feature-matchup .location, .feature-matchup .matchup, .feature-matchup .leg-type {
    margin:2px 0;
    font-size:3vw;
  }

  .feature-matchup .date {
    font-size:3vw;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import { f7, f7ready, f7Router } from 'framework7-svelte';
import { windowMetrics as wm } from '../../../../js/stores.js';
import { findSourceStore, getHrefForMatchCenter } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { resolveUrl } from "../../../../js/url";
import YCLink from "../../utilities/YCLink.svelte";
import { t, tdate, ttime } from "../../../../js/i18n";
import _ from "lodash";
export let element;
export let sources;
export let sourceStore;
export let contentActive = false;
let isNative = (Device.cordova) ? true : false;
$: isDesktop = $wm.width > 1024;
let cardClick = function (element, gameId, state) {
    if (!isDesktop) {
        var titleString = 'Detalles Del Partido';
        var listName;
        if (!gameId) {
            return;
        }
        if (state && state.toUpperCase() === 'P') {
            listName = 'MatchPreview';
        }
        else {
            listName = 'MatchCenter';
        }
        f7.views.main.router.navigate({ name: 'cards', query: { gameId: gameId, titleText: titleString }, params: { listName: listName } });
    }
    else {
        return false;
    }
};
let teamClick = function (team, element) {
    if (!isDesktop) {
        return false;
    }
    else {
        if (team) {
            f7.views.main.router.navigate({ name: 'cards', query: { teamId: team._attributes.Id, titleText: team._attributes.Name, teamTricode: team._attributes.TriCode }, params: { listName: 'Team' } });
        }
    }
};
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGameScores');
}
;
$: data = (sourceStore && $sourceStore) ? $sourceStore : null;
$: gameId = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: isDefault = String((_c = (_b = data === null || data === void 0 ? void 0 : data.CardData) === null || _b === void 0 ? void 0 : _b.IsDefault) === null || _c === void 0 ? void 0 : _c._text).toLowerCase() === 'true';
$: timestamp = (_d = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _d === void 0 ? void 0 : _d._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_e = data === null || data === void 0 ? void 0 : data.Venue) === null || _e === void 0 ? void 0 : _e._text;
$: legType = (_f = data === null || data === void 0 ? void 0 : data.LegType) === null || _f === void 0 ? void 0 : _f._text;
$: buttons = [].concat((_g = data === null || data === void 0 ? void 0 : data.Buttons) === null || _g === void 0 ? void 0 : _g.Button).map(i => {
    var _a, _b, _c;
    return {
        title: (_a = i === null || i === void 0 ? void 0 : i.Title) === null || _a === void 0 ? void 0 : _a._text,
        url: resolveUrl((_b = i === null || i === void 0 ? void 0 : i.URL) === null || _b === void 0 ? void 0 : _b._text),
        imageUrl: (_c = i === null || i === void 0 ? void 0 : i.ImageURL) === null || _c === void 0 ? void 0 : _c._text
    };
}).filter(i => i === null || i === void 0 ? void 0 : i.title);
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeName = (_h = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _h === void 0 ? void 0 : _h.Name;
$: homeScore = (_k = (_j = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.Score;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayName = (_l = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _l === void 0 ? void 0 : _l.Name;
$: awayScore = (_o = (_m = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _m === void 0 ? void 0 : _m._attributes) === null || _o === void 0 ? void 0 : _o.Score;
$: state = (_p = data === null || data === void 0 ? void 0 : data.State) === null || _p === void 0 ? void 0 : _p._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_q = data === null || data === void 0 ? void 0 : data.GameState) === null || _q === void 0 ? void 0 : _q._text;
$: clock = (_r = data === null || data === void 0 ? void 0 : data.Clock) === null || _r === void 0 ? void 0 : _r._text;
$: {
    contentActive = isDefault;
}
</script>
