import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";

@injectable()
export class YinzCamAppServerGameScoresRoundFilterOptions extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    let data = response?.data as any;
    let filter = [].concat(data?.Scores?.Filters?.Filter).find(e => e?._attributes?.QueryParameter === 'round');
    let options = [].concat(filter?.FilterItems?.Items).filter(item => item?._attributes?.Id).map(item => {
      let attrs = item?._attributes;
      return {
        title: attrs?.Name,
        value: attrs?.Id
      };
    });
    return { options };
  }
}
