<script lang="ts">import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore } from '../../../utilities';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { Readable } from 'svelte/store';
import SingleStatComparison from '../SingleStatComparison/SingleStatComparison.svelte';
import PossessionComparison from '../PossessionComparison/PossessionComparison.svelte';
export let element;
export let sources;
export let sourceStore;
let data = {};
let Type = '';
$: sourceStore = getFirstSourceStore($sources);
$: {
    data = $sourceStore;
    Type = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.Type;
}
</script>

<CardBase {element}>
  <div class="match-stats">
    {#if Type === 'Field'}
      <PossessionComparison {data} />
    {:else if Type === 'SemiCircle'}
      <SingleStatComparison {data} />
    {:else if Type === 'FullBar'}
      <SingleStatComparison {data} />
    {/if}
  </div>
</CardBase>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.match-stats {
  width: 367px;
  height: 227px !important;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .match-stats {
    width: 226px;
    height: 140px !important;
  }
}</style>
