<YCPage title="Not Found" query={pageQuery}>
  <GoogleAnalyticsUA properties={[]} path={f7route.path} params={f7route.query} />
  <Block strong>
    <p>Sorry</p>
    <p>Requested content not found.</p>
  </Block>
</YCPage>

<script type="ts">import YCPage from '../components/YCPage.svelte';
import { Block } from 'framework7-svelte';
import { GoogleAnalyticsUA } from 'svelte-google-analytics/src';
export let f7route;
const pageQuery = (f7route === null || f7route === void 0 ? void 0 : f7route.query) || {};
</script>
