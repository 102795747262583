<CardBase {element}>
  <div class="viewer-hero" style="background-image:url({imageUrl});">
    <div class="detail">
      <div class="title">{headline}</div>
      <div>
        <span class="author">{(author)? author: ''}</span>
        <span class="publish-date">{timestampFmt}</span>
      </div>
    </div>
  </div>
  <div>
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.viewer-hero {
  width:100%;
  padding:100% 0 0 0;
  position:relative;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center;
}

.viewer-hero:after {
  width:100%;
  height:100%;
  background:linear-gradient(transparent,#000);
  position:absolute;
  top:0;
  left:0;
  content:"";
}

.viewer-hero .detail {
  width:100%;
  padding:12px;
  color:#FFF;
  line-height:1;
  position:absolute;
  bottom:0;
  left:0;
  z-index:2;
}

.viewer-hero .title {
  width:100%;
  margin:0 0 6px 0;
  font-size:8vw;
}

.viewer-hero .author, .viewer-hero .publish-date {
  width:100%;
  font-size:4vw;
  font-weight:bold;
  opacity:0.8;
}

@media screen and (min-width:1025px){
  .viewer-hero {
    padding:56.6% 0 0 0;
  }

  .viewer-hero .title {
    font-size:3vw;
  }

  .viewer-hero .author, .viewer-hero .publish-date {
    font-size:2vw;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d;
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaNews');
$: data = (source) ? $source : {};
$: timestamp = (_a = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _a === void 0 ? void 0 : _a._text;
$: timestampFmt = (timestamp) ? new Date(timestamp).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric' }) : '';
$: imageUrl = (_b = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _b === void 0 ? void 0 : _b._text;
$: author = ((_c = data === null || data === void 0 ? void 0 : data.Author) === null || _c === void 0 ? void 0 : _c._text) || "";
$: headline = ((_d = data === null || data === void 0 ? void 0 : data.Headline) === null || _d === void 0 ? void 0 : _d._text) || "";
</script>
