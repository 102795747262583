<div class="custom-select-wrapper" bind:this={selectWrapper} on:click={wrapperClicked}>
  <div class="custom-select" class:open={opened}>
    <div class="custom-select__trigger">
      <div class="custom-select-label-title-wrapper">
        {#if label}
        <div class="custom-select-label">{label}</div>
        {/if}
        <div class="custom-select-selected-title">{selectedTitle}</div>    
      </div>
      <div class="custom-select-arrow">
        <img src={(opened)? ic_chevron_up : ic_chevron_down} alt="">
      </div>
    </div>
    <div class="custom-options" class:open={opened} bind:this={optionsWrapper} use:portal={scrollContainer}>
      {#each items as item (item.value) }
      <div class="custom-option" class:selected={selectedValue === item.value} on:click={optionClicked.bind(null, item.value)}>
        <div class="custom-option-title">{item.title}</div>
        <div class="custom-option-checkmark"><img src={ic_check} alt=""></div>
      </div>
      {/each}
    </div>
  </div>
</div>

<svelte:window on:click={windowClicked}/>

<style>*, *:after, *:before {
  box-sizing: border-box;
}

.custom-select-wrapper {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100%;
}

.custom-select {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: var(--yc-custom-select-font-family, inherit);
  border-width: 0 var(--yc-custom-select-selection-border-width, 0px) 0 var(--yc-custom-select-selection-border-width, 0px);
  border-style: var(--yc-custom-select-selection-border-style, solid);
  border-color: var(--yc-custom-select-selection-border-color, #000000);
}

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--yc-custom-select-selection-padding, 0 16px);
  background: var(--yc-custom-select-selection-background-color, #ffffff);
  color: var(--yc-custom-select-selection-text-color, #000000);
  height: var(--yc-custom-select-height, 56px);
  cursor: pointer;
  border-width: var(--yc-custom-select-selection-border-width, 0px) 0 var(--yc-custom-select-selection-border-width, 0px) 0;
  border-style: var(--yc-custom-select-selection-border-style, solid);
  border-color: var(--yc-custom-select-selection-border-color, #000000);
}

.custom-select-label-title-wrapper {
  height: var(--yc-custom-select-height, 56px);
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.custom-select-label {
  font-family: var(--yc-custom-select-label-font-family, inherit);
  font-size: var(--yc-custom-select-label-font-size, 10px);
  font-weight: var(--yc-custom-select-label-font-weight, 800);
  letter-spacing: var(--yc-custom-select-label-letter-spacing, 0.6);
  text-transform: var(--yc-custom-select-label-text-transform, uppercase);
  color: var(--yc-custom-select-label-color, #848f9a);
}

.custom-select-selected-title {
  font-family: var(--yc-custom-select-selected-title-font-family, inherit);
  font-size: var(--yc-custom-select-selected-title-font-size, 10px);
  font-weight: var(--yc-custom-select-selected-title-font-weight, 300);
  letter-spacing: var(--yc-custom-select-selected-title-letter-spacing, inherit);
  color: var(--yc-custom-select-selected-title-color, inherit);
}

.custom-select-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-options {
  position: absolute;
  top: 100%;
  left: 0;
  border-width: var(--yc-custom-select-options-border-width, 0px);
  border-style: var(--yc-custom-select-options-border-style, solid);
  border-color: var(--yc-custom-select-options-border-color, #000000);
  border-top: 0;
  background-color: var(--yc-custom-select-options-background-color, #ffffff);
  transition: var(--yc-custom-select-options-transition, initial);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: var(--yc-custom-select-options-z-index, 2);
  box-shadow: var(--yc-custom-select-options-box-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19));
}

.custom-options.open  {
  opacity: var(--yc-custom-select-options-opacity, 1);
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  padding: var(--yc-custom-select-options-padding, 0 16px);
  font-size: var(--yc-custom-select-options-font-size, 16px);
  font-weight: var(--yc-custom-select-options-font-weight, 300);
  color: var(--yc-custom-select-options-text-color, #3b3b3b);
  line-height: var(--yc-custom-select-height, 56px);
  cursor: pointer;
  transition: var(--yc-custom-select-options-transition, initial);
}

.custom-option:hover {
  cursor: pointer;
  color: var(--yc-custom-select-options-hover-text-color, var(--yc-custom-select-options-text-color, initial));
  background-color: var(--yc-custom-select-options-hover-background-color, var(--yc-custom-select-options-background-color, #f5f5f5));
}

.custom-option.selected {
  /*
    color: var(--yc-custom-select-options-selected-text-color, var(--yc-custom-select-options-text-color, initial));
    background-color: var(--yc-custom-select-options-selected-background-color, var(--yc-custom-select-options-background-color, initial));
    */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-option-checkmark {
  display: none;
}

.custom-option.selected .custom-option-checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
}</style>

<script lang="ts">var _a;
import { afterUpdate, createEventDispatcher, onMount } from "svelte";
import { portal } from "svelte-portal/src/Portal.svelte";
import ic_chevron_down from "../static/icons/ic_chevron-down.svg";
import ic_chevron_up from "../static/icons/ic_chevron-up.svg";
import ic_check from "../static/icons/ic-check.svg";
export let scrollContainer = document.body;
export let items;
export let selectedValue;
export let label;
let opened = false;
let selectWrapper, optionsWrapper;
$: selectedTitle = ((_a = items === null || items === void 0 ? void 0 : items.find(item => item.value === selectedValue)) === null || _a === void 0 ? void 0 : _a.title) || '';
// https://javascript.info/coordinates
function positionOptionsWrapper(...args) {
    if (!scrollContainer || !selectWrapper) {
        return;
    }
    let scrollRect = scrollContainer.getBoundingClientRect();
    let selectRect = selectWrapper.getBoundingClientRect();
    //console.log('CUSTOMSELECT scrollRect: ', scrollRect);
    //console.log('CUSTOMSELECT selectRect: ', selectRect);
    optionsWrapper.style.left = (selectRect.left - scrollRect.left + scrollContainer.scrollLeft) + "px";
    optionsWrapper.style.top = (selectRect.bottom - scrollRect.top + scrollContainer.scrollTop + 2) + "px";
    optionsWrapper.style.width = (selectWrapper.offsetWidth) + "px";
}
const obs = new ResizeObserver(() => {
    positionOptionsWrapper();
});
const dispatch = createEventDispatcher();
function wrapperClicked() {
    opened = !opened;
}
function optionClicked(optionValue) {
    dispatch('selected', optionValue);
    opened = false;
}
function windowClicked(e) {
    if (opened && !selectWrapper.contains(e.target) && !optionsWrapper.contains(e.target)) {
        opened = false;
    }
}
onMount(() => {
    positionOptionsWrapper();
    obs.observe(selectWrapper);
    return () => {
        obs.disconnect();
    };
});
afterUpdate(() => {
    // in case the scrollContainer changes
    positionOptionsWrapper();
});
</script>
