<CardBase {element}>
<div class="recent-match">
	<div class="match-detail">
		<span class="competition-name">LaLiga Santander</span>
		<span class="date">8 Nov 2020</span>
	</div>
	<div class="matchup">
		<div class="team team-1">
			<div class="logo">
				<span><TeamLogo teamObject={homeTeam} /></span>
			</div>
			<div class="score">2</div>
		</div>
		<div class="separator">-</div>
		<div class="team team-2">
			<div class="logo">
				<span><TeamLogo teamObject={awayTeam} /></span>
			</div>
			<div class="score">1</div>
		</div>
	</div>
</div>
</CardBase>

<style type="text/css">*, *:before, *:after {
  box-sizing:border-box;
}

.recent-match {
  width:100%;
  padding:20px 12px;
  background:#f5f5f5;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-evenly;
  align-items:center;
}

.match-detail {
  width:50%;
  padding:0 6px;
  display:block;
}

.competition-name, .date {
  width:100%;
  margin:3px 0;
  color:var(--neutrals-grey-darker-20);
  font-size:12px;
  display:block;
}

.competition-name {
  font-weight:500;
}

.date {
  font-weight:400;
}

.matchup {
  width:50%;
  padding:0 6px;
  display:flex;
  align-items:center;
}

.team {
  width:50%;
  font-size:20px;
  display:flex;
  align-items:center;
}

.separator {
  margin:0 2px;
  font-size:24px;
  font-weight:bold;
}

.team-2 {
  flex-direction:row-reverse;
}

.logo {
  width:64px;
  height:auto;
  margin:0 6px;
  display:block;
}

.logo span {
  width:100%;
  height:auto;
  display:block;
}

.score {
  color:var(--neutrals-grey-darker-40);
  font-weight:bold;
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import { f7, f7ready, f7Router } from 'framework7-svelte';
import { windowMetrics as wm } from "../../../../../js/stores.js";
import { findSourceStore, getHrefForMatchCenter } from "../../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import TeamLogo from "../../../utilities/TeamLogo.svelte";
import { resolveUrl } from "../../../../../js/url";
import YCLink from "../../../utilities/YCLink.svelte";
import { t, tdate, ttime } from "../../../../../js/i18n";
import _ from "lodash";
export let element;
export let sources;
export let sourceStore;
export let contentActive = false;
// game [object]: past game node, teamContext [string]: team designation ("home" or "away")
let pastGameOutcome = function (game, teamContext) {
    if (!game) {
        return '';
    }
    teamContext = teamContext.charAt(0).toUpperCase() + teamContext.slice(1).toLowerCase();
    var opposingTeamContext = (teamContext === 'Home') ? 'Away' : 'Home';
    if (game[teamContext + 'Score'] > game[opposingTeamContext + 'Score']) {
        return 'G';
    }
    else if (game[teamContext + 'Score'] < game[opposingTeamContext + 'Score']) {
        return 'P';
    }
    else if (game[teamContext + 'Score'] === game[opposingTeamContext + 'Score']) {
        return 'E';
    }
    else {
        return '';
    }
};
let isNative = (Device.cordova) ? true : false;
$: isDesktop = $wm.width > 1024;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGameBox');
}
;
$: data = (sourceStore && $sourceStore) ? $sourceStore : null;
$: if (data) {
    console.log('recentMatches data', data);
}
$: gameId = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: isDefault = String((_c = (_b = data === null || data === void 0 ? void 0 : data.CardData) === null || _b === void 0 ? void 0 : _b.IsDefault) === null || _c === void 0 ? void 0 : _c._text).toLowerCase() === 'true';
$: timestamp = (_d = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _d === void 0 ? void 0 : _d._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_e = data === null || data === void 0 ? void 0 : data.Venue) === null || _e === void 0 ? void 0 : _e._text;
$: competitionLogo = (_g = (_f = data === null || data === void 0 ? void 0 : data.Competition) === null || _f === void 0 ? void 0 : _f.LogoUrlDark) === null || _g === void 0 ? void 0 : _g._text;
$: legType = (_h = data === null || data === void 0 ? void 0 : data.LegType) === null || _h === void 0 ? void 0 : _h._text;
$: buttons = [].concat((_j = data === null || data === void 0 ? void 0 : data.Buttons) === null || _j === void 0 ? void 0 : _j.Button).map(i => {
    var _a, _b, _c;
    return {
        title: (_a = i === null || i === void 0 ? void 0 : i.Title) === null || _a === void 0 ? void 0 : _a._text,
        url: resolveUrl((_b = i === null || i === void 0 ? void 0 : i.URL) === null || _b === void 0 ? void 0 : _b._text),
        imageUrl: (_c = i === null || i === void 0 ? void 0 : i.ImageURL) === null || _c === void 0 ? void 0 : _c._text
    };
}).filter(i => i === null || i === void 0 ? void 0 : i.title);
$: recentGames = [].concat((_k = data === null || data === void 0 ? void 0 : data.RecentGames) === null || _k === void 0 ? void 0 : _k.RecentGame).map(i => {
    var _a, _b, _c;
    if (!i) {
        return false;
    }
    return {
        id: (_a = i === null || i === void 0 ? void 0 : i._attributes) === null || _a === void 0 ? void 0 : _a.Id,
        homeOutcome: pastGameOutcome(i === null || i === void 0 ? void 0 : i._attributes, 'home'),
        homeScore: (_b = i === null || i === void 0 ? void 0 : i._attributes) === null || _b === void 0 ? void 0 : _b.HomeScore,
        awayOutcome: pastGameOutcome(i === null || i === void 0 ? void 0 : i._attributes, 'away'),
        awayScore: (_c = i === null || i === void 0 ? void 0 : i._attributes) === null || _c === void 0 ? void 0 : _c.AwayScore
    };
});
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeName = (_l = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _l === void 0 ? void 0 : _l.Name;
$: homeScore = (_o = (_m = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _m === void 0 ? void 0 : _m._attributes) === null || _o === void 0 ? void 0 : _o.Score;
$: homeScoringPlays = [].concat((_q = (_p = data === null || data === void 0 ? void 0 : data.Scoring) === null || _p === void 0 ? void 0 : _p.ScoringPeriod) === null || _q === void 0 ? void 0 : _q.ScoringPlay).map(i => {
    var _a, _b, _c, _d;
    return {
        teamTricode: (_a = i === null || i === void 0 ? void 0 : i._attributes) === null || _a === void 0 ? void 0 : _a.Team,
        time: (_b = i === null || i === void 0 ? void 0 : i._attributes) === null || _b === void 0 ? void 0 : _b.Time,
        player: (_c = i === null || i === void 0 ? void 0 : i.Description) === null || _c === void 0 ? void 0 : _c._text,
        isHome: (_d = i === null || i === void 0 ? void 0 : i._attributes) === null || _d === void 0 ? void 0 : _d.IsHome
    };
}).filter(i => (i === null || i === void 0 ? void 0 : i.isHome) === 'true');
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayName = (_r = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _r === void 0 ? void 0 : _r.Name;
$: awayScore = (_t = (_s = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _s === void 0 ? void 0 : _s._attributes) === null || _t === void 0 ? void 0 : _t.Score;
$: awayScoringPlays = [].concat((_v = (_u = data === null || data === void 0 ? void 0 : data.Scoring) === null || _u === void 0 ? void 0 : _u.ScoringPeriod) === null || _v === void 0 ? void 0 : _v.ScoringPlay).map(i => {
    var _a, _b, _c, _d;
    return {
        teamTricode: (_a = i === null || i === void 0 ? void 0 : i._attributes) === null || _a === void 0 ? void 0 : _a.Team,
        time: (_b = i === null || i === void 0 ? void 0 : i._attributes) === null || _b === void 0 ? void 0 : _b.Time,
        player: (_c = i === null || i === void 0 ? void 0 : i.Description) === null || _c === void 0 ? void 0 : _c._text,
        isHome: (_d = i === null || i === void 0 ? void 0 : i._attributes) === null || _d === void 0 ? void 0 : _d.IsHome
    };
}).filter(i => (i === null || i === void 0 ? void 0 : i.isHome) === 'false');
$: state = (_w = data === null || data === void 0 ? void 0 : data.State) === null || _w === void 0 ? void 0 : _w._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_x = data === null || data === void 0 ? void 0 : data.GameState) === null || _x === void 0 ? void 0 : _x._text;
$: clock = (_y = data === null || data === void 0 ? void 0 : data.Clock) === null || _y === void 0 ? void 0 : _y._text;
$: round = (_z = data === null || data === void 0 ? void 0 : data.Round) === null || _z === void 0 ? void 0 : _z._text;
$: {
    contentActive = isDefault;
}
</script>