<CardBase {element}>
	{#if hasFirstLeg && !isPregame}
	<h2>{$t('Partidos anteriores')}</h2>
	<div class="game-leg-card" class:state-pregame={isPregame} class:state-live={isCurrent} class:state-final={isFinal}>
		<a href={getHrefForMatchCenter(gameId, state)}>
			<span class="logo"><TeamLogo teamObject={homeTeam} /></span>
			<span class="logo"><TeamLogo teamObject={awayTeam} /></span>
			<div class="detail winning-team-{winningTeamClass}">
				<div class="team home team-1">
					<div class="score">{homeScore}</div>
				</div>
				<div class="team away team-2">
					<div class="score">{awayScore}</div>
				</div>

				<div class="leg-type">{legType}</div>
				<div class="game-start">{$tdate(gameTime, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</div>
				<div class="location">{venue}</div>
			</div>
		</a>
	</div>
	{/if}
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.game-leg-card {
  width:100%;
  padding:24px 12px 12px 12px;
  font-family:var(--f7-font-family);
}

h2 {
  margin:6px 0 12px 0;
  color:var(--f7-theme-color);
  text-align:center;
  text-transform:uppercase;
}

.game-leg-card a {
  width:100%;
  padding:0 6px 6px 6px;
  /*background-color:#000;*/
  font-family:var(--f7-font-family);
  border:solid 1px var(--f7-theme-primary);
  border-radius:12px;
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  overflow:visible;
}

.game-leg-card .logo {
  width:48px;
  height:auto;
  margin:0 auto;
  /*padding:12px;*/
  display:block;
  transform:translateY(-24px);
}

.game-leg-card .detail {
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.game-leg-card .detail > div {
  color:#FFF;
}

.game-leg-card .detail > div:empty {
  display:none;
}

.game-leg-card .team {
  width:50%;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.game-leg-card .score {
  width:30px;
  height:30px;
  margin:0 0 12px 0;
  padding:6px;
  border-radius:50%;
  color:#FFF;
  font-weight:bold;
  font-size:18px;
  line-height:1;
}

.game-leg-card .game-start, .game-leg-card .location, .game-leg-card .leg-type {
  width:100%;
  font-size:13px;
}

.game-leg-card .game-start, .game-leg-card .leg-type {
  margin:3px 0;
  text-transform:uppercase;
}

.winning-team-home .home .score {
  background: rgb(0, 211, 0);
}

.winning-team-away .away .score {
  background: rgb(0, 211, 0);
}

.winning-team-tie .team .score {
  background:rgb(127, 127, 127);
}

.game-leg-card .detail .location {
  color:rgba(255,255,255,0.75);
}

@media screen and (max-width:1000px){
}

@media screen and (max-width:767px){
}

@media screen and (max-width:500px){
  h2 {
    font-size:4vw;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { getToken } from 'inversify-token';
import { findSourceStore, getHrefForMatchCenter } from "../../utilities";
import { Readable } from "svelte/store";
import ROOT from '../../../../inversify.config';
import { YinzCamAppServer, YinzCamAppServerToken } from 'yinzcam-appserver';
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { tdate, ttime, t } from "../../../../js/i18n";
export let element;
export let sources;
const appsrv = getToken(ROOT, YinzCamAppServerToken);
let gameLegData, legBoxScore;
let hasFirstLeg = false;
let winningTeamClass = '';
$: source = findSourceStore($sources, 'YinzCamAppServerGameBox');
$: data = (source && $source) ? $source : null;
$: if (data) {
    // console.log('data',data);
    if (data === null || data === void 0 ? void 0 : data.FirstLegId) {
        hasFirstLeg = true;
        gameLegData = appsrv.getRequest({ path: 'Game/Box/' + data.FirstLegId._text }).store;
    }
}
$: if ($gameLegData) {
    legBoxScore = $gameLegData.data.Boxscore;
    console.log('has first leg', legBoxScore);
}
$: gameId = (_a = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: timestamp = (_b = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.Timestamp) === null || _b === void 0 ? void 0 : _b._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_c = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.Venue) === null || _c === void 0 ? void 0 : _c._text;
$: homeTeam = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.HomeTeam;
$: homeName = (_d = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _d === void 0 ? void 0 : _d.Name;
$: homeScore = (_f = (_e = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.HomeTeam) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.Score;
$: homeWLD = [].concat((_g = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam.Last) === null || _g === void 0 ? void 0 : _g.Result).filter(r => { var _a; return (_a = r === null || r === void 0 ? void 0 : r._attributes) === null || _a === void 0 ? void 0 : _a.Value; }).slice(0, 3).map(r => {
    var _a, _b;
    return {
        value: (_a = r === null || r === void 0 ? void 0 : r._attributes) === null || _a === void 0 ? void 0 : _a.Value,
        text: ((_b = r === null || r === void 0 ? void 0 : r._attributes) === null || _b === void 0 ? void 0 : _b.Text) || ''
    };
});
$: awayTeam = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.AwayTeam;
$: awayName = (_h = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _h === void 0 ? void 0 : _h.Name;
$: awayScore = (_k = (_j = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.AwayTeam) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.Score;
$: awayWLD = [].concat((_l = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam.Last) === null || _l === void 0 ? void 0 : _l.Result).filter(r => { var _a; return (_a = r === null || r === void 0 ? void 0 : r._attributes) === null || _a === void 0 ? void 0 : _a.Value; }).slice(0, 3).map(r => {
    var _a, _b;
    return {
        value: (_a = r === null || r === void 0 ? void 0 : r._attributes) === null || _a === void 0 ? void 0 : _a.Value,
        text: ((_b = r === null || r === void 0 ? void 0 : r._attributes) === null || _b === void 0 ? void 0 : _b.Text) || ''
    };
});
$: winningTeam = (awayScore >= homeScore) ? awayTeam : homeTeam;
$: state = (_m = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.State) === null || _m === void 0 ? void 0 : _m._text;
$: legType = (_o = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.LegType) === null || _o === void 0 ? void 0 : _o._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_p = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.GameState) === null || _p === void 0 ? void 0 : _p._text;
$: clock = (_q = legBoxScore === null || legBoxScore === void 0 ? void 0 : legBoxScore.Clock) === null || _q === void 0 ? void 0 : _q._text;
$: if (awayScore > homeScore) {
    winningTeamClass = 'away';
}
else if (awayScore < homeScore) {
    winningTeamClass = 'home';
}
else {
    winningTeamClass = 'tie';
}
</script>