<Boundary>
  <div class="yinzcam-cards-column {pageContext.discoModeClass}" style="
    {buildCssPadding(column.padding)}
    width: {width};
    flex-grow: {flexGrow};
    flex-shrink: {flexShrink};
  ">
    <CardsArrays parentSequenceId={sequenceId} arrays={column.content} />
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.yinzcam-cards-column.disco-mode-enabled {
  padding: 2px;
  border: 2px solid lime;
}</style>

<script lang="ts">import { YinzCamCardsServiceColumn } from "yinzcam-cards";
import { buildCssPadding } from "../utilities/index";
import CardsArrays from "./CardsArrays.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import _ from "lodash";
import { getContext } from 'svelte';
import { CardsPageContext, CardsPageContextKey } from './context';
export let sequenceId = "";
export let column;
const pageContext = getContext(CardsPageContextKey);
$: hasWidth = _.isNumber(column === null || column === void 0 ? void 0 : column.width) && column.width > 0 && column.width <= 100;
$: width = (hasWidth) ? `${column.width}%` : '100%';
$: flexGrow = (hasWidth) ? ((column === null || column === void 0 ? void 0 : column.grow) ? '1' : '0') : '1';
$: flexShrink = (hasWidth) ? ((column === null || column === void 0 ? void 0 : column.shrink) ? '1' : '0') : '1';
</script>
