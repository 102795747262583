<Boundary>
  <div style="width: 100%;">
    <CardsElement sequenceId={generateSequenceId(parentSequenceId, 0)} element={layout.elements[0]} />
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceArrayLayout } from "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
export let parentSequenceId = "";
export let layout;
</script>
