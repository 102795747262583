<CardBase {element}>
<div class="section-headline {element.presentation}" class:with-button={element.data && element.data.links}>
	{#if heading}
	<h2 class="headline" style="position={headerPosition};width={headerWidth};font-size: {fontSize};">{heading}</h2>
	{/if}
	{#if subHeading}
	<h2 class="subheadline">{subHeading}</h2>
	{/if}
	<span class="headline-space"></span>
	{#if element.data && element.data.links}
	{#each element.data.links as link}
	<a class="headline-link button" href={link.href}>{link.text}</a>
	{/each}
	{/if}
</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.section-headline {
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:flex-end;
}

.section-headline .headline {
  margin:12px 0;
  padding:0;
  line-height:1;
}

.section-headline .subheadline {
  font-weight:normal;
}

.section-headline .headline-space {
  flex: 1 0;
}

.section-headline.center .headline, .section-headline.center .subheadline {
  width:100%;
  text-align:center;
}

.section-headline .headline-link {
  text-decoration:none;
  line-height:1;
}

.section-headline .headline-link.button {
  padding:8px 24px;
  text-align:center;
}

@media screen and (min-width:1025px){
  .section-headline .headline {
    font-size: 40px;
  }

  .section-headline .subheadline {
    font-size:1.15em;
  }

  .section-headline .headline-link {
    font-size:1em;
  }

  .section-headline .headline-link {
    font-weight:bold;
  }
}

@media screen and (min-width:768px) and (max-width:1024px){
  .section-headline .headline {
    font-size: 40px;
  }

  .section-headline .subheadline {
    font-size:2.2em;
  }

  .section-headline .headline-link {
    font-size:1.5em;
  }
}

@media screen and (max-width:767px){
  .section-headline .headline {
    font-size: 24px;
  }

  .section-headline .headline-link {
    font-size:1.75em;
  }
}

@media screen and (max-width:500px){
  .section-headline .headline {
    font-size: 24px;
  }

  .section-headline .subheadline {
    font-size: 1.5em;
  }

  .section-headline .headline-link.button {
    padding:8px;
    font-size: 1.25em;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { t } from "../../../../js/i18n";
import { getFirstSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { getContext, onMount } from "svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../common/context";
export let element;
export let sources;
const tabContext = getContext(CardsTabContextKey);
let heading, subHeading;
let headerPosition, headerWidth;
let fontSize = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.fontSize) || '1.25rem';
$: source = getFirstSourceStore($sources);
$: mergedParams = (_b = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _b === void 0 ? void 0 : _b.store;
$: if ($source) {
    heading = ($source === null || $source === void 0 ? void 0 : $source.heading) || false;
    subHeading = ($source === null || $source === void 0 ? void 0 : $source.subHeading) || false;
    headerPosition = ($source === null || $source === void 0 ? void 0 : $source.headerPosition) || false;
    headerWidth = ($source === null || $source === void 0 ? void 0 : $source.headerWidth) || false;
}
else if (!heading || !subHeading) {
    heading = ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.title) || false;
    subHeading = ((_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.subTitle) || false;
    headerPosition = ((_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.position) || false;
    headerWidth = ((_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.width) || false;
}
$: if (($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.titleText) && heading === false) {
    heading = $mergedParams.titleText;
}
</script>