<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h;
import { Button } from "framework7-svelte";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { findSourceStore } from "../../../utilities";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
import { t } from "../../../../../js/i18n";
import { Readable } from "svelte/store";
import { afterUpdate, getContext, onMount } from "svelte";
import CustomSelect from "../../../../CustomSelect.svelte";
import _ from 'lodash';
import MyLineGraph from './MyLineGraph.svelte';
export let element;
export let sources;
export let sourceStore;
export let data;
const tabContext = getContext(CardsTabContextKey);
$: source = findSourceStore($sources, 'YinzCamAppServerTeamStandingsFilter');
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsStandings');
}
;
$: standingsData = (sourceStore) ? $sourceStore : {};
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: compId = $mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.compId;
$: scrollContainerStore = tabContext === null || tabContext === void 0 ? void 0 : tabContext.scrollContainer;
// secondary options (TODO: move into component)
$: secondaryKey = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.secondaryKey;
$: secondaryLabel = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.secondaryLabel;
$: sourceSecondaryOptions = (_d = $source === null || $source === void 0 ? void 0 : $source.secondaryOptions) === null || _d === void 0 ? void 0 : _d.map((option) => {
    return {
        title: option.title,
        value: option.value
    };
});
$: elementSecondaryOptions = (_f = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.secondaryOptions) === null || _f === void 0 ? void 0 : _f.map((option) => {
    return {
        title: option.title,
        value: option.value
    };
});
$: secondaryOptions = (sourceSecondaryOptions || elementSecondaryOptions || []);
$: selectedSecondaryValue = (secondaryKey) ? ($mergedParams || {})[secondaryKey] : null;
function filterSecondary(value) {
    if (secondaryKey) {
        tabContext.setParam(secondaryKey, value);
    }
}
function secondarySelected(event) {
    filterSecondary(event.detail);
}
afterUpdate(() => {
    if (!selectedSecondaryValue && $mergedParams && secondaryOptions && secondaryOptions.length > 0) {
        filterSecondary(secondaryOptions[0].value);
    }
});
let standingsArray;
let labels;
let values;
$: {
    let newStandingsArray = ((_h = (_g = standingsData === null || standingsData === void 0 ? void 0 : standingsData.Leagues) === null || _g === void 0 ? void 0 : _g.League) === null || _h === void 0 ? void 0 : _h.Standing) || [];
    if (!_.isEqual(standingsArray, newStandingsArray)) {
        standingsArray = newStandingsArray;
        labels = standingsArray.map((roundStanding) => {
            var _a;
            let roundNum = (_a = roundStanding === null || roundStanding === void 0 ? void 0 : roundStanding._attributes) === null || _a === void 0 ? void 0 : _a.Round;
            return 'J' + roundNum;
        });
        values = standingsArray.map((roundStanding) => {
            var _a;
            let standingValue = (_a = roundStanding === null || roundStanding === void 0 ? void 0 : roundStanding._attributes) === null || _a === void 0 ? void 0 : _a.LeagueRank;
            return standingValue;
        });
    }
}
$: data = {
    'labels': labels,
    'values': values
};
</script>

<CardBase {element}>
  {#if data.labels.length !=0 && data.values.length != 0 && (compId && (compId==="1" || compId === "2"))}
    <div class="graph-container">
      <div class="title-bar">
        <h2>Clasification por jornadas</h2>
        <div class="dropdown-menu">
            <CustomSelect scrollContainer={$scrollContainerStore} items={secondaryOptions} selectedValue={selectedSecondaryValue} label={secondaryLabel} on:selected={secondarySelected} />
        </div>
      </div>

      <div class="chartjs-tooltip-wrapper" id="tooltip-seed">
        <div class="chartjs-tooltip">
          <div class="chartjs-tooltip-text"></div>
          <div class='chartjs-tooltip-arrow'></div>
        </div>
      </div>
      <div class="graph-wrapper">
        <MyLineGraph data={data} />
      </div>
    </div>
  {/if}
</CardBase>


<style>.graph-container {
  background-color: #ffffff;
}

.title-bar {
  display: flex;
  align-items: center;
  padding: 0 29px 0 64px;
}

h2 {
  font-family: 'Core Sans C 75';
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
  color: #1c1f22;
  margin: 16px auto 16px 16px;
}

.dropdown-menu {
  width: 224px;
  height: 56px;
  border-bottom: 1px solid #dde0e3;
}

.graph-wrapper {
  height: 200px;
  position: relative;
  padding: 0 25px 33px 35px
}

.chartjs-tooltip {
  color: #2f3235;
  background: #e9ebed;
  opacity: 0.7;
  width: 24px;
  height: 22px;
  box-shadow: 0px 2px 2px #ccc;
  font-family: "Core Sans C 75";
  font-weight: 800;
  font-size: 10px;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  position: relative;
}

.chartjs-tooltip-arrow {
  width: 14px;
  height: 12px;
  border: 7px solid transparent;
  border-top-color: #e2e4e6;
  box-sizing: border-box;
  position: absolute;
  left: 5px;
  top: 22px;
}

.chartjs-tooltip-wrapper {
  position: absolute;
  display: none;
}

@media (max-width: 991px) {
  .graph-container {
    display: none;
  }
}</style>