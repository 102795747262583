<Boundary>
  <div bind:this={wrapper} class="yinzcam-cards-tab {pageContext.discoModeClass}" style="
    {buildCssPadding(tab.padding)}
    {buildCssBackground(tab.background)}
  ">
    {#if contentStore && $contentStore}
      <CardsSections parentSequenceId={sequenceId} sections={$contentStore.sections} />      
    {/if}
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.yinzcam-cards-tab.disco-mode-enabled {
  padding: 2px;
  border: 2px solid gold;
}</style>

<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { YinzCamCardsService, YinzCamCardsServiceSection, YinzCamCardsServiceSource, YinzCamCardsServiceTab, YinzCamCardsServiceToken } from "yinzcam-cards";
import CardsSections from "./CardsSections.svelte";
import { buildCssPadding, buildCssBackground } from '../utilities/index';
import { getContext, onMount, setContext } from "svelte";
import { CardsPageContext, CardsPageContextKey, CardsTabContext, CardsTabContextKey } from "./context";
import { CardsDataSourceManager } from "./CardsDataSourceManager";
import ROOT from "../../../inversify.config";
import { Readable, writable } from "svelte/store";
import { getToken } from "inversify-token";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { JanusModeContextManagerToken } from "../../../js/mode";
import { MergedYinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameters } from "yinzcam-api";
import { AbstractReactiveMicrocomponent, ControlBase, ManualPassthrough } from "yinzcam-rma";
import _ from "lodash";
export let sequenceId = "";
export let tab;
const cardsService = getToken(ROOT, YinzCamCardsServiceToken);
const dataSourceManager = new CardsDataSourceManager(ROOT);
const pageContext = getContext(CardsPageContextKey);
const modeParamsComp = getToken(ROOT, JanusModeContextManagerToken).getMergedRequestParameterComponent();
const pageParamsComp = new ManualPassthrough({ name: `CardsTab_${tab.id}_PageParams`, saveToLocalStorage: false }, { params: pageContext.query });
const internalParamsComp = new ManualPassthrough({ name: `CardsTab_${tab.id}_InternalParams`, saveToLocalStorage: false }, { params: {} });
const mergedApiParamsComp = new MergedYinzCamAPIRequestParameterComponent({ name: `CardsTab_${tab.id}_MergedApiParams`, saveToLocalStorage: false }, modeParamsComp, pageParamsComp, internalParamsComp);
const mergedParamsComp = new class extends AbstractReactiveMicrocomponent {
    update($control, $apiParams) {
        return __awaiter(this, void 0, void 0, function* () {
            return $apiParams === null || $apiParams === void 0 ? void 0 : $apiParams.params;
        });
    }
}({ name: `CardsTab_${tab.id}_MergedParams`, saveToLocalStorage: false }, mergedApiParamsComp);
const mergedParamsStore = mergedParamsComp.store;
const setParam = function (key, value) {
    if (!key) {
        return;
    }
    let apiParams = internalParamsComp.getValue();
    if (!(apiParams === null || apiParams === void 0 ? void 0 : apiParams.params)) {
        apiParams = { params: {} };
    }
    if (!value) {
        delete apiParams.params[key];
    }
    else {
        apiParams.params[key] = value;
    }
    internalParamsComp.setValue(apiParams);
};
const scrollContainer = writable(document.body);
setContext(CardsTabContextKey, { dataSourceManager, mergedParamsComp, setParam, scrollContainer });
let contentStore;
let wrapper;
$: fragmentId = tab === null || tab === void 0 ? void 0 : tab.fragmentId;
$: sections = tab === null || tab === void 0 ? void 0 : tab.sections;
$: if (sections) {
    try {
        if (fragmentId) {
            console.log('cannot define both sections and fragment ID for a tab');
        }
        else {
            contentStore = writable(tab);
        }
    }
    finally { }
}
$: if (fragmentId) {
    try {
        if (sections) {
            console.log('cannot define both sections and fragment ID for a tab');
        }
        else {
            contentStore = cardsService.getCardsFragment(fragmentId).store;
        }
    }
    finally { }
}
$: if (contentStore && !_.isNil($contentStore) && mergedParamsStore && !_.isNil($mergedParamsStore)) {
    try {
        dataSourceManager.setDataSources($contentStore.sources, $mergedParamsStore);
    }
    finally { }
}
function findScrollContainer() {
    let el = wrapper;
    while (el = el.parentElement) {
        let style = getComputedStyle(el);
        if (String(style === null || style === void 0 ? void 0 : style.overflow).toLowerCase() === 'auto') {
            scrollContainer.set(el);
            break;
        }
    }
}
onMount(() => {
    findScrollContainer();
});
</script>
