import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";


@injectable()
export class YinzCamAppServerGameList extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }
  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    let gameData = this.getGamesByMonth(data);
    return gameData;
  }
  getGamesByMonth(data: any): any {
    const monthNames = [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic' ];
    const fullMonthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const team = {
      FullName: "Deportivo Alavés",
      Id: "11",
      LogoId: "ESP_ALA",
      Name: "Alavés",
      TeamPageAvailable: "false",
      TriCode: "ALA"
    };
    let games = [].concat(data?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id);
    games = games.map((game) => {
      game['Team']={"_attributes": team};
      return game;
    });
    let months = _.uniq(games.map(game => new Date(game?.Date?._attributes?.Timestamp).getMonth()));
    let gamesByMonths = _.groupBy(games, game => new Date(game?.Date?._attributes?.Timestamp).getMonth());
    return months.map(month => {
      return {
        title: monthNames[month],
        mobileTitle: fullMonthNames[month],
        data: gamesByMonths[month]
      };
    });
  }
}
