<CardBase {element}>
  <div class="video-player-body" style="color:{textColor};">
    {#if isYouTube}
      <div class='embed-container'>
        <iframe {title} src={ytEmbedUrl} frameborder='0' allowfullscreen autoplay></iframe>
      </div>
    {:else if isNative}
      <video src={mediaUrl} controls>
        <track kind="captions">
      </video>
    {/if}
  </div>
</CardBase>

<style>.video-player-body video {
  width: 100%;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container :global(iframe), .embed-container :global(object), .embed-container :global(embed) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}</style>

<script lang="ts">var _a, _b, _c;
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
let textColor = 'inherit';
$: textColor = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.textColor;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaItem');
$: data = (source) ? $source : {};
$: title = (_b = data === null || data === void 0 ? void 0 : data.Title) === null || _b === void 0 ? void 0 : _b._text;
$: mediaUrl = (_c = data === null || data === void 0 ? void 0 : data.MediaUrl) === null || _c === void 0 ? void 0 : _c._text;
$: isYouTube = mediaUrl && mediaUrl.includes('youtube.com');
$: ytVideoId = (isYouTube) ? new URL(mediaUrl).searchParams.get('v') : '';
$: ytEmbedUrl = (isYouTube) ? `https://www.youtube.com/embed/${ytVideoId}?feature=oembed` : '';
$: isNative = mediaUrl && !isYouTube;
</script>
