<script lang="ts">var _a, _b, _c, _d, _e, _f;
import { Button } from "framework7-svelte";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { findSourceStore } from "../../../utilities";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
import { t } from "../../../../../js/i18n";
import { Readable } from "svelte/store";
import { afterUpdate, getContext, onMount } from "svelte";
import CustomSelect from "../../../../CustomSelect.svelte";
import betwayImg from "./img/betway.png";
export let tableData;
export let element;
export let sources;
let selectedRow = -1;
const tabContext = getContext(CardsTabContextKey);
$: source = findSourceStore($sources, 'YinzCamAppServerStatsStandingsFilter');
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: scrollContainerStore = tabContext === null || tabContext === void 0 ? void 0 : tabContext.scrollContainer;
// secondary options (TODO: move into component)
$: secondaryKey = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.secondaryKey;
$: secondaryLabel = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.secondaryLabel;
$: sourceSecondaryOptions = (_d = $source === null || $source === void 0 ? void 0 : $source.secondaryOptions) === null || _d === void 0 ? void 0 : _d.map((option) => {
    return {
        title: option.title,
        value: option.value
    };
});
$: elementSecondaryOptions = (_f = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.secondaryOptions) === null || _f === void 0 ? void 0 : _f.map((option) => {
    return {
        title: option.title,
        value: option.value
    };
});
$: secondaryOptions = (sourceSecondaryOptions || elementSecondaryOptions || []);
$: selectedSecondaryValue = (secondaryKey) ? ($mergedParams || {})[secondaryKey] : null;
const statusClasses = {
    "G": 'won',
    "P": 'lost',
    "E": 'drawn',
};
$: data = {
    seasonalData: [
        {
            value: "Jornada 20",
            caption: "Jornada 20"
        },
        {
            value: "Jornada 19",
            caption: "Jornada 19"
        },
        {
            value: "Jornada 18",
            caption: "Jornada 18"
        },
        {
            value: "Jornada 17",
            caption: "Jornada 17"
        }
    ],
    'tableData': tableData
};
const championClasses = ['standard', 'champion', 'europa', 'decline'];
function filterSecondary(value) {
    if (secondaryKey) {
        tabContext.setParam(secondaryKey, value);
    }
}
function secondarySelected(event) {
    filterSecondary(event.detail);
}
afterUpdate(() => {
    if (!selectedSecondaryValue && $mergedParams && secondaryOptions && secondaryOptions.length > 0) {
        filterSecondary(secondaryOptions[0].value);
    }
});
</script>

<div class="heading">
  {#if !data.seasonalData || data.seasonalData.length === 0}
    <p>No seasonal data</p>
  {:else}
    <div class="campaign">
      <CustomSelect scrollContainer={$scrollContainerStore} items={secondaryOptions} selectedValue={selectedSecondaryValue} label={secondaryLabel} on:selected={secondarySelected} />
    </div>
  {/if}
  <div class="sponser">
    <span class="por">Patrocinado por</span>
    <span class="header">
      <img src={betwayImg} alt="betway">
    </span>
  </div>
</div>

  {#if !data.tableData || data.tableData.length === 0}
    <p>No table data</p>
  {:else}
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th class="position">POSICIÓN</th>
            <th class="team">
              <span class="team-name">NOMBRE DEL EQUIPO</span>
            </th>
            <th class="Puntos">PUNTOS</th>
            <th class="PJ">PJ</th>
            <th class="PG">PG</th>
            <th class="PE">PE</th>
            <th class="PP">PP</th>
            <th class="GF">GF</th>
            <th class="GC">GC</th>
            <th class="PlugMinus">+/-</th>
            <th class="match">ÚLTIMOS PARTIDOS</th>
          </tr>
        </thead>
        <tbody>
          {#each (data.tableData || []) as item, i}
            <tr
              class={(championClasses[item.championStatus] || '') + (i === selectedRow ? " selected" : "")}
              on:click = {(e) => {selectedRow = i}}
            >
              <td class="position">
                <span>{i + 1}</span>
                {#if item.chevron === 1}
                  <span class="btn-up-expand" role="button">
                    <i class="f7-icons">chevron_up</i>
                  </span>
                {:else if item.chevron === 2}
                  <span class="btn-down-expand" role="button">
                    <i class="f7-icons">chevron_down</i>
                  </span>
                {/if}
              </td>
              <td class="team">
                <div class="d-inline-flex">
                  <span class="logo-wrapper">
                    <img src={item.teamLogo} alt="Team Logo" />
                  </span>
                  <span class="name">{item.teamName}</span>
                  <span class="short-name">{item.teamShortName}</span>
                </div>
              </td>
              <td>{item.Puntos}</td>
              <td>{item.PJ}</td>
              <td>{item.PG}</td>
              <td>{item.PE}</td>
              <td>{item.PP}</td>
              <td>{item.GF}</td>
              <td>{item.GC}</td>
              <td>{item.PlusMinus >= 0 ? '+' : ''}{item.PlusMinus}</td>
              <td class="match position-relative">
                <div class="d-inline-flex">
                  {#each (item.matchStatus || {}) as matchItem, i}
                    <span
                      class={'match-state ' + statusClasses[matchItem.status]}
                    >
                      {matchItem.status}
                      <div class="tooltip-wrapper">
                        <div class="tooltip">
                          <div class="team-info">
                            <div class="name">{matchItem.teamName1}</div>
                            <div class="logo">
                              <img src={matchItem.teamLogo1 || ''} alt="log" />
                            </div>
                          </div>

                          <div class="scoreboard-wrapper">
                            <div class="campaign-info">{matchItem.date || ''}</div>
                            <div class="scoreboard">
                              <div>
                                {isNaN(Number(matchItem.score1)) ? 0 : Number(matchItem.score1)}
                              </div>
                              <div>
                                {isNaN(Number(matchItem.score2)) ? 0 : Number(matchItem.score2)}
                              </div>
                            </div>
                          </div>

                          <div class="team-info away">
                            <div class="name">{matchItem.teamName2 || ''}</div>
                            <div class="logo">
                              <img src={matchItem.teamLogo2 || ''} alt="log" />
                            </div>
                          </div>

                          <div class="arrow" style="left: calc(50% + {(i - item.matchStatus.length / 2.0) * 30.5}px)" />
                        </div>
                      </div>
                    </span>
                  {/each}
                </div>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  {/if}

<style>.heading {
  background: white;
  display: flex;
  align-items: center;
  padding: 0px 32px 0px 16px;
  font-family: CoreSansC-45;
  margin-top: 10px;
  border-bottom: 1px solid #dde0e3;
}

.heading .campaign {
  width: 224px;
  height: 64px;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  color: #1c1f22;
  padding: 0 16px;
  border: 0px;
  font-family: 'Core Sans C 75';
}

.sponser {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.sponser .por {
  font-size: 14px;
  line-height: 1.43;
  color: #788591;
  margin-right: 5px;
  font-family: 'Core Sans C 45';
}

.sponser .header img {
  width: 70px;
  height: 28px;
  display: block;
  line-height: 0;
}

.table-wrapper {
  background: white;
  font-family: 'Core SansC 45';
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  border-top: 1px solid #dde0e3;
  height: 56px;
  transition: all 0.2s;
  cursor: pointer;
}

tbody tr.selected {
  background: #0032a0;
}

tbody tr.selected td {
  color: white;
  font-weight: 800;
}

tr:last-child {
  border-bottom: 1px solid #dde0e3;
}

tbody tr td:first-child {
  position: relative;
}

tbody tr td:first-child:before {
  display: block;
  content: '';
  width: 4px;
  height: 100%;
  background: #aaa;
  position: absolute;
  left: 0px;
  top: 0;
}

th,
  td {
  text-align: center;
  color: #788591;
  box-sizing: border-box;
}

th {
  width: 60px;
  font-family:'Core Sans C 65';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.12px;
}

td {
  font-size: 14px;
  line-height: 1.43;
}

.d-inline-flex {
  display: inline-flex;
  align-items: center;
}

td.team {
  text-align: left;
  vertical-align: bottom;
  padding: 0px;
  vertical-align: bottom !important;
}

.logo-wrapper {
  padding: 4px;
  line-height: 0;
  display: block;
}

.team img {
  width: 32px;
  height: 32px;
  display: block;
}

.team .name {
  text-transform: uppercase;
  margin-left: 10px;
}

.team .short-name {
  text-transform: uppercase;
  margin-left: 3px;
  display: none;
}

.match-state {
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  color: white;
  font-family: CoreSansC-65;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  background: #ccc;
  display: inline-block;
  cursor: pointer;
}

.won {
  background: #15c67e;
}

.lost {
  background: #ef4565;
}

.drawn {
  background: #ed984d;
}

.champion td:first-child::before {
  background: #15c67e;
}

.standard td:first-child::before {
  background: #c4c4c4;
}

.decline td:first-child::before {
  background: #ef4565;
}

.europa td:first-child::before {
  background: #ed984d;
}

.tooltip-wrapper {
  position: absolute;
  left: -20px;
  bottom: 59px;
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
}

.match-state:hover .tooltip-wrapper {
  visibility: visible;
  opacity: 1;
}

.tooltip {
  position: relative;
  background: white;
  box-shadow: 5px 5px 10px #aaa;
  height: 60px;
  font-size: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0px 10px 9px 10px;
  color: #2e3338;
  opacity: 1 !important;
}

.tooltip img {
  width: 24px;
  height: 24px;
  margin-left: 6px;
  display: block;
}

.tooltip .team-info {
  display: flex;
  align-items: center;
}

.tooltip .team-info.away {
  flex-direction: row-reverse;
}

.tooltip .team-info.away img {
  margin-left: 0px;
  margin-right: 6px;
}

.tooltip .scoreboard-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.tooltip .scoreboard {
  display: flex;
  align-items: center;
  width: 50.08px;
  height: 23.04px;
}

.tooltip .scoreboard div {
  width: 26.1px;
  height: 26.1px;
  line-height: 26.1px;
  margin: 0px 1px;
  background: #e9ebed;
  color: #40474f;
}

.tooltip .campaign-info {
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.96px;
  color: #8e9194;
  margin-bottom: 5px;
  text-transform: uppercase;
  position: absolute;
  left: -100px;
  right: -100px;
  text-align: center;
  top: -19px;
}

.tooltip .arrow {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: 12px solid transparent;
  border-top-color: white;
  position: absolute;
  bottom: -23px;
}

.btn-up-expand i,
  .btn-down-expand i {
  font-size: 12px;
  font-weight: bold;
}

.btn-up-expand,
  .btn-down-expand {
  position: absolute;
  padding-left: 24px;
  padding-right: 40px;
  left: 0px;
  right: 0px;
  text-align: center;
  cursor: pointer;
}

.btn-up-expand {
  color: #15c67e;
  top: 5px;
}

.btn-down-expand {
  color: #ef4565;
  bottom: 5px;
}

.position-relative {
  position: relative;
}

/* set width of td cells */

th.position,
  td.position {
  width: 80px;
  padding-left: 24px;
  padding-right: 40px;
}

th.team {
  width: auto;
  padding-left: 52px;
  text-align: left;
}

th.Puntos,
  th.PJ,
  th.PG,
  th.PE,
  th.PP,
  th.GF,
  th.GC,
  th.PlugMinus {
  width: 56px;
  margin: 0px 4px;
}

th.match,
  td.match {
  width: 152px;
  padding-right: 24px;
}

@media (max-width: 991px) {
  .heading {
    flex-direction: column-reverse;
    width: 100%;
    padding: 0px;
  }

  .sponser {
    width: 100%;
    height: 32px;
    margin: 0;
    border-bottom: 1px solid #dde0e3;
    display: flex;
    justify-content: center;
  }

  .campaign {
    width: 100% !important;
    height: 48px !important;
    margin: 0 0 8px 0;
    padding: 0 8px !important;
    font-size: 20px !important;
    font-family: 'Core Sans C 75'
  }

  th {
    font-size: 10px;
  }

  td {
    font-size: 12px;
    line-height: 17.2px;
  }

  th.team .team-name {
    display: none;
  }

  .team img {
    width: 24px;
    height: 24px;
  }

  .team .name {
    display: none;
  }

  .team .short-name {
    display: inline;
    font-size: 14px;
  }

  .match {
    display: none;
  }

  /* set width of td cells */

  th.position,
    td.position {
    width: 32px;
    padding-left: 8px;
    padding-right: 0;
  }

  th.team {
    width: auto;
  }

  th.Puntos,
    th.PJ,
    th.PG,
    th.PE,
    th.PP,
    th.GF,
    th.GC,
    th.PlugMinus {
    width: 24px;
    margin: 0px 2px;
  }

  .btn-up-expand i,
    .btn-down-expand i {
    font-size: 9px;
    font-weight: bold;
  }

  .btn-up-expand,
    .btn-down-expand {
    position: absolute;
    padding-left: 8px;
    padding-right: 0;
    left: 0px;
    right: 0px;
    text-align: center;
    cursor: pointer;
  }

  .btn-up-expand {
    color: #15c67e;
    top: 5px;
  }

  .btn-down-expand {
    color: #ef4565;
    bottom: 5px;
  }
}</style>
