<CardBase {element}>
	{#if attrs}
		<div class="player">
			<a href="{playerHref}">
				<div class="number">{attrs.Number}</div>
				<div class="detail">
					<div class="name">{attrs.Name}</div>
					<div class="position">
						<span class="full">{$t(positionLongFormLookup(attrs.PositionFullName))}</span>
						<span class="abbrevation">{positionAbvLookup(attrs.Position)}</span>
					</div>
					<div class="height">
						<span class="value">{attrs.Height}</span>
						<!-- <span class="label">{$testSource.heightLabel}</span> -->
					</div>
					<div class="weight">
						<span class="value">{attrs.Weight}</span>
						<!-- <span class="label">{$testSource.weightLabel}</span> -->
					</div>
				</div>
				<div class="btn-wrapper">
					<a class="btn" href="{playerHref}">{$t("Ver Más")}</a>
				</div>
			</a>
		</div>
	{/if}
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.player {
  width:100%;
  display:block;
  overflow:hidden;
}

.player a {
  width:100%;
  padding:12px 0;
  background-color:rgba(0,0,0,0.8);
  font-family:var(--f7-font-family);
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
  text-transform:uppercase;
  text-decoration:none;
}

.player .number {
  width:20%;
  color:#FFF;
  font-weight:bold;
  font-size:5vw;
  text-align:center;
}

.player .detail {
  width:50%;
  color:#FFF;
  text-align:left;
  display:flex;
  flex-wrap:wrap;
  justify-content:flex-start;
}

.player .name {
  width:100%;
  font-weight:bold;
  font-size:2vw;
}

.player .name:after {
  width:100%;
  height:2px;
  margin:2px 0 0 0;
  background:linear-gradient(45deg, #fcd069,transparent);
  content:"";
  display:block;
}

.player .position {
  width:100%;
  margin:12px 0 0 0;
  font-weight:bold;
  font-size:1.25vw;
}

.player span {
  font-size:1.25vw;
}

.player .value {
  font-weight:bold;
}

.player .label {
  opacity:0.75;
}

.player .height, .player .weight {
  margin-top:12px;
  margin-bottom:12px;
  line-height:1;
}

.player .height {
  margin-right:24px;
}

.player .btn-wrapper {
  width:30%;
  padding:0 12px;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.player .btn-wrapper a.btn {
  min-width:150px;
  padding:12px 24px;
  background-color:#FFF;
  border-radius:8px;
  color:#000;
  font-size:0.9vw;
  font-weight:bold;
  text-align:center;
  text-decoration:none;
  display:block;
}

@media screen and (max-width:1000px){
  .player .number {
    font-size:9vw;
  }

  .player .name {
    font-size:2.75vw;
  }

  .player span, .player .position {
    font-size:1.75vw;
  }

  .player .btn-wrapper a.btn {
    font-size:14px;
  }
}

@media screen and (min-width:768px){
  .player .abbrevation {
    display:none;
  }
}

@media screen and (max-width:767px){
  .player {
    border-bottom:solid 1px rgba(255,255,255,0.4);
    justify-content:flex-start;
  }

  .player .number {
    font-size:10vw;
  }

  .player .detail {
    width:80%;
    padding:0 25% 0 0;
    position:relative;
  }

  .player .name:after {
    display:none;
  }

  .player .name {
    font-size:3.75vw;
    text-transform:none;
  }

  .player span, .player .position {
    font-size:1.75vw;
  }

  .player .position {
    width:25%;
    height:100%;
    margin:0;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    position:absolute;
    right:0;
    top:0;
  }

  .player .position .full {
    display:none;
  }

  .player .abbrevation {
    font-weight:bold;
    font-size:9vw;
    opacity:0.75;
  }

  .player span {
    font-size:2.75vw;
  }

  .player .value {
    opacity:0.75;
  }

  .player .height, .player .weight {
    margin-top:0;
    margin-bottom:0;
  }

  .player .btn-wrapper {
    display:none;
  }
}</style>

<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { YinzCamAppServer, YinzCamAppServerToken } from 'yinzcam-appserver';
import { JanusModeContextManagerToken } from "../../../../js/mode";
import ROOT from '../../../../inversify.config';
import { getToken } from 'inversify-token';
import { t } from "../../../../js/i18n";
export let element;
export let sources;
const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const appsrv = getToken(ROOT, YinzCamAppServerToken);
let teamId, teamsListData, teamName, teamShortName, teamLogoSuffix;
teamsListData = appsrv.getRequest({ path: 'Team/TeamsList' }).store;
$: if ($teamsListData) {
    var teams = $teamsListData.data.TeamsList.Section.Team;
    teams.forEach(function (value, index, array) {
        if (value._attributes.Id === teamId) {
            teamName = value._attributes.Name;
            teamShortName = value._attributes.TeamShortName;
            teamLogoSuffix = value._attributes.LogoId;
            teamId = value._attributes.Id;
        }
    });
}
contextManager.teamIdInput.store.subscribe(function (val) {
    teamId = val;
});
let playerHref = '#';
$: if (teamId && (attrs === null || attrs === void 0 ? void 0 : attrs.Id)) {
    playerHref = `/cards/Player?playerId=${encodeURIComponent(attrs.Id)}&titleText=${encodeURIComponent(attrs.Name)}&teamName=${encodeURIComponent(teamName)}&teamId=${encodeURIComponent(teamId)}&teamTricode=${encodeURIComponent(teamLogoSuffix.replace('CSF_', ''))}`;
}
let players = [];
let spanishPositionAbvDictionary = {
    'Guarda-redes': 'GK',
    'Defesa': 'DF',
    'Médio': 'MF',
    'Avançado': 'F',
};
let positionAbvLookup = function (string) {
    if (spanishPositionAbvDictionary[string]) {
        return spanishPositionAbvDictionary[string];
    }
    else {
        return string;
    }
};
let positionLongFormLookup = function (abv) {
    for (var i in spanishPositionAbvDictionary) {
        if (spanishPositionAbvDictionary[i] === abv) {
            return i;
        }
    }
    return abv;
};
$: testSource = findSourceStore($sources, 'YinzCamAppServerTeamRoster');
$: attrs = (testSource) ? $testSource === null || $testSource === void 0 ? void 0 : $testSource._attributes : null;
</script>