<CardBase {element}>
<div class="matchcenter-header-wrapper">
	<div class="matchcenter-header" class:state-pregame={isPregame} class:state-live={isCurrent} class:state-final={isFinal}>
		<div class="game-date">
			<img class="league-logo" src="{competitionLogo || 'https://yc-websites.s3.amazonaws.com/lfp/shared/la-liga.png'}" alt="La Liga">
			<span class="fixture">
				<span class="longform">{$t('Jornada')} {round}</span>
				<span class="shortform">{$t('J')}{round}</span>
			</span>

			<span class="date">
				<span>{$tdate(gameTime, { year: 'numeric', month: 'short', day: 'numeric' })}</span>
				<span class="time">{$ttime(gameTime, { hour: 'numeric', minute: 'numeric' })}</span>
			</span>

			<div class="state">{$t(stateFmt || '')}</div>

			<div class="broadcast">
				<a class="live-button" href="">
					<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_broadcast.svg" alt="">
					<span class="label">1 Tiempo</span>
				</a>
			</div>
		</div>

		<div class="matchup-info">
			<div class="team home">
				<span class="logo"><TeamLogo teamObject={homeTeam} blankIfNil={true} /></span>
				<span class="team-name">{homeName}</span>
				<div class="past-results">
					{#if recentGames.length > 0}
					{#each recentGames as game}
					<!-- supports the following classes for game outcomes: g,p,e (I'm guessing these are for wins, losses and draws respectively) -->
					<div class="result {game.homeOutcome}">{game.homeOutcome}</div>
					<!-- <div class="result {pastHomeGame.home.outcome}">P</div>
					<div class="result {pastHomeGame.home.outcome}">G</div>
					<div class="result {pastHomeGame.home.outcome}">P</div>
					<div class="result {pastHomeGame.home.outcome}">E</div> -->
					{/each}
					{/if}
				</div>
			</div>
			
			<div class="game-detail">
				<span class="date">
					<span>{$tdate(gameTime, { year: 'numeric', month: 'short', day: 'numeric' })}</span>
					<span class="time">{$ttime(gameTime, { hour: 'numeric', minute: 'numeric' })}</span>
				</span>

				<div class="score">
					<div class="home-score team-score">{(_.isNil(homeScore))? '' : homeScore}</div>
					<div class="away-score team-score">{(_.isNil(awayScore))? '' : awayScore}</div>
				</div>

				<div class="location">{venue}</div>
			</div>
			<div class="team away">
				<span class="logo"><TeamLogo teamObject={awayTeam} blankIfNil={true} /></span>
				<span class="team-name">{awayName}</span>
				<div class="past-results">
					{#if recentGames.length > 0}
					{#each recentGames as game}
					<!-- supports the following classes for game outcomes: g,p,e (I'm guessing these are for wins, losses and draws respectively) -->
					<div class="result {game.awayOutcome}">{game.awayOutcome}</div>
					{/each}
					{/if}
					<!-- <div class="result p">P</div>
					<div class="result p">P</div>
					<div class="result p">P</div>
					<div class="result p">P</div>
					<div class="result g">G</div> -->
				</div>
			</div>
		</div>

		<div class="clock">{clock || ''}</div>

		<div class="game-events">
			<div class="team home">
				{#each homeScoringPlays as play}
				<div class="event goal">
					<div class="player-name">{play.player}</div>
					<div class="event-label">{play.time}</div>
				</div>
				{/each}
			</div>
			<div class="spacer"></div>
			<div class="team away">
				{#each awayScoringPlays as play}
				<div class="event goal">
					<div class="player-name">{play.player}</div>
					<div class="event-label">{play.time}</div>
				</div>
				{/each}
			</div>
		</div>
	</div>
</div>
</CardBase>
<style>*, *:before, *:after {
  box-sizing:border-box;
}

.matchcenter-header-wrapper {
  background-image:url('https://yc-websites.s3.amazonaws.com/lfp/shared/feature-hero.jpg');
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  overflow:hidden;
}

.matchcenter-header {
  width:100%;
  padding:84px 0;
  background-color:rgba(0,0,0,0.7);
  display:flex;
  flex-wrap:wrap;
  position:relative;
  overflow:hidden;
}

.matchcenter-header .game-date {
  width:100%;
  margin-top:12px;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.matchcenter-header .date {
  width:100%;
  margin:24px auto 3px auto;
  color:#FFF;
  font-size:15px;
  font-weight:bold;
  letter-spacing:1px;
  text-align:center;
  display:none;
}

.matchcenter-header .date .time {
  width:100%;
  margin:4px 0;
  font-size:20px;
  display:block;
}

.matchcenter-header .game-date .league-logo {
  width:25%;
  max-width:200px;
  height:auto;
  margin:0 16px;
  -webkit-filter:brightness(20) grayscale(1);
  filter:brightness(20) grayscale(1);
}

.matchcenter-header .fixture {
  width:100%;
  margin:0 0 16px 0;
  color:#FFF;
  color:var(--neutrals-grey-lighter-85);
  font-size:14px;
  font-weight:normal;
  text-align:center;
  display:block;
}

.matchcenter-header .fixture span {
  display:none;
}

.matchcenter-header .matchup-info {
  width:100%;
  margin:36px 0 0 0;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  text-align:center;
}

.matchcenter-header .matchup-info .team {
  width:33.3%;
  max-width:300px;
  padding:0 16px;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  flex-direction:row-reverse;
  text-align:right;
}

.matchcenter-header .matchup-info .team:not(:nth-child(1)) {
  flex-direction:row;
  text-align:left;
}

.matchcenter-header .matchup-info .team .logo {
  width:64px;
  height:intrinsic;
  height:auto;
}

.matchcenter-header .matchup-info .team .team-name {
  color:#FFF;
  font-size:24px;
  line-height:1;
  text-align:right;
  font-weight:bold;
  /*display:none;*/
}

.matchcenter-header .matchup-info .team:not(:nth-child(1)) .team-name {
  text-align:left;
}

.matchcenter-header .matchup-info .team .past-results {
  width:100%;
  margin:16px 0 0 0;
  justify-content:flex-end;
  align-items:center;
  display:none;
}

.matchcenter-header .matchup-info .team:not(:nth-child(1)) .past-results {
  justify-content:flex-start;
}

.matchcenter-header .matchup-info .team .past-results .result {
  width:24px;
  height:24px;
  margin:6px 6px 6px 0;
  padding:6px;
  color:#FFF;
  font-size:16px;
  font-weight:bold;
  line-height:1;
  text-align:center;
}

.matchcenter-header .matchup-info .team .past-results .result.G {
  background:#15c67e;
}

.matchcenter-header .matchup-info .team .past-results .result.P {
  background:#ef4565;
}

.matchcenter-header .matchup-info .team .past-results .result.E {
  background:#ed984d;
}

.matchcenter-header .matchup-info .game-detail {
  width:15%;
  min-width:150px;
  /*margin:0 5%;*/
  padding:12px 24px;
  color:#FFF;
  text-align:center;
}

.matchcenter-header .state {
  width:100%;
  margin:12px 0;
  color:#FFF;
  font-size:12px;
  text-align:center;
  text-transform:uppercase;
  display:none;
}

.matchcenter-header .clock {
  width:auto;
  margin:12px auto 0 auto;
  padding:10px 16px;
  background-color:rgba(255,255,255,0.2);
  color:#FFF;
  font-size:18px;
  font-weight:bold;
  text-align:center;
  display:none;
}

.matchcenter-header .matchup-info .game-detail .score {
  font-size:32px;
  font-weight:800;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-evenly;
}

.matchcenter-header .matchup-info .game-detail .score .team-score {
  padding:8px;
}

.matchcenter-header .location {
  width:100%;
  margin:4px 0;
  /*padding:0 12px;*/
  color:var(--neutrals-grey-lighter-85);
  font-size:10px;
  font-weight:bold;
  text-align:center;
  text-transform:uppercase;
  line-height:1.5;
  display:none;
}

.matchcenter-header .broadcast {
  padding:0 12px;
  margin:24px 0 0 0;
  display:none;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.matchcenter-header .broadcast a {
  margin:0 6px;
}

.matchcenter-header .broadcast a.live-button {
  padding:7px 8px;
  background-color:#ef4565;
  border-radius:20px;
  color:#FFF;
  font-size:12px;
  font-weight:bold;
  text-decoration:none;
  line-height:1;
  display:flex;
  flex-wrap:nowrap;
  align-items:center;
}

.matchcenter-header .broadcast a.live-button img {
  width:18px;
  height:18px;
  padding:2px;
}

.matchcenter-header .broadcast a.live-button .label {
  margin:0 2px;
  text-transform:uppercase;
}

.matchcenter-header .game-events {
  width:100%;
  padding:12px;
  color:#FFF;
  flex-wrap:wrap;
  justify-content:center;
  align-items:flex-start;
  display:none;
}

.matchcenter-header .game-events .team {
  width:33.3%;
  max-width:300px;
  display:flex;
  flex-wrap:wrap;
  flex-direction:row-reverse;
}

.matchcenter-header .game-events .team:last-child {
  flex-direction:row;
}

.matchcenter-header .game-events .team .event {
  width:100%;
  display:flex;
  align-items:center;
  color:var(--neutrals-grey-lighter-95);
}

.matchcenter-header .game-events .team:first-child .event {
  flex-flow:row-reverse;
}

.matchcenter-header .game-events .team .event .player-name {
  margin:0 16px;
  font-size:14px;
}

.matchcenter-header .game-events .team .event .event-label {
  font-size:15px;
  font-weight:800;
}

.matchcenter-header .game-events .spacer {
  width:15%;
  min-width:150px;
  padding:12px 24px;
}

/*past game*/

.matchcenter-header.state-final {
}

.matchcenter-header.state-final .matchup-info {
  margin:0;
}

.matchcenter-header.state-final .broadcast {
  display:none;
}

.matchcenter-header.state-final .location {
  display:block;
}

.matchcenter-header.state-final .state {
  display:block;
}

.matchcenter-header.state-final .chronical, .matchcenter-header.state-final .tickets {
  display:none;
}

.matchcenter-header.state-final .game-date .date {
  display:block;
}

.matchcenter-header.state-final .fixture {
  width:unset;
  margin:0;
  color:#FFF;
}

.matchcenter-header.state-final .fixture .shortform {
  display:block;
}

.matchcenter-header.state-final .game-events {
  display:flex;
}

/*live game*/

.matchcenter-header.state-live {
}

.matchcenter-header.state-live .chronical, .matchcenter-header.state-live .tickets {
  display:none;
}

.matchcenter-header.state-live .fixture {
  width:unset;
  margin:0;
  color:#FFF;
}

.matchcenter-header.state-live .fixture .shortform {
  display:block;
}

.matchcenter-header.state-live .broadcast {
  width:100%;
  display:flex;
}

.matchcenter-header.state-live .clock {
  display:inline-block;
}

.matchcenter-header.state-live .clock:empty {
  display:none;
}

.matchcenter-header.state-live .game-events {
  display:flex;
}

/*pregame*/

.matchcenter-header.state-pregame {
}

.matchcenter-header.state-pregame .matchup-info .team .past-results {
  display:flex;
}

.matchcenter-header.state-pregame .game-detail .date {
  display:block;
}

.matchcenter-header.state-pregame .score, .matchcenter-header.state-pregame .score, .matchcenter-header.state-pregame .chronical, .matchcenter-header.state-pregame .minutes {
  display:none !important;
}

.matchcenter-header.state-pregame .location {
  display:block;
}

.matchcenter-header.state-pregame .matchup-info {
  align-items:flex-start;
}

.matchcenter-header.state-pregame .matchup-info .game-detail {
  padding:0 24px;
}

.matchcenter-header.state-pregame .matchup-info .date {
  margin:16px auto 3px auto;
}

@media screen and (min-width:768px){
  .matchcenter-header.state-pregame .fixture .longform {
    margin:6px 0 0 0;
    display:block;
  }
}

@media screen and (max-width:767px){
  .matchcenter-header {
    padding:24px 6px;
  }

  .matchcenter-header .matchup-info {
    margin:12px 0;
  }

  .matchcenter-header .matchup-info .team .past-results {
    display:none !important;
  }

  .matchcenter-header .game-date .league-logo {
    width:150px;
    margin:0 6px;
  }

  .matchcenter-header .date {
    margin:6px 0;
  }

  .matchcenter-header .state {
    margin:0 0 6px 0;
  }

  .matchcenter-header .fixture {
    width:auto;
    font-size:12px;
  }

  .matchcenter-header .fixture .shortform {
    color:#FFF;
    display:block;
  }

  .matchcenter-header	.content .name {
    margin:0 0 6px 0;
  }

  .matchcenter-header .fixture {
    margin:12px 0;
    font-weight:bold;
  }

  .matchcenter-header .matchup-info .team {
    width:33.3%;
    padding:6px;
    flex-direction:row;
    justify-content:center;
  }

  .matchcenter-header .matchup-info .team .logo {
    margin:6px auto;
  }

  .matchcenter-header .matchup-info .team .team-name {
    font-size:12px;
    text-align:center;
  }

  .matchcenter-header .matchup-info .game-detail {
    width:33.3%;
    min-width:0;
    padding:6px;
  }

  .matchcenter-header .clock {
    margin:0 auto;
    transform:translateY(-25px);
  }

  .matchcenter-header .game-events {
    padding:6px 0;
  }

  .matchcenter-header .game-events .team {
    width:50%;
    padding:6px;
  }

  .matchcenter-header .game-events .spacer {
    display:none;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import { f7, f7ready, f7Router } from 'framework7-svelte';
import { windowMetrics as wm } from "../../../../../js/stores.js";
import { findSourceStore, getHrefForMatchCenter } from "../../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import TeamLogo from "../../../utilities/TeamLogo.svelte";
import { resolveUrl } from "../../../../../js/url";
import YCLink from "../../../utilities/YCLink.svelte";
import { t, tdate, ttime } from "../../../../../js/i18n";
import _ from "lodash";
export let element;
export let sources;
export let sourceStore;
export let contentActive = false;
// game [object]: past game node, teamContext [string]: team designation ("home" or "away")
let pastGameOutcome = function (game, teamContext) {
    if (!game) {
        return '';
    }
    teamContext = teamContext.charAt(0).toUpperCase() + teamContext.slice(1).toLowerCase();
    var opposingTeamContext = (teamContext === 'Home') ? 'Away' : 'Home';
    if (game[teamContext + 'Score'] > game[opposingTeamContext + 'Score']) {
        return 'G';
    }
    else if (game[teamContext + 'Score'] < game[opposingTeamContext + 'Score']) {
        return 'P';
    }
    else if (game[teamContext + 'Score'] === game[opposingTeamContext + 'Score']) {
        return 'E';
    }
    else {
        return '';
    }
};
let isNative = (Device.cordova) ? true : false;
$: isDesktop = $wm.width > 1024;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGameBox');
}
;
$: data = (sourceStore && $sourceStore) ? $sourceStore : null;
$: if (data) {
    console.log('matchup data', data);
}
$: gameId = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: isDefault = String((_c = (_b = data === null || data === void 0 ? void 0 : data.CardData) === null || _b === void 0 ? void 0 : _b.IsDefault) === null || _c === void 0 ? void 0 : _c._text).toLowerCase() === 'true';
$: timestamp = (_d = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _d === void 0 ? void 0 : _d._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_e = data === null || data === void 0 ? void 0 : data.Venue) === null || _e === void 0 ? void 0 : _e._text;
$: competitionLogo = (_g = (_f = data === null || data === void 0 ? void 0 : data.Competition) === null || _f === void 0 ? void 0 : _f.LogoUrlDark) === null || _g === void 0 ? void 0 : _g._text;
$: legType = (_h = data === null || data === void 0 ? void 0 : data.LegType) === null || _h === void 0 ? void 0 : _h._text;
$: buttons = [].concat((_j = data === null || data === void 0 ? void 0 : data.Buttons) === null || _j === void 0 ? void 0 : _j.Button).map(i => {
    var _a, _b, _c;
    return {
        title: (_a = i === null || i === void 0 ? void 0 : i.Title) === null || _a === void 0 ? void 0 : _a._text,
        url: resolveUrl((_b = i === null || i === void 0 ? void 0 : i.URL) === null || _b === void 0 ? void 0 : _b._text),
        imageUrl: (_c = i === null || i === void 0 ? void 0 : i.ImageURL) === null || _c === void 0 ? void 0 : _c._text
    };
}).filter(i => i === null || i === void 0 ? void 0 : i.title);
$: recentGames = [].concat((_k = data === null || data === void 0 ? void 0 : data.RecentGames) === null || _k === void 0 ? void 0 : _k.RecentGame).map(i => {
    var _a, _b, _c;
    if (!i) {
        return false;
    }
    return {
        id: (_a = i === null || i === void 0 ? void 0 : i._attributes) === null || _a === void 0 ? void 0 : _a.Id,
        homeOutcome: pastGameOutcome(i === null || i === void 0 ? void 0 : i._attributes, 'home'),
        homeScore: (_b = i === null || i === void 0 ? void 0 : i._attributes) === null || _b === void 0 ? void 0 : _b.HomeScore,
        awayOutcome: pastGameOutcome(i === null || i === void 0 ? void 0 : i._attributes, 'away'),
        awayScore: (_c = i === null || i === void 0 ? void 0 : i._attributes) === null || _c === void 0 ? void 0 : _c.AwayScore
    };
});
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeName = (_l = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _l === void 0 ? void 0 : _l.Name;
$: homeScore = (_o = (_m = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _m === void 0 ? void 0 : _m._attributes) === null || _o === void 0 ? void 0 : _o.Score;
$: homeScoringPlays = [].concat((_q = (_p = data === null || data === void 0 ? void 0 : data.Scoring) === null || _p === void 0 ? void 0 : _p.ScoringPeriod) === null || _q === void 0 ? void 0 : _q.ScoringPlay).map(i => {
    var _a, _b, _c, _d;
    return {
        teamTricode: (_a = i === null || i === void 0 ? void 0 : i._attributes) === null || _a === void 0 ? void 0 : _a.Team,
        time: (_b = i === null || i === void 0 ? void 0 : i._attributes) === null || _b === void 0 ? void 0 : _b.Time,
        player: (_c = i === null || i === void 0 ? void 0 : i.Description) === null || _c === void 0 ? void 0 : _c._text,
        isHome: (_d = i === null || i === void 0 ? void 0 : i._attributes) === null || _d === void 0 ? void 0 : _d.IsHome
    };
}).filter(i => (i === null || i === void 0 ? void 0 : i.isHome) === 'true');
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayName = (_r = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _r === void 0 ? void 0 : _r.Name;
$: awayScore = (_t = (_s = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _s === void 0 ? void 0 : _s._attributes) === null || _t === void 0 ? void 0 : _t.Score;
$: awayScoringPlays = [].concat((_v = (_u = data === null || data === void 0 ? void 0 : data.Scoring) === null || _u === void 0 ? void 0 : _u.ScoringPeriod) === null || _v === void 0 ? void 0 : _v.ScoringPlay).map(i => {
    var _a, _b, _c, _d;
    return {
        teamTricode: (_a = i === null || i === void 0 ? void 0 : i._attributes) === null || _a === void 0 ? void 0 : _a.Team,
        time: (_b = i === null || i === void 0 ? void 0 : i._attributes) === null || _b === void 0 ? void 0 : _b.Time,
        player: (_c = i === null || i === void 0 ? void 0 : i.Description) === null || _c === void 0 ? void 0 : _c._text,
        isHome: (_d = i === null || i === void 0 ? void 0 : i._attributes) === null || _d === void 0 ? void 0 : _d.IsHome
    };
}).filter(i => (i === null || i === void 0 ? void 0 : i.isHome) === 'false');
$: state = (_w = data === null || data === void 0 ? void 0 : data.State) === null || _w === void 0 ? void 0 : _w._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_x = data === null || data === void 0 ? void 0 : data.GameState) === null || _x === void 0 ? void 0 : _x._text;
$: clock = (_y = data === null || data === void 0 ? void 0 : data.Clock) === null || _y === void 0 ? void 0 : _y._text;
$: round = (_z = data === null || data === void 0 ? void 0 : data.Round) === null || _z === void 0 ? void 0 : _z._text;
$: {
    contentActive = isDefault;
}
</script>