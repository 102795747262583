<CardBase {element}>
<div class="suplentes">
	<div class="heading">
		{#if homeTeam && homeTeam._attributes}
		<div class="team team-1 logo">
			<span><TeamLogo teamObject={homeTeam} /></span>
		</div>
		{/if}
		<div class="label">Suplentes</div>
		{#if awayTeam && awayTeam._attributes}
		<div class="team team-2 logo">
			<span><TeamLogo teamObject={awayTeam} /></span>
		</div>
		{/if}
	</div>
	
	{#if homeSubs.length}
	{#each homeSubs as player, i}
	<div class="row">
		<div class="team team-1">
			<div class="player">
				<img src="{player.playerThumbnail}" alt="{player.number}">
				<div class="player-detail">
					<div class="number">{player.number}</div>
					<div class="name">{player.name}</div>
					<div class="icons" class:yellow-card="{player._attributes.YellowCards !== '0'}" class:red-card="{player._attributes.RedCards !== '0'}" class:goal="{player._attributes.Goals !== '0'}" class:subbed-on="{player._attributes.SubbedOn !== 'false'}" class:subbed-off="{player._attributes.SubbedOff !== 'false'}">
						<img class="icon goal" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_goal.svg" alt="Goal" />
						<img class="icon assist" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_a.svg" alt="Assist" />
						<img class="icon yellow-card" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_yellow-card.svg" alt="Yellow Card" />
						<img class="icon red-card" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_red-card.svg" alt="Red Card" />
						<img class="icon subbed-on" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_success.svg" alt="Subbed On" />
						<img class="icon subbed-off" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_failure.svg" alt="Subbed Off" />
						<img class="icon expulsion" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_expulsion.svg" alt="expulsion" />
					</div>
				</div>
			</div>
		</div>
		<div class="team team-2">
			<div class="player">
				{#if awaySubs[i]}
				<img src="{awaySubs[i].playerThumbnail}" alt="{awaySubs[i].name}">
				<div class="player-detail">
					<div class="number">{awaySubs[i].number}</div>
					<div class="name">{awaySubs[i].name}</div>
					<div class="icons" class:yellow-card="{awaySubs[i]._attributes.YellowCards !== '0'}" class:red-card="{awaySubs[i]._attributes.RedCards !== '0'}" class:goal="{awaySubs[i]._attributes.Goals !== '0'}" class:subbed-on="{awaySubs[i]._attributes.SubbedOn !== 'false'}" class:subbed-off="{awaySubs[i]._attributes.SubbedOff !== 'false'}">						
						<img class="icon goal" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_goal.svg" alt="Goal" />
						<img class="icon yellow-card" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_yellow-card.svg" alt="Yellow Card" />
						<img class="icon red-card" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_red-card.svg" alt="Red Card" />
						<img class="icon subbed-on" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_success.svg" alt="Subbed On" />
						<img class="icon subbed-off" src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_failure.svg" alt="Subbed Off" />
					</div>
				</div>
				{/if}
			</div>
		</div>
	</div>
	{/each}
	{#if hasCoaches}
	<div class="row-label">
		<div class="label">Entrenador<!--{coachLabel}--></div>
	</div>
	<div class="row">
		<div class="team team-1">
			<div class="player">
				<img src="{homeCoachThumbnail}" alt="{homeCoach.Name}">
				<div class="player-detail">
					<div class="number"></div>
					<div class="name">{homeCoach.Name}</div>
				</div>
			</div>
		</div>
		<div class="team team-2">
			<div class="player">
				<img src="{awayCoachThumbnail}" alt="{awayCoach.Name}">
				<div class="player-detail">
					<div class="number"></div>
					<div class="name">{awayCoach.Name}</div>
				</div>
			</div>
		</div>
	</div>
	{/if}
	{:else}
	{#each playerArray as player}
	<div class="row dummy">
		<div class="team team-1">
			<div class="player">
				<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/dummy-player.jpg" alt="Player">
				<div class="player-detail">
					<div class="number"></div>
					<div class="name"></div>
				</div>
			</div>
		</div>
		<div class="team team-2">
			<div class="player">
				<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/dummy-player.jpg" alt="Player">
				<div class="player-detail">
					<div class="number"></div>
					<div class="name"></div>
				</div>
			</div>
		</div>
	</div>
	{/each}
	{/if}
	
</div>
</CardBase>

<style type="text/css">*, *:before, *:after {
  box-sizing:border-box;
}

.suplentes {
  width:100%;
  padding:12px 0;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
}

.suplentes .heading, .suplentes .row {
  width:100%;
  margin:10px 0;
  padding:0 12px;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items:center;
}

.heading .logo {
  width:40px;
  height:auto;
  display:block;
}

.heading .logo span {
  width:100%;
  height:auto;
  display:block;
}

.heading .team {
  min-width:36px;
  color:var(--neutrals-grey-lighter-20);
  font-size:14px;
  font-weight:bold;
  text-align:center;
  line-height:1.5;
  display:block;
}

.heading .label, .row-label .label {
  text-align:center;
  color: var(--neutrals-grey-darker-40);
  font-size:14px;
  line-height:1.5;
}

.suplentes .row {
  width:100%;
  margin:0;
  padding:10px 0;
  border-bottom:solid 1px rgba(0,0,0,0.1);
}

.row:last-child {
  border-bottom:none;
}

.row .team {
  width:50%;
}

.player {
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
}

.row .team:first-child .player {
  padding:0 0 0 18px;
}

.row .team:nth-child(2) .player {
  padding:0 18px 0 0;
}

.row.dummy .name {
  width:68px;
  height:12px;
  border-radius:6px;
  background:#e9ebed;
}

.player > *:not(:first-child) {
  margin:0 4px;
}

.suplentes > div .team + .team .player {
  flex-direction:row-reverse;
}

.player img {
  width:32px;
  height:32px;
  border-radius:16px;
  display:block;
}

.player .name, .player .number {
  font-size:12px;
  line-height:1;
}

.player .number {
  color: var(--neutrals-grey-darker-10);
}

.player .name {
  color: var(--neutrals-black-black-10);
}

.suplentes > div .team:first-child .player .name, .suplentes > div .team:first-child .player .number {
  margin-left:4px;
}

.suplentes > div .team + .team .player .name, .suplentes > div .team + .team .player .number {
  margin-right:4px;
}

.player .player-detail {
  width:100%;
  display:flex;
  flex-wrap:wrap;
}

.suplentes > div .team + .team .player .player-detail .icons {
  justify-content:flex-end;
}

.suplentes > div .team + .team .player .player-detail {
  flex-direction:row-reverse;
}

.player .player-detail .icons {
  width:100%;
  padding:0 3px;
  display:flex;
}

.player .player-detail .icons:empty {
  display:none;
}

.player .player-detail .icons .icon {
  width:12px;
  height:12px;
  border-radius:50%;
  background-repeat:no-repeat;
  background-size:cover;
  background:black;
  display:none;
}

.suplentes > div .team:first-child .icons .icon {
  margin:2px 4px 2px 0;
}

.suplentes > div .team + .team .icons .icon {
  margin:2px 0 2px 4px;
}

.player .player-detail .icons.goal .icon.goal {
  display:flex;
}

.player .player-detail .icons.yellow-card .icon.yellow-card {
  display:flex;
}

.player .player-detail .icons.red-card .icon.red-card {
  display:flex;
}

.player .player-detail .icons.subbed-on .icon.subbed-on {
  display:flex;
}

.player .player-detail .icons .icon.assists {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_a.svg);
  display:flex;
}

.suplentes .row-label {
  width:100%;
  margin:10px 0;
  padding:0 12px;
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:center;
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { t } from "../../../../../js/i18n";
import { findSourceStore, getFirstSourceStore, expandTemplateParams } from "../../../utilities";
import YCLink from "../../../utilities/YCLink.svelte";
import { Readable } from "svelte/store";
import { getContext, onMount } from "svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
import TeamLogo from '../../../utilities/TeamLogo.svelte';
export let element;
export let sources;
let defaultPlayerThumbnail = 'https://resources-us.yinzcam.com/csf/shared/icons/icon_headshot_placeholder.png';
let hasCoaches = false;
let playerArray = new Array(11); //this is used for dummy data if no lineups are available
function statMapper(stat) {
    var _a;
    let statLabel = (_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Name.toLowerCase().replace(/ /g, '-');
    return statLabel;
}
function subsMapper(sub) {
    var _a, _b;
    let attrs = sub === null || sub === void 0 ? void 0 : sub._attributes;
    return {
        id: attrs === null || attrs === void 0 ? void 0 : attrs.Id,
        number: attrs === null || attrs === void 0 ? void 0 : attrs.Number,
        name: attrs === null || attrs === void 0 ? void 0 : attrs.Name,
        _attributes: attrs,
        playerThumbnail: ((_a = sub === null || sub === void 0 ? void 0 : sub.ImageUrl) === null || _a === void 0 ? void 0 : _a._text) || defaultPlayerThumbnail,
        card: (((attrs === null || attrs === void 0 ? void 0 : attrs.RedCards) || 0) > 0) ? 'red' : ((((attrs === null || attrs === void 0 ? void 0 : attrs.YellowCards) || 0) > 0) ? 'yellow' : 'none'),
        stats: [].concat((_b = sub === null || sub === void 0 ? void 0 : sub.GameStats) === null || _b === void 0 ? void 0 : _b.Stat).filter(stat => { var _a; return parseInt((_a = stat._attributes) === null || _a === void 0 ? void 0 : _a.Value, 10) !== 0; }).map(statMapper)
    };
}
$: source = findSourceStore($sources, 'YinzCamAppServerGameFormation');
$: data = (source) ? $source : {};
$: console.log('suplentes', data);
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeCoach = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.Coaches) === null || _a === void 0 ? void 0 : _a.HomeTeam) === null || _b === void 0 ? void 0 : _b.Player) === null || _c === void 0 ? void 0 : _c._attributes;
$: homeCoachThumbnail = ((_g = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.Coaches) === null || _d === void 0 ? void 0 : _d.HomeTeam) === null || _e === void 0 ? void 0 : _e.Player) === null || _f === void 0 ? void 0 : _f.ImageUrl) === null || _g === void 0 ? void 0 : _g._text) || 'https://yc-websites.s3.amazonaws.com/lfp/shared/dummy-player.jpg';
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayCoach = (_k = (_j = (_h = data === null || data === void 0 ? void 0 : data.Coaches) === null || _h === void 0 ? void 0 : _h.AwayTeam) === null || _j === void 0 ? void 0 : _j.Player) === null || _k === void 0 ? void 0 : _k._attributes;
$: awayCoachThumbnail = ((_p = (_o = (_m = (_l = data === null || data === void 0 ? void 0 : data.Coaches) === null || _l === void 0 ? void 0 : _l.AwayTeam) === null || _m === void 0 ? void 0 : _m.Player) === null || _o === void 0 ? void 0 : _o.ImageUrl) === null || _p === void 0 ? void 0 : _p._text) || 'https://yc-websites.s3.amazonaws.com/lfp/shared/dummy-player.jpg';
$: hasCoaches = (!(homeCoach === null || homeCoach === void 0 ? void 0 : homeCoach.Id) || !(awayCoach === null || awayCoach === void 0 ? void 0 : awayCoach.Id)) ? false : true;
$: coachLabel = (_r = (_q = data === null || data === void 0 ? void 0 : data.Coaches) === null || _q === void 0 ? void 0 : _q._attributes) === null || _r === void 0 ? void 0 : _r.Title;
$: substitutes = data === null || data === void 0 ? void 0 : data.Substitutes;
$: awaySubs = [].concat((_s = substitutes === null || substitutes === void 0 ? void 0 : substitutes.AwayTeam) === null || _s === void 0 ? void 0 : _s.Player).filter(sub => { var _a; return (_a = sub === null || sub === void 0 ? void 0 : sub._attributes) === null || _a === void 0 ? void 0 : _a.Id; }).map(subsMapper);
$: homeSubs = [].concat((_t = substitutes === null || substitutes === void 0 ? void 0 : substitutes.HomeTeam) === null || _t === void 0 ? void 0 : _t.Player).filter(sub => { var _a; return (_a = sub === null || sub === void 0 ? void 0 : sub._attributes) === null || _a === void 0 ? void 0 : _a.Id; }).map(subsMapper);
</script>