<script lang="ts">export let Play = {};
</script>

<div class="live-reporting-image-card">
  <div class="live-reporting-image-card-image">
	<img src={Play.ImageUrl} alt="Image" />
	{#if Play.Text}
      <div class="title">{Play.Text}</div>
    {/if}
  </div>
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.live-reporting-image-card-image {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
}

.live-reporting-image-card-image img {
  max-width: 100%;
  max-height: 800px;
  object-fit: scale-down;
  border-radius: 12px;
  display: block;
}

.live-reporting-image-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.live-reporting-image-card .title {
  margin: 6px 0 0 0;
  font-family: AeonikTRIAL;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #202020;
}

@media (max-width: 767px) {
  .live-reporting-image-card .title {
    margin: 2px 0 0 0;
    font-size: 14px;
  }
}</style>
