import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIRequestComponent, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';

export const YinzCamCardsServerV2Token = new Token<YinzCamCardsServerV2>(Symbol.for("YinzCamCardsServerV2Token"));

@injectable()
export class YinzCamCardsServerV2 {
  private readonly server: YinzCamServer;

  public constructor(@injectToken(YinzCamAPIToken) private readonly api: YinzCamAPI) {
    this.server = api.getServer('pages');
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public getPage(id: string): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: `pages/${id}` });
  }

  public getFragment(id: string): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: `fragments/${id}` });
  }

  public getMessages(id: string): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: `messages/${id}` });
  }

  public getMenu(id: string): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: `menus/${id}` });
  }
}
