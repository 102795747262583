<script lang="ts">var _a, _b;
import ImageWithPlaceholder from '../../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
import iconSubstitue from '../../image/round_arrow.png';
import iconFootball from '../../image/football_icon_white.png';
import iconCorner from '../../image/corner_icon.png';
import iconFoul from '../../image/foul_mark.png';
import iconYellow from '../../image/actions/icon_yellow@3x.png';
import iconRed from '../../image/actions/icon_red@3x.png';
import iconOffside from '../../image/offsides_mark.png';
import iconStartPeriod from '../../image/start_icon.png';
import iconEndPeriod from '../../image/end_icon.png';
import iconOwnGoal from '../../image/own_goal.png';
import iconSecondYellow from '../../image/actions/icon_double_yellow@3x.png';
export let Play = {};
export let substitution = true;
let timeStamp = new Date();
const icons = {
    CORNER: iconCorner,
    SUB: iconSubstitue,
    FOUL: iconFoul,
    GOAL: iconFootball,
    YELLOW: iconYellow,
    RED: iconRed,
    OFF_SIDE: iconOffside,
    START_PERIOD: iconStartPeriod,
    END_PERIOD: iconEndPeriod,
    OWN_GOAL: iconOwnGoal,
    SECOND_YELLOW: iconSecondYellow
};
$: TeamLogo = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_b = (_a = Play === null || Play === void 0 ? void 0 : Play.PlayerTeam) === null || _a === void 0 ? void 0 : _a.LogoId) === null || _b === void 0 ? void 0 : _b.toLowerCase()}_dark.png`;
$: timeStamp = Play.Timestamp ? new Date(Play.Timestamp) : new Date();
</script>

<div class="live-reporting-card">
  <div class="time-container">
    <span>{Play.Time || Play.TimeDetail || (Play.Timestamp ? `${timeStamp.getHours()}:${timeStamp.getMinutes()}` : "")}</span>
  </div>
  <div class="live-content">
    {#if Play.Title}
      <div class="heading">
        {#if icons[Play.Type]}
          <img src={icons[Play.Type]} alt="RoundArrow" class={Play.Type} />
        {/if}
        <h3>{Play.Title || ''}</h3>
      </div>
    {/if}
    <div class="live-body">{Play.Text || ''}</div>
  </div>
  {#if Play.IsHome !== null}
    <div class="team-logo-wrapper">
      <ImageWithPlaceholder src={TeamLogo} alt="TeamLogo" class="team-logo" placeholder="/static/images/default_club.png" />
    </div>
  {/if}
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.live-reporting-card {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 0;
  background-color: #132048;
  border-radius: 12px;
  border: solid 1.5px #e5e5e5;
}

.live-reporting-card .time-container {
  width: 118px;
  padding: 0 0 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-reporting-card .time-container span {
  line-height: 31px;
  font-family: AeonikTRIAL;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.23;
  color: #ffffff;
}

.live-reporting-card .team-logo-wrapper {
  width: 104px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-reporting-card .team-logo-wrapper :global(.team-logo) {
  height: 42px;
  display: inline-block;
}

.live-reporting-card .live-content {
  width: 100%;
}

.live-reporting-card .live-content .heading {
  display: flex;
  align-items: center;
  margin: 0 0 12px 0;
}

.live-reporting-card .live-content .heading img {
  display: block;
  height: 30px;
  margin: 0 16px 0 0;
}

.live-reporting-card .live-content .heading h3 {
  margin: 0;
  padding: 0;
  line-height: 29px;
  font-family: AeonikTRIAL;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
}

.live-reporting-card .live-content .live-body {
  line-height: 21px;
  font-family: AeonikTRIAL;
  font-size: 18px;
  color: #ffffff;
  white-space: pre-line;
}

@media (max-width: 767px) {
  .live-reporting-card {
    padding: 12px 0;
  }

  .live-reporting-card .time-container {
    width: 60px;
    padding: 0;
  }

  .live-reporting-card .time-container span {
    line-height: 26.5px;
    font-size: 14px;
    line-height: 1.23;
  }

  .live-reporting-card .team-logo-wrapper {
    width: 46px;
  }

  .live-reporting-card .team-logo-wrapper :global(.team-logo) {
    height: 28px;
  }

  .live-reporting-card .live-content .heading {
    margin: 0 0 4px 0;
  }

  .live-reporting-card .live-content .heading img {
    height: 13px;
    margin: 0 8px 0 0;
  }

  .live-reporting-card .live-content .heading h3 {
    line-height: 22px;
    font-size: 14px;
    font-weight: bold;
  }

  .live-reporting-card .live-content .live-body {
    line-height: 1.33;
    font-size: 14px;
  }
}</style>
