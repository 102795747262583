import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamAPI, YinzCamAPIToken } from "./YinzCamAPI";
import { Dictionary } from "typescript-collections";
import { YinzCamServer } from "./YinzCamServer";
import { YinzCamInjectModule } from "yinzcam-inject";

const SERVER_CACHE: Dictionary<string, YinzCamServer> = new Dictionary<string, YinzCamServer>();
function getServerCached(service: string, tricode: string, league: string, loader: () => YinzCamServer): YinzCamServer {
  const key = `${service}-${tricode}-${league}`;
  let server = SERVER_CACHE.getValue(key);
  if (!server) {
    server = loader();
    SERVER_CACHE.setValue(key, server);
  }
  return server;
}

export let YinzCamAPIModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    // TODO: To support mode switching, expose a factory that can create an API for different leagues and tricodes
    bindToken(YinzCamAPIToken).to(YinzCamAPI).inSingletonScope();
  }));
});
