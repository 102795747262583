<Boundary> 
  <div style="
    display: grid;
    grid-template-columns: repeat({(layout.splitEqually)? ((layout.elements)? layout.elements.length : 1) : layout.maxColumns || 1}, 1fr);
    grid-auto-rows: min-content;
    grid-gap: {layout.gapPixels || 10}px;
    width: 100%;
  ">
  {#each layout.elements as element, i (element.id)}
    <div style="
      grid-column-end: {(element.span && element.span.columns)? `${element.span.columns} span` : 'initial'};
      grid-row-end: {(element.span && element.span.rows)? `${element.span.rows} span` : 'initial'};
      {childContentAvailable[i] ? "" : "display: none;"}
    ">
      <CardsElement sequenceId={generateSequenceId(parentSequenceId, i)} {element} height='100%' bind:contentAvailable={childContentAvailable[i]} />
    </div>
  {/each}
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceArrayLayout } from "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
export let parentSequenceId = "";
export let layout;
export let contentAvailable = true;
let childContentAvailable = [];
$: {
    contentAvailable = childContentAvailable.some((item) => item);
}
</script>
