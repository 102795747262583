<CardBase {element}>
    <a class="scores-box" class:display-none={!$source} class:state-pregame={isPregame} class:state-live={isCurrent} class:state-final={isFinal} href={getHrefForMatchCenter(gameId, state)}>
		{#if $source}
		<div class="scores-title">
            <span class="scores-date">{$tdate(gameTime, { month: 'short', day: 'numeric' })}</span>
            <span class="scores-tag">{$t('TC')}</span>
        </div>
        <div class="scores-wrapper winning-team-{winningTeam}">
            <div class="home scores-display">
            	<span class="team-icon"><TeamLogo teamObject={homeTeam} /></span>
                <span class="scores-team-name">{homeName}</span>
                <span class="scores-number">{homeScore}</span>
            </div>
            <div class="away scores-display">
            	<span class="team-icon"><TeamLogo teamObject={awayTeam} /></span>
                <span class="scores-team-name">{awayName}</span>
                <span class="scores-number">{awayScore}</span>
            </div>
        </div>
		{/if}
	</a>
</CardBase>

<style type="text/css">.scores-box {
  width:100%;
  display:flex;
  flex-direction:column;
}

.scores-title {
  width:100%;
  margin:10 0;
  text-align:left;
}

.scores-date {
  margin-left: 10%;
  font-weight:normal;
  font-size: 12px;
}

.scores-tag {
  position:absolute;
  right:10%;
  font-weight:normal;
  font-size:12px;
}

.scores-wrapper {
  padding:12px 10%;
  display:flex;
  justify-content:space-between;
}

.scores-display {
  width:43%;
  padding:12px 6px;
  border:1px solid #FFF;
  border-radius:10px;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.winning-team-home .home.scores-display {
  border:1px solid var(--f7-theme-color-border-color);
}

.winning-team-away .away.scores-display {
  border:1px solid var(--f7-theme-color-border-color);
}

.team-icon {
  width:30%;
  margin:0 35%;
}

.scores-team-name {
  width:100%;
  margin:3px 0;
  font-weight:bold;
  font-size:12px;
  text-align:center;
}

.scores-number{
  font-size:24px;
  font-weight:bold;
}

:not(.state-final) .scores-tag {
  visibility:hidden;
}

.state-final .scores-tag {
  visibility:visible;
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore, getHrefForMatchCenter } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { t } from "../../../../js/i18n";
import { resolveUrl } from "../../../../js/url";
import YCLink from "../../utilities/YCLink.svelte";
import { tdate, ttime } from "../../../../js/i18n";
export let element;
export let sources;
let winningTeam = false;
$: source = findSourceStore($sources, 'YinzCamAppServerGameScores');
$: data = (source && $source) ? $source : null;
$: gameId = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: timestamp = (_b = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _b === void 0 ? void 0 : _b._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_c = data === null || data === void 0 ? void 0 : data.Venue) === null || _c === void 0 ? void 0 : _c._text;
$: buttons = [].concat((_d = data === null || data === void 0 ? void 0 : data.Buttons) === null || _d === void 0 ? void 0 : _d.Button).map(i => {
    var _a, _b, _c;
    return {
        title: (_a = i === null || i === void 0 ? void 0 : i.Title) === null || _a === void 0 ? void 0 : _a._text,
        url: resolveUrl((_b = i === null || i === void 0 ? void 0 : i.URL) === null || _b === void 0 ? void 0 : _b._text),
        imageUrl: (_c = i === null || i === void 0 ? void 0 : i.ImageURL) === null || _c === void 0 ? void 0 : _c._text
    };
}).filter(i => i === null || i === void 0 ? void 0 : i.title);
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeName = (_e = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _e === void 0 ? void 0 : _e.Name;
$: homeScore = (_g = (_f = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _f === void 0 ? void 0 : _f._attributes) === null || _g === void 0 ? void 0 : _g.Score;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayName = (_h = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _h === void 0 ? void 0 : _h.Name;
$: awayScore = (_k = (_j = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.Score;
$: state = (_l = data === null || data === void 0 ? void 0 : data.State) === null || _l === void 0 ? void 0 : _l._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_m = data === null || data === void 0 ? void 0 : data.GameState) === null || _m === void 0 ? void 0 : _m._text;
$: clock = (_o = data === null || data === void 0 ? void 0 : data.Clock) === null || _o === void 0 ? void 0 : _o._text;
$: if (awayScore > homeScore) {
    winningTeam = 'away';
}
else if (awayScore < homeScore) {
    winningTeam = 'home';
}
</script>