<Boundary>
  {#if !(isNil && blankIfNil)}
    <object data={primaryImage} type="image/png" title="Logo">
      <img src={fallbackImage} alt="Logo">
    </object>
  {/if}
</Boundary>

<style>*,*:before,*:after {
  box-sizing: border-box;
}

object {
  width: 100%;
  height: auto;
  display: block;
}</style>

<script lang="ts">import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { getTeamLogoFallback, getTeamLogoFromLogoId, getTeamLogoFromTeamObject, getTeamLogoFromTricode } from './index';
export let logoId = null;
export let tricode = null;
export let teamObject = null;
export let blankIfNil = false;
let primaryImage = '#';
let fallbackImage = '#';
let isNil = false;
$: {
    fallbackImage = getTeamLogoFallback();
    isNil = false;
    if (logoId) {
        primaryImage = getTeamLogoFromLogoId(logoId);
    }
    else if (tricode) {
        primaryImage = getTeamLogoFromTricode(tricode);
    }
    else if (teamObject) {
        primaryImage = getTeamLogoFromTeamObject(teamObject);
    }
    else {
        primaryImage = fallbackImage;
        isNil = true;
    }
}
</script>