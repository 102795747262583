<CardBase {element}>
	<a href={getHrefForMatchCenter(gameId, state)}>
		<div class="schedule-card" class:state-pregame={isPregame} class:state-live={isCurrent} class:state-final={isFinal}>
			<div class="date">
				{$tdate(gameTime, { month: 'short', day: 'numeric' })}
			</div>
			<div class="game-detail">
				<div class="team team-1">
					<span class='logo'><TeamLogo teamObject={homeTeam} blankIfNil={true} /></span>
					<div class="score">{homeScore || ''}</div>
				</div>
				<div class="detail">
					<div class="matchup">
						<span class="team-name team-1">{homeName || ''}</span>
						<span class="designation">{$t("vs.")}</span>
						<span class="team-name team-2">{awayName || ''}</span>
					</div>
					<div class="game-start">{$ttime(gameTime, { hour: 'numeric', minute: 'numeric' })}</div>
					<div class="clock">{clock || ''}</div>
					<div class="state">{stateFmt || ''}</div>
					<div class="location">{venue || ''}</div>
				</div>
				<div class="team team-2">
					<span class='logo'><TeamLogo teamObject={awayTeam} blankIfNil={true} /></span>
					<div class="score">{awayScore || ''}</div>
				</div>
			</div>
			<div class="btn-wrapper">
				{#each buttons as button (button.title) }
					<YCLink resolved={button.url}>{button.title || ''}</YCLink>
				{/each}
			</div>
		</div>
	</a>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.schedule-card {
  width:100%;
  font-family:var(--f7-font-family);
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  overflow:hidden;
}

.schedule-card .date {
  width:100%;
  padding:2px 12px;
  background-color:var(--f7-theme-primary);
  color:#000;
  font-weight:bold;
  text-transform:uppercase;
}

.game-detail {
  width:100%;
  min-height:140px;
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  overflow:hidden;
}

.schedule-card .team {
  width:30%;
}

.schedule-card .team .logo {
  width:70%;
  height:auto;
  margin:0 auto;
  padding:12px;
  display:block;
}

.schedule-card .team .score {
  width:100%;
  padding:0 6px;
  font-weight:bold;
  font-size:3.5vw;
  color:#FFF;
}

.schedule-card .detail {
  width:40%;
}

.schedule-card .detail > div {
  color:#FFF;
}

.schedule-card .detail > div:empty {
  display:none;
}

.schedule-card .game-start, .schedule-card .state, .schedule-card .clock {
  margin:12px 0;
  font-weight:bold;
  font-size:1.25vw;
  text-transform:uppercase;
  display:none;
}

.schedule-card .detail .location {
  color:rgba(255,255,255,0.75);
}

.schedule-card .btn-wrapper {
  width:100%;
  margin:6px 0 0 0;
  padding:0;
  border-top:solid 1px var(--f7-theme-color-border-color);
  display:flex;
  flex-wrap:nowrap;
  /*justify-content:space-around;*/
  align-items:center;
}

.schedule-card .btn-wrapper:empty {
  display:none;
}

.schedule-card .btn-wrapper :global(a) {
  width:100%;
  padding:12px;
  color:#FFF;
  font-weight:bold;
  font-size:1vw;
  text-decoration:none;
  text-transform:uppercase;
  /*display:none;*/
}

.schedule-card .btn-wrapper :global(a + a) {
  border-left:solid 1px var(--f7-theme-color-border-color);
}

/*pregame state*/

.schedule-card.state-pregame .pregame {
  display:block;
}

.schedule-card.state-pregame .score {
  visibility:hidden;
}

.schedule-card.state-pregame .game-start {
  display:block;
}

/*live game state*/

.schedule-card.state-live .clock {
  display:block;
}

.schedule-card.state-live .live {
  display:block;
}

/*final game state*/

.schedule-card.state-final .state {
  display:block;
}

.schedule-card.state-final .final {
  display:block;
}

@media screen and (max-width:1000px){
  .schedule-card .team .score {
    font-size:5vw;
  }

  .schedule-card .date, .schedule-card .game-start, .schedule-card .state, .schedule-card .clock, .schedule-card .btn-wrapper :global(a) {
    font-size:1.75vw;
  }
}

@media screen and (max-width:767px){
  .schedule-card .team .score {
    font-size:7vw;
  }

  .schedule-card .matchup, .schedule-card .location {
    font-size:3vw;
  }

  .schedule-card .date, .schedule-card .game-start, .schedule-card .state, .schedule-card .clock, .schedule-card .btn-wrapper :global(a) {
    font-size:2vw;
  }
}

@media screen and (max-width:500px){
  .schedule-card .matchup, .schedule-card .location {
    font-size:3vw;
  }

  .schedule-card .date, .schedule-card .game-start, .schedule-card .state, .schedule-card .clock, .schedule-card .btn-wrapper :global(a) {
    font-size:4vw;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore, getHrefForMatchCenter } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { t } from "../../../../js/i18n";
import { resolveUrl } from "../../../../js/url";
import YCLink from "../../utilities/YCLink.svelte";
import { tdate, ttime } from "../../../../js/i18n";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGameScores');
}
;
$: data = (sourceStore && $sourceStore) ? $sourceStore : null;
$: gameId = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: timestamp = (_b = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _b === void 0 ? void 0 : _b._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_c = data === null || data === void 0 ? void 0 : data.Venue) === null || _c === void 0 ? void 0 : _c._text;
$: buttons = [].concat((_d = data === null || data === void 0 ? void 0 : data.Buttons) === null || _d === void 0 ? void 0 : _d.Button).map(i => {
    var _a, _b, _c;
    return {
        title: (_a = i === null || i === void 0 ? void 0 : i.Title) === null || _a === void 0 ? void 0 : _a._text,
        url: resolveUrl((_b = i === null || i === void 0 ? void 0 : i.URL) === null || _b === void 0 ? void 0 : _b._text),
        imageUrl: (_c = i === null || i === void 0 ? void 0 : i.ImageURL) === null || _c === void 0 ? void 0 : _c._text
    };
}).filter(i => i === null || i === void 0 ? void 0 : i.title);
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeName = (_e = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _e === void 0 ? void 0 : _e.Name;
$: homeScore = (_g = (_f = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _f === void 0 ? void 0 : _f._attributes) === null || _g === void 0 ? void 0 : _g.Score;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayName = (_h = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _h === void 0 ? void 0 : _h.Name;
$: awayScore = (_k = (_j = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.Score;
$: state = (_l = data === null || data === void 0 ? void 0 : data.State) === null || _l === void 0 ? void 0 : _l._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_m = data === null || data === void 0 ? void 0 : data.GameState) === null || _m === void 0 ? void 0 : _m._text;
$: clock = (_o = data === null || data === void 0 ? void 0 : data.Clock) === null || _o === void 0 ? void 0 : _o._text;
</script>