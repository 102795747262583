<CardBase {element}>
	<div class="schedule-card" class:state-pregame={isPregame} class:state-live={isCurrent} class:state-final={isFinal} class:display-none={!$source}>
		{#if $source}
		<div class="team team-1">
			<span class='logo'><TeamLogo teamObject={homeTeam} /></span>
			<span class="team-name team-1">{homeName}</span>
			<div class="last3">
				{#each homeWLD as wld }
					<div class="last3-item-wrapper">
						<div class="last3-item" data-wld={wld.value}>
							<div class="last3-text">{wld.text}</div>
						</div>
					</div>
				{/each}
			</div>
			<div class="score">{homeScore}</div>
		</div>
		<div class="detail">
			<div class="game-start">{$tdate(gameTime, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</div>
			<div class="clock">{clock}</div>
			<div class="state">{stateFmt}</div>
			<div class="location">{venue}</div>
		</div>
		<div class="team team-2">
			<span class='logo'><TeamLogo teamObject={awayTeam} /></span>
			<span class="team-name team-2">{awayName}</span>
			<div class="last3">
				{#each awayWLD as wld }
					<div class="last3-item-wrapper">
						<div class="last3-item" data-wld={wld.value}>
							<div class="last3-text">{wld.text}</div>
						</div>
					</div>
				{/each}
			</div>
			<div class="score">{awayScore}</div>
		</div>
		{/if}
	</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.schedule-card {
  width:100%;
  padding:12px 6px;
  background-color:#000;
  font-family:var(--f7-font-family);
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  overflow:hidden;
}

.schedule-card .team {
  width:30%;
}

.schedule-card .team .logo {
  width:70%;
  height:auto;
  margin:0 auto;
  padding:12px;
  display:block;
}

.schedule-card .team .score {
  width:100%;
  padding:0 6px;
  font-weight:bold;
  font-size:3.5vw;
  color:#FFF;
}

.schedule-card .detail {
  width:40%;
}

.schedule-card .detail > div {
  color:#FFF;
}

.schedule-card .detail > div:empty {
  display:none;
}

.schedule-card .game-start, .schedule-card .state, .schedule-card .clock {
  margin:12px 0;
  font-weight:bold;
  font-size:1.25vw;
  text-transform:uppercase;
  display:none;
}

.schedule-card .team-name {
  display: none;
  height: 3em;
  overflow: hidden;
  padding-bottom: 5px;
}

.schedule-card .last3 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.schedule-card .last3 .last3-item-wrapper {
  font-size: 0.9em;
  flex: 0 0 1.5em;
  margin: 0px 2px 0px 2px;
}

.schedule-card .last3 .last3-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background: rgb(127, 127, 127);
}

.schedule-card .last3 .last3-item[data-wld="L"] {
  background: rgb(255, 0, 0);
}

.schedule-card .last3 .last3-item[data-wld="W"] {
  background: rgb(0, 211, 0);
}

.schedule-card .last3 .last3-item-wrapper .last3-item .last3-text {
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.schedule-card .detail .location {
  color:rgba(255,255,255,0.75);
}

/*pregame state*/

.schedule-card.state-pregame .pregame {
  display:block;
}

.schedule-card.state-pregame .score {
  display: none;
}

.schedule-card.state-pregame .game-start {
  display:block;
}

.schedule-card.state-pregame .team-name {
  display:block;
}

.schedule-card.state-pregame .last3 {
  display:flex;
}

/*live game state*/

.schedule-card.state-live .clock {
  display:block;
}

.schedule-card.state-live .live {
  display:block;
}

/*final game state*/

.schedule-card.state-final .state {
  display:block;
}

.schedule-card.state-final .final {
  display:block;
}

@media screen and (max-width:1000px){
  .schedule-card .team .score {
    font-size:5vw;
  }

  .schedule-card .date, .schedule-card .game-start, .schedule-card .state, .schedule-card .clock, .schedule-card .btn-wrapper a {
    font-size:1.75vw;
  }
}

@media screen and (max-width:767px){
  .schedule-card .team .score {
    font-size:7vw;
  }

  .schedule-card .matchup, .schedule-card .location {
    font-size:3vw;
  }

  .schedule-card .date, .schedule-card .game-start, .schedule-card .state, .schedule-card .clock, .schedule-card .btn-wrapper a {
    font-size:2vw;
  }
}

@media screen and (max-width:500px){
  .schedule-card .matchup, .schedule-card .location {
    font-size:3vw;
  }

  .schedule-card .date, .schedule-card .game-start, .schedule-card .state, .schedule-card .clock, .schedule-card .btn-wrapper a {
    font-size:4vw;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { tdate, ttime } from "../../../../js/i18n";
export let element;
export let sources;
$: source = findSourceStore($sources, 'YinzCamAppServerGameBox');
$: data = (source && $source) ? $source : null;
$: gameId = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: timestamp = (_b = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _b === void 0 ? void 0 : _b._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_c = data === null || data === void 0 ? void 0 : data.Venue) === null || _c === void 0 ? void 0 : _c._text;
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeName = (_d = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _d === void 0 ? void 0 : _d.Name;
$: homeScore = (_f = (_e = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.Score;
$: homeWLD = [].concat((_g = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam.Last) === null || _g === void 0 ? void 0 : _g.Result).filter(r => { var _a; return (_a = r === null || r === void 0 ? void 0 : r._attributes) === null || _a === void 0 ? void 0 : _a.Value; }).slice(0, 3).map(r => {
    var _a, _b;
    return {
        value: (_a = r === null || r === void 0 ? void 0 : r._attributes) === null || _a === void 0 ? void 0 : _a.Value,
        text: ((_b = r === null || r === void 0 ? void 0 : r._attributes) === null || _b === void 0 ? void 0 : _b.Text) || ''
    };
});
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayName = (_h = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _h === void 0 ? void 0 : _h.Name;
$: awayScore = (_k = (_j = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.Score;
$: awayWLD = [].concat((_l = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam.Last) === null || _l === void 0 ? void 0 : _l.Result).filter(r => { var _a; return (_a = r === null || r === void 0 ? void 0 : r._attributes) === null || _a === void 0 ? void 0 : _a.Value; }).slice(0, 3).map(r => {
    var _a, _b;
    return {
        value: (_a = r === null || r === void 0 ? void 0 : r._attributes) === null || _a === void 0 ? void 0 : _a.Value,
        text: ((_b = r === null || r === void 0 ? void 0 : r._attributes) === null || _b === void 0 ? void 0 : _b.Text) || ''
    };
});
$: state = (_m = data === null || data === void 0 ? void 0 : data.State) === null || _m === void 0 ? void 0 : _m._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_o = data === null || data === void 0 ? void 0 : data.GameState) === null || _o === void 0 ? void 0 : _o._text;
$: clock = (_p = data === null || data === void 0 ? void 0 : data.Clock) === null || _p === void 0 ? void 0 : _p._text;
</script>