<CardBase {element}>
{#if $player}
<div class="player-profile">
	<div class="stats">
		<ul class="scrolling-table">
			<li class="legend">
				{#each $player.RecentGamesLegend as column}
				<span class="{column}">{column}</span>
				{/each}
			</li>
			{#each $player.RecentGames as game}
			<li>
				{#each game as stat}
				<span class="">{stat}</span>
				{/each}
			</li>
			{/each}
		</ul>
	</div>
</div>
{/if}
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.player-profile {
  width:100%;
  font-family:var(--f7-font-family);
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
}

.player-profile > div {
  padding:12px 0;
}

.player-profile span {
  font-weight:bold;
  font-size:1vw;
}

.player-profile .stats {
  width:100%;
  padding:0;
  overflow:hidden;
}

ul.scrolling-table {
  width:100%;
  min-width:100%;
  padding:0;
  overflow:scroll;
}

ul.scrolling-table::-webkit-scrollbar {
  display:none;
}

ul.scrolling-table li {
  width:auto;
  display:inline-flex;
}

ul.scrolling-table li span {
  display:block;
}

ul.scrolling-table li > span {
  padding:12px 1px;
  font-size:1vw;
  float:left;
}

ul.scrolling-table li.legend {
  background:#fcd069;
}

ul.scrolling-table li.legend span {
  color:#000;
  text-transform:uppercase;
}

ul.scrolling-table li:not(.legend) {
  background-color:#000;
  color:#FFF;
}

ul.scrolling-table li > span:nth-child(n+2) {
  text-align:center;
}

ul.scrolling-table li > span {
  width:70px;
}

ul.scrolling-table li > span:first-child {
  padding-left:8px;
}

ul.scrolling-table li:not(.legend) > span {
}

ul.scrolling-table li > .opponent {
  width:180px;
}

@media screen and (min-width:1025px){
  ul.scrolling-table li > span:first-child {
    width:220px;
  }

  ul.scrolling-table li > span {
    width:80px;
  }
}

@media screen and (max-width:1000px){
  ul.scrolling-table li > span {
    font-size:1.5vw;
  }
}

@media screen and (max-width:767px){
  .player-profile {
    justify-content:flex-start;
  }

  .player-profile span {
    font-size:2.75vw;
  }

  ul.scrolling-table li > span {
    width:50px;
  }

  ul.scrolling-table li > span:first-child {
    width:170px;
  }

  ul.scrolling-table li:not(.legend) > span:first-child {
    width:170px;
    background-color:#000;
  }

  ul.scrolling-table li:not(.legend) > span {
    background-color:#131313;
  }

  ul.scrolling-table li > span {
    font-size:2vw;
  }
}

@media screen and (max-width:500px){
  ul.scrolling-table li > span {
    font-size:3vw;
  }
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { getContext, onMount } from 'svelte';
import { CardsPageContext, CardsPageContextKey } from '../../common/context';
const pageContext = getContext(CardsPageContextKey);
$: playerId = (_a = pageContext === null || pageContext === void 0 ? void 0 : pageContext.query) === null || _a === void 0 ? void 0 : _a.playerId;
export let element;
export let sources;
let recentGames = [];
let legend = [];
$: player = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
</script>