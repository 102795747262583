<script lang="ts">var _a, _b, _c, _d, _e;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore } from '../../../utilities';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { Readable } from 'svelte/store';
import premierLogo from '../image/Premier_League_logo.png';
export let element;
export let sources;
let tableColumns = [];
let source;
let tableData = [];
$: {
    source = getFirstSourceStore($sources);
    tableColumns = [];
    const statsGroup = ((_c = (_b = (_a = $source === null || $source === void 0 ? void 0 : $source.TeamStats) === null || _a === void 0 ? void 0 : _a.Division) === null || _b === void 0 ? void 0 : _b.StatsGroup) === null || _c === void 0 ? void 0 : _c._attributes) || {};
    for (let i = 3; statsGroup["Stat" + i]; i += 1) {
        tableColumns.push(statsGroup["Stat" + i]);
    }
    const standings = [].concat((_e = (_d = $source === null || $source === void 0 ? void 0 : $source.TeamStats) === null || _d === void 0 ? void 0 : _d.Division) === null || _e === void 0 ? void 0 : _e.Standing).filter(e => e);
    tableData = standings === null || standings === void 0 ? void 0 : standings.map((teamStanding, index) => {
        var _a, _b, _c, _d;
        const teamTriCode = (_a = teamStanding._attributes) === null || _a === void 0 ? void 0 : _a.TriCode;
        const chevronCode = (_b = teamStanding._attributes) === null || _b === void 0 ? void 0 : _b.Change;
        let chevronStatus = 'NONE';
        if (chevronCode === 'U') {
            chevronStatus = 'UP';
        }
        else if (chevronCode === 'D') {
            chevronStatus = 'DOWN';
        }
        let championStatus = '';
        if (index < 4) {
            championStatus = 'GREEN';
        }
        else if (index < 6) {
            championStatus = 'YELLOW';
        }
        else if (index > 16) {
            championStatus = 'RED';
        }
        let retObj = {
            Position: (_c = teamStanding._attributes) === null || _c === void 0 ? void 0 : _c.LeagueRank,
            TeamLogo: `https://resources-uk.yinzcam.com/soccer/shared/logos/fa_${teamTriCode === null || teamTriCode === void 0 ? void 0 : teamTriCode.toLowerCase()}_light.png`,
            teamTriCode,
            TeamName: (_d = teamStanding._attributes) === null || _d === void 0 ? void 0 : _d.Team,
            UpDown: chevronStatus,
            RecordType: championStatus
        };
        tableColumns.forEach((e, i) => {
            var _a, _b;
            retObj[e] = ((_b = (_a = teamStanding.StatsGroup) === null || _a === void 0 ? void 0 : _a._attributes) === null || _b === void 0 ? void 0 : _b["Stat" + (i + 3)]) || "";
        });
        return retObj;
    });
}
</script>

<CardBase {element}>
  <div class="league-table">
    <div class="logo-wrapper">
      <img src={premierLogo} alt="logo" class="logo" />
    </div>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <td class="Position">
              <span class="desktop-show">Position</span>
            </td>
            <td class="Team">Team</td>
            {#each tableColumns as col}
              {#if col === 'GD'}
                <td class={col}>
                  <span class="desktop-show">{col}</span>
                  <span class="mobile-show">+/-</span>
                </td>
              {:else}
                <td class={col}>{col}</td>
              {/if}
            {/each}
          </tr>
        </thead>
        <tbody>
          {#each tableData as record}
            <tr class={record.RecordType || 'NONE'}>
              <td class={'Position ' + (record.UpDown || 'NONE')}>{record.Position}</td>
              <td class="Team">
                <div class="team-logo-wrapper">
                  <img src={record.TeamLogo} alt="TeamLogo" />
                </div>
                <span>{record.TeamName}</span>
              </td>
              {#each tableColumns as col}
                <td class={col}>
                  {col !== 'GD' || record[col] <= 0 ? record[col] : '+' + record[col]}
                </td>
              {/each}
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
</CardBase>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.league-table {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #e5e5e5;
  background: white;
  width: 100%;
}

.logo-wrapper {
  height: 80px;
  padding: 16px 45px;
}

.logo-wrapper img {
  height: 100%;
}

.table-wrapper table {
  border-collapse: collapse;
  font-family: AeonikTRIAL;
  font-size: 14px;
  width: 100%;
}

.table-wrapper tr {
  height: 40px;
}

.table-wrapper thead {
  background: #010d2c;
  font-weight: bold;
  color: #ffffff;
}

.table-wrapper thead td {
  border: 1px solid #010d2c;
  text-align: center;
}

.table-wrapper thead td * {
  color: white;
}

.table-wrapper tbody {
  color: #000000;
}

.table-wrapper tbody tr {
  position: relative;
}

.table-wrapper tbody tr::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background: #53d975;
}

.table-wrapper tbody tr:nth-child(even) {
  background: #eef0f1;
}

.table-wrapper tbody tr.GREEN::after {
  background: #53d975;
}

.table-wrapper tbody tr.YELLOW::after {
  background: #f5c800;
}

.table-wrapper tbody tr.RED::after {
  background: #e3424f;
}

.table-wrapper tbody tr.NONE::after {
  display: none;
}

.table-wrapper tbody td {
  text-align: center;
  border: none;
}

.table-wrapper tbody .Position {
  position: relative;
}

.table-wrapper tbody .Position::before {
  content: '';
  border: 5px solid rgba(0,0,0,0);
  position: absolute;
  left: 19px;
  top: 15px;
  width: 0px;
  height: 0px;
  display: block;
  transform: scale(1, 1.5);
}

.table-wrapper tbody .Position.UP::before {
  top: 10px;
  border-bottom-color: #53d975;
}

.table-wrapper tbody .Position.DOWN::before {
  top: 18px;
  border-top-color: #e3424f;
}

.table-wrapper tbody .Position.NONE::before {
  display: none;
}

.table-wrapper tbody .Team {
  display: flex;
  align-items: center;
  height: 40px;
  border-left: none;
  border-right: none;
}

.table-wrapper tbody .Team .team-logo-wrapper {
  width: 40px;
  height: 28px;
}

.table-wrapper tbody .Team .team-logo-wrapper img {
  height: 100%;
  display: block;
}

.table-wrapper tbody .Team span {
  display: block;
}

.table-wrapper .Position {
  width: 100px;
}

.table-wrapper .Team {
  padding: 0 0 0 26px;
  text-align: left;
}

.table-wrapper .PLD,
.table-wrapper .W,
.table-wrapper .D,
.table-wrapper .L,
.table-wrapper .GF,
.table-wrapper .GA,
.table-wrapper .GD,
.table-wrapper .PTS {
  width: 90px;
}

.mobile-show {
  display: none;
}

@media (max-width: 767px) {
  .logo-wrapper {
    height: 36px;
    padding: 9px 22px;
  }

  .table-wrapper table {
    font-size: 10.1px;
  }

  .table-wrapper tr {
    height: 32px;
  }

  .table-wrapper thead {
    background: #010d2c;
  }

  .table-wrapper thead td {
    border: 1px solid rgba(1,13,44,0);
    text-align: center;
  }

  .table-wrapper tbody tr::after {
    width: 4px;
  }

  .table-wrapper tbody .Position::before {
    content: '';
    border: 3px solid rgba(0,0,0,0);
    left: 11px;
    top: 5px;
  }

  .table-wrapper tbody .Position.UP::before {
    top: 10px;
  }

  .table-wrapper tbody .Position.DOWN::before {
    top: 14px;
  }

  .table-wrapper tbody .Team {
    display: flex;
    align-items: center;
    height: 32px;
    border-left: none;
    position: relative;
  }

  .table-wrapper tbody .Team:after {
    box-shadow: 10px 0 15px -15px inset;
    content: ' ';
    height: 100%;
    position: absolute;
    top: 0;
    right: -15px;
    width: 15px;
  }

  .table-wrapper tbody .Team .team-logo-wrapper {
    width: 24px;
    height: 19px;
  }

  .table-wrapper tbody .Team .team-logo-wrapper img {
    height: 100%;
  }

  .table-wrapper .Position {
    padding: 0 0 0 21px;
    width: 32px;
  }

  .table-wrapper .Team {
    padding: 0 0 0 5px;
  }

  .table-wrapper thead .Team {
    padding: 0;
  }

  .table-wrapper .PLD,
  .table-wrapper .W,
  .table-wrapper .D,
  .table-wrapper .L,
  .table-wrapper .GF,
  .table-wrapper .GA,
  .table-wrapper .GD,
  .table-wrapper .PTS {
    width: 32px;
  }

  .table-wrapper .PLD,
  .table-wrapper .GF,
  .table-wrapper .GA {
    display: none;
  }

  .desktop-show {
    display: none;
  }

  .mobile-show {
    display: inline;
  }
}</style>
