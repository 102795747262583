<Boundary>
  {#if currentLayout}
    <CardsRegion region={currentLayout.region} />
  {/if}
</Boundary>

<script lang='ts'>import ROOT from '../../../inversify.config';
import { getToken } from 'inversify-token';
import { YinzCamCardsServicePage, YinzCamCardsServicePageLayout, YinzCamCardsServiceToken } from 'yinzcam-cards';
import { Readable } from 'svelte/store';
import { windowMetrics as wm } from '../../../js/stores';
import CardsRegion from './CardsRegion.svelte';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { setContext } from 'svelte';
import { CardsPageContext, CardsPageContextKey } from './context';
import { AppConfig, ConfigToken } from 'yinzcam-config';
export let listName;
export let pathParams = [];
export let pageQuery = {};
const pageData = getToken(ROOT, YinzCamCardsServiceToken).getCardsPage(listName).store;
const pathParamsMap = [listName, ...pathParams].reduce((acc, cur, idx) => { acc[`path${idx}`] = cur; return acc; }, {});
const appConfig = getToken(ROOT, ConfigToken);
setContext(CardsPageContextKey, {
    name: listName,
    query: Object.assign(Object.assign({}, pageQuery), pathParamsMap),
    discoModeClass: (appConfig.discoMode) ? 'disco-mode-enabled' : 'disco-mode-disabled',
});
let layouts = [];
$: if ($pageData) {
    try {
        layouts = $pageData.layouts.sort((a, b) => a.breakpoint - b.breakpoint);
    }
    finally { }
}
let currentLayout = null;
$: if (layouts && $wm) {
    try {
        currentLayout = layouts[0];
        for (let layout of layouts) {
            if ($wm.width >= layout.breakpoint) {
                currentLayout = layout;
            }
        }
    }
    finally { }
}
</script>
