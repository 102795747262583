<CardBase {element} {sources}>
  <div class="soccer-playerdetail-statsummarybrief" style="
    width: {element.data.width || 'auto'};
    height: {element.data.height || 'auto'};
  ">
    <div class="property">
      <div class="title">
        Temporada
      </div>
      <div class="value">
        {season}
      </div>
    </div>
    <div class="property">
      <div class="title">
        Partidos
      </div>
      <div class="value">
        {gamesPlayed}/{teamGamesPlayed}
      </div>
    </div>
    <div class="property">
      <div class="title">
        Minutos
      </div>
      <div class="value">
        {minutesPlayed}
      </div>
    </div>
  </div>
</CardBase>

<style type="text/scss">*, *::before, *::after {
  box-sizing: border-box;
}

.soccer-playerdetail-statsummarybrief {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 43px;
  background-color: var(--neutrals-white-100);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-statsummarybrief {
    padding: 16px 9px;
  }
}

.soccer-playerdetail-statsummarybrief .property {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-statsummarybrief .property {
    width: 146px;
  }
}

.soccer-playerdetail-statsummarybrief .property .title {
  margin-bottom: 2px;
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  color: var(--neutrals-grey-lighter-20);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-statsummarybrief .property .title {
    font-family: CoreSansC-75;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.57;
  }
}

.soccer-playerdetail-statsummarybrief .property .value {
  font-family: CoreSansC-75;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.5;
  text-align: center;
  color: var(--neutrals-black-black-10);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-statsummarybrief .property .value {
    font-size: 18px;
    line-height: 1.22;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g;
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore } from "../../../utilities";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
}
;
$: season = ((_d = (_c = (_b = (_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.Leader) === null || _a === void 0 ? void 0 : _a.Stats) === null || _b === void 0 ? void 0 : _b.find(x => { var _a; return ((_a = x === null || x === void 0 ? void 0 : x._attributes) === null || _a === void 0 ? void 0 : _a.StatName) === "Season"; })) === null || _c === void 0 ? void 0 : _c._attributes) === null || _d === void 0 ? void 0 : _d.StatValueFull) || "";
$: gamesPlayed = ((_e = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['games_played']) === null || _e === void 0 ? void 0 : _e.ValueLabel) || "0";
$: teamGamesPlayed = ((_f = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['team_games_played']) === null || _f === void 0 ? void 0 : _f.ValueLabel) || "0";
$: minutesPlayed = ((_g = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['time_played']) === null || _g === void 0 ? void 0 : _g.ValueLabel) || "0";
</script>
