<Navbar hidden={!showNavBar}>
  {#if showBackButton}
    <NavLeft>
      <Link iconIos="f7:chevron_left" iconAurora="f7:chevron_left" iconMd="material:menu" back>{$t("Volver")}</Link>
    </NavLeft>
  {/if}
  <NavTitle>
    <!-- This is for some code that delays the visibility of the logo until its position is calculated. Not currently used. -->
    <div style="visibility: visible; color:var(--f7-theme-color);" bind:this={titleContentDiv}>
      {#if titleText}
        {$t(titleText)}
      {:else if titleImageUrl}
        <img style="max-width:100px;" src={titleImageUrl} alt="">
      {:else}
        <!-- nothing -->      
      {/if}
    </div>
  </NavTitle>
</Navbar>

<style></style>

<script lang="typescript">import { Navbar, NavTitle, NavLeft, Link } from 'framework7-svelte';
import { Device } from 'framework7';
import { getContext, onMount } from 'svelte';
import { CardsPageContext, CardsPageContextKey } from '../components/cards/common/context';
import { AppConfig, ConfigToken } from 'yinzcam-config';
import { getToken } from 'inversify-token';
import ROOT from '../inversify.config';
import { t } from "../js/i18n";
const appConfig = getToken(ROOT, ConfigToken);
const pageContext = getContext(CardsPageContextKey);
const q = pageContext === null || pageContext === void 0 ? void 0 : pageContext.query;
export let titleText = '';
export let titleImageUrl = appConfig.defaultNavBarImageUrl;
export let navBar = true;
export let backButton = Device.ios;
let titleContentDiv;
$: showNavBar = (q === null || q === void 0 ? void 0 : q.navBar) ? q.navBar.toLowerCase() === 'true' : navBar;
$: showBackButton = (q === null || q === void 0 ? void 0 : q.backButton) ? q.backButton.toLowerCase() === 'true' : backButton;
$: titleText = (q === null || q === void 0 ? void 0 : q.titleText) || titleText;
$: titleImageUrl = (q === null || q === void 0 ? void 0 : q.titleImageUrl) || titleImageUrl;
// This is needed to force the navbar to size itself (including centering)
function resizeOnNextLoop(...args) {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        /*
        setTimeout(() => {
          if (titleContentDiv) {
            titleContentDiv.style.visibility = 'initial';
          }
        }, 0);
        */
    }, (Device.cordova) ? 300 : 50);
}
// the arguments cause this function to be called whenever they change, even if they are not used
$: resizeOnNextLoop(titleText, titleImageUrl, showBackButton, showNavBar);
onMount(() => {
    resizeOnNextLoop();
});
// F7 doesn't set the navbar height CSS variable to 0 when it's hidden, this makes that happen
// I think it's because the expected use case for a hidden nav bar is when you scroll down, and you don't want the content to jump
const f7NavbarHeight = document.documentElement.style.getPropertyValue('--f7-navbar-original-height');
$: document.documentElement.style.setProperty('--f7-navbar-height', (!showNavBar) ? '0px' : f7NavbarHeight);
</script>