<CardBase {element}>
	<div class="laliga-wide-news-card type-large-news media-type-{type}">
		<a href="{getHrefForMediaItem(type, id, slug)}">
			<img class="thumbnail" src="{imageUrl}" alt="{title}">
			<div class="content">
				<h3 class="title">{title || ''}</h3>
				<span class="publish-date">{$tdate(publishDate, { month: 'short', day: 'numeric', year: 'numeric' })}</span>
				<span class="category">{categoryName}</span>
				<img class="icon share-icon" src="https://yc-websites.s3.amazonaws.com/lfp/shared/share-icon.svg" alt="Share">
			</div>
		</a>
	</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.laliga-wide-news-card.type-large-news {
  border-radius:8px;
  overflow:hidden;
  width: 100%;
  height: 100%;
}

.laliga-wide-news-card.type-large-news a {
  width:100%;
  text-decoration:none;
  display:flex;
  flex-wrap:wrap;
  position:relative;
  overflow:hidden;
  width: 100%;
  height: 100%;
}

.laliga-wide-news-card.type-large-news a .thumbnail {
  width:100%;
  height:intrinsic;
  height:auto;
  object-fit: contain;
}

.laliga-wide-news-card.type-large-news a .content {
  width:100%;
  padding:5%;
  background:linear-gradient(transparent,black);
  position:absolute;
  left:0;
  bottom:0;
  z-index:2;
}

.laliga-wide-news-card.type-large-news a .content .title {
  margin:0 0 16px 0;
  color:#FFF;
  font-weight:700;
  line-height:1.3;
}

.laliga-wide-news-card.type-large-news.media-type-v a .content .title:before {
  width:18px;
  height:18px;
  margin:0 16px 0 0;
  padding:8px;
  background-color:#FFF;
  background-image:url('https://yc-websites.s3.amazonaws.com/lfp/shared/media-icon-video.svg');
  background-repeat:no-repeat;
  background-size:70%;
  background-position:center;
  content:"";
  display:inline-block;
}

.laliga-wide-news-card.type-large-news a .content .publish-date {
  margin-right:16px;
  color:#FFF;
  font-size:1.25vw;
  font-weight:normal;
  letter-spacing:1px;
  opacity:0.7;
}

.laliga-wide-news-card.type-large-news a .content .category {
  color:#FFF;
  font-size:1.25vw;
  font-weight:700;
  letter-spacing:1px;
  text-transform:uppercase;
}

.laliga-wide-news-card.type-large-news a .content .share-icon {
  width:16px;
  height:intrinsic;
  height:auto;
  margin:0;
  padding:0;
  position:absolute;
  right:5%;
  bottom:10%;
  z-index:2;
}

@media screen and (min-width:1200px){
  .laliga-wide-news-card.type-large-news a .content .title {
    font-size:1.25vw;
  }
}

@media screen and (min-width:501px) and (max-width:1199px){
  .laliga-wide-news-card.type-large-news a .content .title {
    font-size:1.75vw;
  }

  .laliga-wide-news-card.type-large-news.media-type-v a .content .title:before {
    width:16px;
    height:16px;
  }
}

@media screen and (max-width:500px){
  .laliga-wide-news-card.type-large-news.media-type-v a .content .title:before {
    width:8px;
    height:8px;
    margin:0 8px 0 0;
  }

  .laliga-wide-news-card.type-large-news a .content .publish-date, .laliga-wide-news-card.type-large-news a .content .category {
    font-size:1.5vw;
  }

  .laliga-wide-news-card.type-large-news a .content .share-icon {
    width:18px;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { findSourceStore, getHrefForMediaItem } from "../../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { tdate } from "../../../../../js/i18n";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerMediaSource');
}
;
$: data = (sourceStore) ? $sourceStore : {};
$: type = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Type.toLowerCase();
$: id = (_b = data === null || data === void 0 ? void 0 : data.Id) === null || _b === void 0 ? void 0 : _b._text;
$: slug = (_c = data === null || data === void 0 ? void 0 : data.Slug) === null || _c === void 0 ? void 0 : _c._text;
$: imageUrl = (_d = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _d === void 0 ? void 0 : _d._text;
$: thumbUrl = (_e = data === null || data === void 0 ? void 0 : data.ThumbUrl) === null || _e === void 0 ? void 0 : _e._text;
$: categoryName = (((_f = data === null || data === void 0 ? void 0 : data.PrimaryCategoryName) === null || _f === void 0 ? void 0 : _f._text) === '--') ? '' : (_g = data === null || data === void 0 ? void 0 : data.PrimaryCategoryName) === null || _g === void 0 ? void 0 : _g._text;
$: title = (_h = data === null || data === void 0 ? void 0 : data.Title) === null || _h === void 0 ? void 0 : _h._text;
$: timestamp = (_j = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _j === void 0 ? void 0 : _j._text;
$: publishDate = (timestamp) ? new Date(timestamp) : null;
</script>