import { YinzCamCardsServiceBorder, YinzCamCardsServiceBackground, YinzCamCardsServicePadding, YinzCamCardsServiceMargin } from "yinzcam-cards";
import _ from "lodash";
import { getToken } from "inversify-token";
import { ConfigToken } from "yinzcam-config";
import ROOT from "../../../inversify.config";
import { CardsDataSourceRegistration } from "../common/CardsDataSourceRegistration";
import { Readable, writable } from "svelte/store";

const CONFIG = getToken(ROOT, ConfigToken);

export function generateSequenceId(parentSequenceId: string, childSequenceNumber: number) {
  return parentSequenceId + '-' + _.padStart(childSequenceNumber.toString(), CONFIG.cardsSequenceNumberDigits, '0');
}

export function buildCssPadding(padding: YinzCamCardsServicePadding, defaultPadding?: YinzCamCardsServicePadding) {
  return `
    padding-top: ${(padding && padding.top)? padding.top : ((defaultPadding?.top)? defaultPadding.top : 'initial')};
    padding-right: ${(padding && padding.right)? padding.right : ((defaultPadding?.right)? defaultPadding.right : 'initial')};
    padding-bottom: ${(padding && padding.bottom)? padding.bottom : ((defaultPadding?.bottom)? defaultPadding.bottom : 'initial')};
    padding-left: ${(padding && padding.left)? padding.left : ((defaultPadding?.left)? defaultPadding.left : 'initial')};`;
}

export function buildCssMargin(margin: YinzCamCardsServiceMargin, defaultMargin?: YinzCamCardsServiceMargin) {
  return `
    margin-top: ${(margin && margin.top)? margin.top : ((defaultMargin?.top)? defaultMargin.top : 'initial')};
    margin-right: ${(margin && margin.right)? margin.right : ((defaultMargin?.right)? defaultMargin.right : 'initial')};
    margin-bottom: ${(margin && margin.bottom)? margin.bottom : ((defaultMargin?.bottom)? defaultMargin.bottom : 'initial')};
    margin-left: ${(margin && margin.left)? margin.left : ((defaultMargin?.left)? defaultMargin.left : 'initial')};`;
}

export function buildCssBorder(border: YinzCamCardsServiceBorder, defaultBorder?: YinzCamCardsServiceBorder) {
  return `
    border-top: ${(border && border.top)? border.top : ((defaultBorder?.top)? defaultBorder.top : 'initial')};
    border-right: ${(border && border.right)? border.right : ((defaultBorder?.right)? defaultBorder.right : 'initial')};
    border-bottom: ${(border && border.bottom)? border.bottom : ((defaultBorder?.bottom)? defaultBorder.bottom : 'initial')};
    border-left: ${(border && border.left)? border.left : ((defaultBorder?.left)? defaultBorder.left : 'initial')};
    border-radius: ${(border && border.radius)? border.radius : ((defaultBorder?.radius)? defaultBorder.radius : '0')};`;
}

export function buildCssBackground(background: YinzCamCardsServiceBackground) {
  return `
    background-color: ${(background && background.color)? background.color : 'initial'};
    background-image: ${(background && background.url)? `url('${background.url}')` : 'initial'};
    background-size: ${(background && background.size)? background.size.toLowerCase() : 'cover'};
    background-attachment: ${(background && background.attachment)? background.attachment.toLowerCase() : 'scroll'};
    background-position: ${(background && background.position)? background.position.toLowerCase() : '0% 0%'};
    background-repeat: ${(background && background.repeat)? background.repeat.toLowerCase() : 'no-repeat'};`;
}

export function buildCssScrollContainer(height?: string) {
  return `
    height: ${(height)? height : 'initial'};
    overflow-y: ${(height)? 'auto' : 'initial'};
    position: ${(height)? 'relative' : 'initial'};`;
}

export interface RepeatingObject {
  id: string;
  repeat?: number;
}

export function expandTemplateParams(template: string, params: { [key: string]: string; }) {
  if (!template || typeof template !== 'string' || !params || !template.includes('{{')) {
    return template;
  }
  return _.template(template, { interpolate: /{{([\s\S]+?)}}/g })(params);
}

export function expandRepeats<T extends RepeatingObject>(objects?: T[], prefix: string = ""): T[] {
  let expanded: T[] = [];
  if (!objects) {
    return expanded;
  }
  for (let object of objects) {
    let repeat = object.repeat || 1;
    for (let i = 0; i < repeat; i++) {
      let clone = _.clone(object);
      let tag = `${prefix}${i}`;
      if (object.id) {
        clone.id = `${object.id}_C-${tag}`;
      }
      for (let key in clone) {
        // TODO: Probably a way to make this type safe.
        let val: any = clone[key];
        if (Array.isArray(val) && val.length > 0 && typeof val[0] === 'object' && val[0] !== null) {
          clone[key] = expandRepeats(val, `${tag}-`) as any;
        }
      }
      expanded.push(clone);
    }
  }
  return expanded;
}

export function findSourceStore(sources: CardsDataSourceRegistration[], clazz: string, tags?: string[]): Readable<any> {
  if (!sources) {
    return null;
  }
  let reg = sources.find(source => source?.spec?.class === clazz);
  return (reg)? reg.store : null;
}

export function getFirstSourceStore(sources: CardsDataSourceRegistration[], tags?: string[]): Readable<any> {
  if (!sources) {
    return null;
  }
  let reg = sources.find(source => !!source.spec);
  return (reg)? reg.store : null;
}

export function getHrefForMediaItem(type?: string, id?: string, slug?: string): string {
  if (!type || (!id && !slug)) {
    return '#';
  }
  let suffix = '';
  if (slug) {
    suffix = `/${slug}`;
  } else {
    suffix = `?mediaId=${id}`;
  }
  switch (type.toUpperCase()) {
    case 'N':
    case 'B':
      return `/cards/NewsReader${suffix}`;
    case 'V':
    case 'Y':
      return `/cards/VideoPlayer${suffix}`;
    case 'G':
      return `/cards/PhotoViewer${suffix}`;
    case 'A':
      return `/cards/AudioPlayer${suffix}`;
    default:
      return '#';
  }
}

export function getHrefForMatchCenter(gameId: string, state?: string): string {
  //todo: translate this string based on language preference
  var titleString = 'Detalles Del Partido';
  if (!gameId){
    return '#';
  }
  /*
  if (state && state.toUpperCase() === 'P'){  
    return `/cards/MatchPreview?gameId=${encodeURIComponent(gameId)}&titleText=${encodeURIComponent(titleString)}` // not yet implemented for pregame
  }
  */
  return `/cards/MatchCenter?gameId=${encodeURIComponent(gameId)}&titleText=${encodeURIComponent(titleString)}`;
}

export function getTeamLogoFallback(league?: string) {
  league = league || CONFIG.league;
  return `https://resources-us.yinzcam.com/${league.toLowerCase()}/shared/logos/${league.toUpperCase()}_placeholder.png`;
}

export function getTeamLogoFromLeagueAndTricode(league: string, tricode: string) {
  // TODO: make this work for other leagues
  // https://resources-us.yinzcam.com/csf/shared/logos/CSF_ADC.png
  if (!league || !tricode) {
    return getTeamLogoFallback(league);
  }

  if(league.toLowerCase() === 'esp'){
    league = 'lfp';
  }

  return `https://resources-us.yinzcam.com/${league.toLowerCase()}/shared/logos/${league.toLowerCase()}_${tricode.toLowerCase()}_dark.png`;
}

export function getTeamLogoFromTricode(tricode: string) {
  return getTeamLogoFromLeagueAndTricode(CONFIG.league, tricode);
}

export function getTeamLogoFromLogoId(logoId: string) {
  let leagueTricode = (logoId || '').split('_');
  if (!Array.isArray(leagueTricode) || leagueTricode.length < 2) {
    return getTeamLogoFallback();
  }
  return getTeamLogoFromLeagueAndTricode(leagueTricode[0], leagueTricode[1]);
}

export function getTeamLogoFromTeamObject(obj: any) {
  if (!obj || !obj._attributes) {
    return getTeamLogoFallback();
  }
  if (obj._attributes.LogoId) {
    return getTeamLogoFromLogoId(obj._attributes.LogoId);
  }
  if (obj._attributes.TriCode) {
    return getTeamLogoFromTricode(obj._attributes.TriCode);
  }
  return getTeamLogoFallback();
}
