import PlaceholderCard from "./PlaceholderCard/PlaceholderCard.svelte";
import UnknownCard from "./UnknownCard/UnknownCard.svelte";
import AdSlot from "./AdSlot/AdSlot.svelte";
import NavTop from "./NavTop/NavTop.svelte";
import FooterSponsor from "./FooterSponsor/FooterSponsor.svelte";
import FooterLinks from "./FooterLinks/FooterLinks.svelte";
import FooterSocial from "./FooterSocial/FooterSocial.svelte";
import MediaB6 from "./MediaB6/MediaB6.svelte";
import MediaB7 from "./MediaB7/MediaB7.svelte";
import GameBoxMatchup from "./GameBoxMatchup/GameBoxMatchup.svelte";
import GameG28 from "./GameG28/GameG28.svelte";
import GameLeg from "./GameLeg/GameLeg.svelte";
import FilterDropdown from "./FilterDropdown/FilterDropdown.svelte";
import FeatureMatchup from "./FeatureMatchup/FeatureMatchup.svelte";
import HTML from "./HTML/HTML.svelte";
import MediaRegular from "./MediaRegular/MediaRegular.svelte";
import MediaTall from "./MediaTall/MediaTall.svelte";
import PlayerProfileStats from "./PlayerProfileStats/PlayerProfileStats.svelte";
import PlayerProfileTop from "./PlayerProfileTop/PlayerProfileTop.svelte";
import MatchCommentary from "./MatchCommentary/MatchCommentary.svelte";
import MatchDetail from "./MatchDetail/MatchDetail.svelte";
import MatchLineups from "./MatchLineups/MatchLineups.svelte";
import MatchPitch from "./MatchPitch/MatchPitch.svelte";
import NewsReaderBody from "./NewsReaderBody/NewsReaderBody.svelte";
import NewsReaderHeader from "./NewsReaderHeader/NewsReaderHeader.svelte";
import NewsReaderMeta from "./NewsReaderMeta/NewsReaderMeta.svelte";
import PhotoViewerBody from "./PhotoViewerBody/PhotoViewerBody.svelte";
import PhotoViewerHeader from "./PhotoViewerHeader/PhotoViewerHeader.svelte";
import PhotoViewerMeta from "./PhotoViewerMeta/PhotoViewerMeta.svelte";
import ScheduleD0 from "./ScheduleD0/ScheduleD0.svelte";
import ScheduleD1 from "./ScheduleD1/ScheduleD1.svelte";
import ScheduleBar from "./ScheduleBar/ScheduleBar.svelte";
import SectionHeading from "./SectionHeading/SectionHeading.svelte";
import SimpleCard from "./SimpleCard/SimpleCard.svelte";
import StatsLeaders from "./StatsLeaders/StatsLeaders.svelte";
import StatsG32 from "./StatsG32/StatsG32.svelte";
import TeamLogo from "./TeamLogo/TeamLogo.svelte";
import TeamRanking from "./TeamRanking/TeamRanking.svelte";
import TeamRoster from "./TeamRoster/TeamRoster.svelte";
import TeamRosterDropdown from "./TeamRosterDropdown/TeamRosterDropdown.svelte";
import TeamRosterToggle from "./TeamRosterToggle/TeamRosterToggle.svelte";
import TeamSelectCarousel from "./TeamSelectCarousel/TeamSelectCarousel.svelte";
import UpcomingGame from "./UpcomingGame/UpcomingGame.svelte";
import VideoPlayerBody from "./VideoPlayerBody/VideoPlayerBody.svelte";
import VideoPlayerHeader from "./VideoPlayerHeader/VideoPlayerHeader.svelte";
import VideoPlayerMeta from "./VideoPlayerMeta/VideoPlayerMeta.svelte";
import LaLiga__BreadCrumbs from "./LaLiga/BreadCrumbs/BreadCrumbs.svelte";
import LaLiga__FilterBar from "./LaLiga/FilterBar/FilterBar.svelte";
import LaLiga__HeaderTabs from "./LaLiga/HeaderTabs/HeaderTabs.svelte";
import LaLiga__NewsReaderBody from "./LaLiga/NewsReaderBody/NewsReaderBody.svelte";
import LaLiga__NewsReaderHeader from "./LaLiga/NewsReaderHeader/NewsReaderHeader.svelte";
import LaLiga__NewsReaderHero from "./LaLiga/NewsReaderHero/NewsReaderHero.svelte";
import LaLiga__NewsSlim from "./LaLiga/NewsSlim/NewsSlim.svelte";
import LaLiga__PageFooter from "./LaLiga/PageFooter/PageFooter.svelte";
import LaLiga__PageHeader from "./LaLiga/PageHeader/PageHeader.svelte";
import LaLiga__PaginationBar from "./LaLiga/PaginationBar/PaginationBar.svelte";
import LaLiga__PhotosWide from "./LaLiga/PhotosWide/PhotosWide.svelte";
import LaLiga__PhotoViewerBody from "./LaLiga/PhotoViewerBody/PhotoViewerBody.svelte";
import LaLiga__PhotoViewerHeader from "./LaLiga/PhotoViewerHeader/PhotoViewerHeader.svelte";
import LaLiga__ScheduleSection from "./LaLiga/ScheduleSection/ScheduleSection.svelte";
import LaLiga__StandingsSection from "./LaLiga/StandingsSection/StandingsSection.svelte";
import LaLiga__StandingsFilterBar from "./LaLiga/StandingsFilterBar/StandingsFilterBar.svelte";
import LaLiga__StandingsSeasonRoundGraph from "./LaLiga/StandingsSection/StandingsSeasonRoundGraph.svelte"
import LaLiga__SectionHeading from "./LaLiga/SectionHeading/SectionHeading.svelte";
import LaLiga__VideoWide from "./LaLiga/VideoWide/VideoWide.svelte";
import LaLiga__WideNews from "./LaLiga/WideNews/WideNews.svelte";
import LaLiga__Clasification from "./LaLiga/Clasification/Clasification.svelte";
import LaLiga__EventSlider from "./LaLiga/EventSlider/EventSlider.svelte";
import LaLiga__H2HMatch from "./LaLiga/H2HMatch/H2HMatch.svelte";
import LaLiga__H2HSeason from "./LaLiga/H2HSeason/H2HSeason.svelte";
import LaLiga__Historico from "./LaLiga/Historico/Historico.svelte";
import LaLiga__MatchCenterHeader from "./LaLiga/MatchCenterHeader/MatchCenterHeader.svelte";
import LaLiga__MatchPitch from "./LaLiga/MatchPitch/MatchPitch.svelte";
import LaLiga__MatchDetail from "./LaLiga/MatchDetail/MatchDetail.svelte";
import LaLiga__MatchDetailStatus from "./LaLiga/MatchDetailStatus/MatchDetailStatus.svelte";
import LaLiga__RecentMatches from "./LaLiga/RecentMatches/RecentMatches.svelte";
import LaLiga__Suplentes from "./LaLiga/Suplentes/Suplentes.svelte";
import LaLigaWideNewsCard from '../LaLigaWideNewsCard.svelte';
import Soccer__PlayerDetail__Header from "./Soccer/PlayerDetail/Header.svelte";
import Soccer__PlayerDetail__StatSummaryBrief from "./Soccer/PlayerDetail/StatSummaryBrief.svelte";
import Soccer__PlayerDetail__StatSummaryDetail from "./Soccer/PlayerDetail/StatSummaryDetail.svelte";
import Soccer__PlayerDetail__Discipline from "./Soccer/PlayerDetail/Discipline.svelte";
import Soccer__PlayerDetail__Efficiency from "./Soccer/PlayerDetail/Efficiency.svelte";
import Soccer__PlayerDetail__Defense from "./Soccer/PlayerDetail/Defense.svelte";
import Soccer__PlayerDetail__Trajectory from "./Soccer/PlayerDetail/Trajectory.svelte";
import Soccer__PlayerDetail__PositionHeatMap from "./Soccer/PlayerDetail/PositionHeatMap.svelte";
import Soccer__TeamDetail__PlayerCard from "./Soccer/TeamDetail/PlayerCard.svelte";
import Tottenham__PageHeader from "./Tottenham/PageHeader/PageHeader.svelte";
import Tottenham__MatchCenterHeader from "./Tottenham/MatchCenterHeader/MatchCenterHeader.svelte";
import Tottenham__SectionHeading from "./Tottenham/SectionHeading/SectionHeading.svelte";
import Tottenham__MatchEvent from "./Tottenham/MatchEvent/MatchEvent.svelte";
import Tottenham__CommentaryItem from "./Tottenham/CommentaryItem/CommentaryItem.svelte";
import Tottenham__PossessionComparison from "./Tottenham/PossessionComparison/PossessionComparison.svelte";
import Tottenham__SingleStatComparison from "./Tottenham/SingleStatComparison/SingleStatComparison.svelte";
import Tottenham__PlayerStatsSummary from "./Tottenham/PlayerStatsSummary/PlayerStatsSummary.svelte";
import Tottenham__MultiStatComparison from "./Tottenham/MultiStatComparison/MultiStatComparison.svelte";
import Tottenham__MatchStatsCard from "./Tottenham/MatchStatsCard/MatchStatsCard.svelte";
import Tottenham__MatchTeamSelector from "./Tottenham/MatchTeamSelector/MatchTeamSelector.svelte";
import Tottenham__MatchPitchIsometric from "./Tottenham/MatchPitchIsometric/MatchPitchIsometric.svelte";
import Tottenham__MatchTeamLineup from "./Tottenham/MatchTeamLineup/MatchTeamLineup.svelte";
import Tottenham__SimpleConditionalCard from "./Tottenham/SimpleConditionalCard/SimpleConditionalCard.svelte";
import Tottenham__LiveScoreWide from "./Tottenham/LiveScoreWide/LiveScoreWide.svelte";
import Tottenham__LeagueTable from "./Tottenham/LeagueTable/LeagueTable.svelte";
import Tottenham__PresentedByCard from "./Tottenham/PresentedByCard/PresentedByCard.svelte";
import Tottenham__PageFooter from "./Tottenham/PageFooter/PageFooter.svelte";
import Tottenham__MatchStatsComment from "./Tottenham/MatchStatsComment/MatchStatsComment.svelte";

const CARD_LIBRARY = {
  PlaceholderCard,
  AdSlot,
  NavTop,
  FooterSponsor,
  FooterLinks,
  FooterSocial,
  MediaB6,
  MediaB7,
  GameBoxMatchup,
  GameG28,
  GameLeg,
  FilterDropdown,
  FeatureMatchup,
  HTML,
  MediaRegular,
  MediaTall,
  MatchCommentary,
  MatchDetail,
  MatchLineups,
  MatchPitch,
  NewsReaderBody,
  NewsReaderHeader,
  NewsReaderMeta,
  PhotoViewerBody,
  PhotoViewerHeader,
  PhotoViewerMeta,
  PlayerProfileStats,
  PlayerProfileTop,
  ScheduleD0,
  ScheduleD1,
  ScheduleBar,
  SectionHeading,
  SimpleCard,
  StatsLeaders,
  StatsG32,
  TeamLogo,
  TeamRanking,
  TeamRoster,
  TeamRosterDropdown,
  TeamRosterToggle,
  TeamSelectCarousel,
  UpcomingGame,
  VideoPlayerBody,
  VideoPlayerHeader,
  VideoPlayerMeta,
  LaLiga__BreadCrumbs,
  LaLiga__FilterBar,
  LaLiga__HeaderTabs,
  LaLiga__NewsReaderBody,
  LaLiga__NewsReaderHeader,
  LaLiga__NewsReaderHero,
  LaLiga__NewsSlim,
  LaLiga__PageFooter,
  LaLiga__PageHeader,
  LaLiga__PaginationBar,
  LaLiga__PhotosWide,
  LaLiga__PhotoViewerBody,
  LaLiga__PhotoViewerHeader,
  LaLiga__ScheduleSection,
  LaLiga__SectionHeading,
  LaLiga__StandingsFilterBar,
  LaLiga__StandingsSection,
  LaLiga__StandingsSeasonRoundGraph,
  LaLiga__VideoWide,
  LaLiga__WideNews,
  LaLigaWideNewsCard,
  LaLiga__Clasification,
  LaLiga__EventSlider,
  LaLiga__H2HMatch,
  LaLiga__H2HSeason,
  LaLiga__Historico,
  LaLiga__MatchCenterHeader,
  LaLiga__MatchPitch,
  LaLiga__MatchDetail,
  LaLiga__MatchDetailStatus,
  LaLiga__RecentMatches,
  LaLiga__Suplentes,
  Soccer__PlayerDetail__Header,
  Soccer__PlayerDetail__StatSummaryBrief,
  Soccer__PlayerDetail__StatSummaryDetail,
  Soccer__PlayerDetail__Discipline,
  Soccer__PlayerDetail__Efficiency,
  Soccer__PlayerDetail__Defense,
  Soccer__PlayerDetail__Trajectory,
  Soccer__PlayerDetail__PositionHeatMap,
  Soccer__TeamDetail__PlayerCard,
  Tottenham__PageHeader,
  Tottenham__CommentaryItem,
  Tottenham__MatchCenterHeader,
  Tottenham__SectionHeading,
  Tottenham__MatchEvent,
  Tottenham__PossessionComparison,
  Tottenham__SingleStatComparison,
  Tottenham__PlayerStatsSummary,
  Tottenham__MultiStatComparison,
  Tottenham__MatchStatsCard,
  Tottenham__MatchTeamSelector,
  Tottenham__MatchPitchIsometric,
  Tottenham__MatchTeamLineup,
  Tottenham__SimpleConditionalCard,
  Tottenham__LiveScoreWide,
  Tottenham__LeagueTable,
  Tottenham__PresentedByCard,
  Tottenham__PageFooter,
  Tottenham__MatchStatsComment
};

export default function LookupCardComponent(clazz) {
  if (!clazz || typeof clazz !== 'string') {
    return UnknownCard
  }
  clazz = clazz.replace(/\./g, '__');
  let comp = CARD_LIBRARY[clazz];
  if (!comp) {
    comp = UnknownCard;
  }
  return comp;
}
