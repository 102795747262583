<CardBase {element}>
  <div class="hero" style="background-image: url('{imageUrl}');">
  </div>
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.hero {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}</style>

<script lang="ts">var _a;
import { findSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
export let element;
export let sources;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaNews');
$: data = (source) ? $source : {};
$: imageUrl = (_a = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _a === void 0 ? void 0 : _a._text;
</script>
