<CardBase {element}>
  <TeamLogo {teamObject} />
</CardBase>

<style></style>

<script lang="ts">import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { YinzCamCardsServiceElement } from "yinzcam-cards";
export let element;
export let sources;
$: source = findSourceStore($sources, 'YinzCamAppServerTeamTeamsList');
$: teamObject = [].concat($source === null || $source === void 0 ? void 0 : $source.Team).find(team => { var _a, _b; return ((_a = team === null || team === void 0 ? void 0 : team._attributes) === null || _a === void 0 ? void 0 : _a.Id) === ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.teamId); });
</script>