import { Container } from "inversify";
import { YinzCamSegmentationServiceModule } from "yinzcam-segmentation";
import { YinzCamAPIModule } from "yinzcam-api";
import { ConfigModule } from "yinzcam-config";
import { LoggerModule } from "yinzcam-log";
import { YinzCamAppServerModule } from "yinzcam-appserver";
import { YinzCamCardsServerModule } from "yinzcam-cards";
import { CardsDataSourcesModule } from "./components/cards/sources/module";
import { JanusDefaultRequestParameterModule } from "./js/params";
import { JanusModeContextManagerModule } from "./js/mode";
import { JanusPushManagerModule } from "./js/push";
import { JanusInternationalizationManagerModule } from "./js/i18n";

const ROOT: Container = new Container();
LoggerModule.configure(ROOT);
ConfigModule.configure(ROOT);
// TODO: To support mode switching, this may have to be inside a container for each supported mode (?)
YinzCamAPIModule.configure(ROOT); 
YinzCamAppServerModule.configure(ROOT);
YinzCamCardsServerModule.configure(ROOT);
YinzCamSegmentationServiceModule.configure(ROOT);

// default query string params and headers
JanusDefaultRequestParameterModule.configure(ROOT);

// mode context and query params
JanusModeContextManagerModule.configure(ROOT);

// push notifications
JanusPushManagerModule.configure(ROOT);

// internationalization
JanusInternationalizationManagerModule.configure(ROOT);

CardsDataSourcesModule.configure(ROOT);

/*
ROOT.load(new TokenContainerModule((bindToken) => {
  [YinzCamSegmentationRequestParameterComponentToken].forEach((token) => {
    bindToken(YinzCamAPIRequestParameterComponentToken).toConstantValue(getToken(ROOT, token));
  });
}));
*/

// TODO: Move this to some other location, probably in something that instantiates all of the services for an app mode?
//getToken(ROOT, YinzCamSegmentationServiceToken);

export default ROOT;
