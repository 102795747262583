import { YinzCamAPIResponse, YinzCamServer } from "yinzcam-api";
import { ControlBase } from "yinzcam-rma";
import { CardsDataSourceOutput } from "./CardsDataSourceOutput";
import { CardsDataSource } from "./CardsDataSource";
import { CardDataSourceComponent } from "./CardsDataSourceComponent";
import { CardsDataSourceConfiguration } from "./CardsDataSourceConfiguration";
import { injectable } from "inversify";

@injectable()
export abstract class CardsDataSourceBase implements CardsDataSource {

  protected constructor(private readonly config: CardsDataSourceConfiguration) {
  }

  public getDataSourceComponent(path: string, specData?: { [key: string]: any }): CardDataSourceComponent {
    let url = new URL(path, 'http://www.example.org/'); // we just need any url to resolve against since we only care about path and params
    let params = {};
    url.searchParams.forEach((val, key) => params[key] = val);
    let self = this;
    let cardComponentClass = class extends CardDataSourceComponent {
      protected async update($control: ControlBase, $response: YinzCamAPIResponse): Promise<CardsDataSourceOutput> {
        return { data: self.processResponse($response, specData) };
      }
    };
    let apiComponent = this.config.server.getRequest({ path: url.pathname, params });
    return new cardComponentClass(url.toString(), apiComponent);
  }

  protected abstract processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[];

}