<CardBase {element}>
    {#each headerTabs as item (item.id) }
	<div class="header-tab" style= "display:inline-block;" class:active-tab={!isActive} on:click={isActive}>
        <a href={item.url} style="font-size:30px">{item.itemName}</a>
	</div>			
	{/each}
    </CardBase>

<style>.header-tab.active-tab {
  background-color:blue;
  border: none;
  color: black;
  cursor: pointer;
  border-radius: 16px;
}</style>
<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { t } from "../../../../../js/i18n";
import { getFirstSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { getContext, onMount } from "svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
export let element;
export let sources;
$: headerTabs = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.links;
var isActive = function (element) {
    let headertab = document.getElementsByClassName("header-tab");
    for (let i = 0; i < headertab.length; i++) {
        headertab[i].classList.remove("active-tab");
    }
    element.currentTarget.classList.add("active-tab");
};
</script>