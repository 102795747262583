<script>
	import { getContext } from 'svelte';
	import { TABS } from './Tabs.svelte';

	const tab = {};
	const { registerTab, selectTab, selectedTab } = getContext(TABS);

	registerTab(tab);
</script>

<style>button {
  border: none;
  border-radius: 0;
  margin: 0;
  flex: 1;
  text-transform: uppercase;
  font-family: CoreSansC-75;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.96px;
  text-align: center;
  padding: 18px;
  color: #788591;
  background: #e9ebed;
  line-height: 12px;
}

.selected {
  border-bottom: 2px solid teal;
  background: white;
  color: #000;
}</style>

<button class:selected="{$selectedTab === tab}" on:click="{() => selectTab(tab)}">
	<slot></slot>
</button>