import { injectable, Container } from "inversify";
import { injectToken, Token, TokenContainerModule } from "inversify-token";
import { AppConfig, ConfigToken } from "yinzcam-config";
import { YinzCamInjectModule } from 'yinzcam-inject';
import { init, addMessages, locale, t, date, time, number } from 'svelte-i18n';
import type { MessageFormatter, DateFormatter, TimeFormatter, NumberFormatter } from 'svelte-i18n/types/runtime/types/index';
import { JanusModeContextManager, JanusModeContextManagerToken } from "./mode";
import { derived, get } from "svelte/store";
import { YinzCamCardsService, YinzCamCardsServiceToken } from "yinzcam-cards";
import _ from "lodash";

export const JanusInternationalizationManagerToken = new Token<JanusInternationalizationManager>(Symbol.for("JanusInternationalizationManager"));

export let JanusInternationalizationManagerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(JanusInternationalizationManagerToken).to(JanusInternationalizationManager).inSingletonScope();
  }));
});

@injectable()
export class JanusInternationalizationManager {
  public constructor(
      @injectToken(ConfigToken) private readonly appConfig: AppConfig,
      @injectToken(JanusModeContextManagerToken) private readonly contextManager: JanusModeContextManager,
      @injectToken(YinzCamCardsServiceToken) private readonly cardsService: YinzCamCardsService) {
  }

  public init() {
    let fallbackLocale = this.appConfig.defaultLanguage;
    let initialLocale = get(this.contextManager.getLanguageComponent().store);
    let config = { fallbackLocale, initialLocale };
    //console.log('JanusInternationalizationManager: INIT WITH CONFIG ', config);
    init(config);
    this.contextManager.getLanguageComponent().store.subscribe((lang) => {
      //console.log('JanusInternationalizationManager: UPDATE LOCALE TO ' + lang);
      locale.set(lang);
    });
    // TODO: load available languages from a config file
    for (let lang of [ 'en', 'es', 'pt' ]) {
      //console.log('JanusInternationalizationManager: SUBSCRIBE MESSAGES FOR ' + lang);
      this.cardsService.getMessages(lang).store.subscribe((messages) => {
        if (!messages) {
          return;
        }
        //console.log('JanusInternationalizationManager: UPDATE MESSAGES FOR ' + lang, messages);
        //delete lookupCache[lang];
        addMessages(lang, messages);
      });
    }
    //console.log('JanusInternationalizationManager: INIT COMPLETE');
  }
}

const tWrapper = derived(t, ($t) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return args[0];
    }
    return $t(...args);
  } as MessageFormatter;
});

const tdateWrapper = derived(date, ($tdate) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return '';
    }
    return $tdate(...args);
  } as DateFormatter;
});

const ttimeWrapper = derived(time, ($ttime) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return '';
    }
    return $ttime(...args);
  } as TimeFormatter;
});

const tnumWrapper = derived(number, ($tnum) => {
  return function(...args) {
    if (_.isNil(args[0])) {
      return '';
    }
    return $tnum(...args);
  } as NumberFormatter;
});

export { tWrapper as t, tdateWrapper as tdate, ttimeWrapper as ttime, tnumWrapper as tnum };
