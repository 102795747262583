import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { CardsDataSourceToken } from "../common/CardsDataSourceToken";
import { YinzCamAppServerGameBox } from "./YinzCamAppServerGameBox/YinzCamAppServerGameBox";
import { YinzCamAppServerGameCommentary } from "./YinzCamAppServerGameCommentary/YinzCamAppServerGameCommentary";
import { YinzCamAppServerGameFormation } from "./YinzCamAppServerGameFormation/YinzCamAppServerGameFormation";
import { YinzCamAppServerGameList } from "./YinzCamAppServerGameList/YinzCamAppServerGameList";
import { YinzCamAppServerGameListFilter } from "./YinzCamAppServerGameListFilter/YinzCamAppServerGameListFilter";
import { YinzCamAppServerGamePlays } from "./YinzCamAppServerGamePlays/YinzCamAppServerGamePlays";
import { YinzCamAppServerGameSchedule } from "./YinzCamAppServerGameSchedule/YinzCamAppServerGameSchedule";
import { YinzCamAppServerGameScores } from "./YinzCamAppServerGameScores/YinzCamAppServerGameScores";
import { YinzCamAppServerGameScoresRoundFilterOptions } from "./YinzCamAppServerGameScoresRoundFilterOptions/YinzCamAppServerGameScoresRoundFilterOptions";
import { YinzCamAppServerGameState } from "./YinzCamAppServerGameState/YinzCamAppServerGameState";
import { YinzCamAppServerHasData } from "./YinzCamAppServerHasData/YinzCamAppServerHasData";
import { YinzCamAppServerHeadingOptions } from "./YinzCamAppServerHeadingOptions/YinzCamAppServerHeadingOptions";
//YinzCamAppServerMediaGallery
import { YinzCamAppServerMedia } from "./YinzCamAppServerMedia/YinzCamAppServerMedia";
import { YinzCamAppServerMediaGallery } from "./YinzCamAppServerMediaGallery/YinzCamAppServerMediaGallery";
import { YinzCamAppServerMediaItem } from "./YinzCamAppServerMediaItem/YinzCamAppServerMediaItem";
import { YinzCamAppServerMediaNews } from "./YinzCamAppServerMediaNews/YinzCamAppServerMediaNews";
import { YinzCamAppServerStatsClubLeaders } from "./YinzCamAppServerStatsClubLeaders/YinzCamAppServerStatsClubLeaders";
import { YinzCamAppServerStatsPlayer } from "./YinzCamAppServerStatsPlayer/YinzCamAppServerStatsPlayer";
import { YinzCamAppServerStatsStandings } from "./YinzCamAppServerStatsStandings/YinzCamAppServerStatsStandings";
import { YinzCamAppServerStatsTeam } from "./YinzCamAppServerStatsTeam/YinzCamAppServerStatsTeam";
import { YinzCamAppServerTeamRoster } from "./YinzCamAppServerTeamRoster/YinzCamAppServerTeamRoster";
import { YinzCamAppServerTeamPositionFilter } from "./YinzCamAppServerTeamPositionFilter/YinzCamAppServerTeamPositionFilter";
import { YinzCamAppServerTeamTeamsList } from "./YinzCamAppServerTeamTeamsList/YinzCamAppServerTeamTeamsList";
import { YinzCamAppServerTeamTeamsListFilterOptions } from "./YinzCamAppServerTeamTeamsListFilterOptions/YinzCamAppServerTeamTeamsListFilterOptions";
import { YinzCamAppServerStatsStandingsFilter } from "./YinzCamAppServerStatsStandingsFilter/YinzCamAppServerStatsStandingsFilter";
import { YinzCamAppServerCompetitionsFilter } from "./YinzCamAppServerCompetitionsFilter/YinzCamAppServerCompetitionsFilter";
import { YinzCamAppServerTeamStandingsFilter } from "./YinzCamAppServerTeamStandingsFilter/YinzCamAppServerTeamStandingsFilter";
import { YinzCamAppServerPhaseListFilter } from "./YinzCamAppServerPhaseListFilter/YinzCamAppServerPhaseListFilter";
import { YinzCamAppServerGamePhaseList } from "./YinzCamAppServerGamePhaseList/YinzCamAppServerGamePhaseList";

import { YinzCamAppServerGamePlaysArray } from "./YinzCamAppServerGamePlaysArray/YinzCamAppServerGamePlaysArray";
import { YinzCamAppServerGameCommentaryArray } from "./YinzCamAppServerGameCommentaryArray/YinzCamAppServerGameCommentaryArray";




export const CardsDataSourcesModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    // TODO: To support mode switching, expose a factory that can create an API for different leagues and tricodes
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameBox).whenTargetNamed("YinzCamAppServerGameBox");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameCommentary).whenTargetNamed("YinzCamAppServerGameCommentary");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameFormation).whenTargetNamed("YinzCamAppServerGameFormation");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameList).whenTargetNamed("YinzCamAppServerGameList");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameListFilter).whenTargetNamed("YinzCamAppServerGameListFilter");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGamePlays).whenTargetNamed("YinzCamAppServerGamePlays");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameSchedule).whenTargetNamed("YinzCamAppServerGameSchedule");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameScores).whenTargetNamed("YinzCamAppServerGameScores");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameScoresRoundFilterOptions).whenTargetNamed("YinzCamAppServerGameScoresRoundFilterOptions");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameState).whenTargetNamed("YinzCamAppServerGameState");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerHasData).whenTargetNamed("YinzCamAppServerHasData");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerHeadingOptions).whenTargetNamed("YinzCamAppServerHeadingOptions");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerMedia).whenTargetNamed("YinzCamAppServerMedia");
    // XXX: REMOVE THIS! Backwards compatibility for now.
    bindToken(CardsDataSourceToken).to(YinzCamAppServerMedia).whenTargetNamed("YinzCamAppServerMediaSource");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerMediaGallery).whenTargetNamed("YinzCamAppServerMediaGallery");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerMediaItem).whenTargetNamed("YinzCamAppServerMediaItem");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerMediaNews).whenTargetNamed("YinzCamAppServerMediaNews");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerStatsClubLeaders).whenTargetNamed("YinzCamAppServerStatsClubLeaders");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerStatsPlayer).whenTargetNamed("YinzCamAppServerStatsPlayer");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerStatsStandings).whenTargetNamed("YinzCamAppServerStatsStandings");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerStatsTeam).whenTargetNamed("YinzCamAppServerStatsTeam");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerTeamRoster).whenTargetNamed("YinzCamAppServerTeamRoster");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerTeamPositionFilter).whenTargetNamed("YinzCamAppServerTeamPositionFilter");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerTeamTeamsList).whenTargetNamed("YinzCamAppServerTeamTeamsList");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerTeamTeamsListFilterOptions).whenTargetNamed("YinzCamAppServerTeamTeamsListFilterOptions");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerStatsStandingsFilter).whenTargetNamed("YinzCamAppServerStatsStandingsFilter");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerCompetitionsFilter).whenTargetNamed("YinzCamAppServerCompetitionsFilter");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerTeamStandingsFilter).whenTargetNamed("YinzCamAppServerTeamStandingsFilter");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerPhaseListFilter).whenTargetNamed("YinzCamAppServerPhaseListFilter");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGamePhaseList).whenTargetNamed("YinzCamAppServerGamePhaseList");

    bindToken(CardsDataSourceToken).to(YinzCamAppServerGamePlaysArray).whenTargetNamed("YinzCamAppServerGamePlaysArray");
    bindToken(CardsDataSourceToken).to(YinzCamAppServerGameCommentaryArray).whenTargetNamed("YinzCamAppServerGameCommentaryArray");




  }));
});