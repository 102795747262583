<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Swiper from "swiper";
import { onMount, createEventDispatcher } from "svelte";
export let maxDigit = undefined;
let swiperEl;
let swiper;
const dispatch = createEventDispatcher();
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    swiper = new Swiper(swiperEl, {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        direction: "vertical",
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    dispatch("swiper_init", {
        swiper,
    });
}));
</script>

<div class="digit">
  <div class="swiper mySwiper" bind:this={swiperEl}>
    <div class="swiper-wrapper">
      {#each [...Array(maxDigit ? maxDigit : 10).keys()] as i}
        <div class="swiper-slide">{i}</div>
      {/each}
    </div>
  </div>
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.digit {
  background-color: rgba(255,255,255,0.06667);
  border-radius: 4px;
  padding: 0px 10px;
}

.mySwiper {
  height: 47.8px;
  overflow: hidden;
}

.swiper-slide {
  line-height: 47.8px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

@media (max-width: 767px) {
  .digit {
    background-color: rgba(255,255,255,0.06667);
    border-radius: 4px;
    padding: 0px 6.5px;
  }

  .mySwiper {
    height: 34px;
  }

  .swiper-slide {
    line-height: 34px;
    font-size: 16px;
  }
}</style>
