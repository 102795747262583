<Boundary>
  <div class="swiper-layout" class:pagination={!!layout.swiperPagination} style="--yinzcam-cards-array-swiper-layout-slide-width: {(layout.maxColumns)? 'auto' : (layout.slideWidth || '40%')}">
    <Swiper
      bind:this={swiperComponent}
      navigation={!!layout.swiperNavigation}
      pagination={!!layout.swiperPagination}
      speed={layout.scrollSpeed || 400}
      slidesPerView={layout.maxColumns || 'auto'}
      spaceBetween={layout.gapPixels || 10}
      effect={layout.swipeEffect || 'slide'}
      freeMode={!layout.slideSnap}
      observer={true}
      observeParents={true}
      observeSlideChildren={true}
      centerInsufficientSlides={true}
      watchSlidesVisibility
      watchSlidesProgress
    >
    {#each modifiedElements as element, i (element.id)}
      <div class="swiper-slide" class:display-none={!childContentAvailable[i]}>
        <CardsElement sequenceId={generateSequenceId(parentSequenceId, i)} {element} height='100%' bind:contentAvailable={childContentAvailable[i]} bind:contentActive={childContentActive[i]}  />
      </div>
    {/each}
    </Swiper>
  </div>
</Boundary>

<style>/*
  *, *::before, *::after {
    box-sizing: border-box;
  }
  */

.swiper-layout :global(.swiper-slide) {
  width: var(--yinzcam-cards-array-swiper-layout-slide-width);
  /* this is important so that each slide fills the parent div's vertical space */
  height: initial !important;
}

.swiper-layout.pagination :global(.swiper-container) {
  padding:0 0 44px 0 !important;
}

:global(.swiper-button-prev), :global(.swiper-button-next) {
  top: calc(50% - 20px);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(204,204,204,0.4);
}

@media (max-width: 767px) {
  .swiper-layout.pagination :global(.swiper-container) {
    padding:0 0 24px 0 !important;
  }

  :global(.swiper-button-prev), :global(.swiper-button-next) {
    top: calc(50% - 10px);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(204,204,204,0.4);
  }

  :global(.swiper-button-prev::after), :global(.swiper-button-next::after) {
    font-size: 20px;
  }
}</style>

<script lang="ts">import { YinzCamCardsServiceArrayLayout } from "yinzcam-cards";
import { generateSequenceId } from '../utilities';
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { Swiper } from 'swiper/svelte';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/effect-cube/effect-cube.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import 'swiper/components/effect-flip/effect-flip.scss';
import _ from "lodash";
import { afterUpdate } from "svelte";
export let parentSequenceId = "";
export let layout;
export let contentAvailable = true;
let swiperComponent;
let childContentAvailable = [];
let childContentActive = [];
let hasScrolledToActive = false;
let whenFirstContentAvailable;
$: {
    contentAvailable = childContentAvailable.some((item) => item);
}
$: modifiedElements = ([].concat(layout.elements)).map(element => {
    let clone = _.clone(element);
    clone.height = '100%';
    return clone;
});
$: allowScrollToActive = (!_.isNil(layout.scrollToActive)) ? layout.scrollToActive : true;
afterUpdate(() => {
    let swiperInstance = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper();
    if (!whenFirstContentAvailable && contentAvailable) {
        whenFirstContentAvailable = Date.now();
    }
    if (swiperInstance) {
        if (allowScrollToActive && !hasScrolledToActive && contentAvailable && Date.now() - whenFirstContentAvailable < 1000) {
            let firstContentActiveIndex = childContentActive.indexOf(true);
            if (firstContentActiveIndex >= 0) {
                swiperInstance.slideTo(firstContentActiveIndex, 0);
                hasScrolledToActive = true;
            }
        }
        swiperInstance.update();
    }
});
</script>
