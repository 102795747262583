import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";

@injectable()
export class YinzCamAppServerGameBox extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    let retData = data?.Boxscore

    if (["stats_section_available", "stats_section_not_available"].includes(specData?.fetchType)) {
      const condition = specData?.fetchType === "stats_section_available"
      const isPre = retData?.State?._text === 'P'
      const dataReady = [].concat(retData?.Stats?.Stat).filter(e => e?._attributes).length > 0
      const isPremire = Number(retData?.Competition?._attributes?.OptaId) === 8
      const stateAvailable = !(!isPremire && !dataReady && isPre)
      return { value: condition ? stateAvailable : !stateAvailable }
    }

    if (["stats", "offense-stats", "defense-stats"].includes(specData?.fetchType)) {
      retData = this.getMatchStats(data, specData?.fetchType)
      if (specData?.fetchType === "stats") {
        retData = retData.data
      }
    }

    if (specData?.forLiveResultsDisplay) {
      return data?.Boxscore?.Competition?._attributes?.Name?.trim() === "Premier League" ? { active: true } : null;
    }

    return retData
  }

  getMatchStats(data: any, fetchType: string): any {
    let array = []
    let clientIsHomeString
    if (fetchType === "stats") {
      array = [].concat(data?.Boxscore?.Stats?.Stat)
      clientIsHomeString = data?.Boxscore?.Stats?._attributes?.ClientIsHome
    } else if (fetchType === "offense-stats") {
      array = [].concat(data?.Boxscore?.OffenseStats?.Stat)
      clientIsHomeString = data?.Boxscore?.OffenseStats?._attributes?.ClientIsHome
    } else if (fetchType === "defense-stats") {
      array = [].concat(data?.Boxscore?.DefenseStats?.Stat)
      clientIsHomeString = data?.Boxscore?.DefenseStats?._attributes?.ClientIsHome
    }
    const ClientIsHome = clientIsHomeString ? JSON.parse(clientIsHomeString) : false

    let retArray = array.filter((e: any) => !!e?._attributes).map((e: any) => ({
        Away: e?._attributes?.Away,
        AwayValue: Number(e?._attributes?.AwayValue || "0"),
        Home: e?._attributes?.Home,
        HomeValue: Number(e?._attributes?.HomeValue || "0"),
        Max: Number(e?._attributes?.Max || "0"),
        Name: e?._attributes?.Name,
        Type: e?._attributes?.Type,
        HomeTeam: data?.Boxscore?.HomeTeam?._attributes,
        AwayTeam: data?.Boxscore?.AwayTeam?._attributes,
        ClientIsHome,
    }))

    return {
      ClientIsHome,
      HomeTeam: data?.Boxscore?.HomeTeam?._attributes,
      AwayTeam: data?.Boxscore?.AwayTeam?._attributes,
      data: retArray
    }
  }
}
