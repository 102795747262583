<Boundary>
  <div class="yinzcam-cards-element {pageContext.discoModeClass}" style="
    overflow:hidden;
    height: {height};
    {cssDisplay}
    {cssVisibility}
    {buildCssBorder(element.border)}
    {buildCssPadding(element.padding)}
    {buildCssMargin(element.padding)}
    {buildCssBackground(element.background)}
  " {...parentAttributes}>
    <svelte:component
      this={LookupCardComponent(element.class)}
      element={expandedElement}
      {sources}
      {height}
      bind:contentAvailable={childContentAvailable}
      bind:sourceStore={childSourceStore}
      bind:contentActive={childContentActive}
      bind:parentAttributes={parentAttributes}
      bind:componentVisible={childComponentVisible}
    />
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.yinzcam-cards-element {
  display: flex;
  justify-content: center;
  align-items: stretch; /* ensures content fills the entire grid area. A bug noted in desktop news items. Original value of "center" */
  /* this fixes a bug in Safari rendering where it does not correctly apply overflow: hidden when using a border radius */
  /* see https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari */
  transform: translateZ(0);
}

.yinzcam-cards-element.disco-mode-enabled {
  padding: 2px;
  border: 2px solid fuchsia;
}</style>

<script lang="ts">var _a;
import { getContext, onMount } from "svelte";
import { Readable, writable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import LookupCardComponent from "../library/index";
import { CardsDataSourceRegistration } from "./CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "./context";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { buildCssBorder, buildCssBackground, buildCssPadding, expandTemplateParams, buildCssMargin } from "../utilities/index";
import _ from "lodash";
import { CardsPageContext, CardsPageContextKey } from './context';
export let sequenceId = null;
export let element;
export let height = 'initial';
export let contentAvailable = true; // exposed upstream for binding
export let contentActive; // exposed upstream for binding
let expandedElement = element;
let mounted = false;
let childContentAvailable; // bound to card component
let childSourceStore; // bound to card component
let cssDisplay = ''; // display CSS on element (default to no change)
let cssVisibility = 'visibility: hidden;'; // visibility CSS on element (default to invisible until content available)
let childContentActive;
let parentAttributes;
let childComponentVisible;
const pageContext = getContext(CardsPageContextKey);
const tabContext = getContext(CardsTabContextKey);
let sources = writable([]);
const mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: if ((element === null || element === void 0 ? void 0 : element.data) && mergedParams && $mergedParams) {
    let expandedData = {};
    for (const [key, value] of Object.entries(element.data)) {
        if (typeof value === 'string') {
            try {
                expandedData[key] = expandTemplateParams(value, $mergedParams);
            }
            catch (err) {
                expandedData[key] = value;
            }
        }
        else {
            expandedData[key] = value;
        }
    }
    expandedElement = _.clone(element);
    expandedElement.data = expandedData;
}
$: if (sequenceId) {
    sources = tabContext.dataSourceManager.register(element.id, sequenceId, element.sourceIds);
    element = element;
}
$: {
    if (!_.isNil(childContentAvailable)) {
        // If contentAvailable is defined by the card component, that means it wants to explicitly control whether it has content available.
        contentAvailable = childContentAvailable;
    }
    else if (!_.isUndefined(childSourceStore)) {
        // If card provides access to its sourceStore, use the value of the store to determine whether it has content available.
        // Note that null here means that the sourceStore is defined by the card, it's just not available yet.
        contentAvailable = !!$childSourceStore;
    }
    else {
        // Otherwise, assume the card doesn't load dynamic content and just display as soon as we're mounted.
        contentAvailable = mounted;
    }
    if (!_.isUndefined(childComponentVisible)) {
        contentAvailable = !!childComponentVisible;
    }
}
let updateDisplay = _.debounce((ca) => {
    cssDisplay = (ca) ? '' : 'display: none;';
}, 100);
$: updateDisplay(contentAvailable);
$: cssVisibility = (contentAvailable) ? '' : 'visibility: hidden;';
$: contentActive = childContentActive;
onMount(() => {
    mounted = true;
    return () => {
        tabContext.dataSourceManager.unregister(element.id);
    };
});
</script>
