import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";

@injectable()
export class YinzCamAppServerStatsStandingsFilter extends CardsDataSourceBase {
    public constructor(@injectToken(YinzCamAppServerToken) server) {
      super({ server })
    }

    protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
        const data = response?.data as any;
        let secondaryOptions = this.getRoundOptions(data);
        return { secondaryOptions };
      }

      getRoundOptions(data: any): any {
          let filter = [].concat(data?.PhaseList?.Filters?.Filter).find(e => e?._attributes?.QueryParameter === "phase");
          return [].concat(filter?.FilterItems?.Items).filter(e => e._attributes?.Id).map(item => {
              let attrs = item?._attributes;
              return {
                  title:attrs?.Name,
                  value: attrs?.Id
              }
          });
      }

    }