<CardBase {element}>
	<div class="filter-bar">
		{#if logoURL && isDesktop} 
		<div class="filter-logo">
			<img src={logoURL} alt="logo"/>
		</div>
		{/if}
		{#if (compId !== "1" && compId !=="2")}
			<div class="filter-bar-primary">
			{#each options as option (option.value) }
				<Button
					on:click={primarySelected.bind(this, option.value)}
					round={option.value === selectedValue}
					fill={option.value === selectedValue}>{option.title}</Button>
			{/each}
			</div>
		{/if}
		{#if secondaryKey}
		<div class="filter-bar-secondary">
			<CustomSelect scrollContainer={$scrollContainerStore} items={secondaryOptions} selectedValue={selectedSecondaryValue} label={secondaryLabel} on:selected={secondarySelected} />
		</div>
		{/if}
		{#if logoURL && !isDesktop} 
		<div class="filter-logo">
			<img src={logoURL} alt="logo"/>
		</div>
		{/if}
	</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-bar-primary {
  --f7-button-border-color: #1f66ff;
  --f7-button-fill-text-color: #fff;
  --f7-button-fill-bg-color: #1f66ff;
  --f7-button-outline-border-color: #1f66ff;
  --f7-button-text-transform: none;
  display: inline-flex;
  flex-direction: row;
  overflow: auto;
}

.filter-bar-primary :global(.button) {
  margin-right: 8px;
}

.filter-bar-secondary {
  width: 224px;
}

@media (max-width: 991px) {
  .filter-bar {
    display: block;
    text-align: center;
  }

  .filter-bar-secondary {
    padding-top: 4%;
    width: 100%;
  }

  .filter-logo {
    padding-top: 8%;
  }

  /*
		.filter-bar-primary {
			--f7-button-border-color: #1f66ff;
			--f7-button-fill-text-color: #fff;
			--f7-button-fill-bg-color: #1f66ff;
			--f7-button-outline-border-color: #1f66ff;
			--f7-button-text-transform: none;	

			width: 100%;
		}
		.filter-bar-primary :global(.button) {
			margin-right: 8px;
		}

 */
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { Button } from "framework7-svelte";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { getFirstSourceStore } from "../../../utilities";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
import { t } from "../../../../../js/i18n";
import { Readable } from "svelte/store";
import { afterUpdate, getContext, onMount } from "svelte";
import CustomSelect from "../../../../CustomSelect.svelte";
import { windowMetrics as wm } from "../../../../../js/stores";
export let element;
export let sources;
//const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const tabContext = getContext(CardsTabContextKey);
$: source = getFirstSourceStore($sources);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: scrollContainerStore = tabContext === null || tabContext === void 0 ? void 0 : tabContext.scrollContainer;
$: isDesktop = $wm.width > 1024;
// primary options (TODO: move into component)
$: key = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.key;
$: logoURL = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.logoURL;
$: selectionType = (_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.selectionType;
$: sourceOptions = (_e = $source === null || $source === void 0 ? void 0 : $source.options) === null || _e === void 0 ? void 0 : _e.map((option) => {
    return {
        title: $t(option.title),
        value: option.value
    };
});
$: elementOptions = (_g = (_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.options) === null || _g === void 0 ? void 0 : _g.map((option) => {
    return {
        title: $t(option.title),
        value: option.value
    };
});
$: options = (sourceOptions || elementOptions || []);
$: selectedValue = (key) ? ($mergedParams || {})[key] : null;
// secondary options (TODO: move into component)
$: secondaryKey = (_h = element === null || element === void 0 ? void 0 : element.data) === null || _h === void 0 ? void 0 : _h.secondaryKey;
$: secondaryLabel = (_j = element === null || element === void 0 ? void 0 : element.data) === null || _j === void 0 ? void 0 : _j.secondaryLabel;
$: sourceSecondaryOptions = (_k = $source === null || $source === void 0 ? void 0 : $source.secondaryOptions) === null || _k === void 0 ? void 0 : _k.map((option) => {
    return {
        title: $t(option.title),
        value: option.value
    };
});
$: elementSecondaryOptions = (_m = (_l = element === null || element === void 0 ? void 0 : element.data) === null || _l === void 0 ? void 0 : _l.secondaryOptions) === null || _m === void 0 ? void 0 : _m.map((option) => {
    return {
        title: $t(option.title),
        value: option.value
    };
});
$: secondaryOptions = (sourceSecondaryOptions || elementSecondaryOptions || []);
$: selectedSecondaryValue = (secondaryKey) ? ($mergedParams || {})[secondaryKey] : null;
$: compId = $mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.compId;
$: console.log("merged params", $mergedParams);
function filterPrimary(value) {
    if (key) {
        tabContext.setParam(key, value);
        if (selectionType === 'SCROLL') {
            let el = document.querySelector(`[${key}="${value}"]`);
            if (el instanceof HTMLElement) {
                el.offsetParent.scroll({ top: el.offsetTop, behavior: 'smooth' });
            }
        }
    }
}
function filterSecondary(value) {
    if (secondaryKey) {
        tabContext.setParam(secondaryKey, value);
    }
}
function primarySelected(value) {
    filterPrimary(value);
}
function secondarySelected(event) {
    filterSecondary(event.detail);
    filterPrimary(null);
    options = [];
}
afterUpdate(() => {
    if (!selectedValue && $mergedParams && options && options.length > 0) {
        filterPrimary(options[0].value);
    }
    if (!selectedSecondaryValue && $mergedParams && secondaryOptions && secondaryOptions.length > 0) {
        filterSecondary(secondaryOptions[0].value);
    }
});
</script>
