<CardBase {element}>
  <div style="color:{textColor};">
    <div style="font-weight: bold; font-size: 125%;">{title}</div>
    <div style="height: 5px;"></div>
    <div style="font-size: 75%">{timestampFmt}</div>
    <hr>
    {description}
  </div>
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">var _a, _b, _c, _d;
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
let textColor = 'inherit';
$: textColor = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.textColor;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaItem');
$: data = (source) ? $source : {};
$: timestamp = (_b = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _b === void 0 ? void 0 : _b._text;
$: timestampFmt = (timestamp) ? new Date(timestamp).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric' }) : '';
$: title = ((_c = data === null || data === void 0 ? void 0 : data.Title) === null || _c === void 0 ? void 0 : _c._text) || '';
$: description = ((_d = data === null || data === void 0 ? void 0 : data.Description) === null || _d === void 0 ? void 0 : _d._text) || '';
</script>
