<CardBase {element}>
<section class="sponsor">
	{#if element.data.links}
	{#each element.data.links as sponsor}
	<a href="{sponsor.href}">
		<img src="{sponsor.thumbnail}" alt="{sponsor.name}">
	</a>
	{/each}
	{/if}
</section>	
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

:root {
  --nav-background-color:#000;
}

section {
  width:100%;
  margin:24px 0;
  padding:0 10%;
  background-color:var(--nav-background-color);
  color:#FFF;
  font-family:var(--f7-font-family);
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

section.sponsor {
  width:100%;
  margin:0 auto;
}

section.sponsor:first-child {
  border-top:0;
}

section.sponsor a {
  height:64px;
  margin:12px 24px;
  padding:12px;
  opacity:1;
  transition:all 300ms ease;
}

section.sponsor a:hover {
  opacity:1;
}

section.sponsor a img {
  width:auto;
  height:100%;
}

@media screen and (max-width:1199px){
  section {
    padding:0 12px;
  }
}

@media screen and (max-width:500px){
  section.sponsor a {
    height:48px;
    margin:12px;
  }
}</style>

<script lang="ts">import { CardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import sponsorDummyImage from "./images/sponsor-example.png";
export let element;
let sponsors = [];
$: sponsors = element === null || element === void 0 ? void 0 : element.links;
</script>