<script lang="ts">var _a, _b;
import ImageWithPlaceholder from '../../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
export let data = null;
$: homeLogo = ((_a = data === null || data === void 0 ? void 0 : data.HomeLogo) === null || _a === void 0 ? void 0 : _a.replace(/_light/g, '_dark')) || '';
$: awayLogo = ((_b = data === null || data === void 0 ? void 0 : data.AwayLogo) === null || _b === void 0 ? void 0 : _b.replace(/_light/g, '_dark')) || '';
</script>

<div class="card-el">
  <div class="top-info">
    <span class="time">{data.Time}</span>
  </div>
  <div class="player">
    <ImageWithPlaceholder
      src={data.ImageUrl}
      alt={data.Title}
      title={data.Title}
      class="player-img"
      placeholder="/static/images/download.png"
    />
  </div>
  <div class="card-el-body">
    <div class="score">
      <ImageWithPlaceholder src={homeLogo} alt="logo" class="logo" placeholder="/static/images/default_club.png" />
      <div class="score-info">{data.HomeScore || 0}-{data.AwayScore || 0}</div>
      <ImageWithPlaceholder src={awayLogo} alt="logo" class="logo" placeholder="/static/images/default_club.png" />
    </div>
    <div class="goal" class:own={data.IsOwnGoal}>{data.IsOwnGoal ? "Own Goal" : "Goal"}</div>
    <div class="player-name">{data.Title || ""}</div>
  </div>
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.card-el {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 11px 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(0deg, #121A38 0%, #0e3c74 40%, #0e3c74 60%, #121A38 100%);
}

.top-info {
  position: absolute;
  left: 16px;
  top: 11px;
  right: 16px;
}

.time {
  line-height: 31px;
  font-family: AeonikTRIAL;
  font-size: 20.2px;
  font-weight: bold;
  color: white;
  display: block;
}

.player {
  width: 100%;
  height: calc(100% - 120px);
}

.card-el-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
  background-image: linear-gradient(rgba(0,0,0,0) 0%, rgba(17,24,54,0.93333), #111836);
}

.card-el-body .score {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto 0 auto;
}

.card-el-body .score :global(.logo) {
  width: 36px;
  height: 36px;
  display: block;
}

.card-el-body .score .score-info {
  line-height: 31px;
  font-family: AeonikTRIAL;
  font-size: 25.9px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin: 0 8px;
}

.card-el-body .goal {
  text-transform: uppercase;
  line-height: 47px;
  text-shadow: 0 0 6px #0055a3;
  font-family: AeonikTRIAL;
  font-size: 52px;
  font-weight: 900;
  line-height: 0.65;
  text-align: center;
  color: #ffffff;
  margin: 7px 0 6px 0;
}

.card-el-body .goal.own {
  font-size: 32px;
  margin-top: 24px;
}

.card-el-body .player-name {
  font-family: SFUIDisplay;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #0F204B;
  background-color: #3DC1F2;
  padding-top: 2px;
  padding-bottom: 2px;
}

:global(.player-img) {
  width: 100%;
  height: 130%;
  object-fit: contain;
  display: block;
}</style>
