<CardBase {element}>
<div class="match-detail">
	<ul class="details">
		{#each events as event (event.id)}
		{#if event.periodEnd || event.periodStart}
		<li class="period-event" class:period-end={event.periodEnd} class:period-start={event.periodStart} data-play-id={event.id}>
			<div class="title">
				<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_stoppage.png" alt="Whistle Icon" class="icon">
				{#if event.periodEnd}
				<h3 class="heading">{event.title}</h3>
				{:else if event.periodStart}
				<h3 class="heading">{event.text}</h3>
				{/if}
			</div>
			<div class="matchup">
				{#if homeTeam}
				<div class="team team-1">
					<div class="name">{homeTeam._attributes.Name}</div>
					<div class="logo">
						<span><TeamLogo teamObject={homeTeam} /></span>
					</div>
					<div class="score">{event.attrs.HomeScore}</div>
				</div>
				<div class="team team-2">
					<div class="name">{awayTeam._attributes.Name}</div>
					<div class="logo">
						<span><TeamLogo teamObject={awayTeam} /></span>
					</div>
					<div class="score">{event.attrs.AwayScore}</div>
				</div>
				{/if}
			</div>
		</li>
		{:else}
		<li class="event event-type-{event.eventType} {event.teamContext}" data-play-id={event.id}>
			<div class="time-type">
				<div class="time">{event.timeLabel}</div>
				<div class="icon"></div>	
			</div>
			<div class="detail">
				<h3>{event.title}</h3>
				<h4>{event.text}</h4>
			</div>
			<div class="team"><span><TeamLogo teamObject={event.team} /></span></div>
		</li>
		{/if}
		{/each}
	</ul>
</div>
</CardBase>

<style>*,*:before,*:after {
  box-sizing:border-box;
}

.match-detail ul.details {
  width:100%;
  margin:0;
  padding:0;
  position:relative;
}

.match-detail ul.details, .match-detail ul.details li {
  width:100%;
  list-style:none;
}

.match-detail ul.details li {
  width:100%;
  margin:12px 0;
  padding:16px;
  background:var(--neutrals-white-100);
  border-radius:4px;
  display:flex;
  justify-content:space-between;
  position:relative;
  z-index:1;
}

.match-detail ul.details li.event-type-goal * {
  color:#FFF !important;
}

.match-detail ul.details li.event-type-goal .team span {
  -webkit-filter:grayscale(1);
  filter:grayscale(1);
}

.match-detail ul.details li.event-type-goal.primary {
  background:var(--alav-s-primary);
}

.match-detail ul.details li.event-type-goal.opposing {
  background:var(--neutrals-grey-darker-20);
}

.match-detail ul.details li.event-type-goal .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_goal-outline-white.svg);
}

.match-detail ul.details li.event-type-substitution .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_substitution.png);
}

.match-detail ul.details li.event-type-red-card .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_red-card.png);
}

.match-detail ul.details li.event-type-yellow-card .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_yellow-card.png);
}

.match-detail ul.details li.event-type-offside .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_offsides.png);
}

.match-detail ul.details li.event-type-corner .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_corner-kick.png);
}

.match-detail ul.details li.event-type-foul .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_stoppage.png);
}

.match-detail ul.details li .time-type {
  width:64px;
}

.match-detail ul.details li .time {
  width:100%;
  color:var(--neutrals-grey-darker-60);
  font-size:16px;
  font-weight:bold;
  text-align:center;
}

.match-detail ul.details li .icon {
  width:20px;
  height:20px;
  margin:6px auto 0 auto;
  background-size:contain;
  position:relative;
}

.match-detail ul.details li .icon:after {
  width:100%;
  height:100%;
  margin:8px 0 0 0;
  background-repeat:no-repeat;
  background-size:contain;
  position:absolute;
  top:0;
  left:0;
  content:"";
  display:block;
}

.match-detail ul.details li .detail {
  width:75%;
}

.match-detail ul.details li .team {
  width:64px;
}

.match-detail ul.details li .team > span {
  width:40px;
  margin:0 auto;
}

.match-detail ul.details li .detail h3 {
  margin:0;
  color:var(--neutrals-grey-darker-60);
  font-size:20px;
  font-weight:800;
  line-height:1.2;
}

.match-detail ul.details li.event-type-goal .detail h3 {
  text-transform:uppercase;
}

.match-detail ul.details li .detail h4 {
  margin:6px 0 0 0;
  color:var(--neutrals-grey-darker-60);
  font-size:16px;
  font-weight:normal;
  line-height:1.5;
}

.match-detail ul.details li.primary .team.event-type-substitution .player:nth-child(1):after, .match-detail ul.details li.opponent .team.event-type-substitution .player:nth-child(1):before {
  width:11px;
  height:11px;
  background-color:#00ad00;
  border-radius:50%;
  content:"";
  display:inline-block;
}

.match-detail ul.details li.primary .team.event-type-substitution .player:nth-child(2):after, .match-detail ul.details li.opponent .team.event-type-substitution .player:nth-child(2):before {
  width:11px;
  height:11px;
  background-color:#e50000;
  border-radius:50%;
  content:"";
  display:inline-block;
}

.match-detail ul.details li.primary .team.event-type-goal .player:after, .match-detail ul.details li.opponent .team.event-type-goal .player:before {
  content:"\26BD";
}

/*show blank space on the second player listed (assister)*/

.match-detail ul.details li.primary .team.event-type-goal .player:nth-child(2):after, .match-detail ul.details li.opponent .team.event-type-goal .player:nth-child(2):before {
  width:13px;
  display:inline-block;
  content:"";
}

.match-detail ul.details li.primary .team.event-type-yellow-card .player:after, .match-detail ul.details li.opponent .team.event-type-yellow-card .player:before {
  width:11px;
  height:11px;
  background-color:#ffbc00;
  content:"";
  display:inline-block;
}

.match-detail ul.details li.primary .team.event-type-red-card .player:after, .match-detail ul.details li.opponent .team.event-type-red-card .player:before {
  width:11px;
  height:11px;
  background-color:#e50000;
  content:"";
  display:inline-block;
}

/*start final card*/

.match-detail ul.details li.period-event {
  width:100%;
  padding:16px;
  background:var(--neutrals-black-black-10);
  border-radius:4px;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.match-detail ul.details li.period-event .title {
  width:100%;
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:center;
}

.match-detail ul.details li.period-event .title .icon {
  width:18px;
  height:auto;
  margin:0;
}

.match-detail ul.details li.period-event .title h3 {
  margin:8px;
  color:var(--neutrals-white-100);
  font-size:16px;
  font-weight:800;
  text-align:center;
}

.match-detail ul.details li.period-event .matchup {
  width:100%;
  margin:16px 0 0 0;
  padding:8px;
  background-color:var(--neutrals-white-100);
  border-radius:4px;
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:center;
}

.match-detail ul.details li.period-event.period-start .matchup {
  display:none;
}

.match-detail ul.details li.period-event .matchup .team {
  width:50%;
  padding:8px 16px;
  display:flex;
  justify-content:flex-end;
  align-items:center;
}

.match-detail ul.details li.period-event .matchup .team.team-2 {
  flex-direction:row-reverse;
}

.match-detail ul.details li.period-event .matchup .team .name {
  color:var(--neutrals-grey-darker-60);
  font-size:24px;
  font-weight:800;
  line-height:1.2;
}

.match-detail ul.details li.period-event .matchup .team .logo {
  width:56px;
}

.match-detail ul.details li.period-event .matchup .team .score {
  color:var(--neutrals-grey-darker-60);
  font-size:32px;
  font-weight:800;
  line-height:1.2;
}

.match-detail ul.details li.period-event .matchup .team.team-1 .score {
  margin-left:15%;
}

.match-detail ul.details li.period-event .matchup .team.team-2 .score {
  margin-right:15%;
}

@media screen and (min-width:768px){
  .match-detail {
    max-height:400px;
    overflow-y:scroll;
  }
}

@media screen and (max-width:767px){
  .match-detail {
    max-height:none;
  }

  .match-detail ul.details li.period-event .team .name {
    display:none;
  }
}

/*end final card*/</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { findSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import TeamLogo from '../../../utilities/TeamLogo.svelte';
import { onMount } from "svelte";
import { f7ready } from "framework7-svelte";
import { windowMetrics as wm } from "../../../../../js/stores.js";
export let element;
export let sources;
let scrollToPlayIdElement = function (e) {
    var playId = e.detail || false;
    if (!playId) {
        return;
    }
    var matchDetailEl = document.querySelectorAll('.match-detail');
    matchDetailEl.forEach(el => {
        var playItemEl = el.querySelector('[data-play-id="' + playId + '"]');
        playItemEl.scrollIntoView();
        // could handle things slightly differently based on viewport size, since we have a max-height set on the container element for desktop that scrolls (seen below in comments). However, I've found the method directly above (scrollIntoView) to handle both use cases just fine. We could remove the weird max-height/scrolling behavior for desktop and just let the list run down the page normally. Either way - doesn't matter to me, but the above works, so I'm going with it for now.
        // if($wm.width > 768){
        // 	el.scrollTop = playItemEl.offsetTop;
        // } else {
        // 	playItemEl.scrollIntoView();
        // }
    });
};
const eventMap = {
    'sub': 'substitution',
    'yellow': 'yellow-card',
    'red': 'red-card',
    'goal': 'goal',
    'foul': 'foul',
    'offside': 'offside',
    'corner': 'corner',
    'startperiod': 'startperiod',
    'endperiod': 'endperiod'
};
$: source = findSourceStore($sources, 'YinzCamAppServerGamePlays');
$: data = (source) ? $source : {};
$: plays = [].concat((_a = data === null || data === void 0 ? void 0 : data.Plays) === null || _a === void 0 ? void 0 : _a.Play).filter(play => { var _a; return (_a = play === null || play === void 0 ? void 0 : play._attributes) === null || _a === void 0 ? void 0 : _a.Id; });
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
// $: console.log('matchdetail data',data);
$: events = plays.map(play => {
    var _a;
    let attrs = play === null || play === void 0 ? void 0 : play._attributes;
    let team = (((attrs === null || attrs === void 0 ? void 0 : attrs.IsHome) || 'true').toLowerCase() === 'true') ? data === null || data === void 0 ? void 0 : data.HomeTeam : data === null || data === void 0 ? void 0 : data.AwayTeam;
    return {
        id: attrs === null || attrs === void 0 ? void 0 : attrs.Id,
        attrs: attrs,
        periodStart: ((attrs === null || attrs === void 0 ? void 0 : attrs.Type) === 'startperiod') ? true : false,
        periodEnd: ((attrs === null || attrs === void 0 ? void 0 : attrs.Type) === 'endperiod') ? true : false,
        eventType: eventMap[((attrs === null || attrs === void 0 ? void 0 : attrs.Type) || '')],
        timeInt: parseInt(((attrs === null || attrs === void 0 ? void 0 : attrs.Time) || "0'").slice(0, -1)),
        timeLabel: attrs === null || attrs === void 0 ? void 0 : attrs.Time,
        title: ((attrs === null || attrs === void 0 ? void 0 : attrs.Title) === 'Goal:') ? 'Goooool' : ((attrs === null || attrs === void 0 ? void 0 : attrs.Title) || ''),
        text: ((_a = play === null || play === void 0 ? void 0 : play.Text) === null || _a === void 0 ? void 0 : _a._text) || '',
        subTitle: [].concat((attrs === null || attrs === void 0 ? void 0 : attrs.Title) || []).concat((attrs === null || attrs === void 0 ? void 0 : attrs.SubTitle) || []),
        team: team,
        teamContext: ((team === null || team === void 0 ? void 0 : team._attributes.TriCode.toLowerCase()) === 'ala') ? 'primary' : 'opposing',
        players: [].concat((attrs === null || attrs === void 0 ? void 0 : attrs.Title) || []).concat((attrs === null || attrs === void 0 ? void 0 : attrs.SubTitle) || [])
    };
}).sort((a, b) => b.timeInt - a.timeInt);
onMount(() => {
    f7ready(() => {
        window.addEventListener('playIDFocus', scrollToPlayIdElement);
    });
});
</script>