<script lang="ts">import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import ScheduleItem from './ScheduleItem.svelte';
import { findSourceStore, getHrefForMediaItem } from "../../../../../components/cards/utilities";
export let element;
export let sources;
export let sourceStore;
export let parentAttributes;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGameList');
}
;
$: source_data = (sourceStore) ? $sourceStore : {};
$: title = source_data === null || source_data === void 0 ? void 0 : source_data.mobileTitle;
$: game_data = source_data === null || source_data === void 0 ? void 0 : source_data.data;
$: console.log(game_data);
let gameYear = "";
let monthNumber;
$: list = game_data === null || game_data === void 0 ? void 0 : game_data.map((game) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18;
    const date = new Date(Date.parse((_b = (_a = game === null || game === void 0 ? void 0 : game.Date) === null || _a === void 0 ? void 0 : _a._attributes) === null || _b === void 0 ? void 0 : _b.Timestamp));
    let formattedDate = "";
    let gameTime = "";
    if (date) {
        const dateFormatter = new Intl.DateTimeFormat('es-ES', { weekday: 'short', day: '2-digit', month: 'short' });
        // Go brute force, Luke
        let weekDay = new Intl.DateTimeFormat('es-ES', { weekday: 'short' }).format(date);
        let month = new Intl.DateTimeFormat('es-ES', { month: 'short' }).format(date);
        let day = new Intl.DateTimeFormat('es-ES', { day: '2-digit' }).format(date);
        gameTime = date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        monthNumber = date.getMonth();
        gameYear = date.getFullYear().toString();
        weekDay = weekDay[0].toUpperCase() + weekDay.slice(1);
        month = month[0].toUpperCase() + month.slice(1);
        formattedDate = weekDay + " " + day + " " + month;
        //   formattedDate = dateFormatter.format(date).split(",").join('');
    }
    const homeTeam = ((_c = game === null || game === void 0 ? void 0 : game._attributes) === null || _c === void 0 ? void 0 : _c.Home) === "true" ? (_e = (_d = game === null || game === void 0 ? void 0 : game.Team) === null || _d === void 0 ? void 0 : _d._attributes) === null || _e === void 0 ? void 0 : _e.FullName : (_g = (_f = game === null || game === void 0 ? void 0 : game.Opponent) === null || _f === void 0 ? void 0 : _f._attributes) === null || _g === void 0 ? void 0 : _g.FullName;
    const awayTeam = ((_h = game === null || game === void 0 ? void 0 : game._attributes) === null || _h === void 0 ? void 0 : _h.Home) === "true" ? (_k = (_j = game === null || game === void 0 ? void 0 : game.Opponent) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.FullName : (_m = (_l = game === null || game === void 0 ? void 0 : game.Team) === null || _l === void 0 ? void 0 : _l._attributes) === null || _m === void 0 ? void 0 : _m.FullName;
    const teamLogoURL = 'https://resourceslfp.azureedge.net/lfp/shared/logos/' + ((_q = (_p = (_o = game === null || game === void 0 ? void 0 : game.Team) === null || _o === void 0 ? void 0 : _o._attributes) === null || _p === void 0 ? void 0 : _p.LogoId) === null || _q === void 0 ? void 0 : _q.toLowerCase()) + '_dark.png';
    const opponentLogoURL = 'https://resourceslfp.azureedge.net/lfp/shared/logos/' + ((_t = (_s = (_r = game === null || game === void 0 ? void 0 : game.Opponent) === null || _r === void 0 ? void 0 : _r._attributes) === null || _s === void 0 ? void 0 : _s.LogoId) === null || _t === void 0 ? void 0 : _t.toLowerCase()) + '_dark.png';
    const homeLogoUrl = ((_u = game === null || game === void 0 ? void 0 : game._attributes) === null || _u === void 0 ? void 0 : _u.Home) === "true" ? teamLogoURL : opponentLogoURL;
    const awayLogoUrl = ((_v = game === null || game === void 0 ? void 0 : game._attributes) === null || _v === void 0 ? void 0 : _v.Home) === "true" ? opponentLogoURL : teamLogoURL;
    const score1 = (_x = (_w = game === null || game === void 0 ? void 0 : game._attributes) === null || _w === void 0 ? void 0 : _w.Result) === null || _x === void 0 ? void 0 : _x.split(' ')[0].split('-')[0];
    const score2 = (_z = (_y = game === null || game === void 0 ? void 0 : game._attributes) === null || _y === void 0 ? void 0 : _y.Result) === null || _z === void 0 ? void 0 : _z.split(' ')[0].split('-')[1];
    const homeScore = ((_0 = game === null || game === void 0 ? void 0 : game._attributes) === null || _0 === void 0 ? void 0 : _0.Home) === "true" ? score1 : score2;
    const awayScore = ((_1 = game === null || game === void 0 ? void 0 : game._attributes) === null || _1 === void 0 ? void 0 : _1.Home) === "true" ? score2 : score1;
    let scoreDetails1 = [];
    let scoreDetails2 = [];
    let hideScoreDetails = false;
    const scoringDetails = ((_2 = game === null || game === void 0 ? void 0 : game.ScoringPlays) === null || _2 === void 0 ? void 0 : _2.ScoringPlay) || [];
    if (scoringDetails !== null && Array.isArray(scoringDetails)) {
        scoringDetails.map((matchScorers) => {
            var _a, _b, _c;
            const goalScorer = (_a = matchScorers === null || matchScorers === void 0 ? void 0 : matchScorers.Description) === null || _a === void 0 ? void 0 : _a._text;
            const goalTime = (_b = matchScorers === null || matchScorers === void 0 ? void 0 : matchScorers._attributes) === null || _b === void 0 ? void 0 : _b.Time;
            const score = { label: goalScorer, goals: goalTime };
            ((_c = matchScorers === null || matchScorers === void 0 ? void 0 : matchScorers._attributes) === null || _c === void 0 ? void 0 : _c.IsHome) === "true" ? scoreDetails1.push(score) : scoreDetails2.push(score);
        });
    }
    else if (scoringDetails !== null) {
        const goalScorer = (_3 = scoringDetails === null || scoringDetails === void 0 ? void 0 : scoringDetails.Description) === null || _3 === void 0 ? void 0 : _3._text;
        const goalTime = (_4 = scoringDetails === null || scoringDetails === void 0 ? void 0 : scoringDetails._attributes) === null || _4 === void 0 ? void 0 : _4.Time;
        const score = { label: goalScorer, goals: goalTime };
        ((_5 = scoringDetails === null || scoringDetails === void 0 ? void 0 : scoringDetails._attributes) === null || _5 === void 0 ? void 0 : _5.IsHome) === "true" ? scoreDetails1.push(score) : scoreDetails2.push(score);
    }
    if (scoreDetails1.length === 0 && scoreDetails2.length === 0) {
        hideScoreDetails = true;
    }
    let roundNum = (_6 = game._attributes) === null || _6 === void 0 ? void 0 : _6.Round;
    let mobileCompTitle = (((_8 = (_7 = game._attributes) === null || _7 === void 0 ? void 0 : _7.Competition) === null || _8 === void 0 ? void 0 : _8.includes("LaLiga")) ? "LaLiga - J" + roundNum : (_9 = game._attributes) === null || _9 === void 0 ? void 0 : _9.Competition);
    let originalLogo = (_11 = (_10 = game === null || game === void 0 ? void 0 : game.Competition) === null || _10 === void 0 ? void 0 : _10.LogoUrl) === null || _11 === void 0 ? void 0 : _11._text;
    // remove this once the data source is fixed
    let campaignLogo = originalLogo.replace('HzposColor', 'VtColor').replace('Hzcolorpos', 'Vtcolor').replace('Hzcolornpos', 'Vtcolornpos');
    if (((_12 = game === null || game === void 0 ? void 0 : game._attributes) === null || _12 === void 0 ? void 0 : _12.Type) === "F") {
        return ({
            'id': (_13 = game === null || game === void 0 ? void 0 : game._attributes) === null || _13 === void 0 ? void 0 : _13.Id,
            'campaignLogo': campaignLogo,
            'date': formattedDate,
            'date1': `Jornada ${(_14 = game === null || game === void 0 ? void 0 : game._attributes) === null || _14 === void 0 ? void 0 : _14.Round}`,
            'stadium': (_15 = game === null || game === void 0 ? void 0 : game._attributes) === null || _15 === void 0 ? void 0 : _15.Venue,
            'team1': homeTeam,
            'teamLogo1': homeLogoUrl,
            'team2': awayTeam,
            'teamLogo2': awayLogoUrl,
            'score1': homeScore || '',
            'score2': awayScore || '',
            'scoreDetails1': scoreDetails1,
            'scoreDetails2': scoreDetails2,
            'type': 1,
            'mobileCompTitle': mobileCompTitle,
            'hideScoreDetails': hideScoreDetails
        });
    }
    else {
        return ({
            'id': (_16 = game === null || game === void 0 ? void 0 : game._attributes) === null || _16 === void 0 ? void 0 : _16.Id,
            'campaignLogo': campaignLogo,
            'date': formattedDate,
            'date1': `Jornada ${(_17 = game === null || game === void 0 ? void 0 : game._attributes) === null || _17 === void 0 ? void 0 : _17.Round}`,
            'stadium': (_18 = game === null || game === void 0 ? void 0 : game._attributes) === null || _18 === void 0 ? void 0 : _18.Venue,
            'team1': homeTeam,
            'teamLogo1': homeLogoUrl,
            'team2': awayTeam,
            'teamLogo2': awayLogoUrl,
            'time': gameTime,
            'inProgress': true,
            'type': 0,
            'mobileCompTitle': mobileCompTitle
        });
    }
});
$: data = {
    'title': title + ` ${gameYear}` || [],
    'monthNumber': monthNumber,
    'list': list || [],
};
$: console.log(data);
$: {
    parentAttributes = { 'data-schedule-section-month': data.monthNumber };
}
;
</script>

<CardBase {element}>
  <h1 class="schedule-title">{data.title}</h1>
  {#if (data.list.length === 0)}
    <div class="empty-schedule-list">
      <div>
        <img src="/assets/img/watch.png" height="24" alt="watch" />
      </div>
      <h2>Lo sentimos, no hay partidos contemplados para este mes</h2>
      <div>Vuelve un poco más tarde para comprobar si hay actualizaciones</div>
    </div>
  {:else}
    <ul class="schedule-list">
      {#each data.list as item}
        <ScheduleItem {item} scheduleType={item.type} />
      {/each}
    </ul>
  {/if}
  </CardBase>

<style>.schedule-title {
  font-size: 20px;
  line-height: 24px;
  margin: 20px 32px;
  color: #1c1f22;
}

.schedule-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.empty-schedule-list {
  width: 360px;
  margin: 75px auto;
  font-family: 'Core Sans C 75';
  text-align: center;
}

.empty-schedule-list h2 {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  color: #2e3338;
}

.empty-schedule-list div {
  font-size: 14px;
  line-height: 1.43;
  color: #535c65;
}</style>
