<CardBase {element}>
<div class="match-lineup">
	<div class="legend">
		<div class="team opponent">
			{#if homeTeam && homeTeam._attributes}
			<div class="team-name">{homeTeam._attributes.Name || ''}</div>
			<span class='logo'><TeamLogo teamObject={homeTeam} /></span>
			{/if}
		</div>
		<h3 class="title">{$t("Suplentes")}</h3>
		<div class="team primary">
			{#if awayTeam && awayTeam._attributes}
			<div class="team-name">{awayTeam._attributes.Name || ''}</div>
			<span class='logo'><TeamLogo teamObject={awayTeam} /></span>
			{/if}
		</div>
	</div>
	<ul class="lineup opponent">
		{#if Array.isArray(homeSubs)}
		{#each homeSubs as sub (sub.id)}
		<li class="{sub.card}-card">
			<span class="number">{sub.number}</span>
			<span class="name">{sub.name}</span>
		</li>
		{/each}
		{/if}
	</ul>
	<ul class="lineup primary">
		{#if Array.isArray(awaySubs)}
		{#each awaySubs as sub (sub.id)}
		<li class="{sub.card}-card">
			<span class="number">{sub.number}</span>
			<span class="name">{sub.name}</span>
		</li>
		{/each}
		{/if}
	</ul>
</div>
</CardBase>
<style>*, *:before, *:after {
  box-sizing:border-box;
}

.match-lineup {
  width:100%;
  background:#000;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  overflow:hidden;
}

.match-lineup .legend {
  width:100%;
  padding:12px;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items:center;
}

.match-lineup .legend .team {
  width:72px;
}

.match-lineup .legend .team-name {
  display:none;
}

.match-lineup .legend .logo {
  width:90%;
  height:intrinsic;
  height:auto;
  margin:8px auto;
  display:block;
}

.match-lineup .legend .title {
  width:auto;
  color:#FFF;
  font-size:1.5vw;
  text-align:center;
}

.match-lineup .lineup {
  width:50%;
  margin:12px 0;
  padding:0;
  list-style:none;
}

/*.match-lineup .lineup.opponent {
		padding-left:5%;
	}

	.match-lineup .lineup.primary {
		padding-right:5%;
	}*/

.match-lineup .lineup li {
  width:100%;
  padding:8px 2px;
  color:#c3c3c3;
  font-size:1.1vw;
  text-align:center;
  display:flex;
  align-items:center;
}

.match-lineup .lineup + .lineup li {
  flex-direction:row-reverse;
}

.match-lineup .lineup li .name:before, .match-lineup .lineup li .name:after {
  font-size:10px;
}

.match-lineup .lineup.opponent li.yellow-card .name:after, .match-lineup .lineup.primary li.yellow-card .name:before {
  width:11px;
  height:11px;
  background-color:#ffbc00;
  content:"";
  display:inline-block;
  /*content:"\1F7E8";*/
}

.match-lineup .lineup.opponent li.red-card .name:after, .match-lineup .lineup.primary li.red-card .name:before {
  width:11px;
  height:11px;
  background-color:#e50000;
  content:"";
  display:inline-block;
  /*content:"\1F7E5";*/
}

.match-lineup .lineup.opponent li.goal .name:after, .match-lineup .lineup.primary li.goal .name:before {
  content:"\26BD";
}

.match-lineup .lineup.opponent li .name:after {
  margin-left:4px;
}

.match-lineup .lineup.primary li .name:before {
  margin-right:4px;
}

.match-lineup .lineup li span {
  margin:0 3px;
  position:relative;
}

.match-lineup .lineup li span.name {
  overflow:hidden;
  white-space:nowrap;
}

.match-lineup .lineup li span.number {
  width:15%;
}

@media screen and (max-width:1001px) and (max-width:1199px){
  .match-lineup .legend .title {
    font-size:2.5vw;
  }

  .match-lineup .lineup li {
    font-size:1.25vw;
  }
}

@media screen and (max-width:1000px){
  .match-lineup .legend .title {
    font-size:3vw;
  }

  .match-lineup .lineup li {
    font-size:1.5vw;
  }
}

@media screen and (max-width:767px){
  .match-lineup .legend .title {
    font-size:4vw;
  }

  .match-lineup .lineup li {
    font-size:2vw;
  }
}

@media screen and (max-width:500px){
  .match-lineup .lineup li {
    font-size:3.25vw;
  }
}</style>

<script lang="ts">var _a, _b;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { t } from "../../../../js/i18n";
export let element;
export let sources;
function subsMapper(sub) {
    let attrs = sub === null || sub === void 0 ? void 0 : sub._attributes;
    return {
        id: attrs === null || attrs === void 0 ? void 0 : attrs.Id,
        number: attrs === null || attrs === void 0 ? void 0 : attrs.Number,
        name: attrs === null || attrs === void 0 ? void 0 : attrs.Name,
        card: (((attrs === null || attrs === void 0 ? void 0 : attrs.RedCards) || 0) > 0) ? 'red' : ((((attrs === null || attrs === void 0 ? void 0 : attrs.YellowCards) || 0) > 0) ? 'yellow' : 'none'),
    };
}
$: source = findSourceStore($sources, 'YinzCamAppServerGameFormation');
$: data = (source) ? $source : {};
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: substitutes = data === null || data === void 0 ? void 0 : data.Substitutes;
$: awaySubs = [].concat((_a = substitutes === null || substitutes === void 0 ? void 0 : substitutes.AwayTeam) === null || _a === void 0 ? void 0 : _a.Player).filter(sub => { var _a; return (_a = sub === null || sub === void 0 ? void 0 : sub._attributes) === null || _a === void 0 ? void 0 : _a.Id; }).map(subsMapper);
$: homeSubs = [].concat((_b = substitutes === null || substitutes === void 0 ? void 0 : substitutes.HomeTeam) === null || _b === void 0 ? void 0 : _b.Player).filter(sub => { var _a; return (_a = sub === null || sub === void 0 ? void 0 : sub._attributes) === null || _a === void 0 ? void 0 : _a.Id; }).map(subsMapper);
</script>
