<CardBase {element}>
  <div class="laliga-wide-photos-card type-large-photo">
    <a href={getHrefForMediaItem(type, id)}>
      <img class="thumbnail" src={imageUrl} alt="">
      <div class="content">
        <h3 class="title">{title}</h3>
        <span class="publish-date">{publishDateFmt}</span>
        <span class="gallery-detail">{cat}</span>
      </div>
    </a>
  </div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.laliga-wide-photos-card.type-large-photo {
  width:100%;
  height:100%;
  border-radius:8px;
  overflow:hidden;
  --font-scaling: 1.0;
}

.laliga-wide-photos-card.type-large-photo a {
  width:100%;
  height:100%;
  background-color:#000;
  text-decoration:none;
  display:flex;
  flex-wrap:wrap;
  position:relative;
  overflow:hidden;
}

.laliga-wide-photos-card.type-large-photo a .thumbnail {
  width:100%;
  height:intrinsic;
  height:auto;
  opacity:0.8;
  object-fit: cover;
}

.laliga-wide-photos-card.type-large-photo a .content {
  width:100%;
  height:100%;
  padding:3%;
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
  position:absolute;
  top:0;
  left:0;
  z-index:2;
}

.laliga-wide-photos-card.type-large-photo a .content .title {
  width:50%;
  margin:0 0 16px 0;
  color:#FFF;
  font-weight:700;
  line-height:1.3;
}

.laliga-wide-photos-card.type-large-photo a .content .publish-date {
  width:100%;
  color:#FFF;
  font-size:1vw;
  font-weight:normal;
  letter-spacing:1px;
  opacity:0.7;
}

.laliga-wide-photos-card.type-large-photo a .content .gallery-detail {
  width:100%;
  margin:0;
  color:#FFF;
  font-size:1.5vw;
  font-weight:700;
  letter-spacing:1px;
  position:absolute;
  bottom:5%;
}

@media screen and (min-width:1200px){
  .laliga-wide-photos-card.type-large-photo a .content .title {
    font-size: calc(1.25vw * var(--font-scaling));
  }
}

@media screen and (min-width:501px) and (max-width:1199px){
  .laliga-wide-photos-card.type-large-photo a .content .title {
    font-size: calc(1.75vw * var(--font-scaling));
  }

  .laliga-wide-photos-card.type-large-photo a .content .publish-date, .laliga-wide-photos-card.type-large-photo a .content .gallery-detail {
    font-size: calc(0.9vw * var(--font-scaling));
  }
}

@media screen and (max-width:500px){
  .laliga-wide-photos-card.type-large-photo a .content .title {
    margin:0 0 8px 0;
  }

  .laliga-wide-photos-card.type-large-photo a .content .publish-date, .laliga-wide-photos-card.type-large-photo a .content .gallery-detail {
    font-size:1.5vw;
  }
}</style>

<script type="ts">var _a, _b, _c, _d, _e, _f;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore, getHrefForMediaItem } from "../../../../../components/cards/utilities";
import { tdate } from "../../../../../js/i18n";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerMediaSource');
}
;
$: data = (sourceStore) ? $sourceStore : {};
$: type = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Type;
$: id = (_b = data === null || data === void 0 ? void 0 : data.Id) === null || _b === void 0 ? void 0 : _b._text;
$: imageUrl = (_c = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _c === void 0 ? void 0 : _c._text;
$: title = (_d = data === null || data === void 0 ? void 0 : data.Title) === null || _d === void 0 ? void 0 : _d._text;
$: timestamp = (_e = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _e === void 0 ? void 0 : _e._text;
$: publishDate = (timestamp) ? new Date(timestamp) : null;
$: publishDateFmt = $tdate(publishDate, { month: 'short', day: 'numeric', year: 'numeric' });
$: cat = (_f = data === null || data === void 0 ? void 0 : data.PrimaryCategoryName) === null || _f === void 0 ? void 0 : _f._text;
</script>