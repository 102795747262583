<script lang="ts">import ImageWithPlaceholder from '../../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
import footballImage from '../../image/football_icon_white.png';
import chromeMask from '../../image/chrome_mask.png';
import { Link } from 'framework7-svelte';
export let Play = {};
</script>

<div class="live-reporting-goal-card">
  <div class="headline">
    <div class="time">
      {Play.Time || ""}
    </div>
    <div class="score">
      <div class="team-name">{Play.HomeTeam.TriCode}</div>
      <div class="score-info">
        {Play.HomeScore || 0}-{Play.AwayScore || 0}
      </div>
      <div class="team-name">{Play.AwayTeam.TriCode}</div>
    </div>
  </div>
  <div class="live-content">
    <div class="goal-content-media">
      <div class="media">
        <ImageWithPlaceholder
          title={Play.Player1.Name}
          src={Play.Player1.ImageUrl}
          alt="Player"
          class="player-image"
          placeholder="/static/images/download.png"
        />
      </div>
      <div class="media-body">
        <div class="heading">
				<!--<img src={footballImage} alt="Footballl" class="heading-icon" />-->
          <h3>{Play.Title}</h3>
        </div>
        <div>
          <div class="name">{Play.Player1.Name}</div>
        </div>
      </div>
    </div>
    <div class="live-body">
      {Play.Text || ""}
    </div>
  </div>
  <!-- <div class="link-container">
    <Link href={Play.FacebookUrl} external={true} target="_blank">
      <i class="f7-icons">logo_facebook</i>
    </Link>
    <Link href={Play.TwitterUrl} external={true} target="_blank">
      <i class="f7-icons">logo_twitter</i>
    </Link>
    <Link href={Play.Url} external={true} target="_blank">
      <i class="f7-icons">link</i>
    </Link>
  </div> -->
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.live-reporting-goal-card {
  padding: 28px 24px 24px 42px;
  background: #0F204B;
  border-radius: 12px;
  width: 100%;
}

.headline {
  display: flex;
  justify-content: space-between;
}

.headline .time {
  font-family: AeonikTRIAL;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.23;
  text-align: center;
  color: #ffffff;
}

.headline .score {
  display: flex;
  align-items: center;
}

.headline .score .team-name {
  font-family: AeonikTRIAL;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #ffffff;
}

.headline .score .score-info {
  margin: 0px 12px;
  font-family: AeonikTRIAL;
  font-size: 24px;
  font-weight: bold;
  line-height: 0.95;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.live-content {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 0;
}

.live-content .goal-content-media {
  display: flex;
  align-items: center;
}

.live-content .goal-content-media .media {
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 50%;
  background: #9aa7a7;
}

.live-content .goal-content-media .media :global(.player-image) {
  width: 100%;
  height: 100%;
}

.live-content .goal-content-media .media :global(img) {
  width: 100%;
  height: 100%;
}

.live-content .goal-content-media .media-body {
  margin: 0 0 0 24px;
}

.live-content .goal-content-media .media-body .heading {
  margin: 0 0 9px 0;
  display: flex;
}

.live-content .goal-content-media .media-body .heading img {
  width: 29px;
  height: 29px;
  display: block;
}

.live-content .goal-content-media .media-body .heading h3 {
  font-family: zuume-cut,sans-serif;
  font-size: 110px;
  font-style: italic;
  font-weight: bold;
  line-height: 1;
  background: url("../../image/chrome_mask.png") 0;
  background-size: contain;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ffffff;
  margin: -60px 0 0 0;
  text-transform: uppercase;
}

.live-content .goal-content-media .media-body .name {
  display: inline-block;
  margin: -20px 0 0 0;
  padding: 9px 12px 9px 12px;
  background-color: #3DC1F2;
  font-style: italic;
  line-height: 36px;
  font-family: zuume-cut,sans-serif;
  font-size: 52px;
  font-weight: 700;
  color: #0F204B;
}

.live-content .live-body {
  width: 50%;
  font-family: AeonikTRIAL;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  white-space: pre-line;
}

.link-container {
  display: flex;
  justify-content: flex-end;
  grid-gap: 24px;
  gap: 24px;
}

.link-container :global(a .f7-icons) {
  font-size: 24px;
  color: white;
}

@media (max-width: 767px) {
  .live-reporting-goal-card {
    padding: 6px 12px 12px 10px;
  }

  .headline {
    display: flex;
    justify-content: space-between;
  }

  .headline .time {
    font-size: 14px;
    line-height: 1.83;
  }

  .headline .score {
    display: flex;
    align-items: center;
  }

  .headline .score .team-name {
    font-size: 12px;
    line-height: 1;
  }

  .headline .score .score-info {
    margin: 0px 4px;
    font-size: 16px;
    line-height: 1.42;
  }

  .live-content {
    display: flex;
    flex-direction: column;
    margin: 15px 0 8px 0;
  }

  .live-content .goal-content-media {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
  }

  .live-content .goal-content-media .media {
    width: 58px;
    height: 58px;
  }

  .live-content .goal-content-media .media :global(img) {
    width: 100%;
    height: 100%;
  }

  .live-content .goal-content-media .media-body {
    margin: 0 0 0 15px;
  }

  .live-content .goal-content-media .media-body .heading {
    margin: 0 0 9px 0;
    display: flex;
  }

  .live-content .goal-content-media .media-body .heading img {
    width: 24px;
    height: 24px;
    display: block;
  }

  .live-content .goal-content-media .media-body .heading h3 {
    font-family: zuume-cut,sans-serif;
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    margin: 0 0 0 0;
  }

  .live-content .goal-content-media .media-body .name {
    margin: -10px 0 0 0;
    padding: 5px 7px;
    background-color: #3dc1f2;
    line-height: 21.6px;
    font-size: 32px;
  }

  .live-content .live-body {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
  }

  .link-container {
    display: flex;
    justify-content: flex-end;
    grid-gap: 12px;
    gap: 12px;
  }

  .link-container :global(a .f7-icons) {
    font-size: 20px;
    color: white;
  }
}</style>
