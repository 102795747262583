import { Logger, LoggerToken } from 'yinzcam-log';
import { injectable, interfaces as Inversify } from 'inversify';
import { injectToken, Token, interfaces as InversifyToken, multiInjectToken } from 'inversify-token';
import { AppConfig, ConfigToken } from 'yinzcam-config';
import { Dictionary } from 'typescript-collections';
import { YinzCamServer } from './YinzCamServer';
import { YinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameterComponentToken } from './YinzCamAPIRequestParameterComponent';
import { MergedYinzCamAPIRequestParameterComponent } from './MergedYinzCamAPIRequestParameterComponent';

/* --- Note to the reader ---
  I expect that this file will be the target of a lot of development over time, both
  in understanding how it works and extending it to add more services and capabilities.

  To that end, I've documented this far more verbosely than I normally would, and included links to materials
  that I found useful when learning the concepts used here (Svelte, TypeScript, Axios, HTTP header parsing, etc.).

  I hope that the extra documentation here will help serve as an onboarding guide for developers and also provide
  a reference for concepts that are used throughout the code.
*/

/*
  More info on XML-JS conversion: https://www.npmjs.com/package/xml-js

  More info on Axios: https://www.npmjs.com/package/axios
  ... and Axios TypeScript bindings: https://medium.com/@enetoOlveda/how-to-use-axios-typescript-like-a-pro-7c882f71e34a

  JavaScript regex guide: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions

  Cordova extended storage options (if needed in the future): https://cordova.apache.org/docs/en/latest/cordova/storage/storage.html
  I'm a bit worried about the total size of the locally cached page objects exceeding localStorage capacity. Could use gzip as well.
*/

export const YinzCamAPIToken = new Token<YinzCamAPI>(Symbol.for("YinzCamAPI"));

// TypeScript Classes: https://www.typescriptlang.org/docs/handbook/classes.html
@injectable()
export class YinzCamAPI {
  private static readonly SERVER_CACHE: Dictionary<string, YinzCamServer> = new Dictionary<string, YinzCamServer>();

  private static getServerCached(service: string, tricode: string, league: string, loader: () => YinzCamServer): YinzCamServer {
    const key = `${service}-${tricode}-${league}`;
    let server = YinzCamAPI.SERVER_CACHE.getValue(key);
    if (!server) {
      server = loader();
      YinzCamAPI.SERVER_CACHE.setValue(key, server);
    }
    return server;
  }

  /*
  public static createServerFactory<T extends YinzCamServer>(createFunc: (league: string, tricode: string) => T) {
    return (context: Inversify.Context) => {
      return (tricode: string, league: string) => {
        return YinzCamAPI.getServerCached('app', tricode, league, createFunc.bind(league, tricode)) as T;
      };
    };
  }
  */

  public readonly league: string;
  public readonly tricode: string;
  private readonly requestParameterComponent: MergedYinzCamAPIRequestParameterComponent;

  constructor(
      @injectToken(LoggerToken) private readonly log: Logger,
      @injectToken(ConfigToken) private readonly config: AppConfig,
      @multiInjectToken(YinzCamAPIRequestParameterComponentToken) requestParameterInputs: YinzCamAPIRequestParameterComponent[]) {
    this.league = config.league;
    this.tricode = config.tricode;
    this.requestParameterComponent = new MergedYinzCamAPIRequestParameterComponent({ name: 'YinzCamAPI_MergedRequestParameters' }, ...requestParameterInputs);
  }

  public getServer(service: string, pathPrefix?: string): YinzCamServer {
    return YinzCamAPI.getServerCached(service, this.tricode, this.league, () => {
      return new YinzCamServer(this.log, this.config, this.requestParameterComponent, service, pathPrefix);
    });
  }
}
