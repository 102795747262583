<CardBase {element}>
  <div class="type-g32" style="margin-bottom:80px;">
    <!-- barchart stuff -->
    {#each barChartData as barChart}
    <div class="viz bar-chart" class:hidden="{barChart.hidden === true}">
      <div class="chart">
        {#each barChart.data as teamStat}
        <div class="team {teamStat.team}">
          <div class="bar {teamStat.team}" style="height:{teamStat.width}%;background-color:{teamStat.fillColor};"></div>
          <div class="chart-value">{teamStat.label}</div>
        </div>
        {/each}
      </div>
      <h3 class="title">{barChart.title}</h3>
    </div>
    {/each}
    <div class="legend">
      {#if primaryTeam}
      <div class="team">
        <span class="marker" style="background-color:{primaryTeam.color};"></span>
        <span class="team-name">{primaryTeam.name}</span>
      </div>
      {/if}
      {#if otherTeam}
      <div class="team">
        <span class="marker" style="background-color:{otherTeam.color};"></span>
        <span class="team-name">{otherTeam.name}</span>
      </div>
      {/if}
    </div>
  </div>
</CardBase>

<style>*,*:before,*:after {
  box-sizing:border-box;
}

.type-g32 {
  width:100%;
  padding:0 12px;
  background-color:var(--f7-theme-color-bg-color);
  font-family:var(--f7-font-family);
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
}

.type-g32 .viz {
  /*width:100%;*/
  margin:8px 0;
}

.type-g32 .title {
  width:100%;
  margin:0;
  padding:8px 0;
  color:var(--f7-theme-color);
  font-size:3vw;
  font-weight:400;
  text-align:center;
}

/*bar chart*/

.bar-chart {
  width:30%;
  padding:0 6px;
  display:flex;
  flex-wrap:wrap;
  align-items:stretch;
}

.bar-chart .chart {
  width:100%;
  height:200px;
  padding:36px 6px 0 6px;
  border-bottom:solid 1px rgba(255,255,255,0.5);
  position:relative;
  display:flex;
  flex-direction:column;
  transition:all 200ms;
}

.bar-chart .chart .team {
  width:50%;
  height:100%;
  padding:36px 4px 0 4px;
  display:flex;
  flex-direction:column-reverse;
  position:absolute;
  top:0;
  overflow:visible;
}

.bar-chart .chart .team:nth-child(1) {
  left:0;
}

.bar-chart .chart .team:nth-child(2) {
  right:0;
}

.bar-chart .chart .bar {
  width:100%;
  margin:0 auto;
  border-radius:8px 8px 0 0;
}

.bar-chart .chart .bar:nth-child(odd) {
  background-color:orange;
}

.bar-chart .chart .bar:nth-child(even) {
  background-color:#FFF;
}

.bar-chart .title {
  width:100%;
  text-align:center;
}

.bar-chart .chart-value {
  width:100%;
  padding:0 6px;
  text-align:center;
  font-size: 3vw;
}

.type-g32 .legend {
  width:100%;
  margin:12px 0;
  display:flex;
  justify-content:space-around;
  align-items:center;
}

.type-g32 .legend .team {
  width:40%;
  display:flex;
  align-items:center;
}

.type-g32 .legend .team .marker {
  width:24px;
  height:24px;
  margin:0 12px 0 0;
  border-radius:8px 8px 0 0;
}

.type-g32 .legend .team .team-name {
  font-size:3vw;
  font-weight:bold;
}

@media screen and (min-width:1025px) {
  .type-g32 {
    justify-content:flex-start;
  }

  .bar-chart {
    width:14%;
  }

  .type-g32 .viz {
    margin:24px 3%;
    order:2;
  }

  .type-g32 .legend {
    order:1;
  }

  .type-g32 .legend .team {
    justify-content:center;
  }

  .type-g32 .legend .team .team-name {
    font-size:1vw;
  }

  .bar-chart .title {
    font-size:0.8vw;
    font-weight:bold;
  }

  .bar-chart .chart-value {
    font-size:0.8vw;
  }
}</style>

<script lang="ts">var _a, _b;
import { Link } from 'framework7-svelte';
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
let barChartData = [];
//will need to be dynamic
let primaryTeamAbv = 'team-1';
//set colors
//find a way to do this via JS and theme options
let primaryHexColor = '#fcd069'; //primary teams color
let secondaryHexColor = '#EAEAEA'; //other teams color
let inActiveArcColor = '#222222'; //fill color for "inactive" part of radial chart
let fallbackColorConfig = {
    team: 'default',
    primary: '#EAEAEA',
    inActiveArcColor: inActiveArcColor
};
let colorConfig = [
    {
        team: 'team-1',
        primary: primaryHexColor
    },
    {
        team: 'team-2',
        primary: secondaryHexColor
    },
    fallbackColorConfig //adding the fallback here to be queried against
];
//this may be useful as a shared utility function, as with the config above
let findTeamColor = function (teamName, colorName) {
    let color;
    let findFallbackColor = function (color) {
        if (!fallbackColorConfig.hasOwnProperty(color)) {
            console.log('No fallback under that name either, so defaulting to fallback primary: ' + fallbackColorConfig.primary);
            return fallbackColorConfig.primary;
        }
        else {
            return fallbackColorConfig[color];
        }
    };
    colorConfig.forEach(function (config) {
        //found a team match
        if (config.team === teamName) {
            //team found, but no such color name configured
            if (!config.hasOwnProperty(colorName)) {
                console.log('No color ' + colorName + ' configured for ' + teamName, config[colorName]);
                color = findFallbackColor(colorName);
            }
            else {
                color = config[colorName];
            }
        }
    });
    // no team was found
    if (!color) {
        color = findFallbackColor(colorName);
    }
    return color;
};
let transformBarChartData = function (data) {
    data.forEach(function (chart, index, array) {
        var totalValues = 0;
        //get totals
        chart.data.forEach(function (v) {
            totalValues += v.value;
        });
        if (totalValues === 0) {
            //don't show if no stats have been registered, commenting out for now so at least something shows (50/50 split)
            chart.hidden = true;
            chart.data.forEach(function (v) {
                v.width = Math.round(50);
                v.fillColor = findTeamColor(v.team, 'primary');
            });
        }
        else {
            var oneTeamHasZeroValue = false;
            //loop over each team
            chart.data.forEach(function (v) {
                v.width = Math.round((v.value / totalValues) * 100);
                v.fillColor = findTeamColor(v.team, 'primary');
            });
        }
    });
};
//tranform barcharts to include data necessary to render bar charts
//transformBarChartData();
//the next to objects are created to populate the legend.
let primaryTeam, otherTeam;
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsTeam');
}
;
$: data = (sourceStore) ? $sourceStore : {};
$: stats = (_b = (_a = data === null || data === void 0 ? void 0 : data.TeamStats) === null || _a === void 0 ? void 0 : _a.Stats) === null || _b === void 0 ? void 0 : _b.Stat;
$: if (Array.isArray(stats)) {
    stats.filter(stat => { var _a; return ((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Type) === 'StatHeader'; }).forEach(stat => {
        let attrs = stat === null || stat === void 0 ? void 0 : stat._attributes;
        primaryTeam = {
            name: attrs.Home,
            color: findTeamColor('team-1' /*data?.TeamStats?.TriCode*/, 'primary')
        };
        otherTeam = {
            name: attrs.Away,
            color: findTeamColor('team-2', 'primary')
        };
    });
    barChartData = [];
    stats.filter(stat => { var _a; return ((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Type) !== 'StatHeader'; }).forEach(stat => {
        barChartData.push({
            title: stat._attributes.Name,
            data: [
                {
                    team: 'team-1',
                    value: parseInt(stat._attributes.HomeValue),
                    label: stat._attributes.Home
                },
                {
                    team: 'team-2',
                    value: parseInt(stat._attributes.AwayValue),
                    label: stat._attributes.Away
                }
            ]
        });
    });
    transformBarChartData(barChartData);
}
else {
    primaryTeam = null;
    otherTeam = null;
    barChartData = [];
    transformBarChartData(barChartData);
}
</script>