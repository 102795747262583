
<CardBase {element}>
  <div class="laliga-wide-video-card type-large-multimedia media-type-v">
    <!-- svelte-ignore a11y-missing-attribute -->
    <a on:click={openPopup}>
      <img class="thumbnail" src={imageUrl} alt="">
      <div class="content">
        <h3 class="title">{title}</h3>
        <span class="category">{cat}</span>
        <span class="publish-date">{publishDateFmt}</span>
      </div>
    </a>
  </div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.laliga-wide-video-card.type-large-multimedia {
  border-radius:8px;
  overflow:hidden;
  width: 100%;
  height: 100%;
  --font-scaling: 0.25;
}

.laliga-wide-video-card.type-large-multimedia a {
  width:100%;
  height: 100%;
  text-decoration:none;
  display:flex;
  flex-wrap:wrap;
  position:relative;
  overflow:hidden;
}

.laliga-wide-video-card.type-large-multimedia a .thumbnail {
  width:100%;
  height:intrinsic;
  height:auto;
  object-fit: contain;
}

.laliga-wide-video-card.type-large-multimedia a .content {
  width:100%;
  padding:5%;
  background:linear-gradient(transparent,rgba(0,0,0,0.6));
  position:absolute;
  left:0;
  bottom:0;
  z-index:2;
}

.laliga-wide-video-card.type-large-multimedia a .content .title {
  margin:0 0 16px 0;
  color:#FFF;
  font-weight:700;
  line-height:1.3;
}

.laliga-wide-video-card.type-large-multimedia.media-type-v a:before {
  width:32px;
  height:32px;
  margin:0;
  padding:8px;
  background-color:#FFF;
  background-image:url('./images/media-icon-video-blue.svg');
  background-repeat:no-repeat;
  background-size:70%;
  background-position:center;
  position:absolute;
  top:7.5%;
  left:5%;
  z-index:2;
  content:"";
  display:inline-block;
}

.laliga-wide-video-card.type-large-multimedia a .content .publish-date {
  color:#FFF;
  font-size: 1vw;
  font-weight:normal;
  letter-spacing:1px;
  opacity:0.7;
}

.laliga-wide-video-card.type-large-multimedia a .content .category {
  margin:0 16px 0 0;
  color:#FFF;
  font-size: 1vw;
  font-weight:normal;
  letter-spacing:1px;
  text-transform:uppercase;
  opacity:0.7;
}

@media screen and (min-width:1200px){
  .card.type-large-multimedia a .content .title {
    font-size: calc(2.5vw * var(--font-scaling));
  }
}

@media screen and (min-width:501px) and (max-width:1199px){
  .laliga-wide-video-card.type-large-multimedia a .content .title {
    font-size: calc(3.5vw * var(--font-scaling));
  }

  .laliga-wide-video-card.type-large-multimedia a .content .publish-date, .laliga-wide-video-card.type-large-multimedia a .content .category {
    font-size: calc(1.8vw * var(--font-scaling));
  }
}

@media screen and (max-width:500px){
  .laliga-wide-video-card.type-large-multimedia a .content .publish-date, .laliga-wide-video-card.type-large-multimedia a .content .category {
    font-size:3vw;
  }

  .laliga-wide-video-card.type-large-multimedia.media-type-v a:before {
    width:36px;
    height:36px;
  }
}</style>

<script type="ts">var _a, _b, _c, _d, _e, _f, _g, _h;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { Link } from 'framework7-svelte';
import { findSourceStore, getHrefForMediaItem } from "../../../../../components/cards/utilities";
import { tdate } from "../../../../../js/i18n";
import { f7 } from 'framework7-svelte';
import { onMount } from "svelte";
import { windowMetrics as wm } from '../../../../../js/stores.js';
export let element;
export let sources;
export let sourceStore;
let pb;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerMediaSource');
}
;
$: data = (sourceStore) ? $sourceStore : {};
$: type = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Type;
$: id = (_b = data === null || data === void 0 ? void 0 : data.Id) === null || _b === void 0 ? void 0 : _b._text;
$: imageUrl = (_c = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _c === void 0 ? void 0 : _c._text;
$: title = ((_d = data === null || data === void 0 ? void 0 : data.Title) === null || _d === void 0 ? void 0 : _d._text) || '';
$: description = ((_e = data === null || data === void 0 ? void 0 : data.Description) === null || _e === void 0 ? void 0 : _e._text) || '';
$: timestamp = (_f = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _f === void 0 ? void 0 : _f._text;
$: publishDate = (timestamp) ? new Date(timestamp) : null;
$: publishDateFmt = $tdate(publishDate, { month: 'short', day: 'numeric', year: 'numeric' });
$: cat = (_g = data === null || data === void 0 ? void 0 : data.PrimaryCategoryName) === null || _g === void 0 ? void 0 : _g._text;
$: pbType = ($wm.width >= 768) ? 'popup' : 'page';
$: mediaUrl = (_h = data === null || data === void 0 ? void 0 : data.MediaUrl) === null || _h === void 0 ? void 0 : _h._text;
$: isYouTube = mediaUrl && mediaUrl.includes('youtube.com');
$: ytVideoId = (isYouTube) ? new URL(mediaUrl).searchParams.get('v') : '';
$: ycEmbedUrl = (isYouTube) ? `https://www.youtube.com/embed/${ytVideoId}?feature=oembed` : '';
$: isNative = mediaUrl && !isYouTube;
function openPopup() {
    if (!pb) {
        // TODO: There is a MUCH better way to do this by using a F7 popup directly, and then you can use Svelte. This sux.
        let videoEmbedHtml = "";
        if (isYouTube) {
            videoEmbedHtml = `<div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%;">
          <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" title="${title}" src="${ycEmbedUrl}" frameborder="0" allowfullscreen autoplay></iframe>
        </div>`;
        }
        else if (isNative) {
            videoEmbedHtml = `<video src="${mediaUrl}" controls>
          <track kind="captions">
        </video>`;
        }
        pb = f7.photoBrowser.create({
            type: 'popup',
            photos: [
                {
                    html: `<div style="width: 100%; display: flex; align-items: center; justify-content: center;">
              <div style="width: 90%; color: white">
                ${videoEmbedHtml}
                <div style="padding-top: 22px; color: #bdbdbd; width: 100%; display: inline-flex; flex-direction: row; align-items: center;">
                  <div style="margin-right: 8px; font-face: 'Core Sans C 45'; font-size: 12px;">${publishDateFmt}</div>
                  <div style="margin-right: 8px; font-face: 'Core Sans C 75'; font-size: 12px;">${cat}</div>
                  <div style="margin-left: auto;">
                    <img style="margin-right: 8px;" src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/ic-facebook.svg" alt="Facebook">
                    <img style="" src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/ic-twitter.svg" alt="Twitter">
                  </div>
                </div>
                <div style="padding-top: 10px; color: #f2f2f2; font-face: 'Core Sans C 75'; font-size: 32px;">${title}</div>
                <div style="padding-top: 10px; color: #f2f2f2; font-face: 'Core Sans C 45'; font-size: 16px;">${description}</div>
              </div>
            </div>`
                }
            ],
            theme: 'dark',
            exposition: true,
            expositionHideCaptions: false,
            navbar: true,
            toolbar: true,
            swipeToClose: true,
            routableModals: false,
            virtualSlides: true
        });
    }
    pb.open();
    console.log(pb.el);
}
onMount(() => {
    return () => {
        if (pb) {
            f7.photoBrowser.destroy(pb);
        }
    };
});
</script>