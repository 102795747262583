<script lang="ts">import { onMount } from "svelte";
import { Readable } from "svelte/store";
import Select, { Option } from "@smui/select";
import h337 from "heatmap.js";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore } from "../../../utilities";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
}
;
let currentMatch;
let heatmapRef;
let matches = [
    "Deportivo Alavés - CA Osasuna · J25",
    "Eibar - CA Osasuna · J26",
    "Eibar - CA Osasuna · J27",
];
onMount(() => {
    const heatmapInstance = h337.create({
        container: heatmapRef,
        radius: 10,
        maxOpacity: 1,
        minOpacity: 0,
        gradient: {
            '.6': '#0067AC',
            '.8': '#00E663',
        }
    });
    var points = [];
    var max = 0;
    var width = 240;
    var height = 400;
    var len = 30;
    while (len--) {
        var val = Math.floor(Math.random() * 100);
        var radius = Math.floor(Math.random() * 70);
        max = Math.max(max, val);
        var point = {
            x: Math.floor(Math.random() * width),
            y: Math.floor(Math.random() * height),
            value: val,
            radius: radius
        };
        points.push(point);
    }
    var data = {
        max: max,
        data: points
    };
    heatmapInstance.setData(data);
});
</script>

<CardBase {element} {sources}>
  <div
    class="soccer-playerdetail-postionheatmap"
    style="
      width: {element.data.width || 'auto'};
      height: {element
      .data.height || 'auto'};
    "
  >
    <h2 class="soccer-playerdetail-postionheatmap-header">Posición de juego</h2>
    <Select
      bind:currentMatch
      label="Partido"
      anchor$class="select-match"
      menu$class="select-match"
    >
      {#each matches as match}
        <Option value={match}>{match}</Option>
      {/each}
    </Select>
    <div bind:this={heatmapRef} class="match-heatmap" />
  </div>
</CardBase>

<style type="text/scss">*,
*::before,
*::after {
  box-sizing: border-box;
}

.soccer-playerdetail-postionheatmap {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 22px 22px 26.4px 22px;
  border-radius: 4px;
  background-color: var(--neutrals-white-100);
}

.soccer-playerdetail-postionheatmap .soccer-playerdetail-postionheatmap-header {
  width: 100%;
  margin: 0;
  font-family: CoreSansC-75;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-grey-darker-60);
}

.soccer-playerdetail-postionheatmap .match-heatmap {
  width: 296px;
  height: 501.6px;
  margin-top: 15px;
  background-image: url("./images/playground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

* :global(.mdc-select) {
  width: 100%;
}

* :global(.select-match) {
  width: 100%;
}

* :global(.select-match .mdc-select__selected-text) {
  font-family: CoreSansC-45;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: left;
  color: var(--neutrals-black-black-10);
}

* :global(.select-match .mdc-deprecated-list-item) {
  font-family: CoreSansC-45;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: left;
  color: var(--neutrals-black-black-10);
}

* :global(.select-match .mdc-select__selected-text) {
  font-family: CoreSansC-45;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: left;
  color: var(--neutrals-black-black-10);
}

* :global(.select-match label) {
  font-family: CoreSansC-75;
  font-size: 10px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  text-transform: uppercase;
  color: var(--neutrals-grey-lighter-40);
}</style>
