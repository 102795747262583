import { Readable, Writable, writable, derived } from "svelte/store";
import { ReactiveMicrocomponentConfig } from "./ReactiveMicrocomponentConfig";
import { ReactiveMicrocomponent } from "./ReactiveMicrocomponent";
import { ControlBase } from "./ControlBase";
import { StateBase } from "./StateBase";
import { ReactiveMicrocomponentState } from "./ReactiveMicrocomponentState";
import { ReactiveMicrocomponentLog } from "./ReactiveMicrocomponentLog";

const CONFIG_DEFAULTS: ReactiveMicrocomponentConfig<any, any> = {
  name: 'DEFAULT',
  initialOutputValue: undefined,
  initialControlValue: undefined,
  saveToLocalStorage: true,
  localStoragePrefix: 'ReactiveMicrocomponent',
  log: {
    trace: (msg: string) => {},
    debug: (msg: string) => {},
    info: (msg: string) => {},
    /*
    trace: (msg: string) => console.log(msg),
    debug: (msg: string) => console.log(msg),
    info: (msg: string) => console.log(msg),
    */
    warn: (msg: string) => console.log(msg),
    error: (msg: string) => console.log(msg)
  }
}

// NOTE: variadic type lists are a TypeScript 4.0 feature
export type InputComponents<I extends unknown[] = unknown[]> = { [K in keyof I]: ReactiveMicrocomponent<I[K]> };
export type InputStores = [Readable<unknown>, ...Readable<unknown>[]];
export type OutputSetter<O> = (value: O) => void;
export type Inputs<I extends unknown[] = unknown[], C extends ControlBase = unknown> = [C, ...I];

export abstract class AbstractReactiveMicrocomponent<O, I extends unknown[] = unknown[], C extends ControlBase = ControlBase, S extends StateBase = StateBase> implements ReactiveMicrocomponent<O> {
  public readonly store: Readable<O>;

  protected readonly log: ReactiveMicrocomponentLog;

  private readonly config: ReactiveMicrocomponentConfig<O, C>;
  private readonly control: Writable<C>;
  private updateInProgress: boolean;
  private pendingInputs: Inputs<I, C>;
  //private stopped: boolean;
  private cachedState: ReactiveMicrocomponentState<O, C, S>;

  public constructor(config: ReactiveMicrocomponentConfig<O, C>, ...inputs: InputComponents<I>) {
    this.config = {...CONFIG_DEFAULTS, ...config};
    this.log = this.config.log;

    // Load any previous checkpoint data
    this.cachedState = this.loadCheckpointInternal();

    // We use the stop on the control to detect unsubscriptions on the component, because the stop on the derived store is also called
    // when the value is about to change (in addition to when all subscribers unsubscribe). This is different from the contract
    // of stop on writable, which is only called once the last subscriber unsubscribes.
    this.control = writable(this.cachedState.controlValue, () => this.stopInternal.bind(this));
    let inputStores: InputStores = [this.control, ...inputs.map(c => c.store)];
    // TODO: Why doesn't this work?
    //let inputStores: [Readable<C>, ...{ [K in keyof I]: Readable<I[K]> }] = [this.control, ...inputs.map(c => c.store)];
    this.store = derived(inputStores, this.updateInternal.bind(this), this.cachedState.outputValue);

    this.updateInProgress = false;
    this.pendingInputs = null;
    //this.stopped = false;

    // Save a fresh checkpoint (includes any initial values)
    this.saveCheckpointInternal();
  }

  /*** PROTECTED METHODS THAT YOU CAN OVERRIDE BELOW HERE ***/

  /**
   * NOTE: This is the only method that you MUST implement when subclassing this class to write a new component.
   * 
   * This method is called whenever inputs change and there are no pending updates (with unresolved promises). It can be implemented 
   * as either an async method that returns the output type (recommended), or as a standard method that returns a promise
   * (useful for things like timers).
   * 
   * Up to one input change is buffered if an input change occurs during a long-running update process. In this case,
   * once the update completes, this method will be called again with the buffered input. Subsequent input changes that arrive
   * while an input change is buffered result in those input changes overwriting the buffer (so that update will be called
   * with the most recent input change once the pending update completes).
   * 
   * If the promise is fulfilled:
   * - The output value is set to the computed value.
   * - If checkpoints are enabled, the component saves a new checkpoint.
   * - If buffered inputs are present, this method is called again with the buffered inputs, and buffered inputs are cleared.
   * 
   * If the promise is rejected:
   * - The output value is not set (remains at its current value).
   * - If the promise is rejected with an Error, the Error is logged.
   * - If checkpoints are enabled, the component saves a new checkpoint.
   * - If buffered inputs are present, this method is called again with the buffered inputs, and buffered inputs are cleared.
   * 
   * @param $control The current control value.
   * @param $values The current input component values (one for each input component).
   */
  protected abstract update($control: C, ...$values: I): Promise<O>;

  /**
   * This method is called just before an update will be attempted. It is called every time the input changes, and isn't async.
   * You should not do expensive computation here. This is to give the component a chance to cancel any long-running operations
   * from a previous update in preparation to receive new inputs (for example, to cancel a pending update promise).
   * The behavior here is entirely up to the component. By default, no action is taken, which means any pending update promise
   * will continue and the new input change will be buffered (any previous buffered input changes will be discarded).
   */
  protected inputChanged(): void {
    // by default, do nothing
  }

  /**
   * This method is called whenever the microcomponent runtime detects that there are no more subscribers for changes. This component should
   * cancel any pending operations, release any acquired resources, and prepare to stop.
   */
  protected stop(): void {
    // by default, do nothing
  }


  /*** PROTECTED METHODS THAT YOU CAN CALL (BUT SHOULDN'T OVERRIDE) BELOW HERE ***/

  /**
   * Updates the internal control value, which is an input to the component, so update() should be called shortly after.
   * This is useful for components that change their outputs based on external events (like timers and click handlers),
   * and need to convert those events into reactive state changes.
   * 
   * @param value The new control value.
   */
  protected setControl(value: C): void {
    this.log.debug(`AbstractReactiveMicrocomponent ${this.config.name}: setting control value to ${JSON.stringify(value)}`);
    /*
    if (this.stopped) {
      this.log.warn("setControl called while stopped, ignoring (possible bug)");
      return;
    }
    */
    this.control.set(value);
  }

  /**
   * Saves a new checkpoint with the latest control/output values and new internal state. Call this when you've updated
   * internal state that you want to save, but are not ready to emit a new output value. This will result in a call to
   * packInternalState to get the latest internal state before saving.
   */
  protected saveCheckpoint(): void {
    this.saveCheckpointInternal();
  }

  /**
   * Gets an object that subclasses can use to store internal component state. Anything set on this object
   * will be automatically saved along with checkpoints, and restored when the component is reloaded.
   */
  protected get state(): Partial<S> {
    return this.cachedState.internalValue;
  }


  /*** PRIVATE METHODS BELOW HERE ***/

  private getCheckpointStorageKey(): string {
    return `${this.config.localStoragePrefix}__${this.config.name}__STATE`;
  }

  private loadCheckpointInternal(): ReactiveMicrocomponentState<O, C, S> {
    let ret: ReactiveMicrocomponentState<O, C, S>  = {
      outputValue: this.config.initialOutputValue,
      controlValue: this.config.initialControlValue,
      internalValue: {},
    };
    if (this.config.saveToLocalStorage && window.localStorage) {
      let lsJson: string = window.localStorage.getItem(this.getCheckpointStorageKey());
      if (lsJson) {
        ret = JSON.parse(lsJson);
      } else {
        this.log.debug(`loadCheckpointData ${this.config.name}: couldn't find a previous checkpoint in local storage, returning initial values`);
      }
    }
    return ret;
  }

  private saveCheckpointInternal(): void {
    if (!this.config.saveToLocalStorage) {
      return;
    }
    if (!window.localStorage) {
      this.log.warn(`saveCheckpointData ${this.config.name}: saveToLocalStorage set but window.localStorage isn't available`);
      return;
    }
    window.localStorage.setItem(this.getCheckpointStorageKey(), JSON.stringify(this.cachedState));
  }

  public toString(): string {
    return `${this.constructor.name}[${this.config.name}]`;
  }

  private updateInternal(inputs: Inputs<I, C>, set: OutputSetter<O>): void {
    this.log.debug(`AbstractReactiveMicrocomponent ${this.config.name}: updateInternal ${JSON.stringify(inputs)}`);
    /*
    if (this.stopped) {
      this.log.error("updateInternal called while stopped! This shouldn't happen.");
      return;
    }
    */
    this.inputChanged();
    if (this.updateInProgress) {
      // buffer the input change
      // note that this buffers at most one input (the most recent one)
      this.pendingInputs = inputs;
    } else {
      // update now
      this.doUpdate(inputs, set);
    }
  }

  private doUpdate([$control, ...$inputs]: Inputs<I, C>, set: OutputSetter<O>): void {
    /*
    if (this.stopped) {
      this.log.warn("doUpdate called while stopped, ignoring (possible bug)");
      return;
    }
    */
    this.update($control, ...$inputs)
      .then((value: O) => {
        this.log.debug(`doUpdate(resolve) ${this.config.name}: got update value ${value}`);
        //debugger;
        set(value);
        // the checkpoint value we save includes the control value that resulted in this update
        // the control value may have since changed and have been buffered
        this.cachedState.controlValue = $control;
        this.cachedState.outputValue = value;
        this.saveCheckpointInternal();
      })
      .catch((reason: any) => {
        if (reason && reason instanceof Error) {
          this.log.error(reason.stack);
        }
        this.cachedState.controlValue = $control;
        this.saveCheckpointInternal();
      })
      .finally(() => {
        this.updateInProgress = false;
        if (this.pendingInputs) {
          this.doUpdate(this.pendingInputs, set);
          this.pendingInputs = null;
        }
      });
  }

  private stopInternal(): void {
    this.log.trace(`AbstractReactiveMicrocomponent ${this.config.name}: stopInternal`);
    //this.stopped = true;
    this.stop();
  }

}