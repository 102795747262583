import { Token } from "inversify-token";
import { AppConfig } from "./AppConfig";

// TODO: Think about allowing other modules to register config sections and types here somehow?
// FIXME: When the json file changes during development, a hot reload doesn't update the config data. Killing and recompiling works though.....
// Uses node-config engine, more info here: https://github.com/lorenwest/node-config/wiki/Configuration-Files

export const ConfigToken = new Token<AppConfig>(Symbol.for("Config"));

export class Config {
  static getAppConfig() {
    return CONFIG as AppConfig;
  }
}
