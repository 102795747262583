<CardBase {element}>
<div class="pitch {orientation}" class:display-none={hasFormations === false}>
	<div class="team team-home formation-{homeFormationName?homeFormationName:''}" style="background-image:url(https://resources-us.yinzcam.com/csf/shared/logos/CSF_{homeTriCode}.png)">
		{#if Array.isArray(homeFormation) && homeFormation.length >= 11 }
		<div class="keeper">
			<div class="player" class:yellow-card="{homeFormation[0]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[0]._attributes.RedCards !== '0'}" class:goal="{homeFormation[0]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[0]._attributes.Name}">
				<span class="player-number">{homeFormation[0]._attributes.Number}</span>
				<span class="player-name">{homeFormation[0]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{homeFormation[1]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[1]._attributes.RedCards !== '0'}" class:goal="{homeFormation[1]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[1]._attributes.Name}">
				<span class="player-number">{homeFormation[1]._attributes.Number}</span>
				<span class="player-name">{homeFormation[1]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{homeFormation[2]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[2]._attributes.RedCards !== '0'}" class:goal="{homeFormation[2]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[2]._attributes.Name}">
				<span class="player-number">{homeFormation[2]._attributes.Number}</span>
				<span class="player-name">{homeFormation[2]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{homeFormation[3]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[3]._attributes.RedCards !== '0'}" class:goal="{homeFormation[3]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[3]._attributes.Name}">
				<span class="player-number">{homeFormation[3]._attributes.Number}</span>
				<span class="player-name">{homeFormation[3]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{homeFormation[4]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[4]._attributes.RedCards !== '0'}" class:goal="{homeFormation[4]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[4]._attributes.Name}">
				<span class="player-number">{homeFormation[4]._attributes.Number}</span>
				<span class="player-name">{homeFormation[4]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{homeFormation[5]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[5]._attributes.RedCards !== '0'}" class:goal="{homeFormation[5]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[5]._attributes.Name}">
				<span class="player-number">{homeFormation[5]._attributes.Number}</span>
				<span class="player-name">{homeFormation[5]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{homeFormation[6]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[6]._attributes.RedCards !== '0'}" class:goal="{homeFormation[6]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[6]._attributes.Name}">
				<span class="player-number">{homeFormation[6]._attributes.Number}</span>
				<span class="player-name">{homeFormation[6]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{homeFormation[7]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[7]._attributes.RedCards !== '0'}" class:goal="{homeFormation[7]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[7]._attributes.Name}">
				<span class="player-number">{homeFormation[7]._attributes.Number}</span>
				<span class="player-name">{homeFormation[7]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{homeFormation[8]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[8]._attributes.RedCards !== '0'}" class:goal="{homeFormation[8]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[8]._attributes.Name}">
				<span class="player-number">{homeFormation[8]._attributes.Number}</span>
				<span class="player-name">{homeFormation[8]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{homeFormation[9]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[9]._attributes.RedCards !== '0'}" class:goal="{homeFormation[9]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[9]._attributes.Name}">
				<span class="player-number">{homeFormation[9]._attributes.Number}</span>
				<span class="player-name">{homeFormation[9]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{homeFormation[10]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[10]._attributes.RedCards !== '0'}" class:goal="{homeFormation[10]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{homeFormation[10]._attributes.Name}">
				<span class="player-number">{homeFormation[10]._attributes.Number}</span>
				<span class="player-name">{homeFormation[10]._attributes.Name}</span>
			</div>
		</div>
		{/if}
	</div>
	<div class="team team-away formation-{awayFormationName?awayFormationName:''}" style="background-image:url(https://resources-us.yinzcam.com/csf/shared/logos/CSF_{awayTriCode}.png)">
		{#if Array.isArray(awayFormation) && awayFormation.length >= 11 }
		<div class="keeper">
			<div class="player" class:yellow-card="{awayFormation[0]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[0]._attributes.RedCards !== '0'}" class:goal="{awayFormation[0]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[0]._attributes.Name}">
				<span class="player-number">{awayFormation[0]._attributes.Number}</span>
				<span class="player-name">{awayFormation[0]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{awayFormation[1]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[1]._attributes.RedCards !== '0'}" class:goal="{awayFormation[1]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[1]._attributes.Name}">
				<span class="player-number">{awayFormation[1]._attributes.Number}</span>
				<span class="player-name">{awayFormation[1]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{awayFormation[2]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[2]._attributes.RedCards !== '0'}" class:goal="{awayFormation[2]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[2]._attributes.Name}">
				<span class="player-number">{awayFormation[2]._attributes.Number}</span>
				<span class="player-name">{awayFormation[2]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{awayFormation[3]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[3]._attributes.RedCards !== '0'}" class:goal="{awayFormation[3]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[3]._attributes.Name}">
				<span class="player-number">{awayFormation[3]._attributes.Number}</span>
				<span class="player-name">{awayFormation[3]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{awayFormation[4]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[4]._attributes.RedCards !== '0'}" class:goal="{awayFormation[4]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[4]._attributes.Name}">
				<span class="player-number">{awayFormation[4]._attributes.Number}</span>
				<span class="player-name">{awayFormation[4]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{awayFormation[5]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[5]._attributes.RedCards !== '0'}" class:goal="{awayFormation[5]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[5]._attributes.Name}">
				<span class="player-number">{awayFormation[5]._attributes.Number}</span>
				<span class="player-name">{awayFormation[5]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{awayFormation[6]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[6]._attributes.RedCards !== '0'}" class:goal="{awayFormation[6]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[6]._attributes.Name}">
				<span class="player-number">{awayFormation[6]._attributes.Number}</span>
				<span class="player-name">{awayFormation[6]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{awayFormation[7]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[7]._attributes.RedCards !== '0'}" class:goal="{awayFormation[7]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[7]._attributes.Name}">
				<span class="player-number">{awayFormation[7]._attributes.Number}</span>
				<span class="player-name">{awayFormation[7]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{awayFormation[8]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[8]._attributes.RedCards !== '0'}" class:goal="{awayFormation[8]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[8]._attributes.Name}">
				<span class="player-number">{awayFormation[8]._attributes.Number}</span>
				<span class="player-name">{awayFormation[8]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{awayFormation[9]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[9]._attributes.RedCards !== '0'}" class:goal="{awayFormation[9]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[9]._attributes.Name}">
				<span class="player-number">{awayFormation[9]._attributes.Number}</span>
				<span class="player-name">{awayFormation[9]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{awayFormation[10]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[10]._attributes.RedCards !== '0'}" class:goal="{awayFormation[10]._attributes.Goals !== '0'}">
				<img src={playerThumbnail} alt="{awayFormation[10]._attributes.Name}">
				<span class="player-number">{awayFormation[10]._attributes.Number}</span>
				<span class="player-name">{awayFormation[10]._attributes.Name}</span>
			</div>
		</div>
		{/if}
	</div>
</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.pitch {
  background-repeat:no-repeat, repeat;
  background-size:96%, 50px;
  background-position:center, center;
  font-family:var(--f7-font-family);
  position:relative;
}

.pitch.vertical {
  width:100%;
  padding:175% 0 0 0;
  background-image:url(./images/data-viz_field-lines-vertical.svg), url(./images/data-viz_grass-texture.jpg);
  overflow:hidden;
}

.pitch .team {
  display:flex;
  flex-wrap:wrap;
  position:absolute;
  z-index:2;
}

.pitch .team {
  background-size:0;
  background-repeat:no-repeat;
}

.pitch .team > div {
  display:flex;
  justify-content:center;
}

.pitch .team .keeper {
}

.pitch .team .defender {
}

.pitch .team .midfielder {
}

.pitch .team .attacker {
}

.pitch .team .player {
  padding:2px;
  text-align:center;
}

.pitch .team .player .player-number {
  position:relative;
}

.pitch .team .player .player-number:before {
  position:absolute;
  top:3%;
  right:3%;
}

.pitch .team .player .player-number:after {
  position:absolute;
  bottom:0;
  right:0;
}

.pitch .team .player.goal .player-number:after {
  content:"\26BD";
}

.pitch .team .player.yellow-card .player-number:before {
  width:6px;
  height:6px;
  background-color:#ffbc00;
  content:"";
}

.pitch .team .player.red-card .player-number:before {
  width:6px;
  height:6px;
  background-color:#e50000;
  content:"";
}

.pitch .team .player img {
  width:50%;
  max-width:64px;
  height:intrinsic;
  height:auto;
  margin:3px 0;
  border-radius:50%;
}

.pitch .team .player span {
  padding:2px;
  background-color:rgba(0,0,0,0.5);
  border-radius:6px;
  display:block;
  color:#FFF;
  font-family:500;
  font-size:10px;
}

.pitch .team .player .player-name {
}

.pitch .team .player .player-number {
}

.pitch .team:nth-child(1) .player-number {
  background:#000;
  color:#FFF;
}

.pitch .team:nth-child(2) .player-number {
  background:#FFF;
  color:#000;
}

.pitch.vertical .team {
  width:100%;
  height:50%;
  justify-content:center;
  align-items:center;
}

.pitch.vertical .team > div {
  height:20%;
}

.pitch.vertical .team .player img {
  /*width:auto;
		height:100%;*/
}

.pitch.vertical .team:nth-child(1) {
  top:0;
}

.pitch.vertical .team:nth-child(2) {
  bottom:0;
}

.pitch .team:nth-child(2) > div:nth-child(11) {
  order:1;
}

.pitch .team:nth-child(2) > div:nth-child(10) {
  order:2;
}

.pitch .team:nth-child(2) > div:nth-child(9) {
  order:3;
}

.pitch .team:nth-child(2) > div:nth-child(8) {
  order:4;
}

.pitch .team:nth-child(2) > div:nth-child(7) {
  order:5;
}

.pitch .team:nth-child(2) > div:nth-child(6) {
  order:6;
}

.pitch .team:nth-child(2) > div:nth-child(5) {
  order:7;
}

.pitch .team:nth-child(2) > div:nth-child(4) {
  order:8;
}

.pitch .team:nth-child(2) > div:nth-child(3) {
  order:9;
}

.pitch .team:nth-child(2) > div:nth-child(2) {
  order:10;
}

.pitch .team:nth-child(2) > div:nth-child(1) {
  order:11;
}

.pitch.vertical .team .keeper {
  width:100%;
}

.pitch.vertical .team .defender {
  width:25%;
}

.pitch.vertical .team .midfielder, .pitch.vertical .team .attacker {
  width:33.3%;
}

.pitch.vertical .team .player img {
  display:none;
}

.pitch.vertical .team .player-number {
  width:32px;
  height:32px;
  margin:2px auto;
  padding:8px 2px;
  border-radius:16px;
  font-size:16px;
  font-weight:bold;
  line-height:1;
  text-align:center;
  order:1;
}

.pitch.vertical .team .player-name {
  order:2;
}

.pitch.vertical .team .player .player-number:before {
  font-size:6px;
}

.pitch.vertical .team .player .player-number:after {
  font-size:8px;
}

/*vertical 3-2-4-1*/

.pitch.vertical .team.formation-3-2-4-1 .defender:nth-child(2), .pitch.vertical .team.formation-3-2-4-1 .defender:nth-child(3), .pitch.vertical .team.formation-3-2-4-1 .defender:nth-child(4) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-2-4-1 .defender:nth-child(5), .pitch.vertical .team.formation-3-2-4-1 .midfielder:nth-child(6) {
  width:50% !important;
}

.pitch.vertical .team.formation-3-2-4-1 .midfielder:nth-child(7), .pitch.vertical .team.formation-3-2-4-1 .midfielder:nth-child(8), .pitch.vertical .team.formation-3-2-4-1 .attacker:nth-child(9), .pitch.vertical .team.formation-3-2-4-1 .attacker:nth-child(10) {
  width:25%;
}

.pitch.vertical .team.formation-3-2-4-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 3-4-1-2*/

.pitch.vertical .team.formation-3-4-1-2 .defender:nth-child(2), .pitch.vertical .team.formation-3-4-1-2 .defender:nth-child(3), .pitch.vertical .team.formation-3-4-1-2 .defender:nth-child(4) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-4-1-2 .defender:nth-child(5), .pitch.vertical .team.formation-3-4-1-2 .midfielder {
  width:25% !important;
}

.pitch.vertical .team.formation-3-4-1-2 .attacker:nth-child(9) {
  width:100% !important;
}

.pitch.vertical .team.formation-3-4-1-2 .attacker:nth-child(10), .pitch.vertical .team.formation-3-4-1-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 3-4-2-1*/

.pitch.vertical .team.formation-3-4-2-1 .defender:nth-child(2), .pitch.vertical .team.formation-3-4-2-1 .defender:nth-child(3), .pitch.vertical .team.formation-3-4-2-1 .defender:nth-child(4) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-4-2-1 .defender:nth-child(5), .pitch.vertical .team.formation-3-4-2-1 .midfielder {
  width:25% !important;
}

.pitch.vertical .team.formation-3-4-2-1 .attacker:nth-child(9), .pitch.vertical .team.formation-3-4-2-1 .attacker:nth-child(10) {
  width:50% !important;
}

.pitch.vertical .team.formation-3-4-2-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 4-1-2-1-2*/

.pitch.vertical .team.formation-4-1-2-1-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-1-2-1-2 .midfielder:nth-child(6) {
  width:100% !important;
}

.pitch.vertical .team.formation-4-1-2-1-2 .midfielder:nth-child(7), .pitch.vertical .team.formation-4-1-2-1-2 .midfielder:nth-child(8) {
  width:50% !important;
}

.pitch.vertical .team.formation-4-1-2-1-2 .attacker:nth-child(9) {
  width:100% !important;
}

.pitch.vertical .team.formation-4-1-2-1-2 .attacker:nth-child(10), .pitch.vertical .team.formation-4-1-2-1-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 4-1-3-2*/

.pitch.vertical .team.formation-4-1-3-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-1-3-2 .midfielder:nth-child(6) {
  width:100% !important;
}

.pitch.vertical .team.formation-4-1-3-2 .midfielder:nth-child(7), .pitch.vertical .team.formation-4-1-3-2 .midfielder:nth-child(8), .pitch.vertical .team.formation-4-1-3-2 .attacker:nth-child(9) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-4-1-3-2 .attacker:nth-child(10), .pitch.vertical .team.formation-4-1-3-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 4-1-4-1*/

.pitch.vertical .team.formation-4-1-4-1 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-1-4-1 .midfielder:nth-child(6) {
  width:100% !important;
}

.pitch.vertical .team.formation-4-1-4-1 .midfielder:nth-child(7), .pitch.vertical .team.formation-4-1-4-1 .midfielder:nth-child(8), .pitch.vertical .team.formation-4-1-4-1 .attacker:nth-child(9), .pitch.vertical .team.formation-4-1-4-1 .attacker:nth-child(10) {
  width:25% !important;
}

.pitch.vertical .team.formation-4-1-4-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 4-2-2-2*/

.pitch.vertical .team.formation-4-2-2-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-2-2-2 .midfielder, .pitch.vertical .team.formation-4-2-2-2 .attacker {
  width:50% !important;
}

/*vertical 4-3-1-2*/

.pitch.vertical .team.formation-4-3-1-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-3-1-2 .midfielder {
  width:33.3% !important;
}

.pitch.vertical .team.formation-4-3-1-2 .attacker:nth-child(9) {
  width:100%;
}

.pitch.vertical .team.formation-4-3-1-2 .attacker:nth-child(10), .pitch.vertical .team.formation-4-3-1-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 4-3-3*/

.pitch.vertical .team.formation-4-3-3 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-3-3 .midfielder, .pitch.vertical .team.formation-4-3-3 .attacker {
  width:33.3% !important;
}

/*vertical 4-4-1-1*/

.pitch.vertical .team.formation-4-4-1-1 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-4-1-1 .midfielder, .pitch.vertical .team.formation-4-4-1-1 .attacker:nth-child(9) {
  width:25% !important;
}

.pitch.vertical .team.formation-4-4-1-1 .attacker:nth-child(10), .pitch.vertical .team.formation-4-4-1-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 4-4-2*/

.pitch.vertical .team.formation-4-4-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-4-2 .midfielder, .pitch.vertical .team.formation-4-4-2 .attacker:nth-child(9) {
  width:25% !important;
}

.pitch.vertical .team.formation-4-4-2 .attacker:nth-child(10), .pitch.vertical .team.formation-4-4-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 4-5-1*/

.pitch.vertical .team.formation-4-5-1 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-5-1 .midfielder, .pitch.vertical .team.formation-4-5-1 .attacker:nth-child(9), .pitch.vertical .team.formation-4-5-1 .attacker:nth-child(10) {
  width:20% !important;
}

.pitch.vertical .team.formation-4-5-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 5-4-1*/

.pitch.vertical .team.formation-5-4-1 .midfielder:nth-child(6), .pitch.vertical .team.formation-5-4-1 .defender {
  width:20% !important;
}

.pitch.vertical .team.formation-5-4-1 .midfielder, .pitch.vertical .team.formation-5-4-1 .attacker:nth-child(9), .pitch.vertical .team.formation-5-4-1 .attacker:nth-child(10) {
  width:25% !important;
}

.pitch.vertical .team.formation-5-4-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 4-2-3-1*/

.pitch.vertical .team.formation-4-2-3-1 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-2-3-1 .midfielder:nth-child(6), .pitch.vertical .team.formation-4-2-3-1 .midfielder:nth-child(7) {
  width:50% !important;
}

.pitch.vertical .team.formation-4-2-3-1 .midfielder:nth-child(8), .pitch.vertical .team.formation-4-2-3-1 .attacker:nth-child(9), .pitch.vertical .team.formation-4-2-3-1 .attacker:nth-child(10) {
  width:33.3%;
}

.pitch.vertical .team.formation-4-2-3-1 .midfielder, .pitch.vertical .team.formation-4-2-3-1 .attacker:nth-child(9), .pitch.vertical .team.formation-4-2-3-1 .attacker:nth-child(10) {
  width:25% !important;
}

.pitch.vertical .team.formation-4-2-3-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 5-3-2*/

.pitch.vertical .team.formation-5-3-2 .midfielder:nth-child(6), .pitch.vertical .team.formation-5-3-2 .defender {
  width:20% !important;
}

.pitch.vertical .team.formation-5-3-2 .midfielder, .pitch.vertical .team.formation-5-3-2 .attacker:nth-child(9) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-5-3-2 .attacker:nth-child(10), .pitch.vertical .team.formation-5-3-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 3-5-2*/

.pitch.vertical .team.formation-3-5-2 .defender:not(:nth-child(5)) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-5-2 .defender:nth-child(5), .pitch.vertical .team.formation-3-5-2 .midfielder, .pitch.vertical .team.formation-3-5-2 .attacker:nth-child(9) {
  width:20% !important;
}

.pitch.vertical .team.formation-3-5-2 .attacker:nth-child(10), .pitch.vertical .team.formation-3-5-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 3-4-3*/

.pitch.vertical .team.formation-3-4-3 .defender:not(:nth-child(5)) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-4-3 .defender:nth-child(5), .pitch.vertical .team.formation-3-4-3 .midfielder {
  width:20% !important;
}

.pitch.vertical .team.formation-3-4-3 .attacker {
  width:33.3% !important;
}

/*--start horizontal pitch*/

.pitch.horizontal {
  padding:59% 0 0 0;
  background-image:url(./images/data-viz_field-lines-horizontal.svg), url(./images/data-viz_grass-texture.jpg);
}

.pitch.horizontal .team {
  width:50%;
  height:100%;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  top:0;
}

.pitch.horizontal .team:nth-child(1) {
  left:0;
}

.pitch.horizontal .team:nth-child(2) {
  right:0;
}

.pitch.horizontal .team > div {
  width:20%;
  align-items:center;
}

.pitch.horizontal .team .keeper {
  height:100%;
}

.pitch.horizontal .team .defender {
  height:25%;
}

.pitch.horizontal .team .midfielder, .pitch.horizontal .team .attacker {
  height:33.3%;
}

.pitch.horizontal .team .player img {
  display:none;
}

.pitch.horizontal .team .player-name {
  display:none;
}

.pitch.horizontal .team .player-number {
  width:24px;
  height:24px;
  padding:7px 0;
  border-radius:12px;
  font-size:10px;
  font-weight:bold;
  line-height:1;
  text-align:center;
}

.pitch.horizontal .team .player .player-number:before {
  font-size:4px;
}

.pitch.horizontal .team .player .player-number:after {
  font-size:6px;
}

.pitch.horizontal .team .player .player-number:after {
  bottom:0;
  right:0;
}

/*horizontal 3-2-4-1*/

.pitch.horizontal .team.formation-3-2-4-1 .defender:nth-child(2), .pitch.horizontal .team.formation-3-2-4-1 .defender:nth-child(3), .pitch.horizontal .team.formation-3-2-4-1 .defender:nth-child(4) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-2-4-1 .defender:nth-child(5), .pitch.horizontal .team.formation-3-2-4-1 .midfielder:nth-child(6) {
  height:50% !important;
}

.pitch.horizontal .team.formation-3-2-4-1 .midfielder:nth-child(7), .pitch.horizontal .team.formation-3-2-4-1 .midfielder:nth-child(8), .pitch.horizontal .team.formation-3-2-4-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-3-2-4-1 .attacker:nth-child(10) {
  height:25%;
}

.pitch.horizontal .team.formation-3-2-4-1 .attacker:last-child {
  height:100% !important;
}

/*horizontal 3-4-1-2*/

.pitch.horizontal .team.formation-3-4-1-2 .defender:nth-child(2), .pitch.horizontal .team.formation-3-4-1-2 .defender:nth-child(3), .pitch.horizontal .team.formation-3-4-1-2 .defender:nth-child(4) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-4-1-2 .defender:nth-child(5), .pitch.horizontal .team.formation-3-4-1-2 .midfielder {
  height:25% !important;
}

.pitch.horizontal .team.formation-3-4-1-2 .attacker:nth-child(9) {
  height:100% !important;
}

.pitch.horizontal .team.formation-3-4-1-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-3-4-1-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 3-4-2-1*/

.pitch.horizontal .team.formation-3-4-2-1 .defender:nth-child(2), .pitch.horizontal .team.formation-3-4-2-1 .defender:nth-child(3), .pitch.horizontal .team.formation-3-4-2-1 .defender:nth-child(4) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-4-2-1 .defender:nth-child(5), .pitch.horizontal .team.formation-3-4-2-1 .midfielder {
  height:25% !important;
}

.pitch.horizontal .team.formation-3-4-2-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-3-4-2-1 .attacker:nth-child(10) {
  height:50% !important;
}

.pitch.horizontal .team.formation-3-4-2-1 .attacker:last-child {
  height:100% !important;
}

/*horizontal 4-1-2-1-2*/

.pitch.horizontal .team.formation-4-1-2-1-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-1-2-1-2 .midfielder:nth-child(6) {
  height:100% !important;
}

.pitch.horizontal .team.formation-4-1-2-1-2 .midfielder:nth-child(7), .pitch.horizontal .team.formation-4-1-2-1-2 .midfielder:nth-child(8) {
  height:50% !important;
}

.pitch.horizontal .team.formation-4-1-2-1-2 .attacker:nth-child(9) {
  height:100% !important;
}

.pitch.horizontal .team.formation-4-1-2-1-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-1-2-1-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 4-1-3-2*/

.pitch.horizontal .team.formation-4-1-3-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-1-3-2 .midfielder:nth-child(6) {
  height:100% !important;
}

.pitch.horizontal .team.formation-4-1-3-2 .midfielder:nth-child(7), .pitch.horizontal .team.formation-4-1-3-2 .midfielder:nth-child(8), .pitch.horizontal .team.formation-4-1-3-2 .attacker:nth-child(9) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-4-1-3-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-1-3-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 4-1-4-1*/

.pitch.horizontal .team.formation-4-1-4-1 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-1-4-1 .midfielder:nth-child(6) {
  height:100% !important;
}

.pitch.horizontal .team.formation-4-1-4-1 .midfielder:nth-child(7), .pitch.horizontal .team.formation-4-1-4-1 .midfielder:nth-child(8), .pitch.horizontal .team.formation-4-1-4-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-4-1-4-1 .attacker:nth-child(10) {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-1-4-1 .attacker:last-child {
  height:100% !important;
}

/*horizontal 4-2-2-2*/

.pitch.horizontal .team.formation-4-2-2-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-2-2-2 .midfielder, .pitch.horizontal .team.formation-4-2-2-2 .attacker {
  height:50% !important;
}

/*horizontal 4-3-1-2*/

.pitch.horizontal .team.formation-4-3-1-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-3-1-2 .midfielder {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-4-3-1-2 .attacker:nth-child(9) {
  height:100%;
}

.pitch.horizontal .team.formation-4-3-1-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-3-1-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 4-3-3*/

.pitch.horizontal .team.formation-4-3-3 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-3-3 .midfielder, .pitch.horizontal .team.formation-4-3-3 .attacker {
  height:33.3% !important;
}

/*horizontal 4-4-1-1*/

.pitch.horizontal .team.formation-4-4-1-1 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-4-1-1 .midfielder, .pitch.horizontal .team.formation-4-4-1-1 .attacker:nth-child(9) {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-4-1-1 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-4-1-1 .attacker:last-child {
  height:100% !important;
}

/*horizontal 4-4-2*/

.pitch.horizontal .team.formation-4-4-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-4-2 .midfielder, .pitch.horizontal .team.formation-4-4-2 .attacker:nth-child(9) {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-4-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-4-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 4-5-1*/

.pitch.horizontal .team.formation-4-5-1 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-5-1 .midfielder, .pitch.horizontal .team.formation-4-5-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-4-5-1 .attacker:nth-child(10) {
  height:20% !important;
}

.pitch.horizontal .team.formation-4-5-1 .attacker:last-child {
  height:100% !important;
}

/*horiz 5-4-1*/

.pitch.horizontal .team.formation-5-4-1 .midfielder:nth-child(6), .pitch.horizontal .team.formation-5-4-1 .defender {
  height:20% !important;
}

.pitch.horizontal .team.formation-5-4-1 .midfielder, .pitch.horizontal .team.formation-5-4-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-5-4-1 .attacker:nth-child(10) {
  height:25% !important;
}

.pitch.horizontal .team.formation-5-4-1 .attacker:last-child {
  height:100% !important;
}

/*horiz 4-2-3-1*/

.pitch.horizontal .team.formation-4-2-3-1 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-2-3-1 .midfielder:nth-child(6), .pitch.horizontal .team.formation-4-2-3-1 .midfielder:nth-child(7) {
  height:50% !important;
}

.pitch.horizontal .team.formation-4-2-3-1 .midfielder:nth-child(8), .pitch.horizontal .team.formation-4-2-3-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-4-2-3-1 .attacker:nth-child(10) {
  height:33.3%;
}

.pitch.horizontal .team.formation-4-2-3-1 .midfielder, .pitch.horizontal .team.formation-4-2-3-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-4-2-3-1 .attacker:nth-child(10) {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-2-3-1 .attacker:last-child {
  height:100% !important;
}

/*horiz 5-3-2*/

.pitch.horizontal .team.formation-5-3-2 .midfielder:nth-child(6), .pitch.horizontal .team.formation-5-3-2 .defender {
  height:20% !important;
}

.pitch.horizontal .team.formation-5-3-2 .midfielder, .pitch.horizontal .team.formation-5-3-2 .attacker:nth-child(9) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-5-3-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-5-3-2 .attacker:last-child {
  height:50% !important;
}

/*horiz 3-5-2*/

.pitch.horizontal .team.formation-3-5-2 .defender:not(:nth-child(5)) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-5-2 .defender:nth-child(5), .pitch.horizontal .team.formation-3-5-2 .midfielder, .pitch.horizontal .team.formation-3-5-2 .attacker:nth-child(9) {
  height:20% !important;
}

.pitch.horizontal .team.formation-3-5-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-3-5-2 .attacker:last-child {
  height:50% !important;
}

/*horiz 3-4-3*/

.pitch.horizontal .team.formation-3-4-3 .defender:not(:nth-child(5)) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-4-3 .defender:nth-child(5), .pitch.horizontal .team.formation-3-4-3 .midfielder {
  height:20% !important;
}

.pitch.horizontal .team.formation-3-4-3 .attacker {
  height:33.3% !important;
}

/*--end horizontal pitch*/

@media screen and (min-width:1025px){
  .pitch.vertical .team {
    background-size:56px;
    /*background-position:*/
  }

  .pitch.vertical .team.team-home {
    background-position:97% 5%;
  }

  .pitch.vertical .team.team-away {
    background-position:97% 95%;
  }
}

@media screen and (min-width:1200px){
  .pitch .team .player span {
    padding:8px 2px;
    font-size:0.8vw;
  }
}

@media screen and (max-width:500px){
  .pitch.vertical .team .player .player-name {
    font-size:7px;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
$: orientation = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.orientation) || 'horizontal';
const playerThumbnail = 'https://resources-us.yinzcam.com/csf/shared/icons/icon_headshot_placeholder.png';
$: source = findSourceStore($sources, 'YinzCamAppServerGameFormation');
$: data = (source) ? $source : {};
$: formations = data === null || data === void 0 ? void 0 : data.Formations;
$: homeFormationName = (_c = (_b = formations === null || formations === void 0 ? void 0 : formations.HomeTeamFormation) === null || _b === void 0 ? void 0 : _b._attributes) === null || _c === void 0 ? void 0 : _c.Name;
$: awayFormationName = (_e = (_d = formations === null || formations === void 0 ? void 0 : formations.AwayTeamFormation) === null || _d === void 0 ? void 0 : _d._attributes) === null || _e === void 0 ? void 0 : _e.Name;
$: hasFormations = (!homeFormationName && !awayFormationName) ? false : true;
$: homeTriCode = (_g = (_f = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _f === void 0 ? void 0 : _f._attributes) === null || _g === void 0 ? void 0 : _g.TriCode;
$: awayTriCode = (_j = (_h = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _h === void 0 ? void 0 : _h._attributes) === null || _j === void 0 ? void 0 : _j.TriCode;
$: homeFormation = [].concat((_k = formations === null || formations === void 0 ? void 0 : formations.HomeTeamFormation) === null || _k === void 0 ? void 0 : _k.Row).reduce((acc, cur) => acc.concat([].concat(cur === null || cur === void 0 ? void 0 : cur.Player)), []);
$: awayFormation = [].concat((_l = formations === null || formations === void 0 ? void 0 : formations.AwayTeamFormation) === null || _l === void 0 ? void 0 : _l.Row).reduce((acc, cur) => acc.concat([].concat(cur === null || cur === void 0 ? void 0 : cur.Player)), []);
</script>