<CardBase {element}>
  {#if $teamsListData}
  <div class="team-select-carousel" class:isModal={(element.data.isModal === true)}>
    <div class="wrapper" style="background:{countryBackground};">
      <div class="heading">{$t('Equipos')}</div>
      <div class="country-list listing">
        {#each countryOptions as option, i}
            <div class="selection" class:active={(!userSelectionMade && i===0) || countryFilter === option.value} data-key="country" data-select-value={option.value} on:click={registerSelection}>
              <img src={option.thumbnail} alt={$t(option.title)}>
              <span>{$t(option.title)}</span>
            </div>
          {/each}   
      </div>  
    </div>
    
  
    <div class="team-list listing">
        {#each myCountryTeamsList as option (option.id)}
          {#if option.country === countryFilter || countryFilter === false}
          <div class="selection popup-close" data-team-name="{option.name}" data-team-tricode="{option.logoSuffix.replace('CSF_','')}" data-key="favoriteTeam" data-select-value={option.id} on:click={registerSelection}>
            <div class="img-wrapper"><TeamLogo tricode={option.logoSuffix.replace('CSF_','')} /></div>
            <span>{option.name}</span>
          </div>
          {/if}
        {/each}
    </div>
    </div>
  {/if}
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.team-select-carousel {
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
}

.listing {
  width:100%;
  margin:12px 0;
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:flex-start;
  align-content:start;
}

.selection img, .selection .img-wrapper {
  border-radius:50%;
  border-style:solid;
  border-width:3px;
  overflow:hidden;
}

.selection img, .selection .img-wrapper {
  border-color:#FFF;
}

.selection:not(.active) img, .selection:not(.active) .img-wrapper {
  border-color:transparent !important;
  border-radius:0;
}

/*.team-list .selection img, .team-list .selection .img-wrapper {
    transform:scale(0.95);
  }

  .team-list .selection:not(.active) img, .team-list .selection:not(.active) .img-wrapper {
    transform:scale(1);
  }*/

.selection, .team-list .selection:hover {
  transform:scale(1.2);
}

.selection:not(.active) {
  transform:scale(1);
}

.listing .selection {
  width:10%;
  margin:12px;
  padding:0;
  border-radius:50%;
  text-align:center;
  position:relative;
  z-index:2;
  transition:all 300ms ease;
}

.team-list .selection {
}

.listing:empty:after {
  margin:24px auto;
  padding:24px;
  border:solid 1px var(--f7-theme-color-border-color);
  border-radius:8px;
  color:var(--f7-theme-color);
  font-size:16px;
  text-align:center;
  display:block;
  /*content:"No selections meet your criteria";*/
}

:lang(en) .listing:empty:after {
  content:"No selections meet your criteria";
}

:lang(es) .listing:empty:after {
  content:"Ninguna selección cumple con sus criterios";
}

:lang(pt) .listing:empty:after {
  content:"Nenhuma seleção atende aos seus critérios";
}

.listing .selection img, .listing .selection .img-wrapper {
  width:100%;
  height:intrinsic;
  height:auto;
  display:block;
}

.listing .selection span {
  width:100%;
  margin:6px 0;
  color:var(--f7-theme-color);
  text-align:center;
  display:block;
}

.team-select-carousel:not(.isModal) .country-list {
  width:auto;
  padding:8px;
}

.country-list .selection {
  width:auto;
}

.country-list .selection img {
  width:50px;
  margin:0 auto;
}

.country-list .listing .selection span {
  font-size:13px;
}

.team-list .listing .selection span {
  font-size:1vw;
}

.isModal .heading {
  display:none;
}

.wrapper {
  width:100%;
  padding:12px;
  border-radius:12px;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
}

.isModal .listing .selection {
  width:84px;
}

.isModal .team-list:before {
  width:80%;
  height:3px;
  margin:12px 10%;
  background:linear-gradient(45deg, transparent,#fcd069,transparent);
  display:block;
  content:"";
}

.isModal .team-list .selection {
  width:16%;
}

@media screen and (min-width:1025px){
  .heading {
    margin-left:8px;
    font-size:3vw;
    font-weight:bold;
    text-transform:uppercase;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore, getHrefForMatchCenter } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { t } from "../../../../js/i18n";
import { resolveUrl } from "../../../../js/url";
import YCLink from "../../utilities/YCLink.svelte";
import { tdate, ttime } from "../../../../js/i18n";
export let element;
export let sources;
import ROOT from '../../../../inversify.config';
import { getToken } from 'inversify-token';
import { getContext, onMount } from "svelte";
import { f7, f7ready, f7Router } from 'framework7-svelte';
import { YinzCamAppServer, YinzCamAppServerToken } from 'yinzcam-appserver';
import { JanusModeContextManagerToken } from "../../../../js/mode";
import { CardsTabContext, CardsTabContextKey } from "../../common/context";
const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
let isModal = false;
let countryBackground = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.countryBackground) || 'transparent';
let teamsList = [];
let countryFilter = false;
let countryOptions = new Array();
let countries = [
    { 'abv': 'ARG', 'full': 'Argentina' },
    { 'abv': 'BOL', 'full': 'Bolivia' },
    { 'abv': 'BRA', 'full': 'Brasil' },
    { 'abv': 'CHI', 'full': 'Chile' },
    { 'abv': 'COL', 'full': 'Colombia' },
    { 'abv': 'ECU', 'full': 'Ecuador' },
    { 'abv': 'PAR', 'full': 'Paraguay' },
    { 'abv': 'PER', 'full': 'Perú' },
    { 'abv': 'URU', 'full': 'Uruguay' },
    { 'abv': 'VEN', 'full': 'Venezuela' }
];
let getCountryFullName = function (countryAbv) {
    for (var i in countries) {
        if (countries[i].abv === countryAbv) {
            return countries[i].full;
        }
    }
    //return unknown if abbreviation not found in array
    return 'unknown';
};
const appsrv = getToken(ROOT, YinzCamAppServerToken);
let teamsListData = appsrv.getRequest({ path: 'Team/TeamsList' }).store;
$: if (teamsListData) {
    teamsList = [];
    (_f = (_e = (_d = (_c = $teamsListData === null || $teamsListData === void 0 ? void 0 : $teamsListData.data) === null || _c === void 0 ? void 0 : _c.TeamsList) === null || _d === void 0 ? void 0 : _d.Section) === null || _e === void 0 ? void 0 : _e.Team) === null || _f === void 0 ? void 0 : _f.forEach(function (value, index, array) {
        teamsList.push({
            id: value._attributes.Id,
            name: value._attributes.Name,
            logoSuffix: value._attributes.LogoId,
            country: getCountryFullName(value._attributes.Country)
        });
    });
}
$: myCountryTeamsList = teamsList;
for (var i = 0; i < countries.length; i++) {
    var countryFilePath = countries[i].full.toLowerCase();
    if (countryFilePath === 'perú') {
        countryFilePath = 'peru';
    }
    countryOptions.push({
        title: countries[i].full,
        value: countries[i].full,
        thumbnail: `static/onboarding/flags/country_${countryFilePath}.png`
    });
}
let countrySelection, teamSelection, userSelectionMade = false;
//default to the first country in the list, if none yet selected by user
$: if (!userSelectionMade && teamsList) {
    countryFilter = countries[0];
}
contextManager.getCountryIdComponent().store.subscribe(function (val) {
    countrySelection = val;
});
contextManager.getTeamIdComponent().store.subscribe(function (val) {
    teamSelection = val;
});
//onboarding methods
let registerSelection = function (e) {
    var selectValue = e.currentTarget.getAttribute('data-select-value');
    var dataKey = e.currentTarget.getAttribute('data-key');
    // console.log('selection made',dataKey,selectValue);
    if (userSelectionMade === false) {
        userSelectionMade = true;
    }
    if (dataKey === 'country') {
        countryFilter = selectValue;
        contextManager.setCountryId(selectValue);
    }
    else if (dataKey === 'favoriteTeam') {
        contextManager.setTeamId(selectValue);
        var teamName = e.currentTarget.getAttribute('data-team-name');
        var tricode = e.currentTarget.getAttribute('data-team-tricode');
        f7.views.main.router.navigate({ name: 'cards', query: { teamId: selectValue, titleText: teamName, teamTricode: tricode }, params: { listName: 'Team' } });
    }
};
let notificationOptIn = function (e) {
    console.log('user has opted to subscribe to notifications');
};
let notificationOptOut = function (e) {
    console.log('user has opted out of notifications');
};
let notificationUpdate = function (e) {
    //console.log('notificationUpdate',e,e.currentTarget);
    let tag = e.currentTarget.getAttribute('name');
    pushManager.togglePushTag(tag);
};
$: if ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.isModal) {
    isModal = true;
}
onMount(() => {
    f7ready(() => {
        //
    });
});
</script>