<script lang="ts">export let Play = {};
</script>

<div class="live-reporting-youtube-card">
  {@html Play.EmbedCode}
  {#if Play.Text}
    <div class="title">{Play.Text}</div>
  {/if}
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.live-reporting-youtube-card {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.live-reporting-youtube-card .title {
  margin: 6px 0 0 0;
  font-family: AeonikTRIAL;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #202020;
}

@media (max-width: 767px) {
  .live-reporting-youtube-card .title {
    margin: 2px 0 0 0;
    font-size: 14px;
  }
}</style>
