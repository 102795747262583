<CardBase {element}>
  <div class="container" style="color:{textColor};">
    <div><img style="max-width: 100%;" src={imageUrl} alt=""></div>
    <div style="font-weight: bold; font-size: 125%;">{headline}</div>
    <div style="height: 5px;"></div>
    <div style="font-size: 75%">{(author)? author + " - " : ""}{$tdate(publishDate, { year: 'numeric', month: 'long', day: 'numeric' })} {$ttime(publishDate, { format: 'short' })}</div>
    <hr>
  </div>
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f;
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { tdate, ttime } from "../../../../js/i18n";
export let element;
export let sources;
let textColor = 'inherit';
$: textColor = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.textColor;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaNews');
$: data = (source) ? $source : {};
$: timestamp = (_b = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _b === void 0 ? void 0 : _b._text;
$: publishDate = (timestamp) ? new Date(timestamp) : null;
$: imageUrl = (_c = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _c === void 0 ? void 0 : _c._text;
$: author = ((_d = data === null || data === void 0 ? void 0 : data.Author) === null || _d === void 0 ? void 0 : _d._text) || "";
$: headline = ((_e = data === null || data === void 0 ? void 0 : data.Headline) === null || _e === void 0 ? void 0 : _e._text) || "";
$: description = ((_f = data === null || data === void 0 ? void 0 : data.Description) === null || _f === void 0 ? void 0 : _f._text) || "";
</script>
