<CardBase {element} {sources}>
  <div class="soccer-playerdetail-statsummarydetail" style="
    width: {element.data.width || 'auto'};
    height: {element.data.height || 'auto'};    
  ">
    <div class="property">
      <div class="label">Partidos Jugados<span>{gamesPlayed}</span></div>
      <div class="label">Titular<span>{gamesStarted}</span></div>
      <div class="label">Suplente<span>{substitutes}</span></div>
      <div class="label">Sustituido<span>{substitutions}</span></div>
    </div>
    <div class="property">
      <div class="label">Goles<span>{goals}</span></div>
      <div class="label">Asistencias<span>{assists}</span></div>
      <div class="label">Disparos<span>{shots}</span></div>
      <div class="label">Remates<span>{shotsOnGoal}</span></div>
    </div>
  </div>
</CardBase>

<style type="text/scss">*, *::before, *::after {
  box-sizing: border-box;
}

.soccer-playerdetail-statsummarydetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 73px 35px 77px;
  border-radius: 4px;
  background-color: var(--neutrals-white-100);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-statsummarydetail {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    padding: 24px;
  }
}

.soccer-playerdetail-statsummarydetail .property {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 199px;
  grid-gap: 16px;
  gap: 16px;
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-statsummarydetail .property {
    width: 100%;
  }
}

.soccer-playerdetail-statsummarydetail .property .label {
  display: flex;
  justify-content: space-between;
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
  color: var(--neutrals-grey-darker-60);
}

.soccer-playerdetail-statsummarydetail .property .label span {
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: right;
  color: var(--neutrals-grey-lighter-20);
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h;
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore } from "../../../utilities";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
}
;
$: gamesPlayed = ((_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['games_played']) === null || _a === void 0 ? void 0 : _a.ValueLabel) || "0";
$: gamesStarted = ((_b = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['starts']) === null || _b === void 0 ? void 0 : _b.ValueLabel) || "0";
$: substitutions = ((_c = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['substitute_on']) === null || _c === void 0 ? void 0 : _c.ValueLabel) || "0";
$: substitutes = ((_d = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['substitute_off']) === null || _d === void 0 ? void 0 : _d.ValueLabel) || "0";
$: goals = ((_e = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['goals']) === null || _e === void 0 ? void 0 : _e.ValueLabel) || "0";
$: assists = ((_f = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['goal_assists']) === null || _f === void 0 ? void 0 : _f.ValueLabel) || "0";
$: shots = ((_g = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['total_shots']) === null || _g === void 0 ? void 0 : _g.ValueLabel) || "0";
$: shotsOnGoal = ((_h = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['shots_on_target_inc_goals']) === null || _h === void 0 ? void 0 : _h.ValueLabel) || "0";
</script>
