import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";

@injectable()
export class YinzCamAppServerTeamRoster extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    let data = response?.data as any;
    let returnData = [];
    let heading = "";
    [].concat(data?.Team?.Group).forEach(function(value,index){
      if (!_.isNil(specData?.groupId)) {
        if (value?._attributes?.Id !== specData.groupId)
          return;
        heading = value?._attributes?.Heading;
      }
      [].concat(value?.PlayerStats).forEach(function(player){
        returnData.push(player);
      });
    });
    if (!_.isNil(specData?.groupId) && returnData.length > 0)
      returnData.unshift({ heading });
    return returnData;
  }
}