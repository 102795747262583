import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";

@injectable()
export class YinzCamAppServerGameScores extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;

    if (specData?.getDayGames) {
      return [].concat(data?.Scores?.Day?.Game).filter(e => e)
    }
    let gamesScores = {
      'games': [].concat(data?.PhaseList?.ScoresArray?.ScoresXml?.Day?.Game)
    }
    return gamesScores;
  }
}
