<svelte:head>
  <title>{title}</title>
  {#if description}
  <meta name="description" content={description}>
  {:else}
  <meta name="description" content="">
  {/if}
  <!-- <meta name="keywords" content="{post.metadata.keywords}"/> -->

  <!-- Open Graph / Facebook -->
  <meta property="og:type" content="website">
  <meta property="og:url" content={window.location.href}>
  <meta property="og:title" content={title}>
  {#if description}
  <meta property="og:description" content={description}>
  {/if}
  {#if imageUrl}
  <meta property="og:image" content={socialThumbUrl}>
  <meta property="og:image:width" content="300">
  <meta property="og:image:height" content="300">
  {/if}

  <!-- Twitter -->
  <meta property="twitter:card" content="summary_large_image">
  <meta property="twitter:url" content={window.location.href}>
  <meta property="twitter:title" content={title}>
  {#if description}
  <meta property="twitter:description" content={description}>
  {/if}
  {#if imageUrl}
  <meta property="twitter:image" content={thumbUrl}>
  {/if}
  {#if ytEmbedUrl}
  <meta property="twitter:player" content={ytEmbedUrl}>
  <meta property="twitter:player:width" content="640">
  <meta property="twitter:player:height" content="360">
  {/if}
</svelte:head>

<script lang="ts">var _a, _b, _c, _d, _e, _f;
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
export const contentAvailable = false;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaItem');
$: data = (source) ? $source : {};
$: title = ((_a = data === null || data === void 0 ? void 0 : data.Title) === null || _a === void 0 ? void 0 : _a._text) || '';
$: imageUrl = (_b = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _b === void 0 ? void 0 : _b._text;
$: thumbUrl = (_c = data === null || data === void 0 ? void 0 : data.ThumbUrl) === null || _c === void 0 ? void 0 : _c._text;
$: socialThumbUrl = (_d = data === null || data === void 0 ? void 0 : data.SocialThumbUrl) === null || _d === void 0 ? void 0 : _d._text;
$: mediaUrl = (_e = data === null || data === void 0 ? void 0 : data.MediaUrl) === null || _e === void 0 ? void 0 : _e._text;
$: isYouTube = mediaUrl && mediaUrl.includes('youtube.com');
$: ytVideoId = (isYouTube) ? new URL(mediaUrl).searchParams.get('v') : '';
$: ytEmbedUrl = (isYouTube) ? `https://www.youtube.com/embed/${ytVideoId}?feature=oembed` : '';
$: description = ((_f = data === null || data === void 0 ? void 0 : data.Description) === null || _f === void 0 ? void 0 : _f._text) || '';
</script>
