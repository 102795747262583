<script>
  import ScheduleTournamentItem from './ScheduleTournamentItem.svelte';

  export let data;
</script>

<div>
  <h1 class="schedule-title">{data.title}</h1>
  {#if data.list.length === 0}
    <div class="empty-schedule-list">
      <div>
        <img src="/assets/img/watch.png" height="24" alt="watch" />
      </div>
      <h2>Lo sentimos, no hay partidos contemplados para este mes</h2>
      <div>Vuelve un poco más tarde para comprobar si hay actualizaciones</div>
    </div>
  {:else}
    <ul class="schedule-list">
      {#each data.list as item}
        <ScheduleTournamentItem {item} scheduleType={data.type} />
      {/each}
    </ul>
  {/if}
</div>

<style>.schedule-title {
  font-size: 20px;
  font-family: 'Core Sans C 75';
  line-height: 24px;
  margin: 20px 32px;
  padding-top: 20px;
  color: #1c1f22;
}

.schedule-list {
  padding: 0;
  margin: 0;
}

.empty-schedule-list {
  width: 360px;
  margin: 75px auto;
  font-family: CoreSansC-75;
  text-align: center;
}

.empty-schedule-list h2 {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  color: #2e3338;
}

.empty-schedule-list div {
  font-size: 14px;
  line-height: 1.43;
  color: #535c65;
}</style>
