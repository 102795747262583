import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { Config, ConfigToken } from "./Config";

export let ConfigModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    bindToken(ConfigToken).toConstantValue(Config.getAppConfig());
  }));
});
