import { readable } from 'svelte/store';
import { v4 as uuid4 } from 'uuid';
import { Device } from 'framework7';

// Stores allow components to subscribe to changes in shared state that can be updated by an external service
// Useful tutorial on readable stores in general: https://svelte.dev/tutorial/readable-stores

// This builds a "Window Metrics" object with useful size info and flags about the type of screen inferred from width/height.
// I looked at a number of articles about where the breakpoints for mobile/tablet/desktop/4K should be:
// https://devfacts.com/media-queries-breakpoints-2020/
// https://medium.com/@uiuxlab/the-most-used-responsive-breakpoints-in-2017-of-mine-9588e9bd3a8a
// https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints/
// https://docs.microsoft.com/en-us/windows/uwp/design/layout/screen-sizes-and-breakpoints-for-responsive-design
// https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
// https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
// In the end, the last one did a clustering analysis on several common devices and found breakpoints with a good buffer on each side,
// so I went with that one.
function buildWindowMetrics() {
    let wm = {};

    // base width and height
    wm = {
        ...wm,
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
    };
    
    // base width and height
    wm = {
        ...wm,
        isPortrait: wm.width < wm.height,
        isLandscape: wm.width >= wm.height, // want portrait to be !landscape
        isSquare: wm.width === wm.height, // not sure if this is useful
    };
    
    // screen orientation
    wm = {
        ...wm,
        isPortrait: wm.width < wm.height,
        isLandscape: wm.width >= wm.height, // want portrait to be !landscape
        isSquare: wm.width === wm.height, // not sure if this is useful
    };

    // screen width flags
    // NOTE: Must align with screen width breakpoints in tailwind.config.js
    wm = {
        ...wm,
        isMobile: wm.width < 600, // mobile portrait
        isSmall: wm.width >= 600 && wm.width < 900, // mobile landscape, tablet portrait
        isMedium: wm.width >= 900 && wm.width < 1200, // tablet landscape, small laptops, desktop portrait
        isLarge: wm.width >= 1200 && wm.width < 2000, // laptops, desktops (covers 720p, 1080p)
        isXLarge: wm.width >= 2000, // 4K laptops, desktops
    };

    // combo flags
    wm = {
        ...wm,
        isMobilePortrait: wm.isMobile && wm.isPortrait,
        isMobileLandscape: wm.isSmall && wm.isLandscape,
        isTabletPortrait: wm.isSmall && wm.isPortrait,
        isTabletLandscape: wm.isMedium && wm.isLandscape,
        isDesktopPortrait: wm.isMedium && wm.isPortrait,
        isDesktopLandscape: wm.isLarge && wm.isLandscape,
        is4KPortrait: wm.isLarge && wm.isPortrait,
        is4KLandscape: wm.isXLarge && wm.isLandscape,
    };

    return wm;
}

// A store for the window width/height that's updated when the JS event fires for window size change
// More info: https://developer.mozilla.org/en-US/docs/Web/API/Window/resize_event

export const windowMetrics = readable(buildWindowMetrics(), function start(set) {
    function resizeListener() {
        set(buildWindowMetrics());
    };
    window.addEventListener('resize', resizeListener);

    function stopHandler() {
        window.removeEventListener('resize', resizeEventListener);
    }
	return stopHandler;
});

export const windowVisibility = readable(true, function start(set) {
    function pageHideListener() {
        set(false);
    };
    function pageShowListener() {
        set(true);
    };
    function windowBlurListener() {
        set(false);
    };
    function windowFocusListener() {
        set(true);
    };
    function visibilityListener() {
        var hidden = false;
        if (typeof document.hidden !== "undefined") {
            hidden = document.hidden;
        } else if (typeof document.mozHidden !== "undefined") {
            hidden = document.mozHidden;
        } else if (typeof document.msHidden !== "undefined") {
            hidden = document.msHidden;
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = document.webkitHidden;
        } else {
            return;
        }
        set(!hidden);
    };

    window.addEventListener("pagehide", pageHideListener);
    window.addEventListener("pageshow", pageShowListener);
    window.addEventListener("blur", windowBlurListener);
    window.addEventListener("focus", windowFocusListener);
    document.addEventListener("visibilitychange", visibilityListener);

    function stopHandler() {
        window.removeEventListener("pagehide", pageHideListener);
        window.removeEventListener("pageshow", pageShowListener);
        window.removeEventListener("blur", windowBlurListener);
        window.removeEventListener("focus", windowFocusListener);
        document.removeEventListener("visibilitychange", visibilityListener);
    }
	return stopHandler;
});

// Temporary hack to work with components that can't subscribe to this cleanly right now
export let windowVisible = true;
windowVisibility.subscribe((visibility) => {
    windowVisible = visibility;
});

export function getBrowserId() {
    let id = window.localStorage.getItem('JanusBrowserId');
    if (!id) {
        id = uuid4();
        window.localStorage.setItem('JanusBrowserId', id);
    }
    return id;
}

/*
export const browserId = readable(getBrowserId(), function start(set) {
    // way to listen to local storage cleared? maybe timer?
    set(getBrowserId());

    function stopHandler() {
        // do nothing
    }
    return stopHandler;
});
*/

export function getDeviceId() {
    let deviceId;
    // @ts-ignore
    if (Device && Device.cordova && device) {
        // @ts-ignore
        deviceId = device.uuid;
    } else {
        let id = getBrowserId();
        if (id) {
            deviceId = id;
        }
    }
    return deviceId;
}
