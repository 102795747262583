<script>
  import { getHrefForMatchCenter } from "../../../utilities";
  import { f7ready } from "framework7-svelte";

  export let item;
  export let scheduleType;

  const handleGameClick = () => f7ready((f7) => f7.views.main.router.navigate(getHrefForMatchCenter(item.id)))

  let expanded = false;

  function onClickExpand() {
		expanded = !expanded;
	}
</script>

<li class="item">
  <div class="main-row">
    <div class="campaign-info">
      <div class="logo">
        <img src={item.campaignLogo} alt="log" />
      </div>
      <div class="match-time">
        {#if !item.inProgress}
          <div class="date">{item.date}</div>
        {/if}
        <div class="date1">{item.date1}</div>
        <div class="stadium">{item.stadium}</div>
      </div>
    </div>

    <div class="team-info">
      <div class="name">{item.team1}</div>
      <div class="logo">
        <img src={item.teamLogo1} alt="log" />
      </div>
    </div>

    <div>
      <div class="mobile-campaign-info">
        {item.mobileCompTitle}
      </div>
      {#if item.inProgress}
        <div class="startinfo">
          <span class="date">{item.date}</span>
          <div class="time">{item.time}</div>
        </div>
      {:else}
        <div class="scoreboard">
          <div>{item.score1}</div>
          <div>{item.score2}</div>
          {#if !item.hideScoreDetails}
            <span class="btn-expand" role="button" on:click={onClickExpand}>
              <i class="f7-icons">{expanded ? "chevron_up" : "chevron_down"}</i>
            </span>
          {/if}
        </div>
      {/if}
    </div>

    <div class="team-info away">
      <div class="name">{item.team2}</div>
      <div class="logo">
        <img src={item.teamLogo2} alt="log" />
      </div>
    </div>

    <div class="history">
      <button class="btn btn-primary" on:click={handleGameClick}>
        {scheduleType == 1 ? 'Crónica' : 'Previa'}
      </button>
      <div class="summary">{scheduleType == 1 ? '*Resumen disponible' : ''}</div>
    </div>

    <span class="btn-right-expand" role="button" on:click={handleGameClick}>
      <i class="f7-icons">chevron_right</i>
    </span>
  </div>
  {#if expanded}
    <div class="detail-row">
      <div class="detail1">
        {#each item.scoreDetails1 as detailItem}
          <div class="detail-item">
            <span class="label">{detailItem.label}</span>
            <span class="goals">{detailItem.goals}</span>
          </div>
        {/each}
      </div>
      <div class="detail2">
        {#each item.scoreDetails2 as detailItem}
          <div class="detail-item">
            <span class="label">{detailItem.label}</span>
            <span class="goals">{detailItem.goals}</span>
          </div>
        {/each}
      </div>
    </div>
  {/if}
</li>

<style>.main-row {
  display: flex;
  align-items: center;
  padding: 22px 32px;
  border-top: 1px solid #dde0e3;
  line-height: 1;
  font-family: 'Core Sans C 75';
}

.item:last-child {
  border-bottom: 1px solid #dde0e3;
}

.campaign-info {
  display: flex;
  align-items: center;
  width: 25%;
}

.campaign-info .logo {
  padding-right: 30px;
  border-right: 1px solid #dde0e3;
}

.campaign-info .logo img {
  width: 60px;
}

.match-time {
  margin-left: 32px;
  color: #8e9194;
  font-size: 12px;
  width: 200px;
}

.match-time .date {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.38;
  color: #131516;
}

.match-time .date1 {
  font-weight: normal;
  margin: 5px 0 8px 0;
  font-family: 'Core Sans C 45';
}

.match-time .stadium {
  font-weight: normal;
  margin: 5px 0 8px 0;
  font-family: 'Core Sans C 45';
}

.team-info {
  margin-left: auto;
  display: flex;
  align-items: center;
  text-align: right;
}

.team-info .name {
  width: 100px;
  font-size: 17.9px;
  font-weight: 800;
  color: #282c30;
}

.team-info .logo img {
  width: 61.8px;
}

.team-info.away {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: auto;
  text-align: left;
}

.scoreboard {
  background: #b0b8bf;
  margin: 6px 0;
  text-align: center;
  height: 62px;
  position: relative;
}

.scoreboard div {
  font-size: 32px;
  font-weight: 800;
  line-height: 62px;
  display: inline-block;
  width: 62px;
  height: 62px;
  color: #2e3338;
}

.scoreboard .btn-expand {
  position: absolute;
  font-size: 14px;
  left: 0px;
  width: 100%;
  bottom: -30px;
  color: #848f9a;
  cursor: pointer;
}

.btn-expand:hover i {
  color: #0088cc;
}

.startinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 128px;
  margin: 6px 0;
  color: #788591;
  text-align: center;
}

.startinfo .header {
  display: none
}

.startinfo .date {
  font-size: 14px;
  line-height: 1.71;
  font-family: 'Core Sans C 45';
}

.startinfo .time {
  font-weight: 800;
  font-size: 20px;
  line-height: 1.2;
}

.btn-primary {
  width: 140px;
  height: 34px;
  text-align: center;
  background: #788591;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
}

.summary {
  color: #535c65;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-family: 'Core Sans C 45';
}

.btn-right-expand {
  display: none;
}

.mobile-campaign-info {
  display: none;
}

.detail-row {
  display: flex;
  padding: 0px 0px 20px 185px;
}

.detail1 {
  margin-left: auto;
  text-align: right;
}

.detail2 {
  margin-right: auto;
  margin-left: 150px;
}

.detail-row .label {
  height: 15px;
  flex-grow: 0;
  font-family: 'Core Sans C 45';
  font-size: 10.5px;
  line-height: 1.43;
  text-align: right;
  color: #49525a;
}

.detail-row .goals {
  height: 11px;
  flex-grow: 0;
  font-family: 'Core Sans C 75';
  font-size: 11.2px;
  font-weight: 800;
  letter-spacing: 0.9px;
  color: #49525a;
}

.detail-item {
  margin-bottom: 4px;
}

@media (max-width: 1100px) {
  .main-row {
    padding: 12px 0px;
    position: relative;
    align-items: flex-end;
  }

  .campaign-info,
    .history {
    display: none;
  }

  .team-info {
    flex-direction: column-reverse !important;
    text-align: center !important;
  }

  .team-info .logo img {
    width: 48px;
  }

  .team-info .name {
    margin: 0 !important;
  }

  .scoreboard {
    width: 80px;
    height: 40px;
    display: flex;
    margin: 0px 15px;
  }

  .scoreboard div {
    font-size: 20px;
    line-height: 40px;
    width: 40px;
    height: 40px;
  }

  .scoreboard .btn-expand {
    display: none;
  }

  .startinfo {
    width: 80px;
    margin: 0 15px;
    color: #2e3338;
  }

  .startinfo .header {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-family: 'Core Sans C 45';
  }

  .startinfo .date {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-family: 'Core Sans C 65';
  }

  .team-info .name {
    font-family: 'Core Sans C 45';
    font-size: 12px;
    line-height: 1;
    margin-top: 8px;
    color: #282c30;
  }

  .btn-right-expand {
    display: block;
    position: absolute;
    right: 8px;
    top: calc(50% - 7px);
    cursor: pointer;
  }

  .btn-right-expand .f7-icons {
    font-size: 16px;
  }

  .mobile-campaign-info {
    display: block;
    text-align: center;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-family: 'Core Sans C 45';
    line-height: 1.6;
    color: #9aa3ac;
  }

  .detail-row {
    display: none;
  }
}</style>
