import { AxiosRequestConfig, Method } from "axios";
import { AppConfig } from "yinzcam-config";
import { YinzCamAPIRequest } from "./YinzCamAPIRequest";
import { YinzCamAPIRequestParameters } from "./YinzCamAPIRequestParameters";

export function buildAxiosRequest(appConfig: AppConfig, parameters: YinzCamAPIRequestParameters, request: YinzCamAPIRequest): AxiosRequestConfig {
  // set up request parameters
  // internal defaults followed by external injected params followed by request params
  // keep in mind that params later in the chain override earlier ones
  const defaultHeaders: { [key: string]: string; } = { };
  const defaultParams: { [key: string]: string; } = { };
  const finalHeaders = {
    ...defaultHeaders,
    ...(parameters?.headers) ? parameters.headers : {},
    ...(request.headers) ? request.headers : {},
  };
  const finalParams = {
    ...defaultParams,
    ...(parameters?.params) ? parameters.params : {},
    ...(request.params) ? request.params : {},
  };

  let axiosReq: AxiosRequestConfig = {
    url: request.path,
    method: request.method as Method,
    headers: finalHeaders,
    params: finalParams,
    data: request.data
  };
  return axiosReq;
}
