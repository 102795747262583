<script lang="ts">export let src = '';
export let alt = '';
export let title = '';
let clazz;
export { clazz as class };
export let placeholder;
let isShowPlaceholder = true;
const handleError = (e) => {
    e.target.src = placeholder;
};
function cached(url) {
    const test = document.createElement("img");
    test.src = url;
    return test.complete || test.width + test.height > 0;
}
$: {
    isShowPlaceholder = !cached(src);
}
const handleLoad = (e) => {
    isShowPlaceholder = false;
};
</script>

<div
  class={clazz}
  {title}
  style="{ isShowPlaceholder ? `background-image: url(${placeholder}); background-size: 100% 100%;` : ''}"
>
  <img
    src={src}
    {alt}
    class={clazz}
    on:error={handleError}
    on:load={handleLoad}
    class:display-none={isShowPlaceholder}
  />
</div>
<!-- <img {src} class="display-none" on:load={handleLoad} /> -->

<style type="text/scss"></style>
