<CardBase {element}>
<div class="match-detail" class:display-none={state !== 'F'} data-state={state}>
	<div class="final-card">
		<div class="title">
			<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/icon_stoppage.png" alt="Whistle Icon" class="icon">
			<h3 class="heading">Final del partido</h3>
		</div>
		<div class="matchup">
			{#if homeTeam}
			<div class="team team-1">
				<div class="name">{homeTeam._attributes.Name}</div>
				<div class="logo">
					<span><TeamLogo teamObject={homeTeam} /></span>
				</div>
				<div class="score">2<!--{homeTeam.Score}--></div>
			</div>
			<div class="team team-2">
				<div class="name">{awayTeam._attributes.Name}</div>
				<div class="logo">
					<span><TeamLogo teamObject={awayTeam} /></span>
				</div>
				<div class="score">2<!--{awayTeam.Score}--></div>
			</div>
			{/if}
		</div>
	</div>
</div>
</CardBase>

<style>*,*:before,*:after {
  box-sizing:border-box;
}

.match-detail {
  font-family:var(--f7-font-family);
}

.match-detail {
  font-family:var(--f7-font-family);
}

/*start final card*/

.final-card {
  width:100%;
  padding:16px;
  background:var(--neutrals-black-black-10);
  border-radius:4px;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.final-card .title {
  width:100%;
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:center;
}

.final-card .title .icon {
  width:18px;
  height:auto;
}

.final-card .title h3 {
  margin:8px;
  color:var(--neutrals-white-100);
  font-size:16px;
  font-weight:800;
  text-align:center;
}

.final-card .matchup {
  width:100%;
  margin:16px 0 0 0;
  padding:8px;
  background-color:var(--neutrals-white-100);
  border-radius:4px;
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:center;
}

.final-card .matchup .team {
  width:50%;
  padding:8px 16px;
  display:flex;
  justify-content:flex-end;
  align-items:center;
}

.final-card .matchup .team.team-2 {
  flex-direction:row-reverse;
}

.final-card .matchup .team .name {
  color:var(--neutrals-grey-darker-60);
  font-size:24px;
  font-weight:800;
  line-height:1.2;
}

.final-card .matchup .team .logo {
  width:56px;
}

.final-card .matchup .team .score {
  color:var(--neutrals-grey-darker-60);
  font-size:32px;
  font-weight:800;
  line-height:1.2;
}

.final-card .matchup .team.team-1 .score {
  margin-left:15%;
}

.final-card .matchup .team.team-2 .score {
  margin-right:15%;
}

/*end final card*/</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { findSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import TeamLogo from "../../../utilities/TeamLogo.svelte";
export let element;
export let sources;
const eventMap = {
    'S': 'substitution',
    'Y': 'yellow-card',
    'R': 'red-card',
    'G': 'goal'
};
const eventTitleMap = {
    'S': 'Cambio',
    'F': 'Falta',
    '': '',
    'Y': 'Tarjeta Amarilla',
    'R': 'Tarjeta Rojo',
    'G': 'Goooool'
};
$: gameStateSource = findSourceStore($sources, 'YinzCamAppServerGameState');
$: gameState = (gameStateSource) ? $gameStateSource : {};
$: source = findSourceStore($sources, 'YinzCamAppServerGameBox');
$: data = (source) ? $source : {};
$: console.log('matchdetailstatus data', data);
$: console.log('gameState', gameState);
$: state = ((_a = data === null || data === void 0 ? void 0 : data.State) === null || _a === void 0 ? void 0 : _a._text) || '';
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
</script>