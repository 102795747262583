<CardBase {element}>
	<div class="stats-leaders" class:display-none={!source}>
		{#each leaders as leader (leader.id) }
			<div class="leader">
				<div class="number">#{leader.number}</div>
				<div class="player">{leader.name}</div>
				<div class="statistic">{leader.statValue} {leader.statName}</div>
			</div>
		{/each}
	</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.stats-leaders {
  width:100%;
  padding:0 12px;
  overflow:hidden;
  font-family:var(--f7-font-family);
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
}

.stats-leaders .leader {
  width:33.3%;
  margin:12px 0;
  padding:0 6px;
  color:var(--f7-theme-color);
}

.stats-leaders .leader > * {
  width:100%;
  margin:0 0 8px 0;
  text-align:center;
  line-height:1;
  display:block;
}

.stats-leaders .leader .number {
  font-size:11vw;
  font-weight:bold;
}

.stats-leaders .leader .player {
  font-size:3.5vw;
}

.stats-leaders .leader .statistic {
  font-size:3vw;
  opacity:0.8;
}</style>

<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
$: source = findSourceStore($sources, 'YinzCamAppServerStatsClubLeaders');
$: leaders = [].concat($source === null || $source === void 0 ? void 0 : $source.TopPlayer).filter(item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item._attributes) === null || _a === void 0 ? void 0 : _a.Id; }).slice(0, 3).map(item => {
    var _a;
    let playerAttrs = item === null || item === void 0 ? void 0 : item._attributes;
    let statsAttrs = (_a = item === null || item === void 0 ? void 0 : item.StatsGroup) === null || _a === void 0 ? void 0 : _a._attributes;
    return {
        id: playerAttrs === null || playerAttrs === void 0 ? void 0 : playerAttrs.Id,
        number: (playerAttrs === null || playerAttrs === void 0 ? void 0 : playerAttrs.Number) || '00',
        name: (playerAttrs === null || playerAttrs === void 0 ? void 0 : playerAttrs.Name) || '',
        statName: (statsAttrs === null || statsAttrs === void 0 ? void 0 : statsAttrs.Stat1) || '',
        statValue: (statsAttrs === null || statsAttrs === void 0 ? void 0 : statsAttrs.Stat0) || ''
    };
});
//$: goalsLeader = $source.
</script>