<CardBase {element}>
<div class="historical">
	<div class="statistic heading">
		<div class="team team-1 logo">
			<img src="https://resources-us.yinzcam.com/lfp/shared/logos/lfp_alc_light.png" alt="Team 1">
		</div>
		<div class="label">10 partidos | 2 Empates</div>
		<div class="team team-2 logo">
			<img src="https://resources-us.yinzcam.com/lfp/shared/logos/lfp_alc_light.png" alt="Team 1">
		</div>
	</div>
	<div class="statistic">
		<div class="team team-1">3</div>
		<div class="label">Vicorias</div>
		<div class="team team-2 advantage">5</div>
	</div>
	<div class="statistic">
		<div class="team team-1">3</div>
		<div class="label">Vicorias local</div>
		<div class="team team-2 advantage">4</div>
	</div>
	<div class="statistic">
		<div class="team team-1">0</div>
		<div class="label">Vicorias visitante</div>
		<div class="team team-2 advantage">1</div>
	</div>
	<div class="statistic">
		<div class="team team-1">14</div>
		<div class="label">Goles</div>
		<div class="team team-2 advantage">18</div>
	</div>
</div>
</CardBase>

<style type="text/css">*, *:before, *:after {
  box-sizing:border-box;
}

.historical {
  width:100%;
  padding:12px 6px;
  background:#f5f5f5;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
}

.statistic {
  width:100%;
  margin:10px 0;
  padding:0 6px;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items:center;
}

.statistic.heading {
}

.logo {
  width:36px;
  height:auto;
  display:block;
}

.logo img {
  width:100%;
  height:auto;
  display:block;
}

.team {
  min-width:36px;
  color:var(--neutrals-grey-lighter-20);
  font-weight:bold;
  text-align:center;
  display:block;
}

.team.advantage {
  color:var(--alav-s-primary);
}

.label {
  text-align:center;
  color: var(--neutrals-grey-darker-40);
  font-size:14px;
}</style>

<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { t } from "../../../../../js/i18n";
import { getFirstSourceStore, expandTemplateParams } from "../../../utilities";
import YCLink from "../../../utilities/YCLink.svelte";
import { Readable } from "svelte/store";
import { getContext, onMount } from "svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
export let element;
export let sources;
</script>