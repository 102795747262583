<svelte:head>
  <title>{appConfig.defaultPageTitle}</title>
  <meta name="description" content={appConfig.defaultPageDescription} />
  <meta name="keywords" content={appConfig.defaultPageKeywords} />
</svelte:head>
<F7Page name={titleText || ''} noToolbar={_noBottomNav} onPageAfterIn={onPageAfterIn} onPageBeforeOut={onPageBeforeOut}>
  <YCTopBar {titleText} {titleImageUrl} navBar={_navBar} backButton={_backButton} />
  <!-- Uses slots to let pages insert whatever content they want. See https://svelte.dev/tutorial/slots -->
  <slot></slot>
</F7Page>

<style></style>

<script lang="ts">import { Page as F7Page } from 'framework7-svelte';
import YCTopBar from './YCTopBar.svelte';
import { windowMetrics as wm } from '../js/stores';
import ROOT from '../inversify.config';
import { Config, ConfigToken } from 'yinzcam-config';
import { getToken } from 'inversify-token';
import _ from 'lodash';
import { ycSetScrollYPosition } from '../js/ycapp';
// Declaring props: https://svelte.dev/tutorial/declaring-props
export let titleText;
export let titleImageUrl;
export let navBar;
export let backButton;
export let noBottomNav;
export let query;
const appConfig = getToken(ROOT, ConfigToken);
let _navBar;
let _noBottomNav;
let _backButton;
$: {
    _navBar = true;
    if (!_.isNil(appConfig.defaultNavBarVisible)) {
        _navBar = appConfig.defaultNavBarVisible;
    }
    if (!_.isNil(navBar)) {
        _navBar = navBar;
    }
    else if (!_.isNil(query.navBar)) {
        _navBar = query.navBar === 'true';
    }
    else if ($wm.width >= 768) {
        _navBar = false;
    }
}
$: {
    _noBottomNav = false;
    if (!_.isNil(appConfig.defaultBottomNavHidden)) {
        _noBottomNav = appConfig.defaultBottomNavHidden;
    }
    if (!_.isNil(noBottomNav)) {
        _noBottomNav = noBottomNav;
    }
    else if (!_.isNil(query.noBottomNav)) {
        _noBottomNav = query.noBottomNav === 'true';
    }
    else if ($wm.width >= 768) {
        _noBottomNav = true;
    }
}
$: {
    _backButton = true;
    if (!_.isNil(backButton)) {
        _backButton = backButton;
    }
    else if (!_.isNil(query.backButton)) {
        _backButton = query.backButton === 'true';
    }
}
// TODO: MAKE THIS CONFIGURABLE ON/OFF IF IT WORKS (DEFAULT OFF)
function scrollForwarder(e) {
    ycSetScrollYPosition(e.target.scrollTop);
}
function onPageAfterIn(page) {
    var _a;
    if (!((_a = page === null || page === void 0 ? void 0 : page.view) === null || _a === void 0 ? void 0 : _a.main)) {
        return;
    }
    const contentDiv = page.$el.find('.page-content');
    contentDiv.on('scroll', scrollForwarder);
}
function onPageBeforeOut(page) {
    var _a;
    if (!((_a = page === null || page === void 0 ? void 0 : page.view) === null || _a === void 0 ? void 0 : _a.main)) {
        return;
    }
    const contentDiv = page.$el.find('.page-content');
    contentDiv.off('scroll', scrollForwarder);
}
</script>
