<script lang="ts">import ImageWithPlaceholder from '../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
export let player = {
    GameStats: []
};
</script>

<div class="card-el">
  <div class="player-popup-image-container">
    <h3>{player.Name}</h3>
    <div class="number">{player.Number}</div>
    <ImageWithPlaceholder
      src={player.ImageUrl}
      title={player.Name}
      alt="PlayerImage"
      class="player-popup-image"
      placeholder="/static/images/download.png"
    />
  </div>
  <div class="comment">Game Stats</div>
  <div class="details">
    <div class="row-1">
      {#if player.GameStats}
        {#each player.GameStats as detail (detail.Name)}
          <div class="detail-card">
            <h4>{detail.Name}</h4>
            <span>{detail.Value}</span>
          </div>
        {/each}
      {/if}
    </div>
  </div>

</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.card-el {
  position: relative;
  background: #1E2636;
  /*border-radius: 12px;*/
  width: 100%;
  height: 100%;
}

.player-popup-image-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  overflow: visible;
  height: 180px;
  background: #1E2636;
}

.player-popup-image-container .number {
  line-height: 40px;
  font-family: zuume-cut,sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #000000;
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 2;
  background: #3dc1f2;
  padding: 0 10px;
}

.player-popup-image-container :global(.player-popup-image) {
  margin: 0 0 0 0;
  height: 270px;
  width: 270px;
  position: absolute;
  bottom: 0;
}

.player-popup-image-container h3 {
  line-height: 34px;
  font-size: 70px;
  font-weight: bold;
  font-family: zuume-cut,sans-serif;
  color: #2a2e34;
  margin: 0;
  position: absolute;
  background: #3dc1f2;
  text-transform: uppercase;
  left: 0;
  top: calc(50% - 17px);
  padding: 20px;
}

.comment {
  background: #132048;
  color: #ffffff;
  text-align: center;
}

.details {
  width: 100%;
  padding: 10px;
}

.details .row-1 {
  width: 100%;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

.details .row-2 {
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  padding: 0 30px;
}

.details .detail-card {
  padding: 7px 12px;
  border-radius: 8px;
  border: solid 1px #e5e5e5;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
}

.details .detail-card h4 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.details .detail-card span {
  font-size: 30px;
  font-weight: bold;
  display: block;
}

@media (max-width: 767px) {
  .card-el {
    height: 400px;
  }

  .player-popup-image-container {
    height: 180px;
  }
}</style>
