import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";

@injectable()
export class YinzCamAppServerStatsPlayer extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;

    var recentGames = [];
    var legend = [];

  	[].concat(data?.Player?.RecentGameStats?.StatsSection?.PlayerRecentGameStats).forEach(function(value,index){
  		var obj = value?.StatsGroup?._attributes;
  		var gameStatsArray = [];
			if (obj) {
				for(var i in obj){
					if(i.indexOf('Stat') !== -1){
						gameStatsArray.push(obj[i]);
					}
				}	
			}
  		recentGames.push(gameStatsArray);
  	});
		data.Player.RecentGames = recentGames;

  	var statsGroup = data?.Player?.RecentGameStats?.StatsSection?.StatsGroup?._attributes;
		if (statsGroup) {
			for(var i in statsGroup){
				if(i.indexOf('Stat') !== -1){
					legend.push(statsGroup[i]);
				}
			}	
		}
    data.Player.RecentGamesLegend = legend;

		data.Player.PlayerSeasonStatsMap = [].concat(data?.Player?.PlayerSeasonStats?.Stat)
			.filter(s => s?._attributes?.Key)
			.reduce((acc, s) => { acc[s._attributes.Key] = s._attributes; return acc; }, {});

    return data.Player;
  }
}
