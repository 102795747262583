import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";

@injectable()
export class YinzCamAppServerCompetitionsFilter extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;

    let secondaryOptions = this.getCompetitionOptions(data);
    return { secondaryOptions };
  }
  

  getCompetitionOptions(data: any): any {
    let filter = [].concat(data?.Standings?.Filters?.Filter).find(e => e?._attributes?.QueryParameter === 'compId');
    return [].concat(filter?.FilterItems?.Items).filter(item => item?._attributes?.Id).map(item => {
      let attrs = item?._attributes;
      return {
        title: attrs?.Name,
        value: attrs?.Id
      };
    });
  }
}
