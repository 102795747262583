<Boundary>
  <Tabs>
    {#each tabs as tab, i (tab.id)}
      <Tab id={tab.id} tabActive={defaultTabIndex === i}>
        <CardsTab sequenceId={tab.id} {tab} />
      </Tab>
    {/each}
  </Tabs>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceTab } from "yinzcam-cards";
import CardsTab from "./CardsTab.svelte";
import { Tabs, Tab } from 'framework7-svelte';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
export let tabs;
const defaultTabIndex = Math.max(tabs === null || tabs === void 0 ? void 0 : tabs.findIndex((val) => val === null || val === void 0 ? void 0 : val.default), 0);
</script>
