<CardBase {element}>
	{#each commentary as item (item.id) }
	<div class="match-commentary">
		<div class="detail">
			<div class="team">
				<div class="name">{item.teamName}</div>
				<span class='logo'><TeamLogo teamObject={item.teamObject} /></span>
			</div>
			<div class="time">
				<div class="minute">{item.timeLabel}</div>
				<div class="extra-time"></div>
				<div class="full-time">{item.timeDetail}</div>
			</div>
			<div class="description">{item.title} {item.text}</div>
		</div>
	</div>			
	{/each}
</CardBase>
<style>*, *:before, *:after {
  box-sizing:border-box;
}

.match-commentary {
  width:100%;
  font-family:var(--f7-font-family);
  overflow:hidden;
}

.match-commentary .detail {
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
}

.match-commentary .detail > div {
  padding:8px;
  color:var(--f7-theme-color);
  font-size:1.1vw;
}

.match-commentary .detail .team {
  width:15%;
}

.match-commentary .detail .team .name {
  font-weight:bold;
  display:none;
}

.match-commentary .detail .team .logo {
  width:100%;
  max-width:80px;
  height:intrinsic;
  height:auto;
  margin:8px auto;
  display:block;
}

.match-commentary .detail .time {
  width:20%;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  text-align:center;
}

.match-commentary .detail .time .minute {
  font-weight:bold;
}

.match-commentary .detail .time .extra-time {
  font-weight:bold;
}

.match-commentary .detail .time .full-time {
  width:100%;
}

.match-commentary .detail .description {
  width:65%;
}

@media screen and (max-width:1001px) and (max-width:1199px){
  .match-commentary .detail > div {
    font-size:1.25vw;
  }
}

@media screen and (max-width:1000px){
  .match-commentary .detail > div {
    font-size:1.5vw;
  }
}

@media screen and (max-width:767px){
  .match-commentary .detail {
    color:#000;
  }

  .match-commentary .detail > div {
    font-size:2vw;
  }

  .match-commentary .detail .team {
    order:3;
  }

  .match-commentary .detail .time {
    width:15%;
    order:1;
  }

  .match-commentary .detail .description {
    order:2;
  }
}

@media screen and (max-width:500px){
  .match-commentary .detail > div {
    font-size:2.75vw;
  }
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
export let element;
export let sources;
$: source = findSourceStore($sources, 'YinzCamAppServerGameCommentary');
$: data = (source) ? $source : {};
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: plays = [].concat((_a = data === null || data === void 0 ? void 0 : data.Plays) === null || _a === void 0 ? void 0 : _a.Play).filter(play => { var _a; return (_a = play === null || play === void 0 ? void 0 : play._attributes) === null || _a === void 0 ? void 0 : _a.Id; });
$: commentary = plays.map(play => {
    var _a, _b;
    let attrs = play === null || play === void 0 ? void 0 : play._attributes;
    let teamObject = (((attrs === null || attrs === void 0 ? void 0 : attrs.IsHome) || 'true').toLowerCase() === 'true') ? homeTeam : awayTeam;
    return {
        id: attrs === null || attrs === void 0 ? void 0 : attrs.Id,
        //eventType: eventMap[(attrs?.Type || '').toUpperCase()],
        //timeInt: parseInt((attrs?.Time || "0'").slice(0, -1)),
        timeLabel: (attrs === null || attrs === void 0 ? void 0 : attrs.Time) || '',
        timeDetail: (attrs === null || attrs === void 0 ? void 0 : attrs.TimeDetail) || '',
        title: (attrs === null || attrs === void 0 ? void 0 : attrs.Title) || '',
        text: ((_a = play === null || play === void 0 ? void 0 : play.Text) === null || _a === void 0 ? void 0 : _a._text) || '',
        teamObject,
        teamName: ((_b = teamObject === null || teamObject === void 0 ? void 0 : teamObject._attributes) === null || _b === void 0 ? void 0 : _b.Name) || '',
        //players: [].concat(attrs?.Title || []).concat(attrs?.SubTitle || [])
    };
});
</script>