import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Logger } from 'yinzcam-log';
import { Dictionary } from 'typescript-collections';
import { ManualPassthrough, RepeatingTimer } from 'yinzcam-rma';
import { YinzCamAPIRequest } from './YinzCamAPIRequest';
import { YinzCamAPIRequestComponent } from './YinzCamAPIRequestComponent';
import { YinzCamAPIRequestPipeline } from "./YinzCamAPIRequestPipeline";
import { YCAPIREQUEST_DEFAULTS } from './defaults';
import { AppConfig } from 'yinzcam-config';
import { MergedYinzCamAPIRequestParameterComponent } from './MergedYinzCamAPIRequestParameterComponent';
import { YinzCamAPIRequestParameters } from './YinzCamAPIRequestParameters';
import { get } from 'svelte/store';
import { buildAxiosRequest } from './utilities';

export class YinzCamServer {
  public readonly league: string;
  public readonly tricode: string;
  public readonly hostname: string;
  public readonly baseUrl: URL;

  private readonly serverKey: string;
  private readonly axios: AxiosInstance;
  private readonly pipelines: Dictionary<string, YinzCamAPIRequestPipeline>;

  public constructor(private readonly log: Logger, private readonly config: AppConfig, private readonly requestParameterComponent: MergedYinzCamAPIRequestParameterComponent,
     public readonly service: string, public readonly prefix?: string) {
    this.league = config.league;
    this.tricode = config.tricode;
    let protocol;
    if (config.apiServiceOverrideEnabled && config.apiServiceOverrideUrls.hasOwnProperty(service)) {
      let overrideUrl = new URL(config.apiServiceOverrideUrls[service]);
      protocol = overrideUrl.protocol;
      this.hostname = overrideUrl.hostname + ((overrideUrl.port)? `:${overrideUrl.port}` : '');
    } else {
      protocol = 'https:';
      this.hostname = `${service}-${this.tricode}-${this.league}.yinzcam.com`;
    }
    this.baseUrl = (prefix)? new URL(prefix, new URL(`${protocol}//${this.hostname}/`)) : new URL(`${protocol}//${this.hostname}/`);
    this.axios = Axios.create({
      baseURL: this.baseUrl.toString(),
      method: 'GET',
      timeout: this.config.defaultRequestTimeoutSeconds * 1000,
      // TODO: apparently our servers (at least .NET ones) don't respect order of preference here; even if you send the below
      // and the server supports json, you'll get XML instead
      //headers: { Accept: 'application/json, application/xml, text/xml, text/*, */*' };
      // Explicitly request JSON, knowing we might get back XML anyway.
      headers: { Accept: 'application/xml' }
    });
    this.pipelines = new Dictionary<string, YinzCamAPIRequestPipeline>();
  }

  public toString(): string {
    return `YCServer[${this.serverKey}]`;
  }

  private getRequestHashKey(req: YinzCamAPIRequest): string {
    let hashKey: string = '';
    if (req.method) {
      hashKey += `__${req.method}`;
    }
    hashKey += `__${this.hostname}`;
    hashKey += `__${req.path}`;
    if (req.params) {
      for (const e of Object.getOwnPropertyNames(req.params).sort()) {
        hashKey += `__${e}=${req.params[e]}`;
      }
    }
    if (req.headers) {
      for (const e of Object.getOwnPropertyNames(req.headers).sort()) {
        hashKey += `__${e}:${req.headers[e]}`;
      }
    }
    if (req.data) {
      if (typeof req.data === 'string') {
        hashKey += `__${req.data}`;
      } else {
        hashKey += `__${JSON.stringify(req.data)}`;
      }
    }
    return hashKey;
  }

  private getRequestPipeline(req: YinzCamAPIRequest): YinzCamAPIRequestPipeline {
    req = { ...YCAPIREQUEST_DEFAULTS, ...req };
    const reqHashKey: string = this.getRequestHashKey(req);
    let pipeline = this.pipelines.getValue(reqHashKey);
    if (!pipeline) {
      this.log.debug(`creating new pipeline for ${reqHashKey}`);
      let c1 = new ManualPassthrough<number>(`timerFrequencyInput__${reqHashKey}`, this.config.defaultCacheTimeSeconds * 1000);
      let c2 = new RepeatingTimer(`autoRefreshTimer__${reqHashKey}`, 0, c1);
      let c3 = new YinzCamAPIRequestComponent(`request__${reqHashKey}`, this.config, this.axios, req, c2, this.requestParameterComponent, c1);
      pipeline = {
        timerFrequency: c1,
        autoRefreshTimer: c2,
        request: c3,
      };
      this.pipelines.setValue(reqHashKey, pipeline);
    }
    return pipeline;
  }

  public getRequest(req: YinzCamAPIRequest): YinzCamAPIRequestComponent {
    return this.getRequestPipeline(req).request;
  }

  public async singleRequest(req: YinzCamAPIRequest): Promise<AxiosResponse<any>> {
    let params: YinzCamAPIRequestParameters = get(this.requestParameterComponent.store);
    let axiosReq: AxiosRequestConfig = buildAxiosRequest(this.config, params, req);
    let rsp: AxiosResponse<any> = await this.axios.request(axiosReq);
    return rsp;
  }
}
