<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
import { getContext } from "svelte";
import { getFirstSourceStore } from "../../../utilities";
import { windowMetrics as wm } from "../../../../../js/stores";
import { findSourceStore, getHrefForMediaItem } from "../../../../../components/cards/utilities";
import StandingsSeasonTable from "./StandingsSeasonTable.svelte";
import StandingsSeasonLegend from "./StandingsSeasonLegend.svelte";
import StandingsTournamentKnockoutTable from "./StandingsTournamentKnockoutTable/StandingsTournamentKnockoutTable.svelte";
export let element;
export let sources;
export let parentAttributes;
let data;
let sourceStore;
let secondStore;
const statusClasses = {
    G: 'won',
    P: 'lost',
    E: 'drawn',
};
const competitions = {
    1: 'LaLiga Santander',
    2: 'LaLiga SmartBank',
};
const championClasses = ['standard', 'champion', 'europa', 'declined'];
const tabContext = getContext(CardsTabContextKey);
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGamePhaseList');
}
;
$: {
    secondStore = findSourceStore($sources, 'YinzCamAppServerGamePhaseList');
}
;
$: isTwoRounds = false;
$: isDesktop = $wm.width > 1024;
$: console.log("isDesktop ", isDesktop);
$: source = getFirstSourceStore($sources);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
// let params = new URLSearchParams(window.location.search);
// let compId = params.get('compId');
$: compId = $mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.compId;
$: tableRowsData = [];
$: rowsData = {};
$: rowsDataV = {};
$: {
    if (compId && (compId === "1" || compId === "2")) {
        data = (sourceStore) ? $sourceStore : {};
        let standingsData = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.Standings) === null || _b === void 0 ? void 0 : _b.Leagues) === null || _c === void 0 ? void 0 : _c.League) === null || _d === void 0 ? void 0 : _d.Standing;
        tableRowsData = standingsData === null || standingsData === void 0 ? void 0 : standingsData.map((teamStanding, index) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            let teamTriCode = (_a = teamStanding._attributes) === null || _a === void 0 ? void 0 : _a.TriCode;
            let teamLogo = "https://resourceslfp.blob.core.windows.net/lfp/shared/logos/esp_" + teamTriCode.toLowerCase() + "_dark.png";
            let teamName = (_b = teamStanding._attributes) === null || _b === void 0 ? void 0 : _b.Team;
            let puntos = (_d = (_c = teamStanding.StatsGroup) === null || _c === void 0 ? void 0 : _c._attributes) === null || _d === void 0 ? void 0 : _d.Stat3;
            let pj = (_f = (_e = teamStanding.StatsGroup) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.Stat4;
            let pe = (_h = (_g = teamStanding.StatsGroup) === null || _g === void 0 ? void 0 : _g._attributes) === null || _h === void 0 ? void 0 : _h.Stat5;
            let pg = (_k = (_j = teamStanding.StatsGroup) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.Stat6;
            let pp = (_m = (_l = teamStanding.StatsGroup) === null || _l === void 0 ? void 0 : _l._attributes) === null || _m === void 0 ? void 0 : _m.Stat7;
            let gf = (_p = (_o = teamStanding.StatsGroup) === null || _o === void 0 ? void 0 : _o._attributes) === null || _p === void 0 ? void 0 : _p.Stat8;
            let gc = (_r = (_q = teamStanding.StatsGroup) === null || _q === void 0 ? void 0 : _q._attributes) === null || _r === void 0 ? void 0 : _r.Stat9;
            let pm = (_t = (_s = teamStanding.StatsGroup) === null || _s === void 0 ? void 0 : _s._attributes) === null || _t === void 0 ? void 0 : _t.Stat9;
            let chevronCode = (_u = teamStanding._attributes) === null || _u === void 0 ? void 0 : _u.Change;
            let chevronStatus = 0;
            if (chevronCode === "U") {
                chevronStatus = 1;
            }
            else if (chevronCode === "D") {
                chevronStatus = 2;
            }
            let matchStatus = [];
            if ((_v = teamStanding === null || teamStanding === void 0 ? void 0 : teamStanding.Last) === null || _v === void 0 ? void 0 : _v.Result) {
                let results = (_w = teamStanding === null || teamStanding === void 0 ? void 0 : teamStanding.Last) === null || _w === void 0 ? void 0 : _w.Result;
                results = (Array.isArray(results) ? results : ([results] || []));
                matchStatus = (results || []).map((result) => {
                    var _a, _b, _c, _d, _e, _f, _g;
                    let homeTeam = (_a = result.HomeTeam) === null || _a === void 0 ? void 0 : _a._attributes;
                    let awayTeam = (_b = result.AwayTeam) === null || _b === void 0 ? void 0 : _b._attributes;
                    let teamLogo1 = "";
                    let teamLogo2 = "";
                    if (homeTeam) {
                        teamLogo1 = "https://resourceslfp.blob.core.windows.net/lfp/shared/logos/" + ((_c = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam.LogoId) === null || _c === void 0 ? void 0 : _c.toLowerCase()) + "_dark.png";
                    }
                    if (awayTeam) {
                        teamLogo2 = "https://resourceslfp.blob.core.windows.net/lfp/shared/logos/" + ((_d = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam.LogoId) === null || _d === void 0 ? void 0 : _d.toLowerCase()) + "_dark.png";
                    }
                    let teamName1 = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam.TriCode;
                    let teamName2 = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam.TriCode;
                    let score1 = (_e = result._attributes) === null || _e === void 0 ? void 0 : _e.HomeScore;
                    let score2 = (_f = result._attributes) === null || _f === void 0 ? void 0 : _f.AwayScore;
                    let date = (_g = result._attributes) === null || _g === void 0 ? void 0 : _g.RoundTitle;
                    let status = 'E';
                    let isHome = (teamTriCode === teamName1) ? 1 : 0;
                    if (isHome) {
                        if (score1 > score2) {
                            status = 'G';
                        }
                        else if (score2 > score1) {
                            status = 'P';
                        }
                    }
                    else {
                        if (score1 > score2) {
                            status = 'P';
                        }
                        else if (score2 > score1) {
                            status = 'G';
                        }
                    }
                    return ({
                        'teamLogo1': teamLogo1,
                        'teamName1': teamName1,
                        'teamLogo2': teamLogo2,
                        'teamName2': teamName2,
                        'score1': score1,
                        'score2': score2,
                        'date': date,
                        'status': status,
                    });
                });
            }
            let championStatus = 0;
            if (index < 4) {
                championStatus = 1;
            }
            else if (index < 7) {
                championStatus = 2;
            }
            else if (index > 16) {
                championStatus = 3;
            }
            let retObj = {
                'teamLogo': teamLogo,
                'teamShortName': teamTriCode,
                'teamName': teamName,
                'Puntos': puntos,
                'PJ': pj,
                'PG': pg,
                'PE': pe,
                'PP': pp,
                'GF': gf,
                'GC': gc,
                'PlusMinus': pm,
                'chevron': chevronStatus,
                'championStatus': championStatus,
                'matchStatus': matchStatus
            };
            return (retObj);
        });
    }
    else if (compId) {
        data = (secondStore) ? $secondStore : {};
        let gamesData = (_e = data === null || data === void 0 ? void 0 : data.ScoresArray) === null || _e === void 0 ? void 0 : _e.ScoresXml;
        let allGames = [];
        let allGamesV = [];
        if (gamesData === null || gamesData === void 0 ? void 0 : gamesData.Day) {
            isTwoRounds = false;
            gamesData = (_h = (_g = (_f = data === null || data === void 0 ? void 0 : data.ScoresArray) === null || _f === void 0 ? void 0 : _f.ScoresXml) === null || _g === void 0 ? void 0 : _g.Day) === null || _h === void 0 ? void 0 : _h.Game;
            if (!Array.isArray(gamesData)) {
                allGames = [gamesData];
            }
            else {
                allGames = gamesData;
            }
        }
        else {
            isTwoRounds = true;
            let gamesDataIda = (_l = (_k = (_j = data === null || data === void 0 ? void 0 : data.ScoresArray) === null || _j === void 0 ? void 0 : _j.ScoresXml[0]) === null || _k === void 0 ? void 0 : _k.Day) === null || _l === void 0 ? void 0 : _l.Game;
            let gamesDataVuelta = (_p = (_o = (_m = data === null || data === void 0 ? void 0 : data.ScoresArray) === null || _m === void 0 ? void 0 : _m.ScoresXml[1]) === null || _o === void 0 ? void 0 : _o.Day) === null || _p === void 0 ? void 0 : _p.Game;
            gamesData = gamesDataIda;
            if (!Array.isArray(gamesData)) {
                allGames = [gamesData];
            }
            else {
                allGames = gamesData;
            }
            if (!Array.isArray(gamesDataVuelta)) {
                allGamesV = [gamesDataVuelta];
            }
            else {
                allGamesV = gamesDataVuelta;
            }
        }
        let rawTableData = allGames === null || allGames === void 0 ? void 0 : allGames.map((game) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
            let awayTeam = (_b = (_a = game === null || game === void 0 ? void 0 : game.AwayTeam) === null || _a === void 0 ? void 0 : _a._attributes) === null || _b === void 0 ? void 0 : _b.Id;
            let homeTeam = (_d = (_c = game === null || game === void 0 ? void 0 : game.HomeTeam) === null || _c === void 0 ? void 0 : _c._attributes) === null || _d === void 0 ? void 0 : _d.Id;
            let awayTeamName = (_f = (_e = game === null || game === void 0 ? void 0 : game.AwayTeam) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.Name;
            let homeTeamName = (_h = (_g = game === null || game === void 0 ? void 0 : game.HomeTeam) === null || _g === void 0 ? void 0 : _g._attributes) === null || _h === void 0 ? void 0 : _h.Name;
            let stadium = (_j = game === null || game === void 0 ? void 0 : game.Venue) === null || _j === void 0 ? void 0 : _j._text;
            let awayTeamLogo = "";
            let homeTeamLogo = "";
            if (awayTeam) {
                awayTeamLogo = "https://resourceslfp.blob.core.windows.net/lfp/shared/logos/" + (awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam.toLowerCase()) + "_dark.png";
            }
            if (homeTeam) {
                homeTeamLogo = "https://resourceslfp.blob.core.windows.net/lfp/shared/logos/" + (homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam.toLowerCase()) + "_dark.png";
            }
            let score1 = (_l = (_k = game === null || game === void 0 ? void 0 : game.HomeTeam) === null || _k === void 0 ? void 0 : _k._attributes) === null || _l === void 0 ? void 0 : _l.Score;
            let score2 = (_o = (_m = game === null || game === void 0 ? void 0 : game.HomeTeam) === null || _m === void 0 ? void 0 : _m._attributes) === null || _o === void 0 ? void 0 : _o.Score;
            let timestamp = game === null || game === void 0 ? void 0 : game.Timestamp;
            let dateStr = "";
            let matchTime = "";
            if (timestamp) {
                const date = new Date(Date.parse(timestamp === null || timestamp === void 0 ? void 0 : timestamp._text));
                let weekDay = new Intl.DateTimeFormat('es-ES', { weekday: 'long' }).format(date);
                let month = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(date);
                let day = new Intl.DateTimeFormat('es-ES', { day: '2-digit' }).format(date);
                weekDay = ((_p = weekDay[0]) === null || _p === void 0 ? void 0 : _p.toUpperCase()) + (weekDay === null || weekDay === void 0 ? void 0 : weekDay.slice(1));
                month = ((_q = month[0]) === null || _q === void 0 ? void 0 : _q.toUpperCase()) + (month === null || month === void 0 ? void 0 : month.slice(1));
                dateStr = (weekDay || "") + " " + (day || "") + " " + (month || "");
                matchTime = new Intl.DateTimeFormat('default', { hour: '2-digit', minute: '2-digit' }).format(date);
            }
            let retObj = {
                'team2': awayTeamName,
                'team1': homeTeamName,
                'teamLogo2': awayTeamLogo,
                'teamLogo1': homeTeamLogo,
                'stadium': stadium,
                'place': " ",
                'score1': score1,
                'score2': score2,
                'dateStr': dateStr,
                'matchTime': matchTime
            };
            return (retObj);
        });
        rowsData = _.groupBy(rawTableData, rawTableData => rawTableData.dateStr);
        if (isTwoRounds === true) {
            let rawTableDataV = allGamesV === null || allGamesV === void 0 ? void 0 : allGamesV.map((game) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                let awayTeam = (_b = (_a = game === null || game === void 0 ? void 0 : game.AwayTeam) === null || _a === void 0 ? void 0 : _a._attributes) === null || _b === void 0 ? void 0 : _b.Id;
                let homeTeam = (_d = (_c = game === null || game === void 0 ? void 0 : game.HomeTeam) === null || _c === void 0 ? void 0 : _c._attributes) === null || _d === void 0 ? void 0 : _d.Id;
                let awayTeamName = (_f = (_e = game === null || game === void 0 ? void 0 : game.AwayTeam) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.Name;
                let homeTeamName = (_h = (_g = game === null || game === void 0 ? void 0 : game.HomeTeam) === null || _g === void 0 ? void 0 : _g._attributes) === null || _h === void 0 ? void 0 : _h.Name;
                let stadium = (_j = game === null || game === void 0 ? void 0 : game.Venue) === null || _j === void 0 ? void 0 : _j._text;
                let awayTeamLogo = "";
                let homeTeamLogo = "";
                if (awayTeam) {
                    awayTeamLogo = "https://resourceslfp.blob.core.windows.net/lfp/shared/logos/" + (awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam.toLowerCase()) + "_dark.png";
                }
                if (homeTeam) {
                    homeTeamLogo = "https://resourceslfp.blob.core.windows.net/lfp/shared/logos/" + (homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam.toLowerCase()) + "_dark.png";
                }
                let score1 = (_l = (_k = game === null || game === void 0 ? void 0 : game.HomeTeam) === null || _k === void 0 ? void 0 : _k._attributes) === null || _l === void 0 ? void 0 : _l.Score;
                let score2 = (_o = (_m = game === null || game === void 0 ? void 0 : game.HomeTeam) === null || _m === void 0 ? void 0 : _m._attributes) === null || _o === void 0 ? void 0 : _o.Score;
                let timestamp = game === null || game === void 0 ? void 0 : game.Timestamp;
                let dateStr = "";
                let matchTime = "";
                if (timestamp) {
                    const date = new Date(Date.parse(timestamp === null || timestamp === void 0 ? void 0 : timestamp._text));
                    let weekDay = new Intl.DateTimeFormat('es-ES', { weekday: 'long' }).format(date);
                    let month = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(date);
                    let day = new Intl.DateTimeFormat('es-ES', { day: '2-digit' }).format(date);
                    weekDay = ((_p = weekDay[0]) === null || _p === void 0 ? void 0 : _p.toUpperCase()) + (weekDay === null || weekDay === void 0 ? void 0 : weekDay.slice(1));
                    month = ((_q = month[0]) === null || _q === void 0 ? void 0 : _q.toUpperCase()) + (month === null || month === void 0 ? void 0 : month.slice(1));
                    dateStr = (weekDay || "") + " " + (day || "") + " " + (month || "");
                    matchTime = new Intl.DateTimeFormat('default', { hour: '2-digit', minute: '2-digit' }).format(date);
                }
                let retObj = {
                    'team2': awayTeamName,
                    'team1': homeTeamName,
                    'teamLogo2': awayTeamLogo,
                    'teamLogo1': homeTeamLogo,
                    'stadium': stadium,
                    'place': " ",
                    'score1': score1,
                    'score2': score2,
                    'dateStr': dateStr,
                    'matchTime': matchTime
                };
                return (retObj);
            });
            rowsDataV = _.groupBy(rawTableDataV, rawTableDataV => rawTableDataV.dateStr);
        }
    }
}
$: tableData = tableRowsData || [];
$: otherData = rowsData || {};
$: otherDataV = rowsDataV || {};
</script>

<CardBase {element}>
    {#if compId && (compId==='1' || compId==='2')}
        <StandingsSeasonTable {tableData} {element} {sources} />
        {#if isDesktop}
            <StandingsSeasonLegend />
        {/if}
    {:else if (compId && compId==='3')}
        <StandingsTournamentKnockoutTable tableData={otherData} tableDataV={otherDataV} isTwoRounds={isTwoRounds}/>
    {/if}
</CardBase>
