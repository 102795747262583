<Boundary>
  <div class="yinzcam-cards-sections">
    {#each sections as section, i (section.id)}
      <CardsSection sequenceId={generateSequenceId(parentSequenceId, i)} {section} />
    {/each}
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceSection } from "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsSection from "./CardsSection.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
export let parentSequenceId = "";
export let sections;
</script>
