<CardBase {element}>
<div class="matchup {orientation}">
	<div class="team team-home">
		<img src="https://resources-us.yinzcam.com/lfp/shared/logos/lfp_{homeTriCode}_dark.png" alt="" class="logo">
		<div class="team-detail">
			<span class="team-name">{homeName}</span>
			<span class="team-formation">{homeFormationName?homeFormationName:''}</span>
		</div>
	</div>
	<div class="team team-away">
		<img src="https://resources-us.yinzcam.com/lfp/shared/logos/lfp_{awayTriCode}_dark.png" alt="" class="logo">
		<div class="team-detail">
			<span class="team-name">{awayName}</span>
			<span class="team-formation">{awayFormationName?awayFormationName:''}</span>
		</div>
	</div>
</div>

<div class="pitch {orientation}" class:no-lineups={!hasFormations}>
	<div class="team team-home formation-{homeFormationName?homeFormationName:''}" style="background-image:url(https://resources-us.yinzcam.com/csf/shared/logos/CSF_{homeTriCode}.png)">
		{#if Array.isArray(homeFormation) && homeFormation.length >= 11 }
		<div class="keeper">
			<div class="player" class:yellow-card="{homeFormation[0]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[0]._attributes.RedCards !== '0'}" class:goal="{homeFormation[0]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[0].ImageUrl._text || playerThumbnail} alt="{homeFormation[0]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[0]._attributes.Number}</span>
				<span class="player-name">{homeFormation[0]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{homeFormation[1]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[1]._attributes.RedCards !== '0'}" class:goal="{homeFormation[1]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[1].ImageUrl._text || playerThumbnail} alt="{homeFormation[1]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[1]._attributes.Number}</span>
				<span class="player-name">{homeFormation[1]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{homeFormation[2]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[2]._attributes.RedCards !== '0'}" class:goal="{homeFormation[2]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[2].ImageUrl._text || playerThumbnail} alt="{homeFormation[2]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[2]._attributes.Number}</span>
				<span class="player-name">{homeFormation[2]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{homeFormation[3]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[3]._attributes.RedCards !== '0'}" class:goal="{homeFormation[3]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[3].ImageUrl._text || playerThumbnail} alt="{homeFormation[3]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[3]._attributes.Number}</span>
				<span class="player-name">{homeFormation[3]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{homeFormation[4]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[4]._attributes.RedCards !== '0'}" class:goal="{homeFormation[4]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[4].ImageUrl._text || playerThumbnail} alt="{homeFormation[4]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[4]._attributes.Number}</span>
				<span class="player-name">{homeFormation[4]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{homeFormation[5]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[5]._attributes.RedCards !== '0'}" class:goal="{homeFormation[5]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[5].ImageUrl._text || playerThumbnail} alt="{homeFormation[5]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[5]._attributes.Number}</span>
				<span class="player-name">{homeFormation[5]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{homeFormation[6]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[6]._attributes.RedCards !== '0'}" class:goal="{homeFormation[6]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[6].ImageUrl._text || playerThumbnail} alt="{homeFormation[6]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[6]._attributes.Number}</span>
				<span class="player-name">{homeFormation[6]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{homeFormation[7]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[7]._attributes.RedCards !== '0'}" class:goal="{homeFormation[7]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[7].ImageUrl._text || playerThumbnail} alt="{homeFormation[7]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[7]._attributes.Number}</span>
				<span class="player-name">{homeFormation[7]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{homeFormation[8]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[8]._attributes.RedCards !== '0'}" class:goal="{homeFormation[8]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[8].ImageUrl._text || playerThumbnail} alt="{homeFormation[8]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[8]._attributes.Number}</span>
				<span class="player-name">{homeFormation[8]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{homeFormation[9]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[9]._attributes.RedCards !== '0'}" class:goal="{homeFormation[9]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[9].ImageUrl._text || playerThumbnail} alt="{homeFormation[9]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[9]._attributes.Number}</span>
				<span class="player-name">{homeFormation[9]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{homeFormation[10]._attributes.YellowCards !== '0'}" class:red-card="{homeFormation[10]._attributes.RedCards !== '0'}" class:goal="{homeFormation[10]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={homeFormation[10].ImageUrl._text || playerThumbnail} alt="{homeFormation[10]._attributes.Name}">
				</span>
				<span class="player-number">{homeFormation[10]._attributes.Number}</span>
				<span class="player-name">{homeFormation[10]._attributes.Name}</span>
			</div>
		</div>
		{/if}
	</div>
	<div class="team team-away formation-{awayFormationName?awayFormationName:''}" style="background-image:url(https://resources-us.yinzcam.com/csf/shared/logos/CSF_{awayTriCode}.png)">
		{#if Array.isArray(awayFormation) && awayFormation.length >= 11 }
		<div class="keeper">
			<div class="player" class:yellow-card="{awayFormation[0]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[0]._attributes.RedCards !== '0'}" class:goal="{awayFormation[0]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[0].ImageUrl._text || playerThumbnail} alt="{awayFormation[0]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[0]._attributes.Number}</span>
				<span class="player-name">{awayFormation[0]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{awayFormation[1]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[1]._attributes.RedCards !== '0'}" class:goal="{awayFormation[1]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[1].ImageUrl._text || playerThumbnail} alt="{awayFormation[1]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[1]._attributes.Number}</span>
				<span class="player-name">{awayFormation[1]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{awayFormation[2]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[2]._attributes.RedCards !== '0'}" class:goal="{awayFormation[2]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[2].ImageUrl._text || playerThumbnail} alt="{awayFormation[2]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[2]._attributes.Number}</span>
				<span class="player-name">{awayFormation[2]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{awayFormation[3]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[3]._attributes.RedCards !== '0'}" class:goal="{awayFormation[3]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[3].ImageUrl._text || playerThumbnail} alt="{awayFormation[3]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[3]._attributes.Number}</span>
				<span class="player-name">{awayFormation[3]._attributes.Name}</span>
			</div>
		</div>
		<div class="defender">
			<div class="player" class:yellow-card="{awayFormation[4]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[4]._attributes.RedCards !== '0'}" class:goal="{awayFormation[4]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[4].ImageUrl._text || playerThumbnail} alt="{awayFormation[4]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[4]._attributes.Number}</span>
				<span class="player-name">{awayFormation[4]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{awayFormation[5]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[5]._attributes.RedCards !== '0'}" class:goal="{awayFormation[5]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[5].ImageUrl._text || playerThumbnail} alt="{awayFormation[5]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[5]._attributes.Number}</span>
				<span class="player-name">{awayFormation[5]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{awayFormation[6]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[6]._attributes.RedCards !== '0'}" class:goal="{awayFormation[6]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[6].ImageUrl._text || playerThumbnail} alt="{awayFormation[6]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[6]._attributes.Number}</span>
				<span class="player-name">{awayFormation[6]._attributes.Name}</span>
			</div>
		</div>
		<div class="midfielder">
			<div class="player" class:yellow-card="{awayFormation[7]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[7]._attributes.RedCards !== '0'}" class:goal="{awayFormation[7]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[7].ImageUrl._text || playerThumbnail} alt="{awayFormation[7]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[7]._attributes.Number}</span>
				<span class="player-name">{awayFormation[7]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{awayFormation[8]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[8]._attributes.RedCards !== '0'}" class:goal="{awayFormation[8]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[8].ImageUrl._text || playerThumbnail} alt="{awayFormation[8]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[8]._attributes.Number}</span>
				<span class="player-name">{awayFormation[8]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{awayFormation[9]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[9]._attributes.RedCards !== '0'}" class:goal="{awayFormation[9]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[9].ImageUrl._text || playerThumbnail} alt="{awayFormation[9]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[9]._attributes.Number}</span>
				<span class="player-name">{awayFormation[9]._attributes.Name}</span>
			</div>
		</div>
		<div class="attacker">
			<div class="player" class:yellow-card="{awayFormation[10]._attributes.YellowCards !== '0'}" class:red-card="{awayFormation[10]._attributes.RedCards !== '0'}" class:goal="{awayFormation[10]._attributes.Goals !== '0'}">
				<span class="container">
					<img src={awayFormation[10].ImageUrl._text || playerThumbnail} alt="{awayFormation[10]._attributes.Name}">
				</span>
				<span class="player-number">{awayFormation[10]._attributes.Number}</span>
				<span class="player-name">{awayFormation[10]._attributes.Name}</span>
			</div>
		</div>
		{/if}
	</div>
	<div class="notification-box">Todavía no se conocen las alineaciones. Estarán disponibles aproximadamente una hora antes del partido</div>
</div>
<div class="matchup {orientation}">
	<div class="team team-home">
		<img src="https://resources-us.yinzcam.com/lfp/shared/logos/lfp_{homeTriCode}_dark.png" alt="" class="logo">
		<div class="team-detail">
			<span class="team-name">{homeName}</span>
			<span class="team-formation">{homeFormationName?homeFormationName:''}</span>
		</div>
	</div>
	<div class="team team-away">
		<img src="https://resources-us.yinzcam.com/lfp/shared/logos/lfp_{awayTriCode}_dark.png" alt="" class="logo">
		<div class="team-detail">
			<span class="team-name">{awayName}</span>
			<span class="team-formation">{awayFormationName?awayFormationName:''}</span>
		</div>
	</div>
</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.matchup {
  width:100%;
  padding:14px 12px;
  background:#13c57d;
  display:flex;
}

.matchup.horizontal .team {
  width:50%;
}

.matchup.horizontal:last-child {
  display:none;
}

.matchup.vertical .team {
  width:100%;
}

.matchup.vertical:first-child .team:first-child {
  width:100%;
}

.matchup.vertical:first-child .team:nth-child(2), .matchup.vertical:last-child .team:nth-child(1) {
  display:none;
}

.matchup .team {
  display:flex;
  align-items:center;
}

.matchup .team + .team {
  flex-direction:row-reverse;
}

.matchup .logo {
  width:40px;
  height:auto;
  display:block;
}

.matchup .team-detail {
  margin:0 0 0 8px;
  display:flex;
  flex-wrap:wrap;
}

.matchup .team + .team .team-detail {
  margin:0 8px 0 0;
  text-align:right;
}

.matchup .team-name, .matchup .team-formation {
  width:100%;
  font-size:10px;
}

.matchup .team-name {
  color:var(--neutrals-black-black-30);
  font-weight:800;
}

.matchup .team-formation {
}

.pitch {
  background-repeat:no-repeat, repeat;
  background-size:96%, 50px;
  background-position:center, center;
  font-family:var(--f7-font-family);
  position:relative;
}

.pitch.vertical {
  width:100%;
  padding:175% 0 0 0;
  background-image:url(./images/data-viz_field-lines-vertical.svg), url(./images/data-viz_grass-texture-vertical.jpg);
  background-size:102%,1px;
  overflow:hidden;
}

.pitch .team {
  display:flex;
  flex-wrap:wrap;
  position:absolute;
  z-index:2;
}

.pitch .team {
  background-size:0;
  background-repeat:no-repeat;
}

.pitch .team > div {
  display:flex;
  justify-content:center;
}

.pitch .team .player span.container {
  width:100%;
  max-width:40px;
  margin:0 auto;
  padding:4px;
  text-align:center;
  display:block;
}

.pitch .team .player .player-number {
  position:relative;
}

.pitch .team .player span.container:before {
  position:absolute;
  top:0;
  right:0;
}

.pitch .team .player span.container:after {
  position:absolute;
  bottom:2px;
  right:2px;
}

.pitch .team .player.goal span.container:after {
  width:14px;
  height:14px;
  background-image:url('./images/icon_goal.svg');
  border-radius:50%;
  content:"";
}

.pitch .team .player.yellow-card span.container:before {
  width:14px;
  height:14px;
  background-image:url('./images/icon_yellow-card.svg');
  border-radius:50%;
  content:"";
}

.pitch .team .player.red-card span.container:before {
  width:14px;
  height:14px;
  background-image:url('./images/icon_red-card.svg');
  border-radius:50%;
  content:"";
}

/*other icons that need to be implemented*/

/*.pitch .team .player.goal .icon {
		background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_goal.svg);
	}

	.pitch .team .player.yellow-card .icon {
		background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_yellow-card.svg);
	}

	.pitch .team .player.red-card .icon {
		background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_red-card.svg);
	}

	.pitch .team .player.substitution .icon {
		background-color:transparent !important;
		background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_substitution.png);
	}

	.pitch .team .player.expulsion .icon {
		background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_expulsion.svg);
	}

	.pitch .team .player.offsides .icon {
		background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_offsides.png);
	}*/

.pitch .team .player img {
  width:50%;
  max-width:64px;
  height:intrinsic;
  height:auto;
  background-color:#FFF;
  border-radius:50%;
  box-shadow:0 3px 6px rgba(0,0,0,0.1);
}

.pitch .team .player span {
  padding:0 2px;
  display:block;
  color:#000;
  font-family:500;
  font-size:10px;
}

.pitch .team .player .player-name {
  text-align:center;
}

.pitch .team .player .player-number {
}

.pitch .team:nth-child(1) .player-number {
  background:#000;
  color:#FFF;
}

.pitch .team:nth-child(2) .player-number {
  background:#FFF;
  color:#000;
}

.pitch.vertical .team {
  width:100%;
  height:50%;
  justify-content:center;
  align-items:center;
}

.pitch.vertical .team > div {
  height:20%;
}

.pitch.vertical .team .player img {
  /*width:auto;
		height:100%;*/
}

.pitch.vertical .team:nth-child(1) {
  top:0;
}

.pitch.vertical .team:nth-child(2) {
  bottom:0;
}

.pitch .team:nth-child(2) > div:nth-child(11) {
  order:1;
}

.pitch .team:nth-child(2) > div:nth-child(10) {
  order:2;
}

.pitch .team:nth-child(2) > div:nth-child(9) {
  order:3;
}

.pitch .team:nth-child(2) > div:nth-child(8) {
  order:4;
}

.pitch .team:nth-child(2) > div:nth-child(7) {
  order:5;
}

.pitch .team:nth-child(2) > div:nth-child(6) {
  order:6;
}

.pitch .team:nth-child(2) > div:nth-child(5) {
  order:7;
}

.pitch .team:nth-child(2) > div:nth-child(4) {
  order:8;
}

.pitch .team:nth-child(2) > div:nth-child(3) {
  order:9;
}

.pitch .team:nth-child(2) > div:nth-child(2) {
  order:10;
}

.pitch .team:nth-child(2) > div:nth-child(1) {
  order:11;
}

.pitch .notification-box {
  width:65%;
  margin:0 auto;
  padding:16px;
  background:rgba(9,10,8,0.8);
  border-radius:4px;
  color:#FFF;
  font-size:15px;
  line-height:1.4;
  text-align:center;
  display:none;
  position:absolute;
  top:33.3%;
  left:17.5%;
  z-index:10;
}

.pitch.no-lineups .notification-box {
  display:flex;
}

.pitch.vertical .team .keeper {
  width:100%;
}

.pitch.vertical .team .defender {
  width:25%;
}

.pitch.vertical .team .midfielder, .pitch.vertical .team .attacker {
  width:33.3%;
}

.pitch.vertical .team .player span.container {
  position:relative;
}

.pitch.vertical .team .player img {
  width:32px;
  height:32px;
  border:solid 1px #FFF;
  border-radius:16px;
}

.pitch.vertical .team .player-number {
  width:32px;
  height:32px;
  margin:2px auto;
  padding:8px 2px;
  border-radius:16px;
  font-size:16px;
  font-weight:bold;
  line-height:1;
  text-align:center;
  order:1;
  display:none;
}

.pitch.vertical .team .player-name {
  order:2;
}

.pitch.vertical .team .player .player-number:before {
  font-size:6px;
}

.pitch.vertical .team .player .player-number:after {
  font-size:8px;
}

/*vertical 3-1-4-2*/

.pitch.vertical .team.formation-3-1-4-2 .defender:nth-child(2), .pitch.vertical .team.formation-3-1-4-2 .defender:nth-child(3), .pitch.vertical .team.formation-3-1-4-2 .defender:nth-child(4) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-1-4-2 .defender:nth-child(5) {
  width:100% !important;
}

.pitch.vertical .team.formation-3-1-4-2 .midfielder:nth-child(6), .pitch.vertical .team.formation-3-1-4-2 .midfielder:nth-child(7), .pitch.vertical .team.formation-3-1-4-2 .midfielder:nth-child(8), .pitch.vertical .team.formation-3-1-4-2 .attacker:nth-child(9) {
  width:25%;
}

.pitch.vertical .team.formation-3-1-4-2 .attacker:nth-child(10), .pitch.vertical .team.formation-3-1-4-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 3-2-4-1*/

.pitch.vertical .team.formation-3-2-4-1 .defender:nth-child(2), .pitch.vertical .team.formation-3-2-4-1 .defender:nth-child(3), .pitch.vertical .team.formation-3-2-4-1 .defender:nth-child(4) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-2-4-1 .defender:nth-child(5), .pitch.vertical .team.formation-3-2-4-1 .midfielder:nth-child(6) {
  width:50% !important;
}

.pitch.vertical .team.formation-3-2-4-1 .midfielder:nth-child(7), .pitch.vertical .team.formation-3-2-4-1 .midfielder:nth-child(8), .pitch.vertical .team.formation-3-2-4-1 .attacker:nth-child(9), .pitch.vertical .team.formation-3-2-4-1 .attacker:nth-child(10) {
  width:25%;
}

.pitch.vertical .team.formation-3-2-4-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 3-4-1-2*/

.pitch.vertical .team.formation-3-4-1-2 .defender:nth-child(2), .pitch.vertical .team.formation-3-4-1-2 .defender:nth-child(3), .pitch.vertical .team.formation-3-4-1-2 .defender:nth-child(4) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-4-1-2 .defender:nth-child(5), .pitch.vertical .team.formation-3-4-1-2 .midfielder {
  width:25% !important;
}

.pitch.vertical .team.formation-3-4-1-2 .attacker:nth-child(9) {
  width:100% !important;
}

.pitch.vertical .team.formation-3-4-1-2 .attacker:nth-child(10), .pitch.vertical .team.formation-3-4-1-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 3-4-2-1*/

.pitch.vertical .team.formation-3-4-2-1 .defender:nth-child(2), .pitch.vertical .team.formation-3-4-2-1 .defender:nth-child(3), .pitch.vertical .team.formation-3-4-2-1 .defender:nth-child(4) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-4-2-1 .defender:nth-child(5), .pitch.vertical .team.formation-3-4-2-1 .midfielder {
  width:25% !important;
}

.pitch.vertical .team.formation-3-4-2-1 .attacker:nth-child(9), .pitch.vertical .team.formation-3-4-2-1 .attacker:nth-child(10) {
  width:50% !important;
}

.pitch.vertical .team.formation-3-4-2-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 4-1-2-1-2*/

.pitch.vertical .team.formation-4-1-2-1-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-1-2-1-2 .midfielder:nth-child(6) {
  width:100% !important;
}

.pitch.vertical .team.formation-4-1-2-1-2 .midfielder:nth-child(7), .pitch.vertical .team.formation-4-1-2-1-2 .midfielder:nth-child(8) {
  width:50% !important;
}

.pitch.vertical .team.formation-4-1-2-1-2 .attacker:nth-child(9) {
  width:100% !important;
}

.pitch.vertical .team.formation-4-1-2-1-2 .attacker:nth-child(10), .pitch.vertical .team.formation-4-1-2-1-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 4-1-3-2*/

.pitch.vertical .team.formation-4-1-3-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-1-3-2 .midfielder:nth-child(6) {
  width:100% !important;
}

.pitch.vertical .team.formation-4-1-3-2 .midfielder:nth-child(7), .pitch.vertical .team.formation-4-1-3-2 .midfielder:nth-child(8), .pitch.vertical .team.formation-4-1-3-2 .attacker:nth-child(9) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-4-1-3-2 .attacker:nth-child(10), .pitch.vertical .team.formation-4-1-3-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 4-1-4-1*/

.pitch.vertical .team.formation-4-1-4-1 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-1-4-1 .midfielder:nth-child(6) {
  width:100% !important;
}

.pitch.vertical .team.formation-4-1-4-1 .midfielder:nth-child(7), .pitch.vertical .team.formation-4-1-4-1 .midfielder:nth-child(8), .pitch.vertical .team.formation-4-1-4-1 .attacker:nth-child(9), .pitch.vertical .team.formation-4-1-4-1 .attacker:nth-child(10) {
  width:25% !important;
}

.pitch.vertical .team.formation-4-1-4-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 4-2-2-2*/

.pitch.vertical .team.formation-4-2-2-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-2-2-2 .midfielder, .pitch.vertical .team.formation-4-2-2-2 .attacker {
  width:50% !important;
}

/*vertical 4-3-1-2*/

.pitch.vertical .team.formation-4-3-1-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-3-1-2 .midfielder {
  width:33.3% !important;
}

.pitch.vertical .team.formation-4-3-1-2 .attacker:nth-child(9) {
  width:100%;
}

.pitch.vertical .team.formation-4-3-1-2 .attacker:nth-child(10), .pitch.vertical .team.formation-4-3-1-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 4-3-3*/

.pitch.vertical .team.formation-4-3-3 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-3-3 .midfielder, .pitch.vertical .team.formation-4-3-3 .attacker {
  width:33.3% !important;
}

/*vertical 4-4-1-1*/

.pitch.vertical .team.formation-4-4-1-1 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-4-1-1 .midfielder, .pitch.vertical .team.formation-4-4-1-1 .attacker:nth-child(9) {
  width:25% !important;
}

.pitch.vertical .team.formation-4-4-1-1 .attacker:nth-child(10), .pitch.vertical .team.formation-4-4-1-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 4-4-2*/

.pitch.vertical .team.formation-4-4-2 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-4-2 .midfielder, .pitch.vertical .team.formation-4-4-2 .attacker:nth-child(9) {
  width:25% !important;
}

.pitch.vertical .team.formation-4-4-2 .attacker:nth-child(10), .pitch.vertical .team.formation-4-4-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 4-5-1*/

.pitch.vertical .team.formation-4-5-1 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-5-1 .midfielder, .pitch.vertical .team.formation-4-5-1 .attacker:nth-child(9), .pitch.vertical .team.formation-4-5-1 .attacker:nth-child(10) {
  width:20% !important;
}

.pitch.vertical .team.formation-4-5-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 5-4-1*/

.pitch.vertical .team.formation-5-4-1 .midfielder:nth-child(6), .pitch.vertical .team.formation-5-4-1 .defender {
  width:20% !important;
}

.pitch.vertical .team.formation-5-4-1 .midfielder, .pitch.vertical .team.formation-5-4-1 .attacker:nth-child(9), .pitch.vertical .team.formation-5-4-1 .attacker:nth-child(10) {
  width:25% !important;
}

.pitch.vertical .team.formation-5-4-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 4-2-3-1*/

.pitch.vertical .team.formation-4-2-3-1 .defender {
  width:25% !important;
}

.pitch.vertical .team.formation-4-2-3-1 .midfielder:nth-child(6), .pitch.vertical .team.formation-4-2-3-1 .midfielder:nth-child(7) {
  width:50% !important;
}

.pitch.vertical .team.formation-4-2-3-1 .midfielder:nth-child(8), .pitch.vertical .team.formation-4-2-3-1 .attacker:nth-child(9), .pitch.vertical .team.formation-4-2-3-1 .attacker:nth-child(10) {
  width:33.3%;
}

.pitch.vertical .team.formation-4-2-3-1 .midfielder, .pitch.vertical .team.formation-4-2-3-1 .attacker:nth-child(9), .pitch.vertical .team.formation-4-2-3-1 .attacker:nth-child(10) {
  width:25% !important;
}

.pitch.vertical .team.formation-4-2-3-1 .attacker:last-child {
  width:100% !important;
}

/*vertical 5-3-2*/

.pitch.vertical .team.formation-5-3-2 .midfielder:nth-child(6), .pitch.vertical .team.formation-5-3-2 .defender {
  width:20% !important;
}

.pitch.vertical .team.formation-5-3-2 .midfielder, .pitch.vertical .team.formation-5-3-2 .attacker:nth-child(9) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-5-3-2 .attacker:nth-child(10), .pitch.vertical .team.formation-5-3-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 3-5-2*/

.pitch.vertical .team.formation-3-5-2 .defender:not(:nth-child(5)) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-5-2 .defender:nth-child(5), .pitch.vertical .team.formation-3-5-2 .midfielder, .pitch.vertical .team.formation-3-5-2 .attacker:nth-child(9) {
  width:20% !important;
}

.pitch.vertical .team.formation-3-5-2 .attacker:nth-child(10), .pitch.vertical .team.formation-3-5-2 .attacker:last-child {
  width:50% !important;
}

/*vertical 3-4-3*/

.pitch.vertical .team.formation-3-4-3 .defender:not(:nth-child(5)) {
  width:33.3% !important;
}

.pitch.vertical .team.formation-3-4-3 .defender:nth-child(5), .pitch.vertical .team.formation-3-4-3 .midfielder {
  width:20% !important;
}

.pitch.vertical .team.formation-3-4-3 .attacker {
  width:33.3% !important;
}

/*--start horizontal pitch*/

.pitch.horizontal {
  padding:78% 0 0 0;
  background-image:url(./images/data-viz_field-lines-horizontal.svg), url(./images/data-viz_grass-texture-horizontal.jpg);
  background-size:96%,100%;
}

.pitch.horizontal .team {
  width:50%;
  height:100%;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  top:0;
}

.pitch.horizontal .team:nth-child(1) {
  left:0;
}

.pitch.horizontal .team:nth-child(2) {
  right:0;
}

.pitch.horizontal .team > div {
  width:20%;
  align-items:center;
}

.pitch.horizontal .team .keeper {
  height:100%;
}

.pitch.horizontal .team .defender {
  height:25%;
}

.pitch.horizontal .team .midfielder, .pitch.horizontal .team .attacker {
  height:33.3%;
}

.pitch.horizontal .team .player img {
  width:32px;
  height:32px;
  border:solid 1px #FFF;
  border-radius:50%;
}

.pitch.horizontal .team .player-name {
  display:none;
}

.pitch.horizontal .team .player-number {
  width:24px;
  height:24px;
  padding:7px 0;
  border-radius:12px;
  font-size:10px;
  font-weight:bold;
  line-height:1;
  text-align:center;
  display:none;
}

.pitch.horizontal .team .player span.container {
  position:relative;
}

.pitch.horizontal .team .player span.container:after {
  bottom:0;
  right:0;
}

/*horizontal 3-1-4-2*/

.pitch.horizontal .team.formation-3-1-4-2 .defender:nth-child(2), .pitch.horizontal .team.formation-3-1-4-2 .defender:nth-child(3), .pitch.horizontal .team.formation-3-1-4-2 .defender:nth-child(4) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-1-4-2 .defender:nth-child(5) {
  height:100% !important;
}

.pitch.horizontal .team.formation-3-1-4-2 .midfielder:nth-child(6), .pitch.horizontal .team.formation-3-1-4-2 .midfielder:nth-child(7), .pitch.horizontal .team.formation-3-1-4-2 .midfielder:nth-child(8), .pitch.horizontal .team.formation-3-1-4-2 .attacker:nth-child(9) {
  height:25%;
}

.pitch.horizontal .team.formation-3-1-4-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-3-1-4-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 3-2-4-1*/

.pitch.horizontal .team.formation-3-2-4-1 .defender:nth-child(2), .pitch.horizontal .team.formation-3-2-4-1 .defender:nth-child(3), .pitch.horizontal .team.formation-3-2-4-1 .defender:nth-child(4) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-2-4-1 .defender:nth-child(5), .pitch.horizontal .team.formation-3-2-4-1 .midfielder:nth-child(6) {
  height:50% !important;
}

.pitch.horizontal .team.formation-3-2-4-1 .midfielder:nth-child(7), .pitch.horizontal .team.formation-3-2-4-1 .midfielder:nth-child(8), .pitch.horizontal .team.formation-3-2-4-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-3-2-4-1 .attacker:nth-child(10) {
  height:25%;
}

.pitch.horizontal .team.formation-3-2-4-1 .attacker:last-child {
  height:100% !important;
}

/*horizontal 3-4-1-2*/

.pitch.horizontal .team.formation-3-4-1-2 .defender:nth-child(2), .pitch.horizontal .team.formation-3-4-1-2 .defender:nth-child(3), .pitch.horizontal .team.formation-3-4-1-2 .defender:nth-child(4) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-4-1-2 .defender:nth-child(5), .pitch.horizontal .team.formation-3-4-1-2 .midfielder {
  height:25% !important;
}

.pitch.horizontal .team.formation-3-4-1-2 .attacker:nth-child(9) {
  height:100% !important;
}

.pitch.horizontal .team.formation-3-4-1-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-3-4-1-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 3-4-2-1*/

.pitch.horizontal .team.formation-3-4-2-1 .defender:nth-child(2), .pitch.horizontal .team.formation-3-4-2-1 .defender:nth-child(3), .pitch.horizontal .team.formation-3-4-2-1 .defender:nth-child(4) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-4-2-1 .defender:nth-child(5), .pitch.horizontal .team.formation-3-4-2-1 .midfielder {
  height:25% !important;
}

.pitch.horizontal .team.formation-3-4-2-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-3-4-2-1 .attacker:nth-child(10) {
  height:50% !important;
}

.pitch.horizontal .team.formation-3-4-2-1 .attacker:last-child {
  height:100% !important;
}

/*horizontal 4-1-2-1-2*/

.pitch.horizontal .team.formation-4-1-2-1-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-1-2-1-2 .midfielder:nth-child(6) {
  height:100% !important;
}

.pitch.horizontal .team.formation-4-1-2-1-2 .midfielder:nth-child(7), .pitch.horizontal .team.formation-4-1-2-1-2 .midfielder:nth-child(8) {
  height:50% !important;
}

.pitch.horizontal .team.formation-4-1-2-1-2 .attacker:nth-child(9) {
  height:100% !important;
}

.pitch.horizontal .team.formation-4-1-2-1-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-1-2-1-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 4-1-3-2*/

.pitch.horizontal .team.formation-4-1-3-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-1-3-2 .midfielder:nth-child(6) {
  height:100% !important;
}

.pitch.horizontal .team.formation-4-1-3-2 .midfielder:nth-child(7), .pitch.horizontal .team.formation-4-1-3-2 .midfielder:nth-child(8), .pitch.horizontal .team.formation-4-1-3-2 .attacker:nth-child(9) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-4-1-3-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-1-3-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 4-1-4-1*/

.pitch.horizontal .team.formation-4-1-4-1 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-1-4-1 .midfielder:nth-child(6) {
  height:100% !important;
}

.pitch.horizontal .team.formation-4-1-4-1 .midfielder:nth-child(7), .pitch.horizontal .team.formation-4-1-4-1 .midfielder:nth-child(8), .pitch.horizontal .team.formation-4-1-4-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-4-1-4-1 .attacker:nth-child(10) {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-1-4-1 .attacker:last-child {
  height:100% !important;
}

/*horizontal 4-2-2-2*/

.pitch.horizontal .team.formation-4-2-2-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-2-2-2 .midfielder, .pitch.horizontal .team.formation-4-2-2-2 .attacker {
  height:50% !important;
}

/*horizontal 4-3-1-2*/

.pitch.horizontal .team.formation-4-3-1-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-3-1-2 .midfielder {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-4-3-1-2 .attacker:nth-child(9) {
  height:100%;
}

.pitch.horizontal .team.formation-4-3-1-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-3-1-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 4-3-3*/

.pitch.horizontal .team.formation-4-3-3 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-3-3 .midfielder, .pitch.horizontal .team.formation-4-3-3 .attacker {
  height:33.3% !important;
}

/*horizontal 4-4-1-1*/

.pitch.horizontal .team.formation-4-4-1-1 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-4-1-1 .midfielder, .pitch.horizontal .team.formation-4-4-1-1 .attacker:nth-child(9) {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-4-1-1 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-4-1-1 .attacker:last-child {
  height:100% !important;
}

/*horizontal 4-4-2*/

.pitch.horizontal .team.formation-4-4-2 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-4-2 .midfielder, .pitch.horizontal .team.formation-4-4-2 .attacker:nth-child(9) {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-4-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-4-4-2 .attacker:last-child {
  height:50% !important;
}

/*horizontal 4-5-1*/

.pitch.horizontal .team.formation-4-5-1 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-5-1 .midfielder, .pitch.horizontal .team.formation-4-5-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-4-5-1 .attacker:nth-child(10) {
  height:20% !important;
}

.pitch.horizontal .team.formation-4-5-1 .attacker:last-child {
  height:100% !important;
}

/*horiz 5-4-1*/

.pitch.horizontal .team.formation-5-4-1 .midfielder:nth-child(6), .pitch.horizontal .team.formation-5-4-1 .defender {
  height:20% !important;
}

.pitch.horizontal .team.formation-5-4-1 .midfielder, .pitch.horizontal .team.formation-5-4-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-5-4-1 .attacker:nth-child(10) {
  height:25% !important;
}

.pitch.horizontal .team.formation-5-4-1 .attacker:last-child {
  height:100% !important;
}

/*horiz 4-2-3-1*/

.pitch.horizontal .team.formation-4-2-3-1 .defender {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-2-3-1 .midfielder:nth-child(6), .pitch.horizontal .team.formation-4-2-3-1 .midfielder:nth-child(7) {
  height:50% !important;
}

.pitch.horizontal .team.formation-4-2-3-1 .midfielder:nth-child(8), .pitch.horizontal .team.formation-4-2-3-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-4-2-3-1 .attacker:nth-child(10) {
  height:33.3%;
}

.pitch.horizontal .team.formation-4-2-3-1 .midfielder, .pitch.horizontal .team.formation-4-2-3-1 .attacker:nth-child(9), .pitch.horizontal .team.formation-4-2-3-1 .attacker:nth-child(10) {
  height:25% !important;
}

.pitch.horizontal .team.formation-4-2-3-1 .attacker:last-child {
  height:100% !important;
}

/*horiz 5-3-2*/

.pitch.horizontal .team.formation-5-3-2 .midfielder:nth-child(6), .pitch.horizontal .team.formation-5-3-2 .defender {
  height:20% !important;
}

.pitch.horizontal .team.formation-5-3-2 .midfielder, .pitch.horizontal .team.formation-5-3-2 .attacker:nth-child(9) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-5-3-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-5-3-2 .attacker:last-child {
  height:50% !important;
}

/*horiz 3-5-2*/

.pitch.horizontal .team.formation-3-5-2 .defender:not(:nth-child(5)) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-5-2 .defender:nth-child(5), .pitch.horizontal .team.formation-3-5-2 .midfielder, .pitch.horizontal .team.formation-3-5-2 .attacker:nth-child(9) {
  height:20% !important;
}

.pitch.horizontal .team.formation-3-5-2 .attacker:nth-child(10), .pitch.horizontal .team.formation-3-5-2 .attacker:last-child {
  height:50% !important;
}

/*horiz 3-4-3*/

.pitch.horizontal .team.formation-3-4-3 .defender:not(:nth-child(5)) {
  height:33.3% !important;
}

.pitch.horizontal .team.formation-3-4-3 .defender:nth-child(5), .pitch.horizontal .team.formation-3-4-3 .midfielder {
  height:20% !important;
}

.pitch.horizontal .team.formation-3-4-3 .attacker {
  height:33.3% !important;
}

/*--end horizontal pitch*/

@media screen and (min-width:1025px){
  .pitch.vertical .team {
    background-size:56px;
    /*background-position:*/
  }

  .pitch.vertical .team.team-home {
    background-position:97% 5%;
  }

  .pitch.vertical .team.team-away {
    background-position:97% 95%;
  }
}

@media screen and (min-width:1200px){
  .pitch .team .player span {
    padding:8px 2px;
    font-size:0.8vw;
  }
}

@media screen and (max-width:500px){
  .pitch.vertical .team .player .player-name {
    font-size:7px;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { findSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
export let element;
export let sources;
$: orientation = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.orientation) || 'horizontal';
const playerThumbnail = 'https://resources-us.yinzcam.com/csf/shared/icons/icon_headshot_placeholder.png';
$: source = findSourceStore($sources, 'YinzCamAppServerGameFormation');
$: data = (source) ? $source : {};
$: formations = data === null || data === void 0 ? void 0 : data.Formations;
$: hasFormations = (_b = data === null || data === void 0 ? void 0 : data.Formations) === null || _b === void 0 ? void 0 : _b.HomeTeamFormation;
$: console.log('formations', formations);
$: homeFormationName = (_d = (_c = formations === null || formations === void 0 ? void 0 : formations.HomeTeamFormation) === null || _c === void 0 ? void 0 : _c._attributes) === null || _d === void 0 ? void 0 : _d.Name;
$: awayFormationName = (_f = (_e = formations === null || formations === void 0 ? void 0 : formations.AwayTeamFormation) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.Name;
$: homeTriCode = (_h = (_g = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _g === void 0 ? void 0 : _g._attributes) === null || _h === void 0 ? void 0 : _h.TriCode.toLowerCase();
$: homeName = (_k = (_j = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.Name;
$: awayTriCode = (_m = (_l = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _l === void 0 ? void 0 : _l._attributes) === null || _m === void 0 ? void 0 : _m.TriCode.toLowerCase();
$: awayName = (_p = (_o = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _o === void 0 ? void 0 : _o._attributes) === null || _p === void 0 ? void 0 : _p.Name;
$: homeFormation = [].concat((_q = formations === null || formations === void 0 ? void 0 : formations.HomeTeamFormation) === null || _q === void 0 ? void 0 : _q.Row).reduce((acc, cur) => acc.concat([].concat(cur === null || cur === void 0 ? void 0 : cur.Player)), []);
// $: console.log('home formation',homeFormation);
$: awayFormation = [].concat((_r = formations === null || formations === void 0 ? void 0 : formations.AwayTeamFormation) === null || _r === void 0 ? void 0 : _r.Row).reduce((acc, cur) => acc.concat([].concat(cur === null || cur === void 0 ? void 0 : cur.Player)), []);
</script>