<CardBase {element} class="g28">
  <div class="type-g28">
    {#if !hideLogos}
      <div class="matchup">
        <div class="team team-1">
          <TeamLogo teamObject={homeTeam} />
          {#if homeTeam}
          <div class="score">{homeTeam._attributes.Score}</div>
          {/if}
        </div>
        <div class="team team-2">
          <TeamLogo teamObject={awayTeam} />
          {#if awayTeam}
          <div class="score">{awayTeam._attributes.Score}</div>
          {/if}
        </div>
      </div>
    {/if}
    {#if textRowData}
      {#each textRowData as textRow}
      <div class="viz bar-chart" class:hidden="{textRow.hidden === true}">
        <h3 class="title">{textRow.title}</h3>
        {#each textRow.data as teamStat}
          <div class="chart-value {teamStat.team}">{teamStat.label}</div>
        {/each}
      </div>
      {/each}
    {/if}
    {#if radialTitle && teamOneArcs && teamTwoArcs}
    <div class="viz radial-chart">
      <h3 class="title">{radialTitle}</h3>
        <svg id="team-1-radial-chart" viewBox='0 0 100 100'>
          <text x='50' y='53'>{teamOnePercentLabel}%</text>
          <g transform='translate(50,50)'>
            {#each teamOneArcs as arc}
              <!-- arc -->
              <path d={arc.d} fill={arc.color}/>
            {/each}
          </g>
        </svg>

        <svg id="team-2-radial-chart" viewBox='0 0 100 100'>
          <text x='50' y='53'>{teamTwoPercentLabel}%</text>
          <g transform='translate(50,50)'>
            {#each teamTwoArcs as arc}
              <!-- arc -->
              <path d={arc.d} fill={arc.color}/>
            {/each}
          </g>
        </svg>
    </div>
    {/if}
    <!-- barchart stuff -->
    {#if barChartData}
      {#each barChartData as barChart}
      <div class="viz bar-chart" class:hidden="{barChart.hidden === true}">
        <h3 class="title">{barChart.title}</h3>
        {#each barChart.data as teamStat}
          <div class="chart-value {teamStat.team}">{teamStat.label}</div>
        {/each}
        <div class="chart">
          {#each barChart.data as teamStat}
            <div class="bar {teamStat.team} {teamStat.hasFullBar === true? 'fullbar' : ''}" style="width:{teamStat.width}%;background-color:{teamStat.fillColor};"></div>
          {/each}
        </div>
      </div>
      {/each}
    {/if}
  </div>
</CardBase>

<style>*,*:before,*:after {
  box-sizing:border-box;
}

.type-g28 {
  width:100%;
  padding:0 12px;
  background-color:var(--f7-theme-color-bg-color);
  font-family:var(--f7-font-family);
  display:flex;
  flex-wrap:wrap;
}

.type-g28 .viz {
  width:100%;
  margin:8px 0;
}

.type-g28 .title {
  width:100%;
  margin:0;
  padding:8px 0;
  color:var(--f7-theme-color);
  font-size:3vw;
  text-align:center;
  text-transform:uppercase;
}

.type-g28 .matchup {
  width:100%;
  margin:12px 0 0 0;
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.type-g28 .matchup .team {
  width:22%;
  padding:8px;
  display:flex;
  align-items:center;
  overflow:visible;
}

.type-g28 .matchup .team-2 {
  flex-direction:row-reverse;
}

.type-g28 .matchup .team .score {
  margin:0 12px;
  font-size:9.5vw;
  font-weight:bold;
  display:block;
}

/*
  .type-g28 .matchup .team :global(object) {
    width:100%;
    height:auto;
  }
  */

.radial-chart {
  width:100%;
  margin:24px 0;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
}

.radial-chart svg {
  width:50%;
  height:calc(100% - 5em);
}

.radial-chart text {
  width:100%;
  font-size:3vw;
  fill:white;
  font-weight:bold;
  text-anchor:middle;
  text-align:center;
}

/*HIDE SVG LABELS - may want to change this behavior for desktop*/

.radial-chart svg text {
  /*display:none;*/
}

/*bar chart*/

.bar-chart  {
  width:100%;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
}

.bar-chart .chart {
  width:100%;
  display:flex;
  order:4;
}

.bar-chart .chart .bar {
  height:10px;
}

.bar-chart .chart .bar:nth-child(odd) {
  background-color:orange;
  border-right:solid 1px #000;
  border-radius:5px 0 0 5px;
}

.bar-chart .chart .bar:nth-child(even) {
  background-color:#FFF;
  border-left:solid 1px #000;
  border-radius:0 5px 5px 0;
}

.bar-chart .chart .bar.fullbar {
  border-radius:5px;
}

.bar-chart .title {
  width:50%;
  text-align:center;
  order:2;
}

.bar-chart .chart-value {
  width:25%;
  padding:0 6px;
  font-weight:bold;
}

.bar-chart .chart-value.team-1 {
  order:1;
  text-align:left;
}

.bar-chart .chart-value.team-2 {
  order:3;
  text-align:right;
}

@media screen and (min-width: 1025px){
}

@media screen and (min-width: 1025px){
  .type-g28 .title {
    font-size:16px;
  }

  .radial-chart text {
    font-size:16px;
  }

  .type-g28 .matchup .team .score {
    font-size:2.5vw;
  }
}</style>

<script lang="ts">var _a, _b;
import { Link } from 'framework7-svelte';
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import * as d3 from 'd3';
import { arc } from 'd3-shape';
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
export let element;
export let sources;
$: hideLogos = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.hideLogos) === 'true';
let radialTitle, teamOneArcs, teamTwoArcs, teamOnePercentLabel, teamTwoPercentLabel, barChartData, textRowData;
//set colors
//find a way to do this via JS and theme options
let primaryArcColor = '#fcd069'; //primary teams color
let secondaryArcColor = '#EAEAEA'; //other teams color
let inActiveArcColor = '#222222'; //fill color for "inactive" part of radial chart
let fallbackColorConfig = {
    team: 'default',
    primary: 'pink',
    inActiveArcColor: inActiveArcColor
};
let colorConfig = [
    {
        team: 'team-1',
        primary: '#EAEAEA'
    },
    {
        team: 'team-2',
        primary: '#fcd069'
    },
    fallbackColorConfig //adding the fallback here to be queried against
];
let findTeamColor = function (teamName, colorName) {
    let color;
    let findFallbackColor = function (color) {
        if (!fallbackColorConfig.hasOwnProperty(color)) {
            //console.log('No fallback under that name either, so defaulting to fallback primary: '+fallbackColorConfig.primary);
            return fallbackColorConfig.primary;
        }
        else {
            return fallbackColorConfig[color];
        }
    };
    colorConfig.forEach(function (config) {
        //found a team match
        //console.log(config.team+' === '+teamName,config.team === teamName);
        if (config.team === teamName) {
            //team found, but no such color name configured
            if (!config.hasOwnProperty(colorName)) {
                //console.log('No color '+colorName+' configured for '+teamName,config[colorName]);
                color = findFallbackColor(colorName);
            }
            else {
                //console.log('returning',config[colorName]);
                color = config[colorName];
            }
        }
    });
    // no team was found
    if (!color) {
        color = findFallbackColor(colorName);
    }
    return color;
};
let transformBarChartData = function (data) {
    data.forEach(function (chart, index, array) {
        var totalValues = 0;
        //get totals
        chart.data.forEach(function (v) {
            totalValues += v.value;
        });
        if (totalValues === 0) {
            //don't show if no stats have been registered, commenting out for now so at least something shows (50/50 split)
            // chart.hidden = true;
            chart.data.forEach(function (v) {
                v.width = Math.round(50);
                v.fillColor = findTeamColor(v.team, 'primary');
            });
        }
        else {
            //console.log(chart.title);
            var oneTeamHasZeroValue = false;
            //loop over each team
            chart.data.forEach(function (v) {
                //if one team isn't registering a stat in this category, then place a class on the bar chart designating this. This helps with making the rounded corners look nice
                if (v.value === 0) {
                    oneTeamHasZeroValue = true;
                }
                if (oneTeamHasZeroValue === true) {
                    v.hasFullBar = true;
                }
                v.width = Math.round((v.value / totalValues) * 100);
                v.fillColor = findTeamColor(v.team, 'primary');
            });
        }
    });
};
//build/tranform barcharts
//transformBarChartData();
let calcRadialPercentage = function (data) {
    let total = 0;
    let returnVal = 0;
    //get total
    data.data.forEach(function (item) {
        total += item.size;
    });
    if (total) {
        data.data.forEach(function (item) {
            //this is VERY fuzzy 
            //console.log('item',item);
            if (item.title !== "") {
                returnVal = Math.round((item.size / total) * 100);
            }
        });
    }
    //console.log('radial',data,returnVal,total);
    return returnVal;
};
let buildArcs = function (teamData) {
    const fn = arc();
    let angle = Math.PI * 2;
    let total = teamData.data.reduce((total, s) => total + s.size, 0);
    let acc = 0;
    return teamData.data.map(segment => {
        let label;
        if (total === 0 || segment.size === 0) {
            label = 0;
        }
        else {
            label = Math.round((segment.size / total) * 100);
        }
        const options = {
            innerRadius: 30,
            outerRadius: 40,
            startAngle: acc,
            endAngle: (acc += (angle * segment.size / total))
        };
        return {
            color: segment.color,
            label: label,
            d: fn(options),
            centroid: fn.centroid(options)
        };
    });
};
$: source = findSourceStore($sources, 'YinzCamAppServerGameBox');
$: data = (source) ? $source : {};
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: console.log('awayTeam', awayTeam);
$: stats = (_b = data === null || data === void 0 ? void 0 : data.Stats) === null || _b === void 0 ? void 0 : _b.Stat;
$: if (Array.isArray(stats)) {
    let radialStat = stats.find(stat => { var _a; return ((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Type) === 'PercentCircle'; });
    if (radialStat) {
        let homeRadialDataValue = parseFloat(radialStat._attributes.HomeValue);
        let homeRadialData = {
            data: [
                {
                    "size": homeRadialDataValue * 100,
                    "title": radialStat._attributes.Name,
                    "color": findTeamColor('team-1', 'primary')
                },
                {
                    "size": 100 - homeRadialDataValue * 100,
                    "title": "",
                    "color": findTeamColor('default', 'inActiveArcColor')
                }
            ]
        };
        let awayRadialDataValue = parseFloat(radialStat._attributes.AwayValue);
        let awayRadialData = {
            data: [
                {
                    "size": awayRadialDataValue * 100,
                    "title": radialStat._attributes.Name,
                    "color": findTeamColor('team-2', 'primary')
                },
                {
                    "size": 100 - awayRadialDataValue * 100,
                    "title": "",
                    "color": findTeamColor('default', 'inActiveArcColor')
                }
            ]
        };
        radialTitle = radialStat._attributes.Name;
        teamOnePercentLabel = calcRadialPercentage(homeRadialData);
        teamTwoPercentLabel = calcRadialPercentage(awayRadialData);
        teamOneArcs = buildArcs(homeRadialData);
        teamTwoArcs = buildArcs(awayRadialData);
    }
    barChartData = [];
    stats.filter(stat => { var _a; return ((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Type) === 'FullBar'; }).forEach(stat => {
        barChartData.push({
            title: stat._attributes.Name,
            data: [
                {
                    team: 'team-1',
                    value: parseInt(stat._attributes.HomeValue),
                    label: stat._attributes.Home
                },
                {
                    team: 'team-2',
                    value: parseInt(stat._attributes.AwayValue),
                    label: stat._attributes.Away
                }
            ]
        });
    });
    transformBarChartData(barChartData);
    textRowData = [];
    stats.filter(stat => { var _a; return ((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Type) === 'Text'; }).forEach(stat => {
        textRowData.push({
            title: stat._attributes.Name,
            data: [
                {
                    team: 'team-1',
                    label: stat._attributes.Home
                },
                {
                    team: 'team-2',
                    label: stat._attributes.Away
                }
            ]
        });
    });
}
</script>
