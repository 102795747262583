<Boundary>
  <div class="yinzcam-cards-array {pageContext.discoModeClass}" style="
      {buildCssPadding((currentLayout)? currentLayout.padding : null, defaultPadding)}
      {buildCssMargin((currentLayout)? currentLayout.margin : null)}
    "
    id={currentLayout.id + "-wrapper"}
  >
    <svelte:component this={currentLayoutComponent} parentSequenceId={sequenceId} layout={currentLayout} bind:contentAvailable={childContentAvailable} />
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.yinzcam-cards-array.disco-mode-enabled {
  padding: 2px;
  border: 2px solid cyan;
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceArray, YinzCamCardsServiceArrayLayout, YinzCamCardsServicePadding } from "yinzcam-cards";
import { windowMetrics as wm } from '../../../js/stores';
import CardsArraySwiperLayout from "./CardsArraySwiperLayout.svelte";
import CardsArrayGridLayout from "./CardsArrayGridLayout.svelte";
import CardsArrayWaistbandLayout from "./CardsArrayWaistbandLayout.svelte";
import CardsArraySingleLayout from "./CardsArraySingleLayout.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { buildCssMargin, buildCssPadding } from "../utilities/index";
import _ from "lodash";
import { getContext } from 'svelte';
import { CardsPageContext, CardsPageContextKey } from './context';
export let sequenceId = "";
export let array;
export let contentAvailable = true;
const pageContext = getContext(CardsPageContextKey);
let childContentAvailable;
// isUndefined is for backwards compatibility with layouts that don't support content availability status
$: {
    contentAvailable = _.isUndefined(childContentAvailable) || childContentAvailable;
}
const defaultPadding = {
    top: '10px', bottom: '10px',
    left: '10px', right: '10px'
};
let currentLayout = null;
let currentLayoutComponent = null;
$: if (array && $wm) {
    const layouts = array.layouts.sort((a, b) => a.breakpoint - b.breakpoint);
    currentLayout = layouts[0];
    for (let layout of layouts) {
        if ($wm.width >= (layout === null || layout === void 0 ? void 0 : layout.breakpoint)) {
            currentLayout = layout;
        }
    }
    switch ((_a = currentLayout.type) === null || _a === void 0 ? void 0 : _a.toUpperCase()) {
        case "GRID":
            currentLayoutComponent = CardsArrayGridLayout;
            break;
        case "SWIPER":
            currentLayoutComponent = CardsArraySwiperLayout;
            break;
        case "SINGLE":
            currentLayoutComponent = CardsArraySingleLayout;
            break;
        case "WAISTBAND":
            currentLayoutComponent = CardsArrayWaistbandLayout;
            break;
        default:
            currentLayoutComponent = CardsArraySingleLayout;
            break;
    }
}
</script>
