<Boundary>
  <div style="
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: {flexDirection};
    flex-wrap: {(flexWrap)? 'wrap':'nowrap'};
  ">
    {#each regions as region (region.id)}
      <div style="
        {flowDimension}: {(region.size)? region.size + '%' : 'auto'};
        flex-shrink: {(region.fill)? 1 : 0};
        flex-grow: {(region.fill)? 1 : 0};
        overflow: {(region.scrollable)? 'auto' : 'hidden'};
      ">
        <CardsRegion {region} />
      </div>
    {/each}
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceRegion } from "yinzcam-cards";
import CardsRegion from "./CardsRegion.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
export let regions;
export let direction;
export let flexWrap;
//{flowDimension}: {(region.size)? region.size : 100}%;
let flexDirection, flowDimension, spanDimension;
$: if ((direction === null || direction === void 0 ? void 0 : direction.toUpperCase()) === 'HORIZONTAL') {
    flexDirection = 'row';
    flowDimension = 'width';
    spanDimension = 'height';
}
else {
    flexDirection = 'column';
    flowDimension = 'height';
    spanDimension = 'width';
}
</script>
