<script lang="ts">var _a, _b, _c, _d, _e, _f;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore, findSourceStore } from '../../../utilities';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { Readable } from 'svelte/store';
export let element;
export let sources;
let source;
let available = true;
let text = "";
let style = "";
$: source = getFirstSourceStore($sources);
$: isPre = ((_a = $source === null || $source === void 0 ? void 0 : $source.State) === null || _a === void 0 ? void 0 : _a._text) === 'P';
$: dataReady = [].concat((_b = $source === null || $source === void 0 ? void 0 : $source.Stats) === null || _b === void 0 ? void 0 : _b.Stat).filter(e => e === null || e === void 0 ? void 0 : e._attributes).length > 0;
$: isPremire = Number((_d = (_c = $source === null || $source === void 0 ? void 0 : $source.Competition) === null || _c === void 0 ? void 0 : _c._attributes) === null || _d === void 0 ? void 0 : _d.OptaId) === 8;
$: text = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.text;
$: style = (_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.style;
</script>

<CardBase {element}>
  <div class="simple-conditional-card" style={style || ""} class:display-none={!(!isPremire && !dataReady && isPre)}>
    {text}
  </div>
</CardBase>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.simple-conditional-card {
  height: calc(100vh - 200px);
}

:root {
  --f7-page-bg-color: #EEF0F1;
}</style>
