<CardBase {element}>
  <header>
    <div class="upper">
      <a class="presenting-sponsor" href="#">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/logos/betway.png" alt="betway">
      </a>
  
      <div class="language-select">
        <a href="" class="active">Es</a>
        <a href="">En</a>
        <a href="">Eu</a>
      </div>
    </div>
    <div class="lower">
      <span class="mobile-menu"><Link href="/home"><img src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/icon_menu.svg" alt="Menu"></Link></span>
  
      <a class="primary-logo" href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/logos/logo-alaves.png" alt="Deportivo Alaves">
      </a>
  
      <nav>
        <div class="label-group">
          <a href="" class="label primary">Label</a>
          <a href="" class="label secondary">Label</a>
        </div>
        
        <ul class="navigation-links">
          <li>
            <a href="/home">Home</a>
            <div class="sub-nav">
              <!--  -->
            </div>
          </li>
          <li>
            <a href="/cards/NewsOneCol">Noticias</a>
            <div class="sub-nav">
              <!--  -->
            </div>
          </li>
          <li>
            <a href="/cards/VideoOneCol">Videos</a>
            <div class="sub-nav">
              <!--  -->
            </div>
          </li>
          <li>
            <a href="/cards/PhotosOneCol">Fotos</a>
            <div class="sub-nav">
              <!--  -->
            </div>
          </li>
          <li>
            <a href="/cards/TeamRoster">Equipo</a>
            <div class="sub-nav">
            </div>
          </li>
          <li>
            <a href="/cards/Schedule">Partidos</a>
            <div class="sub-nav">
              <!--  -->
            </div>
          </li>
          <li>
            <a href="/cards/Standings?compId=1&round=36">Classificaciones</a>
            <div class="sub-nav">
              <!--  -->
            </div>
          </li>
        </ul>
      </nav>
      
      <div class="utilities">
        <div class="search">
          <img src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/icon_search.svg" alt="Search">
          <form name="search" action="">
            <input type="text">
            <input type="submit" value="">
          </form>
        </div>
        <div class="login">
          <a href="">
            <img src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/icon_account.svg" alt="Log In">
            <span>Log in</span>
          </a>
        </div>
      </div>
    </div>
  </header>
</CardBase>
  
<style>*, *:before, *:after {
  box-sizing:border-box;
}

header {
  width:100%;
  background-color:#0c359a;
}

header a {
  color:#FFF;
  text-decoration:none;
}

header .upper {
  width:100%;
  padding:0 10%;
  border-bottom:solid 1px rgba(255,255,255,0.2);
  display:flex;
  flex-wrap:nowrap;
  justify-content:flex-end;
  align-items:center;
}

header .upper .presenting-sponsor {
  height:36px;
  margin:0 12px;
  padding:8px;
}

header .upper .presenting-sponsor img {
  width:auto;
  height:100%;
}

header .upper .language-select {
}

header .upper .language-select a {
  margin:0 4px;
  text-transform:uppercase;
}

header .upper .language-select a.active {
  font-weight:bold;
}

header .lower {
  width:100%;
  padding:0 10%;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items:center;
}

header .lower .mobile-menu {
  display:none;
}

header .lower .primary-logo {
  width:20%;
  padding:12px;
}

header .lower .primary-logo img {
  width:100%;
  height:intrinsic;
}

header .lower .label-group {
  width:100%;
  padding:12px;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
}

header .lower .label-group .label {
  min-width:150px;
  margin:12px;
  padding:12px 24px;
  text-align:center;
  font-size:1vw;
  font-weight:bold;
}

header .lower .label-group .label.primary {
  background-color:#0c359a;
  color:#FFF;
}

header .lower .label-group .label.secondary {
  border:solid 1px #7a8590;
  color:#7a8590;
}

header .lower nav {
  width:50%;
}

header .lower .navigation-links {
  width:100%;
  margin:0;
  padding:0;
  list-style:none;
  display:flex;
  flex-wrap:nowrap;
  justify-content:flex-start;
  align-items:center;
}

header .lower .navigation-links li {
}

header .lower .navigation-links li a {
  padding:8px 12px;
  border-bottom:solid 4px transparent;
  font-size:1vw;
  font-weight:700;
  opacity:0.8;
  transition:all 250ms ease;
}

header .lower .navigation-links li a:hover {
  border-bottom:solid 4px rgba(255,255,255,0.3);
  opacity:1;
}

header .lower .utilities {
  width:20%;
  display:flex;
  justify-content:flex-end;
  align-items:center;
}

header .mobile-menu img, header .lower .utilities .search img, header .lower .utilities .login img {
  width:32px;
  height:intrinsic;
  height:auto;
  padding:6px;
}

header .lower .utilities .search {
  padding:0 12px 0 0;
  border-right:solid 2px rgba(255,255,255,0.2);
}

header .lower .utilities .search form {
  display:none;
}

header .lower .utilities .search form input[type="text"] {
}

header .lower .utilities .search form input[type="submit"] {
}

header .lower .utilities .login {
  padding:0 0 0 12px;
}

header .lower .utilities .login a {
  display:flex;
  align-items:center;
}

@media screen and (min-width:768px) and (max-width:1199px){
  header .upper, header .lower {
    padding:0 12px;
  }

  header .lower nav {
    width:65%;
  }

  header .lower .navigation-links a {
    font-size:1.75vw;
  }

  header .lower .utilities {
    width:15%;
  }

  header .login a span {
    display:none;
  }
}

@media screen and (min-width:768px){
  header .lower .label-group {
    display:none;
  }
}

@media screen and (max-width:767px){
  header .upper {
    display:none;
  }

  header .lower {
    padding:0 100px 0 12px;
    justify-content:flex-start;
    position:relative;
  }

  header .lower .mobile-menu {
    display:block;
    position:relative;
    z-index:1001;
  }

  body.nav-open header .lower .mobile-menu {
    background-image:url('https://resources-us.yinzcam.com/lfp/shared/assets/icons/icon_menu-close.svg');
    background-repeat:no-repeat;
    background-size:90%;
    background-position:center;
  }

  body.nav-open header .lower .mobile-menu img {
    opacity:0;
  }

  header .lower .primary-logo {
    width:auto;
    height:64px;
  }

  header .lower .primary-logo img {
    width:auto;
    height:100%;
  }

  header .login a span {
    display:none;
  }

  header .lower .label-group {
    padding:12px 0;
    display:flex;
  }

  header .lower .label-group .label {
    font-size:14px;
  }

  header .lower nav {
    width:100%;
    height:100%;
    padding:0 12px;
    background-color:#FFF;
    display:block;
    overflow-y:scroll;
    position:fixed;
    top:64px;
    left:0;
    opacity:0;
    transform:translateX(-100%);
    transition:all 300ms ease;
    z-index:1000;
  }

  header .lower nav::-webkit-scrollbar {
    display:none;
  }

  header .lower .navigation-links {
    flex-wrap:wrap;
  }

  header .lower .navigation-links > li {
    width:100%;
    padding:12px;
    border-bottom:solid 1px #DADADA;
  }

  header .lower .navigation-links li a {
    color:#333;
    font-size:14px;
    font-weight:normal;
  }

  body.nav-open header nav {
    transform:translateX(0);
    opacity:1;
  }

  header .lower .utilities {
    width:100px;
    position:absolute;
    top:12px;
    right:12px;
    z-index:2;
  }
}</style>
  
<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { Link } from 'framework7-svelte';
export let element;
export let sources;
</script>
