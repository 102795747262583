<CardBase {element}>
<div class="list" class:isDesktop={isDesktop} class:display-none={!rosterAvailable}>
	{#if element}
	<List>
    <ListItem title="" smartSelect bind:this={smartSelectComp} smartSelectParams={{openIn: ssOpen, searchbar: ($wm.width<1025)?true:false, closeOnSelect: true}}>
      <select name={`SMARTSELECT_${element.id}`}>
      	<!-- make the selection prompt param coming down disabled from selection, but still visible as a context clue for the user -->
        <option value="" selected disabled>{$t(selectionPrompt)}</option>
				{#each options as option, i (option.value)}
					<option value={option.value}>{$t(option.title)}</option>
				{/each}
      </select>
    </ListItem>
	</List>
	{/if}
</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}</style>

<script lang="ts">var _a, _b, _c, _d, _e;
import { List, ListItem } from "framework7-svelte";
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import ROOT from "../../../../inversify.config";
import { getToken } from 'inversify-token';
import { getFirstSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../common/context";
import { afterUpdate, getContext, onMount } from "svelte";
import { windowMetrics as wm } from '../../../../js/stores.js';
import { t } from "../../../../js/i18n";
import { JanusModeContextManagerToken } from "../../../../js/mode";
export let element;
export let sources;
const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const langIdStore = contextManager.getLanguageComponent().store;
const tabContext = getContext(CardsTabContextKey);
let smartSelectComp, ssOpen, isDesktop;
let isNative = (Device.cordova) ? true : false;
$: langSelection = $langIdStore.toLowerCase().split("-", 2)[0];
$: if (isNative) {
    //native app
    ssOpen = 'popup';
}
else {
    if ($wm.width >= 1025) {
        //desktop
        ssOpen = 'popover';
        isDesktop = true;
    }
    else {
        //mobile web
        ssOpen = 'popup';
    }
}
$: selectionPrompt = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.selectionPrompt) || 'Choose an option';
$: key = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.key;
$: source = getFirstSourceStore($sources);
$: sourceOptions = $source === null || $source === void 0 ? void 0 : $source.options;
$: elementOptions = (_d = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.options) === null || _d === void 0 ? void 0 : _d.map((option) => {
    return {
        value: option.value,
        title: $t(option.title)
    };
});
$: options = sourceOptions || elementOptions || [];
$: mergedParams = (_e = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _e === void 0 ? void 0 : _e.store;
$: selectedValue = (key) ? ($mergedParams || {})[key] : null;
$: selectedOption = (options && selectedValue) ? options.find(option => option.value === selectedValue) : null;
/* This function needs to run in afterUpdate because sometimes Svelte will call it out of order with *something* in F7,
   causing the picker to not update until the next state change. This feels pretty fragile so if you touch it please make
     sure to test thoroughly. */
afterUpdate(() => {
    if (smartSelectComp && selectedOption) {
        let ss = smartSelectComp.smartSelectInstance();
        if (ss) {
            ss.setValue(selectedOption.value);
        }
    }
});
let windowInterval;
let querySelector = '.player';
let rosterAvailable = false;
let initRosterToggle = function () {
    var rosterElements = document.querySelectorAll(querySelector);
    rosterElements.forEach(function (element) {
        if (!element.getAttribute('data-toggle-number')) {
            var number = element.querySelector('.number').innerHTML;
            element.setAttribute('data-toggle-number', number);
        }
        if (!element.getAttribute('data-toggle-name')) {
            var name = element.querySelector('.name').innerHTML;
            element.setAttribute('data-toggle-name', name);
        }
        if (!element.getAttribute('data-toggle-position')) {
            var position = element.querySelector('.abbrevation').innerHTML;
            element.setAttribute('data-toggle-position', position);
        }
    });
};
let toggleClick = function (string) {
    switch (string) {
        case 'number':
            toggleSortBy('number');
            return;
        case 'name':
            toggleSortBy('name');
            return;
        case 'position':
            toggleSortBy('position');
            return;
        default:
            //sort by name
            toggleSortBy('name');
            return;
    }
};
let placeOrderOnParentEl = function (el, order) {
    el.parentNode.parentNode.parentNode.style.order = order;
};
let toggleSortBy = function (string) {
    var rosterElements = document.querySelectorAll(querySelector);
    var sortable = [];
    rosterElements.forEach(function (element) {
        var sortAttribute = element.getAttribute('data-toggle-' + string);
        if (string === 'number') {
            if (sortAttribute === '--') {
                sortAttribute = 1000;
            }
            else {
                sortAttribute = parseInt(sortAttribute, 10);
            }
        }
        if (string === 'name') {
            if (sortAttribute.indexOf(' ') !== -1) {
                sortAttribute = sortAttribute.substr(sortAttribute.indexOf(' ') + 1);
            }
        }
        sortable.push({
            element: element,
            attribute: sortAttribute
        });
    });
    sortable.sort(function (a, b) {
        if (a.attribute < b.attribute) {
            return -1;
        }
        else if (a.attribute > b.attribute) {
            return 1;
        }
        else {
            return 0;
        }
    });
    console.log('sorted', sortable);
    sortable.forEach(function (item, index, array) {
        placeOrderOnParentEl(item.element, index);
    });
};
let teamRosterAvailable = function () {
    if (document.querySelectorAll(querySelector).length) {
        return true;
    }
    else {
        return false;
    }
};
let pollHandler = function () {
    if (teamRosterAvailable()) {
        rosterAvailable = true;
        initRosterToggle();
        clearInterval(windowInterval);
    }
};
onMount(() => {
    let ss = smartSelectComp.smartSelectInstance();
    windowInterval = setInterval(pollHandler, 300);
    initRosterToggle();
    ss.on('open', (ss) => {
        var closeButton = document.querySelector('.link.popup-close');
        var searchBar = document.querySelector('.searchbar-input-wrap input');
        var cancelText = document.querySelector('.searchbar-disable-button');
        try {
            closeButton.innerHTML = $t('Cerrar');
            cancelText.innerHTML = $t('Cancelar');
            searchBar.setAttribute('placeholder', $t('Buscar'));
        }
        catch (e) {
            console.log(e);
        }
    });
    ss.on('close', (ss) => {
        if (!ss || !key) {
            return;
        }
        let val = ss.getValue();
        console.log('value', val);
        toggleSortBy(val);
        if (val) {
            tabContext.setParam(key, val);
        }
        else {
            tabContext.setParam(key);
        }
    });
});
</script>