<script lang="ts">var _a, _b;
import ImageWithPlaceholder from '../../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
import footballImage from '../../image/football_icon_white.png';
import chromeMask from '../../image/chrome_mask.png';
import subOnImage from "../../image/sub-on.png";
import subOffImage from "../../image/sub-off.png";
import { Link } from 'framework7-svelte';
export let Play = {};
$: TeamLogo = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_b = (_a = Play === null || Play === void 0 ? void 0 : Play.PlayerTeam) === null || _a === void 0 ? void 0 : _a.LogoId) === null || _b === void 0 ? void 0 : _b.toLowerCase()}_dark.png`;
</script>

<div class="live-reporting-sub-card">
  <div class="headline">
    <div class="time">
      {Play.Time || ""}
    </div>
  </div>
  <div class="live-content">
    <div class="goal-content-media">
      <div class="media-body">
        <div class="heading">
          <h3>SUB</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="players">
	<div class="player-name">
	  <img src={subOnImage} alt="" class="arrow-in" />
	  <span>{Play.Player1.Name}</span>
    </div>
    <div class="player-name">
	  <img src={subOffImage} alt="" class="arrow-out" />
	  <span>{Play.Player2.Name}</span>
    </div>
  </div>
  <div class="team-logo-wrapper">
	<ImageWithPlaceholder src={TeamLogo} alt="TeamLogo" class="team-logo" placeholder="/static/images/default_club.png" />
  </div>
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.live-reporting-sub-card {
  padding: 18px 0;
  background: #0F204B;
  border-radius: 12px;
  width: 100%;
  display: flex;
  align-items: center;
}

.players {
  margin-left: 30px;
  width: 100%;
}

.player-name {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0 10px 0;
}

.player-name img {
  width: 56px;
  display: block;
  margin: 0 6.7px 0 0;
}

.player-name span {
  font-family: 'zuume-cut';
  font-size: 35px;
  font-weight: 700;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.22;
  letter-spacing: .3px;
  text-transform: uppercase;
  text-align: left;
  color: white;
}

.team-logo-wrapper {
  width: 104px;
  height: 100%;
  display: flex;
  align-items: center;
  /*justify-content: center;   */
}

.team-logo-wrapper :global(.team-logo) {
  height: 42px;
  display: inline-block;
}

.headline {
  display: flex;
  justify-content: space-between;
}

.headline .time {
  width: 103.36px;
  font-family: AeonikTRIAL;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.23;
  text-align: center;
  color: #ffffff;
}

.headline .score {
  display: flex;
  align-items: center;
}

.headline .score .team-name {
  font-family: AeonikTRIAL;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #ffffff;
}

.headline .score .score-info {
  margin: 0px 12px;
  font-family: AeonikTRIAL;
  font-size: 24px;
  font-weight: bold;
  line-height: 0.95;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.live-content {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 0;
}

.live-content .goal-content-media {
  display: flex;
  align-items: center;
}

.live-content .goal-content-media .media {
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 50%;
  background: #9aa7a7;
}

.live-content .goal-content-media .media :global(.player-image) {
  width: 100%;
  height: 100%;
}

.live-content .goal-content-media .media :global(img) {
  width: 100%;
  height: 100%;
}

.live-content .goal-content-media .media-body .heading {
  margin: 0 0 9px 0;
  display: flex;
}

.live-content .goal-content-media .media-body .heading img {
  width: 29px;
  height: 29px;
  display: block;
}

.live-content .goal-content-media .media-body .heading h3 {
  font-family: zuume-cut,sans-serif;
  font-size: 110px;
  font-style: italic;
  font-weight: bold;
  line-height: 1;
  background: url("../../image/chrome_mask.png") 0;
  background-size: contain;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ffffff;
  margin: 0 0 0 0;
  padding-right: 5px;
  text-transform: uppercase;
}

.live-content .goal-content-media .media-body .name {
  display: inline-block;
  margin: -20px 0 0 0;
  padding: 9px 12px 9px 12px;
  background-color: #3DC1F2;
  font-style: italic;
  line-height: 36px;
  font-family: zuume-cut,sans-serif;
  font-size: 52px;
  font-weight: 700;
  color: #0F204B;
}

.live-content .live-body {
  width: 50%;
  font-family: AeonikTRIAL;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  white-space: pre-line;
}

.link-container {
  display: flex;
  justify-content: flex-end;
  grid-gap: 24px;
  gap: 24px;
}

.link-container :global(a .f7-icons) {
  font-size: 24px;
  color: white;
}

@media (max-width: 767px) {
  .live-reporting-sub-card {
    padding: 12px 0;
    display: flex;
    align-items: center;
  }

  .players {
    margin-left: 15px;
    width: 100%;
  }

  .player-name {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;
  }

  .player-name img {
    width: 35px;
    display: block;
    margin: 0 6.7px 0 0;
  }

  .player-name span {
    font-family: 'zuume-cut';
    font-size: 20px;
    font-weight: 700;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.22;
    letter-spacing: .3px;
    text-transform: uppercase;
    text-align: left;
    color: white;
  }

  .team-logo-wrapper {
    width: 46px;
  }

  .team-logo-wrapper :global(.team-logo) {
    height: 28px;
  }

  .headline {
    display: flex;
    justify-content: space-between;
  }

  .headline .time {
    font-size: 14px;
    line-height: 1.83;
    width: 60px;
  }

  .headline .score {
    display: flex;
    align-items: center;
  }

  .headline .score .team-name {
    font-size: 12px;
    line-height: 1;
  }

  .headline .score .score-info {
    margin: 0px 4px;
    font-size: 16px;
    line-height: 1.42;
  }

  .live-content {
    display: flex;
    flex-direction: column;
  }

  .live-content .goal-content-media {
    display: flex;
    align-items: center;
  }

  .live-content .goal-content-media .media {
    width: 58px;
    height: 58px;
  }

  .live-content .goal-content-media .media :global(img) {
    width: 100%;
    height: 100%;
  }

  .live-content .goal-content-media .media-body {
    margin: 0 0 0 0;
  }

  .live-content .goal-content-media .media-body .heading {
    margin: 0 0 9px 0;
    display: flex;
  }

  .live-content .goal-content-media .media-body .heading img {
    width: 24px;
    height: 24px;
    display: block;
  }

  .live-content .goal-content-media .media-body .heading h3 {
    font-family: zuume-cut,sans-serif;
    font-size: 50px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    margin: 0 0 0 0;
  }

  .live-content .goal-content-media .media-body .name {
    margin: -10px 0 0 0;
    padding: 5px 7px;
    background-color: #3dc1f2;
    line-height: 21.6px;
    font-size: 20px;
  }

  .live-content .live-body {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
  }

  .link-container {
    display: flex;
    justify-content: flex-end;
    grid-gap: 12px;
    gap: 12px;
  }

  .link-container :global(a .f7-icons) {
    font-size: 20px;
    color: white;
  }
}</style>
