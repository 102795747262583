<Boundary>
  <div class="yinzcam-cards-columns" style={`
    gap: ${gapPixels || 10}px;
  `}>
    {#each columns as column, i (column.id)}
      <CardsColumn sequenceId={generateSequenceId(parentSequenceId, i)} {column} />
    {/each}
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.yinzcam-cards-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
}</style>

<script lang="ts">import { YinzCamCardsServiceColumn } from "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsColumn from "./CardsColumn.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
export let parentSequenceId = "";
export let columns;
export let gapPixels;
</script>
