import { Token } from 'inversify-token';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIRequestComponent, YinzCamServer } from 'yinzcam-api';

export const YinzCamAppServerToken = new Token<YinzCamAppServer>(Symbol.for("YinzCamAppServer"));

export abstract class YinzCamAppServer {
  private readonly server: YinzCamServer;

  public constructor(private readonly api: YinzCamAPI, private readonly pathPrefix: string) {
    this.server = api.getServer('app', pathPrefix);
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public getHomeIndex(): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: 'Home/Index' });
  }

  public getMediaShortList(): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: 'Media/ShortList' });
  }

  public getGameList(): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: 'Game/List' });
  }

  public getPlayers(): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: 'Team/Players' });
  }

  public getPlayerID(id: string): YinzCamAPIRequestComponent {
    console.log(this.server,'/Stats/Player/${id}');
    return this.server.getRequest({ path: `/Stats/Player/${id}` });
  }

  public getMediaNews(id: string): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: `Media/News/${id}` });
  }
}
