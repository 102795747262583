<CardBase {element}>
  <div class="photo-nav">
    {#each thumbs as thumb, i (thumb.id)}
      <div on:click={() => popupOpened = true} class="item" style="">
        <img src={thumb.thumbUrl} alt="asdf">
      </div>
    {/each}
  </div>
  <Popup class="photo-popup" opened={popupOpened} onPopupClosed={() => popupOpened = false} swipeToClose>
    <div style="width: 100%; height: 100%; background: black; display: flex; align-items: center; justify-content: center;">
      <div style="width: 90%; color: white;">
        <div style="height: 400px;">
          <!-- Main Swiper -> pass thumbs swiper instance -->
          <Swiper 
            thumbs={{ swiper: thumbsSwiper }}
            freeMode={false}
            slidesPerView={1}
            observer={true}
            observeParents={true}
            effect={'fade'}
            centeredSlides={true}
            centeredSlidesBounds={true}
          >
          {#each thumbs as thumb, i (thumb.id)}
            <SwiperSlide>
              <div style="text-align: center;"><img src={thumb.imageUrl} style="max-height: 350px;" alt="asdf"></div>
            </SwiperSlide>
          {/each}
          </Swiper>
          <div style="height: 10px;"></div>
          <div style="width: 80%; margin: auto;">
            <Swiper
              on:swiper={setThumbsSwiper}
              watchSlidesVisibility
              watchSlidesProgress
              navigation
              slidesPerView={6}
              centerInsufficientSlides={true}
              observer={true}
              observeParents={true}
            >
            {#each thumbs as thumb, i (thumb.id)}
              <SwiperSlide>
                <div><img src={thumb.imageUrl} style="max-height: 50px; max-width: 100%;" alt="asdf"></div>
              </SwiperSlide>
            {/each}
            </Swiper>
          </div>
        </div>
        <div style="padding-top: 22px; color: #bdbdbd; width: 100%; display: inline-flex; flex-direction: row; align-items: center;">
          <div style="margin-right: 8px; font-face: 'Core Sans C 45'; font-size: 12px;">{publishDateFmt}</div>
          <div style="margin-right: 8px; font-face: 'Core Sans C 75'; font-size: 12px;">{cat}</div>
          <div style="margin-left: auto;">
            <img style="margin-right: 8px;" src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/ic-facebook.svg" alt="Facebook">
            <img style="" src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/ic-twitter.svg" alt="Twitter">
          </div>
        </div>
        <div style="padding-top: 10px; color: #f2f2f2; font-face: 'Core Sans C 75'; font-size: 32px;">{title}</div>
        <div style="padding-top: 10px; color: #f2f2f2; font-face: 'Core Sans C 45'; font-size: 16px;">{description}</div>
      </div>
    </div>
  </Popup>
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.photo-nav {
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-evenly;
  align-items:center;
}

.photo-nav .item {
  width: 48%;
  height: auto;
  margin: 1%;
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;
  border:solid 1px rgba(255,255,255,0.3);
  border-radius:8px;
}

.photo-nav .item img {
  width:100%;
  height:auto;
}

@media screen and (max-width: 500px){
  .photo-nav .item {
    width: 98%;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e;
import { findSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { Popup, Page, Navbar, NavRight, Link, Block } from 'framework7-svelte';
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import { afterUpdate, getContext, onMount } from "svelte";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { windowMetrics as wm } from '../../../../../js/stores.js';
import { tdate } from "../../../../../js/i18n";
import { Swiper, SwiperSlide } from 'swiper/svelte';
// Import Swiper styles
//import 'swiper/swiper.scss';
export let element;
export let sources;
let popupOpened;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaGallery');
$: data = (source) ? $source : {};
$: title = ((_a = data === null || data === void 0 ? void 0 : data.Title) === null || _a === void 0 ? void 0 : _a._text) || '';
$: description = ((_b = data === null || data === void 0 ? void 0 : data.Description) === null || _b === void 0 ? void 0 : _b._text) || "";
$: timestamp = (_c = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _c === void 0 ? void 0 : _c._text;
$: publishDate = (timestamp) ? new Date(timestamp) : null;
$: publishDateFmt = $tdate(publishDate, { month: 'short', day: 'numeric', year: 'numeric' });
$: thumbs = [].concat((_d = data === null || data === void 0 ? void 0 : data.Photos) === null || _d === void 0 ? void 0 : _d.Photo).filter(photo => { var _a; return (_a = photo === null || photo === void 0 ? void 0 : photo.Id) === null || _a === void 0 ? void 0 : _a._text; }).map(photo => {
    var _a, _b, _c, _d;
    return {
        id: (_a = photo === null || photo === void 0 ? void 0 : photo.Id) === null || _a === void 0 ? void 0 : _a._text,
        description: ((_b = photo === null || photo === void 0 ? void 0 : photo.Description) === null || _b === void 0 ? void 0 : _b._text) || '',
        thumbUrl: (_c = photo === null || photo === void 0 ? void 0 : photo.ThumbUrl) === null || _c === void 0 ? void 0 : _c._text,
        imageUrl: (_d = photo === null || photo === void 0 ? void 0 : photo.ImageUrl) === null || _d === void 0 ? void 0 : _d._text,
    };
});
$: photos = thumbs.map(thumb => thumb.imageUrl);
$: photoCount = (photos && photos.length > 0) ? photos.length : 0;
$: isDesktop = $wm.width > 1024;
$: cat = ((_e = data === null || data === void 0 ? void 0 : data.PrimaryCategoryName) === null || _e === void 0 ? void 0 : _e._text) || "";
// store Thumbs swiper instance
let thumbsSwiper = null;
const setThumbsSwiper = (e) => {
    const [swiper] = e.detail;
    console.log('setThumbsSwiper called', swiper);
    // set Thumbs swiper instance
    setTimeout(() => {
        thumbsSwiper = swiper;
    });
};
</script>
