<CardBase {element} {sources}>
  <div style="
    width: {element.data.width || 'auto'};
    height: {element.data.height || 'auto'};
    display: flex;
    justify-content: center;
    align-items: center;
  ">
    {element.data.title || `Placeholder (id: ${element.id})`}
  </div>
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
</script>
