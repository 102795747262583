<CardBase {element}>
  <div class="news-reader-header" style="color:{textColor};">
    <div class="headline">{headline}</div>
    <div style="height: 24px;"></div>
    <div class="description">{description}</div>
    <div style="height: 24px;"></div>
    <div class="byline">
      <div class="category">{cat}</div>
      <div class="date">{$tdate(publishDate, { year: 'numeric', month: 'long', day: 'numeric' })}</div>
      <div class="social">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/ic-16px--facebook.svg" alt="Facebook">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/icons/ic-16px--twitter.svg" alt="Twitter">
      </div>
    </div>
    <hr>
  </div>
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.news-reader-header {
  font-style: normal;
}

.news-reader-header .headline {
  font-weight: 800;
  font-size: 157%;
  line-height: 22px;
  color: #090A08;
}

.news-reader-header .description {
  font-weight: 800;
  font-size: 122%;
  line-height: 22px;
  color: #535C65;
}

.news-reader-header .byline {
  color: #8E9194;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.news-reader-header .byline :not(:last-child) {
  margin-right: 8px;
}

.news-reader-header .byline :last-child {
  margin-left: auto;
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f;
import { findSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { tdate, ttime } from "../../../../../js/i18n";
export let element;
export let sources;
let textColor = 'inherit';
$: textColor = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.textColor;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaNews');
$: data = (source) ? $source : {};
$: timestamp = (_b = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _b === void 0 ? void 0 : _b._text;
$: publishDate = (timestamp) ? new Date(timestamp) : null;
$: author = ((_c = data === null || data === void 0 ? void 0 : data.Author) === null || _c === void 0 ? void 0 : _c._text) || "";
$: headline = ((_d = data === null || data === void 0 ? void 0 : data.Headline) === null || _d === void 0 ? void 0 : _d._text) || "";
$: description = ((_e = data === null || data === void 0 ? void 0 : data.Description) === null || _e === void 0 ? void 0 : _e._text) || "";
$: cat = ((_f = data === null || data === void 0 ? void 0 : data.PrimaryCategoryName) === null || _f === void 0 ? void 0 : _f._text) || "";
</script>
