<script>
	import Tabs from './Tabs.svelte';
	import TabList from './TabList.svelte';
	import TabPanel from './TabPanel.svelte';
	import Tab from './Tab.svelte';
	import ScheduleTournamentSection from './ScheduleTournamentSection.svelte'
  import { keys } from 'lodash';
import ScheduleItem from '../../ScheduleSection/ScheduleItem.svelte';

  export let tableData;
  export let isTwoRounds;
  export let tableDataV;

  $: dataVuelta = []
  $: data = Object.keys(tableData).map((key, index) => {
    return(
      {
        'title': key,
        'list' : tableData[key],
        'id': index
      }
    )
  });
  $: if (isTwoRounds === true) {
      dataVuelta = Object.keys(tableDataV).map((key, index) => {
      return(
        {
          'title': key,
          'list' : tableDataV[key],
          'id': index
        }
      )
    });
  }

</script> 
 
<Tabs>
  {#if isTwoRounds}
    <TabList>
      <Tab>Ida</Tab>
      <Tab>Vuelta</Tab>
    </TabList>
  {/if}

  <TabPanel>
    {#each data as item (item.id)}
        <ScheduleTournamentSection data={item} />
    {/each}
  </TabPanel>

  {#if isTwoRounds}
  <TabPanel>
    {#each dataVuelta as item (item.id)}
        <ScheduleTournamentSection data={item} />
    {/each}
  </TabPanel>
  {/if}

</Tabs>

<style></style>