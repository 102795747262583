import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";

@injectable()
export class YinzCamAppServerMedia extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;
    
    let page, length;
    try {
      if (response?.url) {
        let url = new URL(response.url);
        page = parseInt(url.searchParams?.get("page"));
        length = parseInt(url.searchParams?.get("length"));
      }
    } catch (e) {
      // couldn't get page and legnth, ignore
    }
    
    let list = [].concat(data?.Media?.MediaSection).reduce((acc, cur) => acc.concat([].concat(cur?.Item)), []);
    if (!_.isNil(page) && !_.isNaN(page) && !_.isNil(length) && !_.isNaN(length)) {
      list = list.slice(length*page, Math.min(list.length, length*(page + 1)));
    }
    return list;
  }
}