<CardBase {element}>
  {#if (appOnly && Device.cordova) || (!appOnly)}
      {#if rawHTML}
      {@html rawHTML}
      {/if}
  {/if}
</CardBase>

<style></style>

<script lang="ts">var _a, _b, _c;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { t } from "../../../../js/i18n";
import { resolveUrl } from "../../../../js/url";
import YCLink from "../../utilities/YCLink.svelte";
import { Device } from 'framework7';
import { getFirstSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let sources;
export let element;
let hasData = false;
let rawHTML = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.rawHTML;
$: source = getFirstSourceStore($sources);
$: if ($source) {
    console.log('$source', $source);
    hasData = true;
}
else {
    hasData = false;
}
let fontSize = '14px';
$: fontSize = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.fontSize;
$: appOnly = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.appOnly;
</script>
