<CardBase {element}>
  <div class="news-reader-body" style="color:{textColor};">
    {@html storyHtml}
    <div style="height: 12vh;"></div>
  </div>
</CardBase>

<style>.news-reader-body :global(*) {
  max-width: 100%;
}

.news-reader-body :global(a) {
  color: var(--f7-theme-primary);
}</style>

<script lang="ts">var _a, _b;
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
let textColor = 'inherit';
$: textColor = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.textColor;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaNews');
$: data = (source) ? $source : {};
$: storyHtml = (_b = data === null || data === void 0 ? void 0 : data.StoryHtml) === null || _b === void 0 ? void 0 : _b._text;
</script>
