<CardBase {element}>
<section class="social-links">
	<a href="{$t('https://www.facebook.com/CopaLibertadores/')}" target="_blank" class="link external social-icon">
		<img src="https://resources-us.yinzcam.com/csf/shared/icons/icon_facebook-gold.png" alt="Facebook">
	</a>
	<a href="{$t('https://twitter.com/Libertadores')}" target="_blank" class="link external social-icon">
		<img src="https://resources-us.yinzcam.com/csf/shared/icons/icon_twitter-gold.png" alt="Twitter">
	</a>
	<a href="{$t('https://www.instagram.com/libertadores')}" target="_blank" class="link external social-icon">
		<img src="https://resources-us.yinzcam.com/csf/shared/icons/icon_instagram-gold.png" alt="Instagram">
	</a>
	<a href="{$t('https://www.youtube.com/channel/UClgFf9mS1mZI2D_vo-ZTpmQ')}" target="_blank" class="link external social-icon">
		<img src="https://resources-us.yinzcam.com/csf/shared/icons/icon_youtube-gold.png" alt="YouTube">
	</a>
</section>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

:root {
  --nav-background-color:#000;
}

section {
  width:100%;
  margin:24px 0;
  padding:0 10%;
  color:#FFF;
  font-family:var(--f7-font-family);
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.social-links {
  width:100%;
  margin:36px 0;
  display:flex;
  justify-content:center;
  align-items:center;
}

.social-links a {
  width:48px;
  margin:0 16px;
}

.social-links a img {
  width:100%;
  -webkit-filter:grayscale(100%) brightness(2);
  filter:grayscale(100%) brightness(2);
}

@media screen and (max-width:1199px){
  section {
    padding:0 12px;
  }
}</style>

<script lang="ts">import { CardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
export let element;
</script>