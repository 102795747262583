<script>
  import { onMount, afterUpdate, onDestroy } from 'svelte'
  import { Chart, registerables, LineController } from 'chart.js';
  Chart.register(...registerables);

  // line data
  export let data

  // graph options
  const type = 'line'

  $: dataLine = {
    labels: (data && data.labels.slice()) || [],
    datasets: [
      {
        fill: false,
        lineTension: 0.3,
        backgroundColor: 'rgba(225, 204, 230)',
        borderColor: 'rgba(50, 115, 255)',
        borderCapStyle: 'butt',
        borderWidth: 1,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#0032a0',
        pointBackgroundColor: '#0032a0',
        pointBorderWidth: 5,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: '#0032a0',
        pointHoverBorderColor: '#0032a0',
        pointHoverBorderWidth: 5,
        pointRadius: 1,
        pointHitRadius: 6,
        data: (data && data.values.slice()) || [],
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'point'
    },
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          const tooltipModel = context.tooltip
          const graphWrapper = document.querySelector(".graph-wrapper")
          const seedTip = document.getElementById('tooltip-seed')
          const paddingLeft = parseFloat(window.getComputedStyle(graphWrapper, null).getPropertyValue('padding-left'))
          const canvasWidth = parseFloat(window.getComputedStyle(chartRef, null).getPropertyValue('width'))
          const canvasHeight = parseFloat(window.getComputedStyle(chartRef, null).getPropertyValue('height'))
          graphWrapper.querySelectorAll('.dynamic').forEach((item) => item.remove())
          if (tooltipModel.opacity === 0) {
            return;
          }
          data.labels.forEach((label, i) => {
            const x = 50 + (canvasWidth - 63) * i / (data.labels.length - 1)
            const y = 14 + (canvasHeight - 60) * data.values[i] / 20
            const tooltipEl = seedTip.cloneNode(true)
            tooltipEl.id = ''
            tooltipEl.className = 'chartjs-tooltip-wrapper dynamic'
            tooltipEl.querySelector('.chartjs-tooltip-text').innerHTML = data.values[i]
            tooltipEl.style.left = Math.round(x + paddingLeft - 13) + 'px'
            tooltipEl.style.top = Math.round(y - 40) + 'px'
            tooltipEl.style.display = 'block'
            tooltipEl.style.position = 'absolute'
            graphWrapper.appendChild(tooltipEl)
          })
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Patidos Jugados',
          font: {
            size: 10,
            family: "'Core Sans C 45'",
          },
          color: '#000',
          padding: {
            top: 8,
            bottom: 0,
          },
        },
        ticks: {
          padding: 10,
          font: {
            size: 6,
            family: "'Core Sans C 45'",
          },
          color: '#999',
        },
        grid: {
          lineWidth: 1,
          color: context => context.tick.value == 0 ? "transparent" : "#eeeeee",
          drawBorder: true,
          drawTicks: false,
          zeroLineWidth: 1,
          zeroLineColor: '#eeeeee',
        },
      },
      y: {
        suggestedMin: 0,
        suggestedMax: 20,
        reverse: true,
        title: {
          display: true,
          text: 'Posicion',
          font: {
            size: 10,
            family: "'Core Sans C 45'",
          },
          color: '#000',
          padding: {
            top: 0,
            bottom: 10,
          },
        },
        ticks: {
          stepSize: 5,
          padding: 10,
          font: {
            size: 6,
            family: "'Core Sans C 45'",
          },
          color: '#999',
        },
        grid: {
          lineWidth: 1,
          color: context => context.tick.value == 20 ? "transparent" : "#eeeeee",
          drawTicks: false,
          zeroLineWidth: 1,
          zeroLineColor: '#eeeeee',
        },
      },
    },
    animation: false,
  }

  let chart = null
  let chartRef

  onMount(() => {
    chart = new Chart(chartRef, {
      type,
      data: dataLine,
      options,
    })
    chart.update()
  })

  afterUpdate(() => {
    chart.data = dataLine
    chart.type = type
    chart.options = options
    chart.update()
  })

  onDestroy(() => {
    chart = null
  })

</script>

<canvas bind:this={chartRef} {type} data={dataLine} {options}/>
