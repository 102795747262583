<CardBase {element}>
{#if $player}
<div class="player-profile">
	<div class="player-headline">
		<div class="name">{$player.PlayerCard.LastName._text}</div>
		<div class="number">{$player.PlayerCard.Number._text}</div>
	</div>
	<div class="detail">
		<img class="headshot" src="https://resources-us.yinzcam.com/csf/shared/logos/{$player.TeamLogoId._text}.png" alt="">
		<div class="position">
			<span class="label">{$t("Posición")}</span>
			<span class="value">{$player.PlayerCard.Position._text}</span>
		</div>
		<div class="height">
			<span class="label">{$t("Altura")}</span>
			<span class="value">{$player.PlayerCard.Height._text}</span>
		</div>
		<div class="weight">
			<span class="label">{$t("Peso")}</span>
			<span class="value">{$player.PlayerCard.Weight._text}</span>
		</div>
		<div class="birth">
			<span class="label">{$t("Nacimiento")}</span>
			<span class="value">
				{#if element.data.trimPlayerBirth}
				{playerBirthCountry($player.PlayerCard.BirthPlace._text)}
				{:else}
				{$player.PlayerCard.BirthPlace._text}
				{/if}
			</span>
		</div>
	</div>
</div>	
{:else}
<span style="color:#FFF;font-size:3vw;text-align:center;margin:24px auto;display:block;">Waiting...</span>
{/if}
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.player-profile {
  width:100%;
  display:flex;
  color:#FFF;
  font-family:var(--f7-font-family);
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
}

.player-profile > div {
  padding:12px 0;
}

.player-profile .player-headline {
  width:20%;
  text-align:center;
  color:#FFF;
}

.player-profile .player-headline .number {
  color:#FFF;
  font-size:6vw;
}

.player-profile .detail {
  width:50%;
  color:#FFF;
  text-align:left;
  display:flex;
  flex-wrap:wrap;
  justify-content:flex-start;
}

.player-profile .detail > div {
  display:flex;
  flex-wrap:nowrap;
  justify-content:flex-start;
}

.player-profile span {
  font-weight:bold;
  font-size:1vw;
}

.player-profile .value {
  width:50%;
}

.player-profile .label {
  width:40%;
}

.player-profile .height, .player-profile .weight, .player-profile .birth, .player-profile .position {
  width:100%;
  margin:6px 0;
}

.player-profile .height .label:after, .player-profile .weight .label:after, .player-profile .birth .label:after {
  content:": ";
}

.player-profile .height {
  margin-right:24px;
}

@media screen and (max-width:1000px){
  .player-profile .number {
    font-size:9vw;
  }

  .player-profile .name {
    font-size:2.75vw;
  }

  .player-profile span, .player-profile .position {
    font-size:1.75vw;
  }
}

@media screen and (min-width:768px){
  .player-profile .player-headline .name, .player-profile .detail img.headshot, .player-profile .position {
    display:none !important;
  }
}

@media screen and (max-width:767px){
  .player-profile {
    padding:12px;
    justify-content:flex-start;
  }

  .player-profile .player-headline {
    width:100%;
    display:flex;
    flex-wrap:nowrap;
    justify-content:flex-start;
  }

  .player-profile .player-headline > div, .player-profile .player-headline .number {
    /*color:#000;*/
    font-size:5vw;
    font-weight:bold;
    text-align:left;
  }

  .player-profile .player-headline .number:before {
    margin:0 8px;
    content:"|";
  }

  .player-profile .player-headline, .player-profile .number, .player-profile .detail {
    /*color:#000;*/
  }

  .player-profile .number {
    font-size:10vw;
  }

  .player-profile .detail {
    width:100%;
    padding:0 35% 15% 0;
    position:relative;
  }

  .player-profile .detail img.headshot {
    width:35%;
    height:auto;
    padding:6px;
    position:absolute;
    top:0;
    right:0;
  }

  .player-profile span, .player-profile .position {
    font-size:1.75vw;
  }

  .player-profile span {
    font-size:3vw;
  }

  .player-profile .value {
    opacity:0.75;
  }

  .player-profile .detail > div {
    margin:2px 0;
  }
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { getContext, onMount } from 'svelte';
import { CardsPageContext, CardsPageContextKey } from '../../common/context';
import { t } from "../../../../js/i18n";
const pageContext = getContext(CardsPageContextKey);
$: playerId = (_a = pageContext === null || pageContext === void 0 ? void 0 : pageContext.query) === null || _a === void 0 ? void 0 : _a.playerId;
export let element;
export let sources;
let playerBirthCountry = function (string) {
    if (string) {
        var commaIndex = string.indexOf(', ');
        if (commaIndex !== -1) {
            return string.substring(commaIndex + 1);
        }
        else {
            return string;
        }
    }
    else {
        return string;
    }
};
$: player = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
$: console.log('player', $player);
</script>