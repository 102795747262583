import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";

@injectable()
export class YinzCamAppServerHeadingOptions extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    let data = response?.data as any;

    let transformGameBoxDate = function(dateString){
      if(!dateString){
        return '';
      }

      var d = new Date(dateString);
      var month = d.getMonth();

      if(month>=11){
        month = 1;
      } else {
        month += 1;
      }
      return month+'/'+d.getDate()+'/'+d.getFullYear();
    }

    if(data?.Player?.PlayerCard){
      return {
        heading: data.Player.PlayerCard.Name._text,
        subHeading: (data.Player.PlayerCard.PositionFullName)?data.Player.PlayerCard.PositionFullName._text:data.Player.PlayerCard.Position._text
      };
    } else if(data?.Boxscore){
      return {
        heading: 'Detalles Del Partido',
        subHeading: transformGameBoxDate(data.Boxscore.Timestamp._text)+' '+data.Boxscore.Venue._text
      };
    } else {
      return {};
    }
  }
}
