<CardBase {element}>
	<div class="table-wrapper">
		{#each rankingTables as table (table.name) }
			<ul class="ranking-table">
				{#each table.rows as row (row.name) }
					<li class="{row.cssClass}">
						<span class="rank">{row.rank}</span>
						<a href={row.href}>
							<span class="team-logo">
								{#if row.logoId}
									<span><TeamLogo logoId={row.logoId} /></span>
								{/if}
							</span>
							<span class="team-name">{row.name}</span>
						</a>
						{#each row.stats as stat }
							<span class="{stat.cssClass}">{stat.value}</span>
						{/each}
					</li>
				{/each}
			</ul>
		{/each}
	</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.table-wrapper {
  width:100%;
  font-family:var(--f7-font-family);
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
}

.table-wrapper span {
  font-weight:bold;
  font-size:1vw;
}

.table-wrapper {
  width:100%;
  overflow:hidden;
}

ul.ranking-table {
  width:100%;
  padding:0;
  overflow:scroll;
  scrollbar-width: none;
}

ul.ranking-table::-webkit-scrollbar {
  display:none;
}

ul.ranking-table li {
  width:auto;
  display:flex;
  align-items:center;
}

ul.ranking-table li span, ul.ranking-table li a {
  text-align:center;
  display:block;
}

ul.ranking-table li > span {
  padding:6px 1px;
  font-size:1vw;
}

ul.ranking-table li.legend {
  background:var(--f7-theme-primary);
}

ul.ranking-table li.legend span {
  padding:12px 1px;
  color:#000;
  text-transform:uppercase;
}

ul.ranking-table li:not(.legend) {
  margin:0 0 2px 0;
  background-color:rgba(0,0,0,0.8);
  color:var(--f7-theme-color);
}

ul.ranking-table li.favorite-team {
  background-color:rgba(255,255,255,0.1);
  /*--f7-dialog-background-color-rgb*/
  color:var(--f7-theme-primary);
}

ul.ranking-table li:not(.legend) > span {
  font-weight:400;
}

ul.ranking-table li > span:nth-child(n+2) {
  text-align:center;
}

ul.ranking-table li > span {
  width:10%;
}

ul.ranking-table li > a {
  width:35%;
  display:flex;
  align-items:center;
}

ul.ranking-table li > a .team-name {
  font-weight:bold;
  text-align:left;
}

ul.ranking-table li > span:first-child {
  padding-left:8px;
}

ul.ranking-table li > span.rank {
  font-weight:bold;
  font-size:1.5vw;
  opacity:1;
  display:none;
  /*hiding this element since it's not in the designs*/
}

ul.ranking-table li > a .team-logo {
  width:64px;
  padding:8px;
}

ul.ranking-table li > a .team-logo > span {
  width:100%;
  height:intrinsic;
  height:auto;
  padding:0 8px;
}

@media screen and (max-width:1000px){
  ul.ranking-table li > span {
    font-size:1.5vw;
  }
}

@media screen and (max-width:767px){
  /*ul.ranking-table {
			background-color:#FFF;
		}

		ul.ranking-table li:not(.legend) {
			background-color:transparent;
		}*/

  .table-wrapper span {
    font-size:2.75vw;
  }

  ul.ranking-table li > span {
    width:50px;
  }

  ul.ranking-table li > a {
    width:auto;
  }

  ul.ranking-table li > a .team-name {
    width:170px;
  }

  /*ul.ranking-table li:not(.legend) > span {
			color:#000;
		}

		ul.ranking-table li:not(.legend):nth-child(odd) {
			background-color:#ededed;
		}*/

  ul.ranking-table li > span {
    font-size:2vw;
  }
}

@media screen and (max-width:500px){
  ul.ranking-table li > span {
    font-size:3vw;
  }
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import _ from "lodash";
import TeamLogo from '../../utilities/TeamLogo.svelte';
export let element;
export let sources;
let rankingTables = [];
$: source = findSourceStore($sources, 'YinzCamAppServerStatsStandings');
$: data = (source) ? $source : {};
$: divisions = [].concat((_a = data === null || data === void 0 ? void 0 : data.Divisions) === null || _a === void 0 ? void 0 : _a.Division);
$: if (divisions) {
    function getRowStats(attrs) {
        let out = [];
        if (!attrs) {
            return out;
        }
        for (let i = 3; i < 20; i++) {
            let statN = attrs[`Stat${i}`];
            let widthN = attrs[`Width${i}`];
            if (_.isNil(statN) || _.isNil(widthN)) {
                break;
            }
            out.push({
                value: statN,
                cssClass: `width-${widthN}`
            });
        }
        return out;
    }
    rankingTables = divisions.filter(division => { var _a; return (_a = division === null || division === void 0 ? void 0 : division._attributes) === null || _a === void 0 ? void 0 : _a.Name; }).map(division => {
        var _a, _b, _c;
        let table = {
            name: (_a = division === null || division === void 0 ? void 0 : division._attributes) === null || _a === void 0 ? void 0 : _a.Name,
            rows: [],
        };
        table.rows.push({
            cssClass: 'legend',
            name: (_b = division === null || division === void 0 ? void 0 : division._attributes) === null || _b === void 0 ? void 0 : _b.Name,
            href: '#',
            stats: getRowStats((_c = division === null || division === void 0 ? void 0 : division.StatsGroup) === null || _c === void 0 ? void 0 : _c._attributes)
        });
        table.rows = table.rows.concat([].concat(division === null || division === void 0 ? void 0 : division.Standing).filter(standing => { var _a; return (_a = standing === null || standing === void 0 ? void 0 : standing._attributes) === null || _a === void 0 ? void 0 : _a.Id; }).map((standing, i) => {
            var _a, _b, _c;
            let standingAttrs = standing === null || standing === void 0 ? void 0 : standing._attributes;
            return {
                cssClass: '',
                name: standingAttrs === null || standingAttrs === void 0 ? void 0 : standingAttrs.Team,
                href: (standingAttrs === null || standingAttrs === void 0 ? void 0 : standingAttrs.Id) ? `/cards/Team?teamId=${encodeURIComponent(standingAttrs.Id)}&titleText=${encodeURIComponent(standingAttrs.Team)}&teamName=${encodeURIComponent(standingAttrs.Team)}&teamTricode=${encodeURIComponent(standingAttrs.TriCode)}` : '#',
                logoId: (_b = (_a = standing === null || standing === void 0 ? void 0 : standing.StatsGroup) === null || _a === void 0 ? void 0 : _a._attributes) === null || _b === void 0 ? void 0 : _b.Stat1,
                rank: i + 1,
                stats: getRowStats((_c = standing === null || standing === void 0 ? void 0 : standing.StatsGroup) === null || _c === void 0 ? void 0 : _c._attributes)
            };
        }));
        return table;
    });
}
</script>