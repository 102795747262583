<script lang="ts">var _a, _b, _c;
import { afterUpdate } from "svelte";
import { Readable } from "svelte/store";
import { Chart, registerables } from "chart.js";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore } from "../../../utilities";
Chart.register(...registerables);
let effChart;
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
}
;
$: passes = ((_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['total_passes']) === null || _a === void 0 ? void 0 : _a.ValueLabel) || "0";
$: centers = ((_b = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['forward_passes']) === null || _b === void 0 ? void 0 : _b.ValueLabel) || "0";
$: duels = ((_c = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['duels']) === null || _c === void 0 ? void 0 : _c.ValueLabel) || "0";
let chartDrawn = false;
afterUpdate(() => {
    var _a, _b, _c, _d;
    if (!chartDrawn && effChart && ($sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap)) {
        const data = {
            labels: [
                "Pases cortos OK",
                "Pases cortos KO",
                "Pases largos OK",
                "Pases largos KO",
            ],
            datasets: [
                {
                    data: [
                        parseInt((_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['successful_short_passes']) === null || _a === void 0 ? void 0 : _a.Value),
                        parseInt((_b = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['unsuccessful_short_passes']) === null || _b === void 0 ? void 0 : _b.Value),
                        parseInt((_c = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['successful_long_passes']) === null || _c === void 0 ? void 0 : _c.Value),
                        parseInt((_d = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['unsuccessful_long_passes']) === null || _d === void 0 ? void 0 : _d.Value)
                    ],
                    backgroundColor: ["#0032a0", "#1f66ff", "#e9ebed", "#002066"],
                    hoverOffset: 4,
                },
            ],
        };
        new Chart(effChart, {
            type: "doughnut",
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {
                            boxWidth: 11,
                            boxHeight: 11,
                            font: {
                                family: "CoreSansC-45",
                                size: 10,
                                lineHeight: 1.3,
                            },
                            color: "#000",
                        },
                    },
                },
            },
        });
        chartDrawn = true;
    }
});
</script>

<CardBase {element} {sources}>
  <div
    class="soccer-playerdetail-efficiency"
    style="
    width: {element.data.width || 'auto'};
    height: {element.data
      .height || 'auto'};
  "
  >
    <div class="overview-info">
      <div class="title">Eficiencia</div>
      <div class="overview-count">
        <div class="count">{passes}</div>
        <div class="label">Pases</div>
      </div>
    </div>
    <div class="doughnut-chart">
      <canvas bind:this={effChart} width={200} height={210} />
    </div>
    <div class="property">
      <div class="label">Pases Largos<span>{passes}</span></div>
      <div class="label">Centros<span>{centers}</span></div>
      <div class="label">Duelos<span>{duels}</span></div>
    </div>
  </div>
</CardBase>

<style type="text/scss">*,
*::before,
*::after {
  box-sizing: border-box;
}

.soccer-playerdetail-efficiency {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 40px;
  border-radius: 4px;
  background-color: var(--neutrals-white-100);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-efficiency {
    flex-direction: column;
    padding: 24px 24px 21px 24px;
  }
}

.soccer-playerdetail-efficiency .overview-info {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-efficiency .overview-info {
    flex-direction: column;
    width: 153px;
    grid-gap: 42px;
    gap: 42px;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-efficiency .overview-info {
    width: 100%;
  }
}

.soccer-playerdetail-efficiency .overview-info .title {
  font-family: CoreSansC-75;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-black-black-30);
}

.soccer-playerdetail-efficiency .overview-info .overview-count .count {
  font-family: CoreSansC-75;
  font-size: 84px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-grey-lighter-20);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-efficiency .overview-info .overview-count .count {
    font-size: 48px;
    line-height: 1.16;
    text-align: right;
  }
}

.soccer-playerdetail-efficiency .overview-info .overview-count .label {
  font-family: CoreSansC-65;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-grey-lighter-20);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-efficiency .overview-info .overview-count .label {
    font-family: CoreSansC-75;
    font-size: 18px;
    font-weight: 800;
    line-height: 1.33;
    text-align: right;
  }
}

.soccer-playerdetail-efficiency .doughnut-chart {
  width: 200px;
  height: 210px;
}

.soccer-playerdetail-efficiency .property {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 199px;
  grid-gap: 16px;
  gap: 16px;
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-efficiency .property {
    width: 100%;
    margin-top: 35px;
  }
}

.soccer-playerdetail-efficiency .property .label {
  display: flex;
  justify-content: space-between;
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
  color: var(--neutrals-grey-darker-60);
}

.soccer-playerdetail-efficiency .property .label span {
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: right;
  color: var(--neutrals-grey-lighter-20);
}</style>
