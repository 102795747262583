<CardBase {element}>
  <svelte:component this={comp} {...subProps} />
</CardBase>

<script lang="ts">import { SvelteComponent } from "svelte";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import BlankAdSlot from "./BlankAdSlot.svelte";
import GooglePublisherTagAdSlot from "./GooglePublisherTagAdSlot.svelte";
export let element;
let comp = BlankAdSlot;
let subProps = {};
$: data = element === null || element === void 0 ? void 0 : element.data;
$: type = data === null || data === void 0 ? void 0 : data.type;
$: switch (type) {
    case 'GPT':
        comp = GooglePublisherTagAdSlot;
        subProps = { adUnitId: data === null || data === void 0 ? void 0 : data.adUnitId, size: data === null || data === void 0 ? void 0 : data.size };
        break;
    default:
        comp = BlankAdSlot;
        subProps = {};
}
</script>
