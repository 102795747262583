import Home from '../pages/Home.svelte';
import Cards from '../pages/Cards.svelte';
import WebView from '../pages/WebView.svelte';
import NotFoundPage from '../pages/NotFoundPage.svelte';

// WARNING: Redirects break prerendering for the website. Don't use redirects here unless absolutely necessary.
var routes = [
  {
    path: '/',
    component: Home
  },
  /* ???: For some reason the alias feature was not working, so defined these as separate paths. */
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/index.html',
    component: Home,
  },
  {
    name: 'cards',
    path: '/cards/:listName/:path1',
    component: Cards,
  },
  {
    name: 'cards',
    path: '/cards/:listName',
    component: Cards,
  },
  {
    path: '/webview',
    component: WebView,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
