<CardBase {element} on:playIdFocus={doSomething}>
<div class="match-detail">
	<ul class="details">
		{#each events as event (event.id)}
		<li class="event event-type-{event.eventType} {event.teamContext}">
			<div class="time-type">
				<div class="time">{event.timeLabel}</div>
				<div class="icon"></div>	
			</div>
			<div class="detail">
				<h3>{event.eventTitle}</h3>
				<h4>{event.subTitle}</h4>
			</div>
			<div class="team"><span><TeamLogo teamObject={event.team} /></span></div>
		</li>
		{/each}
	</ul>
</div>
</CardBase>

<style>*,*:before,*:after {
  box-sizing:border-box;
}

.match-detail {
  font-family:var(--f7-font-family);
}

.match-detail ul.details {
  width:100%;
  margin:0;
  padding:0;
  position:relative;
}

.match-detail ul.details, .match-detail ul.details li {
  width:100%;
  list-style:none;
}

.match-detail ul.details li {
  width:100%;
  margin:12px 0;
  padding:16px;
  background:var(--neutrals-white-100);
  border-radius:4px;
  display:flex;
  justify-content:space-between;
  position:relative;
  z-index:1;
}

.match-detail ul.details li.event-type-goal * {
  color:#FFF !important;
}

.match-detail ul.details li.event-type-goal .team span {
  -webkit-filter:grayscale(1);
  filter:grayscale(1);
}

.match-detail ul.details li.event-type-goal.primary {
  background:var(--alav-s-primary);
}

.match-detail ul.details li.event-type-goal.opposing {
  background:var(--neutrals-grey-darker-20);
}

.match-detail ul.details li.event-type-goal .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_goal-outline-white.svg);
}

.match-detail ul.details li.event-type-substitution .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_substitution.png);
}

.match-detail ul.details li.event-type-red-card .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_red-card.png);
}

.match-detail ul.details li.event-type-yellow-card .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_yellow-card.png);
}

.match-detail ul.details li.event-type-offsides .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_offsides.png);
}

.match-detail ul.details li.event-type-corner-kick .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_corner-kick.png);
}

.match-detail ul.details li.event-type-stoppage .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_stoppage.png);
}

.match-detail ul.details li .time-type {
  width:64px;
}

.match-detail ul.details li .time {
  width:100%;
  color:var(--neutrals-grey-darker-60);
  font-size:16px;
  font-weight:bold;
  text-align:center;
}

.match-detail ul.details li .icon {
  width:20px;
  height:20px;
  margin:6px auto 0 auto;
  background-size:contain;
  position:relative;
}

.match-detail ul.details li .icon:after {
  width:100%;
  height:100%;
  margin:8px 0 0 0;
  background-repeat:no-repeat;
  background-size:contain;
  position:absolute;
  top:0;
  left:0;
  content:"";
  display:block;
}

.match-detail ul.details li .detail {
  width:75%;
}

.match-detail ul.details li .team {
  width:64px;
}

.match-detail ul.details li .team > span {
  width:40px;
  margin:0 auto;
}

.match-detail ul.details li .detail h3 {
  margin:0;
  color:var(--neutrals-grey-darker-60);
  font-size:20px;
  font-weight:800;
  line-height:1.2;
}

.match-detail ul.details li.event-type-goal .detail h3 {
  text-transform:uppercase;
}

.match-detail ul.details li .detail h4 {
  margin:6px 0 0 0;
  color:var(--neutrals-grey-darker-60);
  font-size:16px;
  font-weight:normal;
  line-height:1.5;
}

.match-detail ul.details li.primary .team.event-type-substitution .player:nth-child(1):after, .match-detail ul.details li.opponent .team.event-type-substitution .player:nth-child(1):before {
  width:11px;
  height:11px;
  background-color:#00ad00;
  border-radius:50%;
  content:"";
  display:inline-block;
}

.match-detail ul.details li.primary .team.event-type-substitution .player:nth-child(2):after, .match-detail ul.details li.opponent .team.event-type-substitution .player:nth-child(2):before {
  width:11px;
  height:11px;
  background-color:#e50000;
  border-radius:50%;
  content:"";
  display:inline-block;
}

.match-detail ul.details li.primary .team.event-type-goal .player:after, .match-detail ul.details li.opponent .team.event-type-goal .player:before {
  content:"\26BD";
}

/*show blank space on the second player listed (assister)*/

.match-detail ul.details li.primary .team.event-type-goal .player:nth-child(2):after, .match-detail ul.details li.opponent .team.event-type-goal .player:nth-child(2):before {
  width:13px;
  display:inline-block;
  content:"";
}

.match-detail ul.details li.primary .team.event-type-yellow-card .player:after, .match-detail ul.details li.opponent .team.event-type-yellow-card .player:before {
  width:11px;
  height:11px;
  background-color:#ffbc00;
  content:"";
  display:inline-block;
}

.match-detail ul.details li.primary .team.event-type-red-card .player:after, .match-detail ul.details li.opponent .team.event-type-red-card .player:before {
  width:11px;
  height:11px;
  background-color:#e50000;
  content:"";
  display:inline-block;
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
export let element;
export let sources;
export let doSomething = function (e) {
    console.log('hello from doSomething', e);
};
const eventMap = {
    'S': 'substitution',
    'Y': 'yellow-card',
    'R': 'red-card',
    'G': 'goal'
};
const eventTitleMap = {
    'S': 'Cambio',
    'F': 'Falta',
    '': '',
    'Y': 'Tarjeta Amarilla',
    'R': 'Tarjeta Rojo',
    'G': 'Goooool'
};
$: source = findSourceStore($sources, 'YinzCamAppServerGamePlays');
$: data = (source) ? $source : {};
$: plays = [].concat((_a = data === null || data === void 0 ? void 0 : data.Plays) === null || _a === void 0 ? void 0 : _a.Play).filter(play => { var _a; return (_a = play === null || play === void 0 ? void 0 : play._attributes) === null || _a === void 0 ? void 0 : _a.Id; });
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: console.log('matchdetail data', data);
$: events = plays.map(play => {
    let attrs = play === null || play === void 0 ? void 0 : play._attributes;
    let team = (((attrs === null || attrs === void 0 ? void 0 : attrs.IsHome) || 'true').toLowerCase() === 'true') ? data === null || data === void 0 ? void 0 : data.HomeTeam : data === null || data === void 0 ? void 0 : data.AwayTeam;
    return {
        id: attrs === null || attrs === void 0 ? void 0 : attrs.Id,
        eventType: eventMap[((attrs === null || attrs === void 0 ? void 0 : attrs.Type) || '').toUpperCase()],
        eventTitle: eventTitleMap[((attrs === null || attrs === void 0 ? void 0 : attrs.Type) || '').toUpperCase()],
        timeInt: parseInt(((attrs === null || attrs === void 0 ? void 0 : attrs.Time) || "0'").slice(0, -1)),
        timeLabel: attrs === null || attrs === void 0 ? void 0 : attrs.Time,
        title: attrs === null || attrs === void 0 ? void 0 : attrs.Title,
        subTitle: [].concat((attrs === null || attrs === void 0 ? void 0 : attrs.Title) || []).concat((attrs === null || attrs === void 0 ? void 0 : attrs.SubTitle) || []),
        team: team,
        teamContext: ((team === null || team === void 0 ? void 0 : team._attributes.TriCode.toLowerCase()) === 'ala') ? 'primary' : 'opposing',
        players: [].concat((attrs === null || attrs === void 0 ? void 0 : attrs.Title) || []).concat((attrs === null || attrs === void 0 ? void 0 : attrs.SubTitle) || [])
    };
}).sort((a, b) => b.timeInt - a.timeInt);
</script>