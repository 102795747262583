<CardBase {element}>
	<div class="roster-toggle" class:display-none={!rosterAvailable}>
		<div class="toggle-item" on:click={(e) => toggleClick(e,'number')}>{$t("Número")}</div>
		<div class="toggle-item" on:click={(e) => toggleClick(e,'name')}>{$t("Nombre")}</div>
		<div class="toggle-item" on:click={(e) => toggleClick(e,'position')}>{$t("Posición")}</div>
	</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.roster-toggle {
  width:100%;
  border:solid 1px var(--f7-theme-color-border-color);
  border-radius:8px;
  font-family:var(--f7-font-family);
  display:flex;
  overflow:hidden;
}

.roster-toggle .toggle-item {
  width:100%;
  padding:12px 4px;
  background-color:var(--f7-theme-primary);
  color:#000;
  border-right:solid 1px var(--f7-theme-color-border-color);
  font-size:12px;
  font-weight:bold;
  text-align:center;
  text-transform:uppercase;
  line-height:1;
  overflow:hidden;
}

.roster-toggle .toggle-item:not(.active) {
  color:var(--f7-theme-color) !important;
  background-color:transparent !important;
}

.roster-toggle .toggle-item:last-child {
  border-right:0;
}</style>

<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { onMount } from "svelte";
import { t } from "../../../../js/i18n";
export let element;
export let sources;
let windowInterval;
let querySelector = '.player';
let rosterAvailable = false;
let initRosterToggle = function () {
    var rosterElements = document.querySelectorAll(querySelector);
    rosterElements.forEach(function (element) {
        if (!element.getAttribute('data-toggle-number')) {
            var number = element.querySelector('.number').innerHTML;
            element.setAttribute('data-toggle-number', number);
        }
        if (!element.getAttribute('data-toggle-name')) {
            var name = element.querySelector('.name').innerHTML;
            element.setAttribute('data-toggle-name', name);
        }
        if (!element.getAttribute('data-toggle-position')) {
            var position = element.querySelector('.abbrevation').innerHTML;
            element.setAttribute('data-toggle-position', position);
        }
    });
};
let toggleClick = function (e, string) {
    var element = e.currentTarget;
    var parent = e.currentTarget.parentNode;
    parent.querySelectorAll('.toggle-item').forEach(function (child) {
        child.classList.remove('active');
    });
    element.classList.add('active');
    switch (string) {
        case 'number':
            console.log('sort by number');
            toggleSortBy('number');
            return;
        case 'name':
            console.log('sort by name');
            toggleSortBy('name');
            return;
        case 'position':
            console.log('sort by position');
            toggleSortBy('position');
            return;
        default:
            //sort by name
            console.log('sort by name (default)');
            toggleSortBy('name');
            return;
    }
};
let placeOrderOnParentEl = function (el, order) {
    el.parentNode.parentNode.parentNode.style.order = order;
};
let toggleSortBy = function (string) {
    var rosterElements = document.querySelectorAll(querySelector);
    var sortable = [];
    rosterElements.forEach(function (element) {
        var sortAttribute = element.getAttribute('data-toggle-' + string);
        if (string === 'number') {
            if (sortAttribute === '--') {
                sortAttribute = 1000;
            }
            else {
                sortAttribute = parseInt(sortAttribute, 10);
            }
        }
        if (string === 'name') {
            if (sortAttribute.indexOf(' ') !== -1) {
                sortAttribute = sortAttribute.substr(sortAttribute.indexOf(' ') + 1);
            }
        }
        sortable.push({
            element: element,
            attribute: sortAttribute
        });
    });
    sortable.sort(function (a, b) {
        if (a.attribute < b.attribute) {
            return -1;
        }
        else if (a.attribute > b.attribute) {
            return 1;
        }
        else {
            return 0;
        }
    });
    console.log('sorted', sortable);
    sortable.forEach(function (item, index, array) {
        placeOrderOnParentEl(item.element, index);
    });
};
let teamRosterAvailable = function () {
    if (document.querySelectorAll(querySelector).length) {
        return true;
    }
    else {
        return false;
    }
};
let pollHandler = function () {
    if (teamRosterAvailable()) {
        rosterAvailable = true;
        initRosterToggle();
        clearInterval(windowInterval);
    }
};
onMount(() => {
    console.log('function mounted');
    //todo: figure out a non-hacky way of listening to the DOM for team roster availability
    windowInterval = setInterval(pollHandler, 300);
});
</script>