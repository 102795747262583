<Boundary>
  <div class="yinzcam-cards-section {pageContext.discoModeClass}" style="
      {buildCssPadding(section.padding)}
      {buildCssBackground(section.background)}
      {buildCssScrollContainer(section.height)}
    "
    class:display-none={!visible}
  >
    <CardsColumns parentSequenceId={sequenceId} {columns} {gapPixels}/>
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.yinzcam-cards-section.disco-mode-enabled {
  padding: 2px;
  border: 2px solid blue;
}</style>

<script lang="ts">import { YinzCamCardsServiceArray, YinzCamCardsServiceColumn, YinzCamCardsServiceSection } from "yinzcam-cards";
import { buildCssBackground, buildCssPadding, buildCssScrollContainer } from "../utilities/index";
import CardsColumns from "./CardsColumns.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import _ from "lodash";
import { getContext } from 'svelte';
import { CardsPageContext, CardsPageContextKey } from './context';
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import { CardsDataSourceRegistration } from "./CardsDataSourceRegistration";
import { getFirstSourceStore } from "../utilities";
import { CardsTabContext, CardsTabContextKey } from "./context";
export let sequenceId = "";
export let section;
export let visible = true;
let sources;
import { Readable } from "svelte/store";
const pageContext = getContext(CardsPageContextKey);
const tabContext = getContext(CardsTabContextKey);
function buildColumnsFromContent(sectionId, content) {
    if (!sectionId || !_.isArray(content)) {
        return [];
    }
    return [{
            id: `${sectionId}_AUTO_COLUMN`,
            width: 100,
            content
        }];
}
$: if (sequenceId) {
    sources = tabContext.dataSourceManager.register(section.id, sequenceId, section.sourceIds);
}
$: columns = (_.isArray(section === null || section === void 0 ? void 0 : section.columns)) ? section.columns : buildColumnsFromContent(section === null || section === void 0 ? void 0 : section.id, section === null || section === void 0 ? void 0 : section.content);
$: gapPixels = section === null || section === void 0 ? void 0 : section.gapPixels;
$: source = getFirstSourceStore($sources);
$: visible = !$source || $source.value;
</script>
