<CardBase {element}>
  <footer>
    <section class="sponsor primary">
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/betway.png" alt="Sponsor">
      </a>
    </section>
    <section class="sponsor secondary">
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
    </section>
    <section class="sponsor tertiary">
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/sponsor-example.png" alt="Sponsor">
      </a>
    </section>
    <section class="">
      <a class="branding" href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/logo-alaves.png" alt="Deportivo Alavés">
      </a>
    </section>
    <section class="social-links">
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/twitter.svg" alt="Twitter">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/facebook.svg" alt="Facebook">
      </a>
      <a href="">
        <img src="https://resources-us.yinzcam.com/lfp/shared/assets/footer/instagram.svg" alt="Instagram">
      </a>
    </section>
    <section>
      <div class="policy-links">
        <a href="">
          Nota Legal
        </a>
        <a href="">
          Politica de Privacidad
        </a>
        <a href="">
          Politica de cookies
        </a>
      </div>
    </section>
    <section class="sitemap">
      <button class="expanded">Sitemap</button>
      <ul></ul>
    </section>
    <section class="copyright">
      <span>Pagina oficial &copy; Deportivo Alavés | Web oficial</span>
    </section>
  </footer>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

footer {
  width:100%;
  background-color:#0c359a;
  color:#FFF;
  text-align:center;
  display:flex;
  flex-wrap:wrap;
}

footer section {
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

footer section.sponsor {
  width:80%;
  margin:0 auto;
  border-top:solid 1px rgba(255,255,255,0.3);
}

footer section.sponsor:first-child {
  border-top:0;
}

footer section.sponsor a {
  height:64px;
  margin:12px 24px;
  padding:12px;
  opacity:0.7;
  transition:all 300ms ease;
}

footer section.sponsor a:hover {
  opacity:1;
}

footer section.sponsor a img {
  width:auto;
  height:100%;
}

footer section.sitemap {
  background-color:rgba(0,0,0,0.3);
}

footer .branding {
  width:80%;
  margin:24px auto;
  position:relative;
}

footer .branding:before, footer .branding:after {
  width:30%;
  height:1px;
  border-top:solid 1px rgba(255,255,255,0.3);
  position:absolute;
  top:50%;
  display:block;
  content:"";
}

footer .branding:before {
  left:0;
}

footer .branding:after {
  right:0;
}

footer .branding img {
  width:100%;
  max-width:400px;
  margin:0 auto;
}

footer .social-links {
  width:80%;
  margin: 36px auto;
  display:flex;
  justify-content:center;
  align-items:center;
}

footer .social-links a {
  width:48px;
  margin:0 16px;
}

footer .social-links a img {
  width:100%;
}

footer .policy-links {
  width:100%;
  margin:36px 0;
  padding:0 10%;
  display:flex;
  justify-content:center;
  align-items:center;
}

footer .policy-links a {
  margin:12px 24px;
  color:#FFF;
  font-size:1vw;
  text-align:center;
  text-decoration:none;
}

footer .sitemap {
  padding:12px 0;
  display: none;
}

footer .copyright {
  padding:24px 0;
  order:10;
}

@media screen and (max-width:500px){
  footer .policy-links {
    flex-wrap:wrap;
  }

  footer .policy-links a {
    width:100%;
    font-size:3.5vw;
    text-align:left;
    display:block;
  }

  footer .branding img {
    width: auto;
    max-width:100%;
    margin:0 auto;
  }

  footer section.sponsor a {
    height:48px;
    margin:12px;
  }

  footer .social-links {
    order:9;
  }
}</style>

<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
export let element;
export let sources;
</script>
