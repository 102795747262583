<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import { getFirstSourceStore } from '../../../utilities';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { Readable } from 'svelte/store';
import { getContext } from 'svelte';
import { windowMetrics as wm } from '../../../../../js/stores';
import ImageWithPlaceholder from '../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
import PlayerStatsSummary from '../PlayerStatsSummary/PlayerStatsSummary.svelte';
import { Popup } from 'framework7-svelte';
import iconGoalTimes from '../image/actions/football_icon_black_white.png';
import iconAssistTimes from '../image/actions/action_assist.png';
import iconYellowCardTimes from '../image/actions/icon_yellow@3x.png';
import iconRedCardTimes from '../image/actions/icon_red@3x.png';
import iconSubOnTimes from '../image/actions/icon_substitution_in@3x.png';
import iconSubOffTimes from '../image/actions/icon_substitution_out@3x.png';
import iconPenaltyMissed from '../image/actions/penalty_missed.png';
import iconDoubleYellowCardTimes from '../image/actions/icon_double_yellow@3x.png';
export let element;
export let sources;
export let source;
// GoalTimes="" AssistTimes="" YellowCardTimes="" RedCardTimes="" SubOnTimes="" SubOffTimes=""
const actionTypes = [
    { Name: 'GoalTimes', Icon: iconGoalTimes },
    { Name: 'AssistTimes', Icon: iconAssistTimes },
    { Name: 'YellowCardTimes', Icon: iconYellowCardTimes },
    { Name: 'RedCardTimes', Icon: iconRedCardTimes },
    { Name: 'SubOnTimes', Icon: iconSubOnTimes },
    { Name: 'SubOffTimes', Icon: iconSubOffTimes },
    { Name: 'PenaltyMissTimes', Icon: iconPenaltyMissed },
];
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: source = getFirstSourceStore($sources);
let teamId;
let Players = [];
let Substitutes = [];
let Team = {};
let IsClientHome;
let isDesktop;
let popupOpened = false;
let popupPlayer = {};
$: isDesktop = $wm.width >= 768;
$: teamId = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.teamId) || ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.teamId);
$: popupOpened = isDesktop ? false : false;
$: {
    let FormationRaw = teamId === 'HOME'
        ? (_c = $source === null || $source === void 0 ? void 0 : $source.Formations) === null || _c === void 0 ? void 0 : _c.HomeTeamFormation
        : (_d = $source === null || $source === void 0 ? void 0 : $source.Formations) === null || _d === void 0 ? void 0 : _d.AwayTeamFormation;
    Players = [];
    FormationRaw === null || FormationRaw === void 0 ? void 0 : FormationRaw.Row.forEach((r) => {
        Players = Players.concat([]
            .concat(r === null || r === void 0 ? void 0 : r.Player)
            .filter((p) => p === null || p === void 0 ? void 0 : p._attributes)
            .map((p) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, p._attributes), { ImageUrl: (_a = p === null || p === void 0 ? void 0 : p.ImageUrl) === null || _a === void 0 ? void 0 : _a._text, GameStats: []
                    .concat((_b = p === null || p === void 0 ? void 0 : p.GameStats) === null || _b === void 0 ? void 0 : _b.Stat)
                    .filter((s) => { var _a; return (_a = s === null || s === void 0 ? void 0 : s._attributes) === null || _a === void 0 ? void 0 : _a.Name; })
                    .map((s) => (Object.assign({}, s._attributes))) }));
        }));
    });
    // Substitues
    FormationRaw =
        teamId === 'HOME'
            ? (_e = $source === null || $source === void 0 ? void 0 : $source.Substitutes) === null || _e === void 0 ? void 0 : _e.HomeTeam
            : (_f = $source === null || $source === void 0 ? void 0 : $source.Substitutes) === null || _f === void 0 ? void 0 : _f.AwayTeam;
    Substitutes = [].concat(FormationRaw === null || FormationRaw === void 0 ? void 0 : FormationRaw.Player)
        .filter((p) => p === null || p === void 0 ? void 0 : p._attributes)
        .map((p) => {
        var _a, _b;
        return (Object.assign(Object.assign({}, p._attributes), { ImageUrl: (_a = p === null || p === void 0 ? void 0 : p.ImageUrl) === null || _a === void 0 ? void 0 : _a._text, GameStats: []
                .concat((_b = p === null || p === void 0 ? void 0 : p.GameStats) === null || _b === void 0 ? void 0 : _b.Stat)
                .filter((s) => s === null || s === void 0 ? void 0 : s._attributes)
                .map((s) => (Object.assign({}, s._attributes))) }));
    });
    Team =
        (teamId === 'HOME'
            ? (_g = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _g === void 0 ? void 0 : _g._attributes
            : (_h = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _h === void 0 ? void 0 : _h._attributes) || {};
    IsClientHome = (_j = $source === null || $source === void 0 ? void 0 : $source.IsClientHome) === null || _j === void 0 ? void 0 : _j._text;
}
const handleImageError = (e) => {
    e.target.style.visibility = "hidden";
};
const handleClickPlayer = (Player) => {
    popupOpened = true;
    popupPlayer = Player;
};
</script>

<div class="lineup-table">
  <div class="heading">
    <div class="logo-wrapper">
      <ImageWithPlaceholder
        src="https://resources-uk.yinzcam.com/soccer/shared/logos/{Team.LogoId && Team.LogoId.toLowerCase()}_light.png"
        alt="Logo"
        class="logo"
        placeholder="/static/images/default_club.png"
      />
    </div>
    <h3>{Team.FullName}</h3>
  </div>
  <table>
    {#each Players as Player, index (Player.Number)}
      <tr class:even={index % 2 === 0} on:click={() => handleClickPlayer(Player)}>
        <td class="number">{Player.Number}</td>
        <td class="name">{Player.Name}</td>
        <td class="actions">
          {#each actionTypes as action}
            {#if Player[action.Name]}
              <div class="action" title={action.Name + ' ' + Player[action.Name]}>
                <div class="icon-wrapper">
				  {#if action.Name === 'YellowCardTimes' && Player[action.Name].includes(',')}
					<img src={iconDoubleYellowCardTimes} alt={action.Name} on:error={handleImageError} />
				  {:else}
					<img src={action.Icon} alt={action.Name} on:error={handleImageError} />
				  {/if}
                </div>
                <span class="time">{Player[action.Name]}</span>
              </div>
            {/if}
          {/each}
        </td>
      </tr>
    {/each}
    <tr>
      <td class="substitutes" colspan="3">Substitutes</td>
    </tr>
    {#each Substitutes as Player, index (Player.Number)}
      <tr class:even={index % 2 === 0} on:click={() => handleClickPlayer(Player)}>
        <td class="number">{Player.Number}</td>
        <td class="name">{Player.Name}</td>
        <td class="actions">
          {#each actionTypes as action}
            {#if Player[action.Name]}
              <div class="action" title={action.Name + ' ' + Player[action.Name]}>
                <div class="icon-wrapper">
                  <img src={action.Icon} alt={action.Name} on:error={handleImageError} />
                </div>
                <span class="time">{Player[action.Name]}</span>
              </div>
            {/if}
          {/each}
        </td>
      </tr>
    {/each}
  </table>

  <Popup class="player-stats-summary-popup" opened={popupOpened} onPopupClosed={() => popupOpened = false} swipeToClose>
    <PlayerStatsSummary player={popupPlayer}/>
  </Popup>
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.lineup-table {
  background: #132048;
  width: 100%;
  color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.heading {
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #e0f0f1;
  border-bottom: none;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.heading .logo-wrapper {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading .logo-wrapper :global(.logo) {
  height: 45px;
  display: block;
}

.heading h3 {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-family: AeonikTRIAL;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

table {
  border-collapse: collapse;
  width: 100%;
  cursor: pointer;
}

table td {
  border: 1px solid #222E44;
  font-family: AeonikTRIAL;
  font-size: 14px;
  height: 40px;
  box-sizing: border-box;
  transition: all 0.3s;
}

table td:hover:not(.substitutes) {
  background: #335F8A;
}

table :global(tr.even) {
  background: rgba(31,46,70,0.50196);
}

table .number {
  width: 80px;
  text-align: center;
  font-weight: bold;
}

table .name {
  padding: 0 0 0 25px;
}

table .actions {
  padding: 0 0 0 24px;
  display: flex;
}

table .actions .action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 0 0;
}

table .actions .action:last-child {
  margin: 0;
}

table .actions .action .icon-wrapper {
  width: 24px;
  margin: 0 8px 0 0;
}

table .actions .action .icon-wrapper img {
  display: block;
  object-fit: contain;
  width: 100%;
}

table .actions .action .time {
  line-height: 16px;
  font-family: AeonikTRIAL;
  font-size: 14px;
}

.substitutes {
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-family: AeonikTRIAL;
}

:global(.player-stats-summary-popup) {
  display: none;
  --f7-popup-tablet-width: 600px;
  --f7-popup-tablet-height: 400px;
  background: white;
  align-items: center;
  overflow: visible;
}

:global(.player-stats-summary-popup.modal-in, .player-stats-summary-popup.modal-out) {
  display: flex !important;
}

@media (max-width: 767px) {
  .heading .logo-wrapper {
    width: 43px;
  }

  .heading .logo-wrapper :global(.logo) {
    height: 35px;
  }

  .heading h3 {
    font-size: 14px;
  }

  table td {
    font-size: 12px;
  }

  table .number {
    width: 43px;
  }

  table .name {
    padding: 0 0 0 13px;
  }

  table .actions {
    padding: 0 0 0 14px;
    display: flex;
  }

  table .actions .action {
    margin: 0 8px 0 0;
  }

  table .actions .action:last-child {
    margin: 0;
  }

  table .actions .action img {
    transform: scale(0.7);
  }

  table .actions .action .time {
    line-height: 12px;
    font-size: 12px;
  }

  :global(.player-stats-summary-popup) {
    --f7-popup-tablet-width: 100%;
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    width: var(--f7-popup-tablet-width);
    height: var(--f7-popup-tablet-height);
    left: 0%;
    top: 50%;
    margin-top: calc(-1 * var(--f7-popup-tablet-height) / 2);
    transform: translate3d(0, 100vh, 0);
    box-shadow: var(--f7-popup-box-shadow);
    border-radius: var(--f7-popup-tablet-border-radius, var(--f7-popup-border-radius));
  }
}</style>
