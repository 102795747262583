<script lang="ts">import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore } from "../../../utilities";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
}
;
</script>

<CardBase {element} {sources}>
  <div
    class="soccer-playerdetail-trajectory"
    style="
      width: {element.data.width || 'auto'};
      height: {element.data
        .height || 'auto'};    
    "
  >
    <div class="title">Trayectoria</div>
    <div class="trajectory-info">
      <div class="trajectory-item">
        <div class="year">2018</div>
        <div class="team-info">
          <img src="/static/images/ic_shield_West-Ham.png" />
          <div class="team-name">West Ham United</div>
        </div>
      </div>
      <div class="trajectory-item">
        <div class="year">2018</div>
        <div class="team-info">
          <img src="/static/images/ic_shield_West-Ham.png" />
          <div class="team-name">West Ham United</div>
        </div>
      </div>
      <div class="trajectory-item">
        <div class="year">2018</div>
        <div class="team-info">
          <img src="/static/images/ic_shield_West-Ham.png" />
          <div class="team-name">West Ham United</div>
        </div>
      </div>
    </div>
  </div>
</CardBase>

<style type="text/scss">*,
*::before,
*::after {
  box-sizing: border-box;
}

.soccer-playerdetail-trajectory {
  padding: 32px 32px 48px;
  border-radius: 4px;
  background-color: var(--neutrals-white-100);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-trajectory {
    padding: 24px;
  }
}

.soccer-playerdetail-trajectory .title {
  margin-bottom: 24px;
  font-family: CoreSansC-75;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-grey-darker-60);
}

.soccer-playerdetail-trajectory .trajectory-info .trajectory-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 8px 0 0;
  border-bottom: 1px solid var(--neutrals-grey-lighter-95);
}

.soccer-playerdetail-trajectory .trajectory-info .trajectory-item .year {
  font-family: CoreSansC-75;
  font-size: 10px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--neutrals-grey-darker-60);
}

.soccer-playerdetail-trajectory .trajectory-info .trajectory-item .team-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.soccer-playerdetail-trajectory .trajectory-info .trajectory-item .team-info .team-name {
  min-width: 140px;
  margin-left: 16px;
  font-family: CoreSansC-45;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-grey-darker-40);
}</style>
