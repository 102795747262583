<CardBase {element}>
<div class="h2h">
	<div class="statistic heading">
		<div class="team team-1 logo">
			<span><TeamLogo teamObject={homeTeam} /></span>
			<!-- <img src="https://yc-websites.s3.amazonaws.com/lfp/shared/fc-barcelona.png" alt="Team 1"> -->
		</div>
		<div class="label">10 partidos | 2 Empates</div>
		<div class="team team-2 logo">
			<span><TeamLogo teamObject={awayTeam} /></span>
			<!-- <img src="https://yc-websites.s3.amazonaws.com/lfp/shared/alaves.png" alt="Team 1"> -->
		</div>
	</div>
	{#if stats.length}
	{#each stats as stat}
	<div class="statistic">
		<div class="team team-1" class:advantage={stat.AwayValue > stat.HomeValue}>{stat.Away}</div>
		<div class="label">{stat.Name}</div>
		<div class="team team-2" class:advantage={stat.AwayValue < stat.HomeValue}>{stat.Home}</div>
	</div>
	{/each}
	{/if}
	<!-- <div class="statistic">
		<div class="team team-1">47.9%</div>
		<div class="label">Promedio Posesión</div>
		<div class="team team-2 advantage">50.3%</div>
	</div>
	<div class="statistic">
		<div class="team team-1">1.1</div>
		<div class="label">Promedio Goles</div>
		<div class="team team-2 advantage">1.2</div>
	</div>
	<div class="statistic">
		<div class="team team-1">2</div>
		<div class="label">Prom. Goles encajados</div>
		<div class="team team-2 advantage">0.6</div>
	</div>
	<div class="statistic">
		<div class="team team-1 advantage">11.5</div>
		<div class="label">Promedio Remates</div>
		<div class="team team-2">11.1</div>
	</div>
	<div class="statistic">
		<div class="team team-1">24.5%</div>
		<div class="label">% Remates a puerta</div>
		<div class="team team-2 advantage">30%</div>
	</div>
	<div class="statistic">
		<div class="team team-1">72.5%</div>
		<div class="label">Posición de pases</div>
		<div class="team team-2 advantage">80.1%</div>
	</div> -->
	{#if yellowCards[0].Name}
	<div class="statistic discipline">
		<div class="team team-1">
			<span class="yellow-card">{yellowCards[0].AwayValue}</span>
			<span class="red-card">{redCards[0].AwayValue}</span>
		</div>
		<div class="label">Discipline<!--Disciplina--></div>
		<div class="team team-2">
			<span class="yellow-card">{yellowCards[0].HomeValue}</span>
			<span class="red-card">{redCards[0].HomeValue}</span>
		</div>
	</div>
	{/if}
</div>
</CardBase>

<style type="text/css">*, *:before, *:after {
  box-sizing:border-box;
}

.h2h {
  width:100%;
  padding:12px 6px;
  /*background:#f5f5f5;*/
  display:flex;
  flex-wrap:wrap;
  align-items:center;
}

.statistic {
  width:100%;
  margin:10px 0;
  padding:0 6px;
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items:center;
}

.statistic.heading {
}

.logo {
  width:40px;
  height:auto;
  display:block;
}

.logo span {
  width:100%;
  height:auto;
  display:block;
}

.team {
  min-width:36px;
  color:var(--neutrals-grey-lighter-20);
  font-size:14px;
  font-weight:bold;
  text-align:center;
  line-height:1.5;
  display:block;
}

.team.advantage {
  color:var(--feedback-success);
}

.label {
  text-align:center;
  color: var(--neutrals-grey-darker-40);
  font-size:14px;
  line-height:1.5;
}

.statistic.discipline .team {
  display:flex;
  justify-content:space-between;
}

.statistic.discipline .team-2 {
  flex-direction:row-reverse;
}

.yellow-card, .red-card {
  min-width:24px;
  padding:6px 4px;
  font-size:12px;
  line-height:1;
  display:block;
  text-align:center;
}

.yellow-card {
  background:#f6be00;
  color:#282c30;
}

.red-card {
  margin:0 4px;
  background:#d20028;
  color:#FFFFFF;
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { t } from "../../../../../js/i18n";
import { getFirstSourceStore, expandTemplateParams } from "../../../utilities";
import YCLink from "../../../utilities/YCLink.svelte";
import { Readable } from "svelte/store";
import { findSourceStore } from "../../../utilities";
import { getContext, onMount } from "svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
import TeamLogo from '../../../utilities/TeamLogo.svelte';
import { resolveUrl } from "../../../../../js/url";
import _ from "lodash";
export let element;
export let sources;
export let sourceStore;
export let contentActive = false;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGameBox');
}
;
$: data = (sourceStore && $sourceStore) ? $sourceStore : null;
$: if (data) {
    console.log('h2h data', data);
}
$: gameId = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: isDefault = String((_c = (_b = data === null || data === void 0 ? void 0 : data.CardData) === null || _b === void 0 ? void 0 : _b.IsDefault) === null || _c === void 0 ? void 0 : _c._text).toLowerCase() === 'true';
$: timestamp = (_d = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _d === void 0 ? void 0 : _d._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_e = data === null || data === void 0 ? void 0 : data.Venue) === null || _e === void 0 ? void 0 : _e._text;
$: competitionLogo = (_g = (_f = data === null || data === void 0 ? void 0 : data.Competition) === null || _f === void 0 ? void 0 : _f.LogoUrlDark) === null || _g === void 0 ? void 0 : _g._text;
$: legType = (_h = data === null || data === void 0 ? void 0 : data.LegType) === null || _h === void 0 ? void 0 : _h._text;
$: buttons = [].concat((_j = data === null || data === void 0 ? void 0 : data.Buttons) === null || _j === void 0 ? void 0 : _j.Button).map(i => {
    var _a, _b, _c;
    return {
        title: (_a = i === null || i === void 0 ? void 0 : i.Title) === null || _a === void 0 ? void 0 : _a._text,
        url: resolveUrl((_b = i === null || i === void 0 ? void 0 : i.URL) === null || _b === void 0 ? void 0 : _b._text),
        imageUrl: (_c = i === null || i === void 0 ? void 0 : i.ImageURL) === null || _c === void 0 ? void 0 : _c._text
    };
}).filter(i => i === null || i === void 0 ? void 0 : i.title);
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeName = (_k = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _k === void 0 ? void 0 : _k.Name;
$: homeScore = (_m = (_l = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _l === void 0 ? void 0 : _l._attributes) === null || _m === void 0 ? void 0 : _m.Score;
$: homeScoringPlays = [].concat((_p = (_o = data === null || data === void 0 ? void 0 : data.Scoring) === null || _o === void 0 ? void 0 : _o.ScoringPeriod) === null || _p === void 0 ? void 0 : _p.ScoringPlay).map(i => {
    var _a, _b, _c, _d;
    return {
        teamTricode: (_a = i === null || i === void 0 ? void 0 : i._attributes) === null || _a === void 0 ? void 0 : _a.Team,
        time: (_b = i === null || i === void 0 ? void 0 : i._attributes) === null || _b === void 0 ? void 0 : _b.Time,
        player: (_c = i === null || i === void 0 ? void 0 : i.Description) === null || _c === void 0 ? void 0 : _c._text,
        isHome: (_d = i === null || i === void 0 ? void 0 : i._attributes) === null || _d === void 0 ? void 0 : _d.IsHome
    };
}).filter(i => (i === null || i === void 0 ? void 0 : i.isHome) === 'true');
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayName = (_q = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _q === void 0 ? void 0 : _q.Name;
$: awayScore = (_s = (_r = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _r === void 0 ? void 0 : _r._attributes) === null || _s === void 0 ? void 0 : _s.Score;
$: awayScoringPlays = [].concat((_u = (_t = data === null || data === void 0 ? void 0 : data.Scoring) === null || _t === void 0 ? void 0 : _t.ScoringPeriod) === null || _u === void 0 ? void 0 : _u.ScoringPlay).map(i => {
    var _a, _b, _c, _d;
    return {
        teamTricode: (_a = i === null || i === void 0 ? void 0 : i._attributes) === null || _a === void 0 ? void 0 : _a.Team,
        time: (_b = i === null || i === void 0 ? void 0 : i._attributes) === null || _b === void 0 ? void 0 : _b.Time,
        player: (_c = i === null || i === void 0 ? void 0 : i.Description) === null || _c === void 0 ? void 0 : _c._text,
        isHome: (_d = i === null || i === void 0 ? void 0 : i._attributes) === null || _d === void 0 ? void 0 : _d.IsHome
    };
}).filter(i => (i === null || i === void 0 ? void 0 : i.isHome) === 'false');
$: stats = [].concat((_v = data === null || data === void 0 ? void 0 : data.Stats) === null || _v === void 0 ? void 0 : _v.Stat).map(i => {
    return (i === null || i === void 0 ? void 0 : i._attributes) || {};
}).filter(i => { var _a; return ((_a = i === null || i === void 0 ? void 0 : i.Name) === null || _a === void 0 ? void 0 : _a.indexOf(' Cards')) === -1; });
$: yellowCards = [].concat((_w = data === null || data === void 0 ? void 0 : data.Stats) === null || _w === void 0 ? void 0 : _w.Stat).map(i => {
    return (i === null || i === void 0 ? void 0 : i._attributes) || {};
}).filter(i => { var _a; return ((_a = i === null || i === void 0 ? void 0 : i.Name) === null || _a === void 0 ? void 0 : _a.indexOf('Red Cards')) !== -1; });
$: redCards = [].concat((_x = data === null || data === void 0 ? void 0 : data.Stats) === null || _x === void 0 ? void 0 : _x.Stat).map(i => {
    return (i === null || i === void 0 ? void 0 : i._attributes) || {};
}).filter(i => { var _a; return ((_a = i === null || i === void 0 ? void 0 : i.Name) === null || _a === void 0 ? void 0 : _a.indexOf('Red Cards')) !== -1; });
$: console.log('statssss', stats, yellowCards, redCards);
$: state = (_y = data === null || data === void 0 ? void 0 : data.State) === null || _y === void 0 ? void 0 : _y._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_z = data === null || data === void 0 ? void 0 : data.GameState) === null || _z === void 0 ? void 0 : _z._text;
$: clock = (_0 = data === null || data === void 0 ? void 0 : data.Clock) === null || _0 === void 0 ? void 0 : _0._text;
$: round = (_1 = data === null || data === void 0 ? void 0 : data.Round) === null || _1 === void 0 ? void 0 : _1._text;
$: {
    contentActive = isDefault;
}
</script>