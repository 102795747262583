<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore } from "../../../utilities";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
}
;
$: name = ((_b = (_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerCard) === null || _a === void 0 ? void 0 : _a.Name) === null || _b === void 0 ? void 0 : _b._text) || "";
$: number = ((_d = (_c = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerCard) === null || _c === void 0 ? void 0 : _c.Number) === null || _d === void 0 ? void 0 : _d._text) || "";
$: position = ((_f = (_e = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerCard) === null || _e === void 0 ? void 0 : _e.Position) === null || _f === void 0 ? void 0 : _f._text) || "";
$: bigImageUrl = ((_g = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.XLargePngImageUrl) === null || _g === void 0 ? void 0 : _g._text) || "";
$: smallImageUrl = ((_h = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.MediumPngImageUrl) === null || _h === void 0 ? void 0 : _h._text) || "";
$: birthDate = ((_k = (_j = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerCard) === null || _j === void 0 ? void 0 : _j.BirthDate) === null || _k === void 0 ? void 0 : _k._text) || "";
$: birthPlace = ((_m = (_l = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerCard) === null || _l === void 0 ? void 0 : _l.BirthPlace) === null || _m === void 0 ? void 0 : _m._text) || "";
$: height = ((_p = (_o = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerCard) === null || _o === void 0 ? void 0 : _o.Height) === null || _p === void 0 ? void 0 : _p._text) || "";
</script>

<CardBase {element} {sources}>
  <div
    class="soccer-playerdetail-header"
    style="
      width: {element.data.width || 'auto'};
      height: {element.data
        .height ||
        'auto'};
    "
  >
    <div class="soccer-playerdetail-header-left" style="background-image: url({bigImageUrl}); background-size: contain; background-repeat: no-repeat;">
      <div class="soccer-playerdetail-avatar">
        <img
          src={smallImageUrl}
          alt="Player Avatar"
        />
        <div class="profile">
          <p class="name">Consigue la camiseta de {name}</p>
        </div>
      </div>
    </div>
    <div class="soccer-playerdetail-header-right">
      <div class="personal-info">
        <div>
          <p class="number">{number}</p>
          <h1 class="player-name">{name}</h1>
          <p class="position">{position}</p>
        </div>
        <div class="social-icons">
          <img src="/static/icons/social/instgram.svg" />
          <img src="/static/icons/social/twitter.svg" />
        </div>
      </div>
      <div class="description">
        Biografía del jugador no disponible.
      </div>
      <p class="property">Fecha de nacimiento <span>{birthDate}</span></p>
      <p class="property">Lugar de nacimiento <span>{birthPlace}</span></p>
      <p class="property">Altura <span>{height}</span></p>
    </div>
  </div>
</CardBase>

<style type="text/scss">*,
*::before,
*::after {
  box-sizing: border-box;
}

.soccer-playerdetail-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-header {
    margin-top: 51px;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header {
    flex-direction: column;
  }
}

.soccer-playerdetail-header-left {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-header-left {
    width: 60%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-left {
    width: 100%;
    height: 293px;
  }
}

.soccer-playerdetail-header-left .soccer-playerdetail-avatar {
  display: flex;
  height: 100%px;
  border-radius: 4px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--neutrals-white-100);
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-header-left .soccer-playerdetail-avatar {
    width: 331px;
    height: 91px;
    margin-bottom: 23px;
  }

  .soccer-playerdetail-header-left .soccer-playerdetail-avatar img {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-left .soccer-playerdetail-avatar {
    width: 235px;
    height: 57px;
    margin-bottom: 20px;
  }

  .soccer-playerdetail-header-left .soccer-playerdetail-avatar img {
    width: 63px;
  }
}

.soccer-playerdetail-header-left .soccer-playerdetail-avatar .profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-header-left .soccer-playerdetail-avatar .profile {
    width: 221px;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-left .soccer-playerdetail-avatar .profile {
    width: 172px;
  }
}

.soccer-playerdetail-header-left .soccer-playerdetail-avatar .profile .name {
  font-family: CoreSansC-75;
  font-weight: 800;
  text-align: left;
  color: #000000;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-header-left .soccer-playerdetail-avatar .profile .name {
    width: 195px;
    font-size: 18px;
    line-height: 1.4;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-left .soccer-playerdetail-avatar .profile .name {
    width: 154px;
    font-size: 14px;
    line-height: 1.57;
  }
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-header-right {
    width: 40%;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-right {
    padding: 14px 14px 0;
  }

  .soccer-playerdetail-header-right .personal-info {
    display: flex;
    justify-content: space-between;
  }
}

.soccer-playerdetail-header-right .number {
  margin: 0;
  font-family: CoreSansC-75;
  font-size: 48px;
  font-weight: 800;
  line-height: 1.16;
  text-align: left;
  color: var(--alav-s-primary);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-right .number {
    font-size: 24px;
    line-height: 1.25;
  }
}

.soccer-playerdetail-header-right .player-name {
  margin: 0;
  font-family: CoreSansC-75;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.16;
  text-align: left;
  color: var(--neutrals-black-black-30);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-right .player-name {
    font-size: 24px;
    line-height: 1.25;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
}

.soccer-playerdetail-header-right .position {
  font-family: CoreSansC-75;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.96px;
  text-align: left;
  color: var(--feedback-warning);
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-right .position {
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0.8px;
  }
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-header-right .social-icons {
    margin: 40px 0 24px;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-header-right .social-icons {
    display: flex;
    justify-content: space-between;
    width: 52px;
    height: 18px;
  }
}

.soccer-playerdetail-header-right .social-icons img {
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-header-right .social-icons img:first-child {
    margin-right: 33px;
  }
}

.soccer-playerdetail-header-right .description {
  font-family: CoreSansC-45;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--neutrals-grey-darker-10);
}

@media screen and (max-width: 768px) {
  .soccer-playerdetail-header-right .description {
    font-size: 14px;
  }
}

.soccer-playerdetail-header-right .property {
  font-family: CoreSansC-45;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--neutrals-grey-lighter-20);
}

@media screen and (max-width: 768px) {
  .soccer-playerdetail-header-right .property {
    font-size: 14px;
  }
}

.soccer-playerdetail-header-right .property span {
  margin-left: 16px;
  color: var(--neutrals-grey-darker-60);
}</style>
