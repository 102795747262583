<script lang="ts">var _a, _b, _c, _d, _e;
import { afterUpdate, onMount } from "svelte";
import { Readable } from "svelte/store";
import { Chart, registerables } from "chart.js";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore } from "../../../utilities";
import PageFooter from "../../LaLiga/PageFooter/PageFooter.svelte";
Chart.register(...registerables);
let defChart;
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
}
;
$: recoveries = ((_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['recoveries']) === null || _a === void 0 ? void 0 : _a.ValueLabel) || "0";
$: blocks = ((_b = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['blocks']) === null || _b === void 0 ? void 0 : _b.ValueLabel) || "0";
$: interceptions = ((_c = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['interceptions']) === null || _c === void 0 ? void 0 : _c.ValueLabel) || "0";
$: throwIns = ((_d = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['throw_ins_to_own_player']) === null || _d === void 0 ? void 0 : _d.ValueLabel) || "0";
$: clearances = ((_e = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['total_clearances']) === null || _e === void 0 ? void 0 : _e.ValueLabel) || "0";
let chartDrawn = false;
afterUpdate(() => {
    var _a, _b, _c, _d;
    if (!chartDrawn && defChart && ($sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap)) {
        const data = {
            labels: [
                "Recuperaciones",
                "Bloqueados",
                "Intercepciones",
                "Despejes",
            ],
            datasets: [
                {
                    data: [
                        parseInt((_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['recoveries']) === null || _a === void 0 ? void 0 : _a.Value),
                        parseInt((_b = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['blocks']) === null || _b === void 0 ? void 0 : _b.Value),
                        parseInt((_c = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['interceptions']) === null || _c === void 0 ? void 0 : _c.Value),
                        parseInt((_d = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['total_clearances']) === null || _d === void 0 ? void 0 : _d.Value)
                    ],
                    backgroundColor: ["#0032a0", "#1f66ff", "#e9ebed", "#002066"],
                    hoverOffset: 4,
                },
            ],
        };
        new Chart(defChart, {
            type: "doughnut",
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {
                            boxWidth: 11,
                            boxHeight: 11,
                            font: {
                                family: "CoreSansC-45",
                                size: 10,
                                lineHeight: 1.3,
                            },
                            color: "#000",
                        },
                    },
                },
            },
        });
        chartDrawn = true;
    }
});
</script>

<CardBase {element}>
  <div
    class="soccer-playerdetail-defense"
    style="
    width: {element.data.width || 'auto'};
    height: {element.data
      .height || 'auto'};
  "
  >
    <div class="overview-info">
      <div class="title">Defensivas</div>
      <div class="overview-count">
        <div class="count">{recoveries}</div>
        <div class="label">Recuperaciones</div>
      </div>
    </div>
    <div class="doughnut-chart">
      <canvas bind:this={defChart} width={200} height={210} />
    </div>
    <div class="property">
      <div class="label">Bloqueados<span>{blocks}</span></div>
      <div class="label">Intercepciones<span>{interceptions}</span></div>
      <div class="label">Recuperaciones<span>{recoveries}</span></div>
      <div class="label">Entradas<span>{throwIns}</span></div>
      <div class="label">Despejes<span>{clearances}</span></div>
    </div>
  </div>
</CardBase>

<style type="text/scss">*,
*::before,
*::after {
  box-sizing: border-box;
}

.soccer-playerdetail-defense {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 40px;
  border-radius: 4px;
  background-color: var(--neutrals-white-100);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-defense {
    flex-direction: column;
    padding: 24px 24px 21px 24px;
  }
}

.soccer-playerdetail-defense .overview-info {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-defense .overview-info {
    flex-direction: column;
    width: 153px;
    grid-gap: 42px;
    gap: 42px;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-defense .overview-info {
    width: 100%;
  }
}

.soccer-playerdetail-defense .overview-info .title {
  font-family: CoreSansC-75;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-black-black-30);
}

.soccer-playerdetail-defense .overview-info .overview-count .count {
  font-family: CoreSansC-75;
  font-size: 84px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-grey-lighter-20);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-defense .overview-info .overview-count .count {
    font-size: 48px;
    line-height: 1.16;
    text-align: right;
  }
}

.soccer-playerdetail-defense .overview-info .overview-count .label {
  font-family: CoreSansC-65;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: var(--neutrals-grey-lighter-20);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-defense .overview-info .overview-count .label {
    font-family: CoreSansC-75;
    font-size: 18px;
    font-weight: 800;
    line-height: 1.33;
    text-align: right;
  }
}

.soccer-playerdetail-defense .doughnut-chart {
  width: 200px;
  height: 210px;
}

.soccer-playerdetail-defense .property {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 199px;
  grid-gap: 16px;
  gap: 16px;
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-defense .property {
    width: 100%;
    margin-top: 35px;
  }
}

.soccer-playerdetail-defense .property .label {
  display: flex;
  justify-content: space-between;
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
  color: var(--neutrals-grey-darker-60);
}

.soccer-playerdetail-defense .property .label span {
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: right;
  color: var(--neutrals-grey-lighter-20);
}</style>
