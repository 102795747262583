<CardBase {element}>
  {#if photos}
  <div class="photo-nav">
    <PhotoBrowser {photos} toolbar theme="dark" type="{($wm.width<1025)?'popup':'page'}" bind:this={browser} />
    {#each thumbs as thumb, i (thumb.id)}
      <div on:click={browser.open(i)} class="item" style="background-image:url({thumb.thumbUrl})"></div>
    {/each}
  </div>
  {/if}
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.photo-nav {
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-evenly;
  align-items:center;
}

.photo-nav .item {
  width:84px;
  height:84px;
  margin:12px;
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;
  border:solid 1px rgba(255,255,255,0.3);
  border-radius:8px;
}

.photo-nav .item img {
  width:100%;
  height:auto;
}</style>

<script lang="ts">var _a;
import { findSourceStore } from "../../utilities";
import { Readable } from "svelte/store";
import { PhotoBrowser, Row, Col, Button } from 'framework7-svelte';
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import { afterUpdate, getContext, onMount } from "svelte";
import CardBase from "../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { windowMetrics as wm } from '../../../../js/stores.js';
export let element;
export let sources;
let browser;
$: source = findSourceStore($sources, 'YinzCamAppServerMediaGallery');
$: data = (source) ? $source : {};
$: thumbs = [].concat((_a = data === null || data === void 0 ? void 0 : data.Photos) === null || _a === void 0 ? void 0 : _a.Photo).filter(photo => { var _a; return (_a = photo === null || photo === void 0 ? void 0 : photo.Id) === null || _a === void 0 ? void 0 : _a._text; }).map(photo => {
    var _a, _b, _c;
    return {
        id: (_a = photo === null || photo === void 0 ? void 0 : photo.Id) === null || _a === void 0 ? void 0 : _a._text,
        thumbUrl: (_b = photo === null || photo === void 0 ? void 0 : photo.ThumbUrl) === null || _b === void 0 ? void 0 : _b._text,
        imageUrl: (_c = photo === null || photo === void 0 ? void 0 : photo.ImageUrl) === null || _c === void 0 ? void 0 : _c._text,
    };
});
$: photos = thumbs.map(thumb => thumb.imageUrl);
$: isDesktop = $wm.width > 1024;
let createDesktopNavigationMarkup = function (targetElement, pbInstance) {
    var navContainer = document.createElement('div');
    navContainer.classList.add('desktop-navigation');
    var previousButtonEl = document.createElement('div');
    previousButtonEl.classList.add('navigation-button', 'navigate-previous');
    var previousButtonIconEl = document.createElement('i');
    previousButtonIconEl.classList.add('icon', 'size-24', 'icon-prev');
    previousButtonEl.appendChild(previousButtonIconEl);
    var nextButtonEl = document.createElement('div');
    nextButtonEl.classList.add('navigation-button', 'navigate-next');
    var nextButtonIconEl = document.createElement('i');
    nextButtonIconEl.classList.add('icon', 'size-24', 'icon-next');
    nextButtonEl.appendChild(nextButtonIconEl);
    navContainer.appendChild(previousButtonEl);
    navContainer.appendChild(nextButtonEl);
    targetElement.appendChild(navContainer);
    previousButtonEl.addEventListener('click', function () {
        pbInstance.swiper.slidePrev(300, true);
        if (!pbInstance.swiper.isBeginning && !pbInstance.swiper.isEnd) {
            previousButtonEl.classList.remove('inactive');
            nextButtonEl.classList.remove('inactive');
        }
        else if (pbInstance.swiper.isBeginning) {
            previousButtonEl.classList.add('inactive');
        }
    });
    nextButtonEl.addEventListener('click', function () {
        pbInstance.swiper.slideNext(300, true);
        if (!pbInstance.swiper.isBeginning && !pbInstance.swiper.isEnd) {
            previousButtonEl.classList.remove('inactive');
            nextButtonEl.classList.remove('inactive');
        }
        else if (pbInstance.swiper.isEnd) {
            nextButtonEl.classList.add('inactive');
        }
    });
};
let createDesktopNavigationUI = function (element, pbInstance) {
    var desktopNavUI = document.querySelectorAll('.desktop-navigation');
    if (!desktopNavUI || !desktopNavUI.length) {
        createDesktopNavigationMarkup(element, pbInstance);
    }
};
onMount(() => {
    var pb = browser.instance();
    pb.on('open', function () {
        if (isDesktop) {
            createDesktopNavigationUI(pb.$el[0], pb);
        }
    });
});
</script>
