<script lang="ts">import ImageWithPlaceholder from '../../ImageWithPlaceholder/ImageWithPlaceholder.svelte';
import imageFoul from "../../image/foul_mark.png";
import imageOffsides from "../../image/offsides_mark.png";
export let data = null;
export let text = "";
</script>

<div class="card-el">
  <div class="top-info">
    <span class="time">{data.Time}</span>
    <ImageWithPlaceholder src={data.TeamLogo} alt="TeamLogo" class="team-logo" placeholder="/static/images/default_club.png" />
  </div>
  <div class="mark">
    <img
      src={data.Type === 'FOUL' ? imageFoul : imageOffsides}
      alt="mark"
      class="mark-img"
    />
  </div>
  <div class="comment">{data.Type}</div>
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.card-el {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 11px 20px 15px 20px;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
}

.top-info {
  position: absolute;
  left: 16px;
  top: 11px;
  right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time {
  line-height: 31px;
  font-family: AeonikTRIAL;
  font-size: 20.2px;
  font-weight: bold;
  color: #2b2c2e;
  display: block;
}

:global(.team-logo) {
  height: 36px;
  display: block;
}

.mark {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
}

.mark img {
  width: 90%;
  display: block;
}

.comment {
  margin: 11px 0 0 0;
  line-height: 22px;
  font-family: AeonikTRIAL;
  font-size: 18.6px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}

@media (max-width: 1px) {
  .top-info {
    position: absolute;
    left: 8px;
    top: 8px;
    right: 8px;
  }

  .time {
    line-height: 21px;
    font-size: 13.8px;
  }

  :global(.team-logo) {
    height: 26px;
  }
}</style>
