import { get } from "svelte/store";
import { AbstractReactiveMicrocomponent } from "../AbstractReactiveMicrocomponent";
import { ReactiveMicrocomponentConfig } from "../ReactiveMicrocomponentConfig";

export class ManualPassthrough<T> extends AbstractReactiveMicrocomponent<T, [], { value: T }> {
  public constructor(arg: string | ReactiveMicrocomponentConfig<T>, initialOutputValue?: T) {
    super((typeof arg === 'string')? 
      { name: arg, initialOutputValue, initialControlValue: { value: initialOutputValue } } : 
      { ...arg, initialOutputValue, initialControlValue: { value: initialOutputValue } });
  }

  public getValue(): T {
    return get(this.store);
  }

  public setValue(value: T): void {
    this.log.debug(`ManualPassthrough: setting value to ${value}`);
    this.setControl({ value });
  }

  protected async update($control: { value: T }): Promise<T> {
    this.log.debug(`ManualPassthrough: update with control ${$control}`);
    return $control.value;
  }
}
