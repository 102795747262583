<CardBase {element}>
  <div class="laliga-slim-news-card type-reg-news media-type-v">
    <a href={getHrefForMediaItem(type, id)}>
      <img class="thumbnail" src={imageUrl} alt="">
      <div class="content">
        <h3 class="title">{title}</h3>
        <span class="publish-date">{publishDateFmt}</span>
        <span class="team-name">{cat}</span>
        <img class="icon share-icon" src={shareIcon} alt="Share">
      </div>
    </a>
  </div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.laliga-slim-news-card.type-reg-news {
  border-radius:2px;
  overflow:hidden;
  width: 100%;
  height: 100%;
  background-color:#FFF;
  --font-scaling: 0.25;
}

.laliga-slim-news-card.type-reg-news a {
  width: 100%;
  height: 100%;
  text-decoration:none;
  display:flex;
  flex-wrap:wrap;
  position:relative;
  overflow:hidden;
}

.laliga-slim-news-card.type-reg-news a .thumbnail {
  width:100%;
  height:intrinsic;
  height:auto;
  object-fit: fill;
}

.laliga-slim-news-card.type-reg-news a .content {
  width:100%;
  padding:10%;
  align-items: center;
}

.laliga-slim-news-card.type-reg-news a .content .title {
  margin:0 0 16px 0;
  color:#111;
  font-weight:700;
  line-height:1.3;
}

.laliga-slim-news-card.type-reg-news a .content .publish-date {
  margin-right:16px;
  color:#000;
  font-weight:normal;
  letter-spacing:1px;
  opacity:0.4;
}

.laliga-slim-news-card.type-reg-news a .content .team-name {
  color:blue;
  font-weight:700;
  letter-spacing:1px;
  text-transform:uppercase;
}

.laliga-slim-news-card.type-reg-news a .content .share-icon {
  width: 1.0vw;
  height:intrinsic;
  height:auto;
  margin:0;
  padding:0;
  /*
    position:absolute;
    right:5%;
    bottom:5%;
    z-index:2;
    */
}

@media screen and (min-width:501px) and (max-width:1199px){
  .laliga-slim-news-card.type-reg-news a .content .title {
    font-size: calc(5vw * var(--font-scaling));
  }

  .laliga-slim-news-card.type-reg-news a .content .publish-date, .laliga-slim-news-card.type-reg-news a .content .team-name {
    font-size: calc(3vw * var(--font-scaling));
  }

  .laliga-slim-news-card.type-reg-news a .content .share-icon {
    width: calc(3vw * var(--font-scaling));
  }
}

@media screen and (max-width:500px){
  .laliga-slim-news-card.type-reg-news a {
    padding:0 8px;
    justify-content:space-between;
    align-items:center;
  }

  .laliga-slim-news-card.type-reg-news a .thumbnail {
    width:33.3%;
    height:auto;
    padding:8px;
  }

  .laliga-slim-news-card.type-reg-news a .content {
    width:66.6%;
    padding:8px;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-evenly;
    align-items:center;
  }

  .laliga-slim-news-card.type-reg-news a .content .title {
    font-size: 4vw;
  }

  .laliga-slim-news-card.type-reg-news a .content .publish-date, .laliga-slim-news-card.type-reg-news a .content .team-name {
    font-size: 2.7vw;
  }

  .laliga-slim-news-card.type-reg-news a .content .share-icon {
    width: 2.7vw;
    position: static;
    right:auto;
    bottom:auto;
    z-index:2;
    display:inline-flex;
  }
}</style>

<script type="ts">var _a, _b, _c, _d, _e, _f;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { Link } from 'framework7-svelte';
import shareIcon from './images/share-icon-blue.svg';
import { findSourceStore, getHrefForMediaItem } from "../../../../../components/cards/utilities";
import { tdate } from "../../../../../js/i18n";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerMediaSource');
}
;
$: data = (sourceStore) ? $sourceStore : {};
$: type = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Type;
$: id = (_b = data === null || data === void 0 ? void 0 : data.Id) === null || _b === void 0 ? void 0 : _b._text;
$: imageUrl = (_c = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _c === void 0 ? void 0 : _c._text;
$: title = (_d = data === null || data === void 0 ? void 0 : data.Title) === null || _d === void 0 ? void 0 : _d._text;
$: timestamp = (_e = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _e === void 0 ? void 0 : _e._text;
$: publishDate = (timestamp) ? new Date(timestamp) : null;
$: publishDateFmt = $tdate(publishDate, { month: 'short', day: 'numeric', year: 'numeric' });
$: cat = (_f = data === null || data === void 0 ? void 0 : data.PrimaryCategoryName) === null || _f === void 0 ? void 0 : _f._text;
</script>