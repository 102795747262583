import convert from 'xml-js';
import deepEqual from 'deep-equal';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { ContentType, ContentTypeInfo } from './ContentType';
import { CacheControl, CacheControlInfo } from './CacheControl';
import { AbstractReactiveMicrocomponent, ManualPassthrough, RepeatingTimer } from 'yinzcam-rma';
import { YinzCamDataFormat } from './YinzCamDataFormat';
import { YinzCamAPIRequest } from './YinzCamAPIRequest';
import { YinzCamAPIResponse } from './YinzCamAPIResponse';
import { CACHE_CONTROL_HEADER_KEY, CONTENT_TYPE_HEADER_KEY, LOCAL_TIMESTAMP_HEADER_KEY } from './constants';
import { AppConfig } from 'yinzcam-config';
import { YinzCamAPIRequestParameters } from './YinzCamAPIRequestParameters';
import { YinzCamAPIRequestParameterComponent } from './YinzCamAPIRequestParameterComponent';
import { buildAxiosRequest } from './utilities';
import { windowVisible } from '../../js/stores';

export class YinzCamAPIRequestComponent extends AbstractReactiveMicrocomponent<YinzCamAPIResponse, [number, YinzCamAPIRequestParameters], undefined, { lastUpdateTime: number; lastParameters: YinzCamAPIRequestParameters; }> {
  private readonly appConfig: AppConfig;
  private readonly axios: AxiosInstance;
  private readonly request: YinzCamAPIRequest;
  private readonly frequencyFeedbackOutput: ManualPassthrough<number>;

  public constructor(name: string, appConfig: AppConfig, axios: AxiosInstance, request: YinzCamAPIRequest, timerInput: RepeatingTimer, parametersInput: YinzCamAPIRequestParameterComponent, frequencyFeedbackOutput: ManualPassthrough<number>) {
    super({ name }, timerInput, parametersInput);
    this.appConfig = appConfig;
    this.axios = axios;
    this.request = request;
    this.frequencyFeedbackOutput = frequencyFeedbackOutput;
  }

  protected async update($control: unknown, $timer: number, $parameters: YinzCamAPIRequestParameters): Promise<YinzCamAPIResponse> {
    //this.log.info(`in update!!! $timer:${$timer}, $parameters:${$parameters}`);
    if (!$timer || !$parameters || 
        (this.state.lastUpdateTime >= $timer && deepEqual(this.state.lastParameters, $parameters))) {
      //this.log.debug('timer and parameters input not updated, ignoring');
      throw null;
    }
    // TODO: I don't like this dependency on stores.js and it should be a reactive thing, not manual here.
    // Want to wrap a store in a reactive component.
    if (this.appConfig.stopPollingWhenWindowInvisible && !windowVisible) {
      throw null;
    }
    this.state.lastUpdateTime = $timer;
    this.state.lastParameters = $parameters;

    try {
      let axiosReq: AxiosRequestConfig = buildAxiosRequest(this.appConfig, $parameters, this.request);
      //console.log("AXIOS REQUEST", axiosReq);
      let rsp = await this.axios.request(axiosReq);
      rsp.headers[LOCAL_TIMESTAMP_HEADER_KEY] = Date.now();
  
      // NOTE: can't rely on rsp.request to be set here. Sometimes it's not set.
      let url: URL = (rsp.config.baseURL) ? new URL(rsp.config.url, rsp.config.baseURL) : new URL(rsp.config.url);
      if (rsp.config.params) {
        for (let k of Object.getOwnPropertyNames(rsp.config.params)) {
          url.searchParams.set(k, rsp.config.params[k]);
        }
      }
  
      let parseType: YinzCamDataFormat = this.extractContentType(rsp);
      let ttl: number = this.extractCacheTTL(rsp);
      let data = this.extractData(rsp, parseType);
  
      // set the upstream timer to the ttl
      this.frequencyFeedbackOutput.setValue(ttl);
  
      return {
        status: rsp.status,
        isStatusNot2xx: rsp.status < 200 || rsp.status > 299,
        url: url.toString(),
        data
      };
    } catch (error) {
      // exponential backoff
      const defaultRefreshInterval = this.appConfig.defaultCacheTimeSeconds * 1000;
      const maxRefreshInterval = defaultRefreshInterval * 2;
      let currentRefreshInterval = this.frequencyFeedbackOutput.getValue();
      if (!currentRefreshInterval) {
        currentRefreshInterval = this.appConfig.defaultCacheTimeSeconds * 1000;
      }
      const newRefreshInterval = Math.min(currentRefreshInterval * 2, maxRefreshInterval);
      this.frequencyFeedbackOutput.setValue(newRefreshInterval);
      throw error;
    }
  }

  private extractContentType(rsp: AxiosResponse<any>): YinzCamDataFormat {
    let parseType: YinzCamDataFormat = YinzCamDataFormat.TEXT;
    if (rsp?.headers?.[CONTENT_TYPE_HEADER_KEY]) {
      let header = rsp.headers[CONTENT_TYPE_HEADER_KEY] as string;
      let index = header.indexOf(',');
      header = (index !== -1)? header.substr(0, index) : header;
      let ct: ContentTypeInfo = ContentType.parse(header);
      // The switch(true) thing here was tempting: https://stackoverflow.com/questions/2896626/switch-statement-for-string-matching-in-javascript
      // ... leaving this here because the reactions to it on SO were funny
      let ctstr: string = ct.type.toLowerCase();
      if (/json/.test(ctstr)) {
        parseType = YinzCamDataFormat.JSON;
      } else if (/xml/.test(ctstr)) {
        parseType = YinzCamDataFormat.XML;
      } else if (/urlencoded/.test(ctstr)) {
        parseType = YinzCamDataFormat.URLENCODED;
      } else {
        parseType = YinzCamDataFormat.TEXT;
      }
    } else {
      this.log.warn('missing Content-Type header');
    }
    return parseType;
  }

  private extractCacheTTL(rsp: AxiosResponse<any>): number {
    let ttl: number = this.appConfig.defaultCacheTimeSeconds * 1000;
    if (rsp?.headers?.[CACHE_CONTROL_HEADER_KEY]) {
      let cc: CacheControlInfo = CacheControl.parse(rsp.headers[CACHE_CONTROL_HEADER_KEY]);
      if (cc && cc.maxAge) {
        ttl = cc.maxAge * 1000;
      }
    } else {
      this.log.warn('missing Cache-Control header');
    }
    return ttl;
  }

  private extractData(rsp: AxiosResponse<any>, parseType: YinzCamDataFormat): string | object {
    let data = rsp.data;
    switch (parseType) {
      case YinzCamDataFormat.JSON:
        if (typeof rsp.data === 'string') {
          // if it's a plain string, it needs parsed
          data = JSON.parse(rsp.data);
        } else if (rsp.data instanceof String) {
          // no idea why we would get a wrapper object but might as well cover that case
          data = JSON.parse(rsp.data.toString());
        } else {
          // assume it was already parsed by the library
          data = rsp.data;
        }
        break;
      case YinzCamDataFormat.XML:
        // More info on XML-JS conversion: https://www.npmjs.com/package/xml-js
        data = convert.xml2js(rsp.data, { compact: true, nativeType: true });
        break;
      case YinzCamDataFormat.URLENCODED:
      // TODO: I've never seen a server send back data in this format. Implement if needed.
      // Otherwise, assume it's just text for the caller to handle.
      case YinzCamDataFormat.TEXT:
      // TODO: had some ideas here to try to detect the content type (JSON/XML/HTML/...)
      // Don't want to get too cute here though.
      // Detecting JSON (trivial): https://stackoverflow.com/questions/9804777/how-to-test-if-a-string-is-json-or-not
      // Detecting XML: https://stackoverflow.com/questions/8672597/how-should-i-test-if-an-object-is-a-xml-document-in-a-cross-browser-way
      // Detecting HTML (troll): https://stackoverflow.com/questions/15458876/check-if-a-string-is-html-or-not
      // (intentional fall-through)
      default:
        data = rsp.data;
        break;
    }
    return data;
  }
}
