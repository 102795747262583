<script>
  export let item;

  let expanded = false;

  function onClickExpand() {
		expanded = !expanded;
	}
</script>

<li class="item">
  <div class="main-row">
    <div class="campaign-info">
      <div class="stadium">{item.stadium}</div>
      <div class="campaign">{item.place}</div>
    </div>

    <div class="team-info">
      <div class="name">{item.team1}</div>
      <div class="logo">
        <img src={item.teamLogo1} alt="log" />
      </div>
    </div>

    <div class="position-relative">
      <div class={"mobile-campaign-info" + (item.idaInfo ? "" : " d-none")}>
        {item.idaInfo}
      </div>
      {#if item.inProgress}
        <div class="startinfo">
          <span class="date">{item.date}</span>
          <div class="time">{item.time}</div>
        </div>
      {:else}
        <div class="scoreboard">
          <div>{item.score1}</div>
          <div>{item.score2}</div>
          <span class="btn-expand" role="button" on:click={onClickExpand}>
            <i class="f7-icons">{expanded ? "chevron_up" : "chevron_down"}</i>
          </span>
        </div>
      {/if}
    </div>

    <div class="team-info away">
      <div class="name">{item.team2}</div>
      <div class="logo">
        <img src={item.teamLogo2} alt="log" />
      </div>
    </div>

    <div class="history">
      <button class="btn btn-primary" disabled='{!item.idaInfo && item.inProgress}'>
        Resúmen
      </button>
    </div>

    <span class="btn-right-expand" role="button">
      <i class="f7-icons">chevron_right</i>
    </span>
  </div>
  {#if expanded}
    <div class="detail-row">
      <div class="detail1">
        {#each item.scoreDetails1 as detailItem}
          <div class="detail-item">
            <span class="label">{detailItem.label}</span>
            <span class="goals">{detailItem.goals}</span>
          </div>
        {/each}
      </div>
      <div class="detail2">
        {#each item.scoreDetails2 as detailItem}
          <div class="detail-item">
            <span class="label">{detailItem.label}</span>
            <span class="goals">{detailItem.goals}</span>
          </div>
        {/each}
      </div>
    </div>
  {/if}
</li>

<style>.item {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.main-row {
  display: flex;
  align-items: center;
  padding: 18px 32px;
  border-top: 1px solid #dde0e3;
  line-height: 1;
  font-family: CoreSansC-75;
}

.item:last-child {
  border-bottom: 1px solid #dde0e3;
}

.campaign-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #dde0e3;
  color: #8e9194;
  font-size: 12px;
  width: 173.8px;
  height: 64px;
  padding: 16px 0px;
}

.campaign-info .stadium {
  font-weight: bold;
  margin: 5px 0 8px 0;
  width: 142.8px;
  height: 12px;
  flex-grow: 0;
  font-family: CoreSansC-65;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #8e9194;
}

.campaign-info .campaign {
  width: 142.8px;
  height: 12px;
  flex-grow: 0;
  font-family: CoreSansC-45;
  font-size: 12px;
  line-height: 1;
  color: #8e9194;
}

.team-info {
  margin-left: auto;
  display: flex;
  align-items: center;
  text-align: right;
}

.team-info .name {
  width: 131.7px;
  font-size: 16px;
  font-weight: bold;
  color: #282c30;
  font-family: 'Core Sans C 65';
  font-size: 16px;
}

.team-info .logo img {
  width: 50px;
}

.team-info.away {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: auto;
  text-align: left;
}

.scoreboard {
  background: #b0b8bf;
  margin: 6px 0;
  text-align: center;
  height: 62px;
  position: relative;
}

.scoreboard div {
  font-size: 32px;
  font-weight: 800;
  line-height: 62px;
  display: inline-block;
  width: 62px;
  height: 62px;
  color: #2e3338;
}

.scoreboard .btn-expand {
  position: absolute;
  font-size: 14px;
  left: 0px;
  width: 100%;
  bottom: -30px;
  color: #848f9a;
  cursor: pointer;
}

.btn-expand:hover i {
  color: #0088cc;
}

.startinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 128px;
  margin: 6px 0;
  color: #788591;
  text-align: center;
}

.startinfo .date {
  font-size: 14px;
  line-height: 1.71;
}

.startinfo .time {
  font-weight: 800;
  font-size: 20px;
  line-height: 1.2;
}

.btn-primary {
  width: 140px;
  height: 34px;
  text-align: center;
  background: #788591;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  font-family: 'Core Sans C 65';
}

.btn-primary[disabled] {
  background: #b0b8bf
}

.summary {
  color: #535c65;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.btn-right-expand {
  display: none;
}

.mobile-campaign-info {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #788591;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -20px;
}

.detail-row {
  display: flex;
  padding: 0px 0px 20px 33px;
}

.detail1 {
  margin-left: auto;
  text-align: right;
}

.detail2 {
  margin-right: auto;
  margin-left: 128px;
}

.detail-row .label {
  height: 15px;
  flex-grow: 0;
  font-family: CoreSansC-45;
  font-size: 10.5px;
  line-height: 1.43;
  text-align: right;
  color: #49525a;
}

.detail-row .goals {
  height: 11px;
  flex-grow: 0;
  font-family: CoreSansC-75;
  font-size: 11.2px;
  font-weight: 800;
  letter-spacing: 0.9px;
  color: #49525a;
}

.detail-item {
  margin-bottom: 4px;
}

.d-none {
  display: none;
}

.position-relative {
  position: relative;
}

@media (max-width: 991px) {
  .main-row {
    padding: 12px 0px;
    position: relative;
    align-items: flex-end;
  }

  .campaign-info,
    .history {
    display: none;
  }

  .team-info {
    flex-direction: column-reverse !important;
    text-align: center !important;
  }

  .team-info .logo img {
    width: 48px;
  }

  .team-info .name {
    margin: 0 !important;
  }

  .scoreboard {
    width: 80px;
    height: 40px;
    display: flex;
    margin: 0px 15px;
  }

  .scoreboard div {
    font-size: 20px;
    line-height: 40px;
    width: 40px;
    height: 40px;
  }

  .scoreboard .btn-expand {
    display: none;
    height: 6px;
  }

  .startinfo {
    width: 80px;
    margin: 0 15px;
  }

  .team-info .name {
    font-family: CoreSansC-45;
    font-size: 12px;
    line-height: 1;
    margin-top: 8px;
    color: #282c30;
  }

  .btn-right-expand {
    display: block;
    position: absolute;
    right: 8px;
    top: calc(50% - 7px);
    cursor: pointer;
  }

  .btn-right-expand .f7-icons {
    font-size: 16px;
  }

  .mobile-campaign-info {
    font-size: 10px;
    line-height: 1.6;
  }

  .detail-row {
    display: none;
  }
}</style>
