<Boundary>
  <div>
    {#each expandedArrays as array, i (array.id)}
      <div class:display-none={!childContentAvailable[i]} id={array.id}>
        <CardsArray sequenceId={generateSequenceId(parentSequenceId, i)} {array} bind:contentAvailable={childContentAvailable[i]} />
      </div>
    {/each}
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceArray } from "yinzcam-cards";
import { expandRepeats, generateSequenceId } from "../utilities";
import CardsArray from "./CardsArray.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
export let parentSequenceId = "";
export let arrays;
export let contentAvailable = true;
let childContentAvailable = [];
$: {
    contentAvailable = childContentAvailable.some((item) => item);
}
let expandedArrays = [];
$: if (arrays) {
    expandedArrays = expandRepeats(arrays);
}
</script>
