<CardBase {element} {sources}>
  <div class="soccer-playerdetail-discipline" style="
    width: {element.data.width || 'auto'};
    height: {element.data.height || 'auto'};
  ">
    <div class="content">
      <div class="title">
        Disciplina
      </div>
      <div class="discipline-cards-info">
        <div class="discipline-card">
          <div class="yellow-card" />
          <div class="card-number">
            <div class="discipline-card-count">{yellowCards}</div>
            <div class="discipline-card-label">Tarjeta Amarilla</div>
          </div>
        </div>
        <div class="discipline-card">
          <div class="red-card" />
          <div class="card-number">
            <div class="discipline-card-count">{redCards}</div>
            <div class="discipline-card-label">Tarjeta Roja</div>
          </div>
        </div>
      </div>
    </div>
    <div class="property">
      <div class="label">Fueras de juego<span>{offsides}</span></div>
      <div class="label">Faltas recibidas<span>{foulsSuffered}</span></div>
      <div class="label">Faltas cometidas<span>{foulsCommitted}</span></div>
      <div class="label">Penaltis<span>{penalties}</span></div>
    </div>
  </div>
</CardBase>

<style type="text/scss">*, *::before, *::after {
  box-sizing: border-box;
}

.soccer-playerdetail-discipline {
  display: flex;
  justify-content: space-between;
  padding: 39px 42px 39px 40px;
  border-radius: 4px;
  background-color: var(--neutrals-white-100);
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-discipline {
    flex-direction: column;
    padding: 24px 24px 32px;
  }
}

.soccer-playerdetail-discipline .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-discipline .content {
    width: 409px;
  }
}

.soccer-playerdetail-discipline .content .title {
  font-family: CoreSansC-75;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  text-align: left;
  color: var(--neutrals-black-black-30);
}

.soccer-playerdetail-discipline .content .discipline-cards-info {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-discipline .content .discipline-cards-info {
    flex-direction: column;
    justify-content: center;
    margin-top: 14px;
  }
}

.soccer-playerdetail-discipline .content .discipline-cards-info .discipline-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 9px;
  gap: 9px;
  height: 58px;
}

@media screen and (min-width: 768px) {
  .soccer-playerdetail-discipline .content .discipline-cards-info .discipline-card {
    width: 172px;
  }
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-discipline .content .discipline-cards-info .discipline-card {
    justify-content: space-between;
  }
}

.soccer-playerdetail-discipline .content .discipline-cards-info .discipline-card .card-number {
  display: flex;
  align-items: center;
  grid-gap: 9px;
  gap: 9px;
}

.soccer-playerdetail-discipline .content .discipline-cards-info .discipline-card .yellow-card {
  width: 33.8px;
  height: 48.3px;
  border-radius: 2.4px;
  background-color: #f6be00;
}

.soccer-playerdetail-discipline .content .discipline-cards-info .discipline-card .red-card {
  width: 33.8px;
  height: 48.3px;
  border-radius: 2.4px;
  background-color: #d20028;
}

.soccer-playerdetail-discipline .content .discipline-cards-info .discipline-card .discipline-card-count {
  font-family: CoreSansC-75;
  font-size: 48px;
  font-weight: 800;
  line-height: 1.16;
  text-align: left;
  color: var(--neutrals-grey-lighter-20);
}

.soccer-playerdetail-discipline .content .discipline-cards-info .discipline-card .discipline-card-label {
  width: 67px;
  height: 24px;
  font-family: CoreSansC-75;
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: left;
  color: var(--neutrals-grey-darker-60);
}

.soccer-playerdetail-discipline .property {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 199px;
  grid-gap: 18px;
  gap: 18px;
}

@media screen and (max-width: 767px) {
  .soccer-playerdetail-discipline .property {
    width: 100%;
    margin-top: 32px;
  }
}

.soccer-playerdetail-discipline .property .label {
  display: flex;
  justify-content: space-between;
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
  color: var(--neutrals-grey-darker-60);
}

.soccer-playerdetail-discipline .property .label span {
  font-family: CoreSansC-65;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  text-align: right;
  color: var(--neutrals-grey-lighter-20);
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f;
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { findSourceStore } from "../../../utilities";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerStatsPlayer');
}
;
$: yellowCards = ((_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['yellow_cards']) === null || _a === void 0 ? void 0 : _a.ValueLabel) || "0";
$: redCards = ((_b = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['red_cards']) === null || _b === void 0 ? void 0 : _b.ValueLabel) || "0";
$: foulsSuffered = ((_c = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['total_fouls_won']) === null || _c === void 0 ? void 0 : _c.ValueLabel) || "0";
$: foulsCommitted = ((_d = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['total_fouls_conceded']) === null || _d === void 0 ? void 0 : _d.ValueLabel) || "0";
$: offsides = ((_e = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['offsides']) === null || _e === void 0 ? void 0 : _e.ValueLabel) || "0";
$: penalties = ((_f = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.PlayerSeasonStatsMap['penalty_goals_conceded']) === null || _f === void 0 ? void 0 : _f.ValueLabel) || "0";
</script>
