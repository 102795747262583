<Boundary>
  {#if resolved}
    <Link href={resolved.href} external={resolved.external} target={(resolved.external)?'_blank':'_self'} {...$$restProps}>
      <slot></slot>
    </Link>
  {:else}
    <Link {href} {external} {...$$restProps}>
      <slot></slot>
    </Link>
  {/if}
</Boundary>

<style>*, *:before, *:after {
  box-sizing:border-box;
}</style>

<script lang="ts">import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { ResolvedUrl } from '../../../js/url';
import { Link } from 'framework7-svelte';
export let resolved;
export let href;
export let external;
</script>
