<div class="laliga-wide-news-card type-large-news media-type-v">
  <a href="#">
    <img class="thumbnail" src="static/thumbs/wide-media.jpg" alt="">
    <div class="content">
      <h3 class="title">Suave sesión de entrenamiento para 'El Glorioso' tras dos días de descanso</h3>
      <span class="publish-date">09 Nov 2020</span>
      <span class="category">Fundacion</span>
      <img class="icon share-icon" src={shareIcon} alt="Share">
    </div>
  </a>
</div>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.laliga-wide-news-card.type-large-news {
  border-radius:8px;
  overflow:hidden;
  width: 100%;
  height: 100%;
}

.laliga-wide-news-card.type-large-news a {
  width:100%;
  text-decoration:none;
  display:flex;
  flex-wrap:wrap;
  position:relative;
  overflow:hidden;
  width: 100%;
  height: 100%;
}

.laliga-wide-news-card.type-large-news a .thumbnail {
  width:100%;
  height:intrinsic;
  height:auto;
  object-fit: contain;
}

.laliga-wide-news-card.type-large-news a .content {
  width:100%;
  padding:5%;
  background:linear-gradient(transparent,black);
  position:absolute;
  left:0;
  bottom:0;
  z-index:2;
}

.laliga-wide-news-card.type-large-news a .content .title {
  margin:0 0 16px 0;
  color:#FFF;
  font-weight:700;
  line-height:1.3;
}

.laliga-wide-news-card.type-large-news.media-type-v a .content .title:before {
  width:18px;
  height:18px;
  margin:0 16px 0 0;
  padding:8px;
  background-color:#FFF;
  background-image:url('./images/media-icon-video.svg');
  background-repeat:no-repeat;
  background-size:70%;
  background-position:center;
  content:"";
  display:inline-block;
}

.laliga-wide-news-card.type-large-news a .content .publish-date {
  margin-right:16px;
  color:#FFF;
  font-size:1.25vw;
  font-weight:normal;
  letter-spacing:1px;
  opacity:0.7;
}

.laliga-wide-news-card.type-large-news a .content .category {
  color:#FFF;
  font-size:1.25vw;
  font-weight:700;
  letter-spacing:1px;
  text-transform:uppercase;
}

.laliga-wide-news-card.type-large-news a .content .share-icon {
  width:16px;
  height:intrinsic;
  height:auto;
  margin:0;
  padding:0;
  position:absolute;
  right:5%;
  bottom:10%;
  z-index:2;
}

@media screen and (min-width:1200px){
  .laliga-wide-news-card.type-large-news a .content .title {
    font-size:1.25vw;
  }
}

@media screen and (min-width:501px) and (max-width:1199px){
  .laliga-wide-news-card.type-large-news a .content .title {
    font-size:1.75vw;
  }

  .laliga-wide-news-card.type-large-news.media-type-v a .content .title:before {
    width:16px;
    height:16px;
  }
}

@media screen and (max-width:500px){
  .laliga-wide-news-card.type-large-news.media-type-v a .content .title:before {
    width:8px;
    height:8px;
    margin:0 8px 0 0;
  }

  .laliga-wide-news-card.type-large-news a .content .publish-date, .laliga-wide-news-card.type-large-news a .content .category {
    font-size:1.5vw;
  }

  .laliga-wide-news-card.type-large-news a .content .share-icon {
    width:18px;
  }
}</style>

<script>
  import shareIcon from './images/share-icon.svg';
</script>
