<CardBase {element}>
	<div class="type-b6 media-{type}">
		<a href={getHrefForMediaItem(type, id, slug)}>
			<div class="thumbnail" style="background-image:url({thumbUrl});"></div>
			<div class="content">
				<h3 class="title">{title || ''}</h3>
				<span class="publish-date">{$tdate(publishDate, { month: 'short', day: 'numeric', year: 'numeric' })}</span>
			</div>
		</a>
	</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.type-b6 {
  width:100%;
  height:100%;
  overflow:hidden;
  font-family:var(--f7-font-family);
  display:flex;
  align-items:stretch;
}

.type-b6 a {
  width:100%;
  min-height:100px;
  padding:0 0 0 40%;
  text-decoration:none;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:stretch;
  position:relative;
  overflow:hidden;
}

.type-b6 .thumbnail {
  width:40%;
  height:100%;
  padding:30% 0 0 0;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  position:absolute;
  top:0;
  left:0;
}

.type-b6 .content {
  width:100%;
  padding:6px 12px;
  color:#FFF;
  display:flex;
  flex-wrap:wrap;
  align-items:flex-start;
}

.type-b6 .content > * {
  width:100%;
  font-family:var(--f7-font-family);
}

.type-b6 .content .title {
  margin:0 0 16px 0;
  line-height:1.4;
  order:2;
}

.type-b6 .content .publish-date {
  margin:4px 0;
  opacity:0.75;
  order:1;
  text-transform:uppercase;
}

.type-b6 .content .title, .type-b6 .content .publish-date {
  font-size:16px;
}

@media screen and (min-width:1025px){
  .type-b6 .content .title {
    font-size:14px;
  }
}

@media screen and (min-width:1000px) and (max-width:1199px){
  .type-b6 .content {
    font-size:1.5vw;
  }
}

@media screen and (min-width:768px) and (max-width:999px){
  .type-b6 .content {
    font-size:2vw;
  }
}

@media screen and (max-width:767px){
  .type-b6.media-V a:before {
    width:40%;
    height:100%;
    background-image:url('https://resources-us.yinzcam.com/csf/shared/icons/icon_play.svg');
    background-repeat:no-repeat;
    background-size:36px 36px;
    background-position:center;
    position:absolute;
    top:0;
    left:0;
    z-index:2;
    content:"";
  }
}

@media screen and (max-width:500px){
  .type-b6 .content .title, .type-b6 .content .publish-date {
    font-size:2.75vw;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore, getHrefForMediaItem } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import { tdate } from "../../../../js/i18n";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerMediaSource');
}
;
$: data = (sourceStore) ? $sourceStore : {};
$: type = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Type;
$: id = (_b = data === null || data === void 0 ? void 0 : data.Id) === null || _b === void 0 ? void 0 : _b._text;
$: slug = (_c = data === null || data === void 0 ? void 0 : data.Slug) === null || _c === void 0 ? void 0 : _c._text;
$: imageUrl = (_d = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _d === void 0 ? void 0 : _d._text;
$: thumbUrl = (_e = data === null || data === void 0 ? void 0 : data.ThumbUrl) === null || _e === void 0 ? void 0 : _e._text;
$: title = (_f = data === null || data === void 0 ? void 0 : data.Title) === null || _f === void 0 ? void 0 : _f._text;
$: timestamp = (_g = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _g === void 0 ? void 0 : _g._text;
$: publishDate = (timestamp) ? new Date(timestamp) : null;
</script>