<YCPage query={pageQuery}>
  <GoogleAnalyticsUA properties={[]} path={f7route.path} params={f7route.query} />
  <CardsPage {listName} {pageQuery} />
</YCPage>

<script lang='ts'>import YCPage from '../components/YCPage.svelte';
import { GoogleAnalyticsUA } from 'svelte-google-analytics/src';
import CardsPage from '../components/cards/common/CardsPage.svelte';
export let f7route;
const listName = 'Home';
const pageQuery = {
    backButton: 'false',
    round: 'previous'
};
</script>
