<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h;
import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { f7ready } from "framework7-svelte";
import ic_circle_arrow_left_white from "../../../../../static/icons/circular-arrow-left-white.svg";
import ic_circle_arrow_right_white from "../../../../../static/icons/circular-arrow-right-white.svg";
import ic_circle_arrow_left_blue from "../../../../../static/icons/circular-arrow-left-blue.svg";
import ic_circle_arrow_right_blue from "../../../../../static/icons/circular-arrow-right-blue.svg";
import { findSourceStore } from "../../../utilities";
let flipped = true;
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerTeamRoster');
}
;
//$: console.log("SOURCESTORE", $sourceStore);
const handlePlayerFile = () => f7ready((f7) => { var _a; return f7.views.main.router.navigate('/cards/PlayerDetail?playerId=' + ((_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore._attributes) === null || _a === void 0 ? void 0 : _a.Id)); });
let type = "black";
$: type = element.data.type;
let history;
$: history = {
    minites: ((_a = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.MinutesPlayed) === null || _a === void 0 ? void 0 : _a._text) || "",
    games: ((_b = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.GamesPlayed) === null || _b === void 0 ? void 0 : _b._text) || "",
    unemployed: ((_c = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.DidNotPlay) === null || _c === void 0 ? void 0 : _c._text) || "",
    embedded: ((_d = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.GoalsConceded) === null || _d === void 0 ? void 0 : _d._text) || "",
};
let meta = { number: "", position: "", name: "", photo: "" };
$: meta = {
    number: ((_e = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore._attributes) === null || _e === void 0 ? void 0 : _e.Number) || "",
    position: ((_f = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore._attributes) === null || _f === void 0 ? void 0 : _f.Position) || "",
    name: ((_g = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore._attributes) === null || _g === void 0 ? void 0 : _g.Name) || "",
    photo: ((_h = $sourceStore === null || $sourceStore === void 0 ? void 0 : $sourceStore.XLargePngImageUrl) === null || _h === void 0 ? void 0 : _h._text) || "",
};
let position = "";
$: position = meta.position.toUpperCase();
function turn(node, { delay = 0, duration = 500 }) {
    return {
        delay,
        duration,
        css: (t, u) => `
				transform: rotateY(${1 - u * 180}deg);
				opacity: ${1 - u};
			`,
    };
}
function flip() {
    flipped = !flipped;
}
</script>

<div class="playercard-container">
  <div class="playercard">
    {#if flipped}
      <div class="side" transition:turn>
        <div
          class="playercard-front {type}"
          style="background-image: url({meta.photo});"
        >
          <div class="playercard-header {type}">
            <div class="number">{meta.number ? meta.number : ''}</div>
            {#if history}
              <div class="flip-icon" on:click|stopPropagation={flip}>
                <img src={type === "white" ? ic_circle_arrow_right_blue : ic_circle_arrow_right_white} width={24} height={24} />
              </div>
            {/if}
          </div>
          <div class="playercard-meta {type}">
            <div class="position">{position}</div>
            <div class="name">{meta.name}</div>
          </div>
          {#if type !== "blue"}
            <div class="playercard-gradient" />
          {/if}
        </div>
      </div>
    {:else}
      <div class="side back" transition:turn>
        <div class="playercard-back {type}">
          <div class="playercard-back-header">
            <div class="avatar">
              <img src={meta.photo} />
            </div>
            <div class="playercard-back-meta {type}">
              <div class="position">{position}</div>
              <div class="name">{meta.name}</div>
            </div>
            <div class="flip-icon" on:click|stopPropagation={flip}>
              <img src={type !== "black" ? ic_circle_arrow_left_blue : ic_circle_arrow_left_white} width={24} height={24} />
            </div>
          </div>
          <div class="playercard-detail {type}">
            <div class="item">
              <div class="value">{history.minites}</div>
              <div class="description">MINUTOS JUGADOS</div>
            </div>
            <div class="item">
              <div class="value">{history.games}</div>
              <div class="description">PARTIDOS JUGADOS</div>
            </div>
            <div class="item">
              <div class="value">{history.unemployed}</div>
              <div class="description">PARADOS</div>
            </div>
            <div class="item">
              <div class="value">{history.embedded}</div>
              <div class="description">ENCAJADOS</div>
            </div>
          </div>
          <button class="btn-submit {type}" on:click={handlePlayerFile}>
            FICHA COMPLETA DEL JUGADOR
          </button>
        </div>
      </div>
    {/if}
  </div>
</div>

<style type="text/scss">.playercard-container {
  position: relative;
  width: 264px;
  height: 351px;
}

.playercard {
  width: 100%;
  height: 100%;
  position: absolute;
  perspective: 600;
}

.side {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playercard-front {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: auto;
}

.playercard-front.black {
  background-color: var(--neutrals-grey-darker-60);
}

.playercard-front.black .playercard-gradient {
  background-image: linear-gradient(rgba(40, 44, 48, 0), var(--neutrals-grey-darker-60));
}

.playercard-front.blue {
  background-color: #1d4990;
}

.playercard-front.white {
  background-color: var(--neutrals-white-100);
}

.playercard-front.white .playercard-gradient {
  background-image: linear-gradient(rgba(255, 255, 255, 0), var(--neutrals-white-100));
}

.playercard-front .playercard-gradient {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 50%;
}

.playercard-front .playercard-header {
  display: flex;
  justify-content: space-between;
}

.playercard-front .playercard-header.white .number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin: 16px 0 0 16px;
  background-color: #0032a0;
  font-family: CoreSansC-75;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.playercard-front .playercard-header .number {
  width: 48px;
  height: 48px;
  margin: 24px 0 0 24px;
  font-family: CoreSansC-75;
  font-size: 48px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
}

.playercard-front .playercard-header .flip-icon {
  width: 24px;
  height: 24px;
  margin-top: 24px;
  margin-right: 24px;
  z-index: 1;
}

.playercard-front .playercard-meta {
  width: 100%;
  padding: 19px 0 24px 24px;
  box-sizing: border-box;
  z-index: 1;
}

.playercard-front .playercard-meta.blue {
  background-color: var(--neutrals-white-100);
  color: var(--neutrals-grey-darker-60);
}

.playercard-front .playercard-meta.black {
  color: var(--neutrals-white-100);
}

.playercard-front .playercard-meta.white {
  color: #0032a0;
}

.playercard-front .playercard-meta .position {
  margin-bottom: 8px;
  font-family: CoreSansC-75;
  font-size: 10px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--alav-s-secondary);
}

.playercard-front .playercard-meta .name {
  font-family: CoreSansC-75;
  font-size: 32px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
}

.playercard-back {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.playercard-back.black {
  background-color: var(--neutrals-grey-darker-60);
}

.playercard-back.white, .playercard-back.blue {
  background-color: var(--neutrals-white-100);
}

.playercard-back .playercard-back-header {
  display: flex;
  padding: 24px 24px 22px 24px;
  border-bottom: 1px solid var(--neutrals-grey-lighter-90);
}

.playercard-back .playercard-back-header .avatar {
  width: 72px;
  height: 72px;
}

.playercard-back .playercard-back-header .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.playercard-back .playercard-back-header .playercard-back-meta {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 11px;
}

.playercard-back .playercard-back-header .playercard-back-meta.black .name {
  color: var(--neutrals-white-100);
}

.playercard-back .playercard-back-header .playercard-back-meta.white .name, .playercard-back .playercard-back-header .playercard-back-meta.blue .name {
  color: #0032a0;
}

.playercard-back .playercard-back-header .playercard-back-meta .position {
  font-family: CoreSansC-75;
  font-size: 10px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--alav-s-secondary);
}

.playercard-back .playercard-back-header .playercard-back-meta .name {
  font-family: CoreSansC-75;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
}

.playercard-back .playercard-detail {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 173px;
  padding: 32px 0 0 40px;
  grid-gap: 14px;
  gap: 14px;
}

.playercard-back .playercard-detail.black.item.value {
  color: var(--neutrals-white-100);
}

.playercard-back .playercard-detail.blue .item .value, .playercard-back .playercard-detail.white .item .value {
  color: #0032a0;
}

.playercard-back .playercard-detail .item {
  display: flex;
  align-items: center;
}

.playercard-back .playercard-detail .item .value {
  width: 35px;
  margin-right: 16px;
  font-family: CoreSansC-65;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.08px;
  text-align: left;
}

.playercard-back .playercard-detail .item .description {
  font-family: CoreSansC-75;
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: left;
  color: var(--neutrals-grey-lighter-20);
}

.playercard-back .btn-submit {
  width: 248px;
  height: 52px;
  margin: 0 8px;
  border-radius: 2px;
  border: none;
  font-family: CoreSansC-75;
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: center;
}

.playercard-back .btn-submit.black {
  background-color: var(--neutrals-white-100);
  color: var(--neutrals-grey-darker-60);
}

.playercard-back .btn-submit.blue, .playercard-back .btn-submit.white {
  background-color: #0032a0;
  color: var(--neutrals-white-100);
}</style>
