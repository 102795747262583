<script lang="ts">import { windowMetrics as wm } from "../../../../../../js/stores";
export let Play = {};
$: isDesktop = $wm.width > 767;
const handleLoad = (e) => {
    const iframe = e.target;
    if (!iframe) {
        return;
    }
    setTimeout(() => {
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
        iframe.style.width = isDesktop ? '600px' : iframe.contentWindow.document.body.scrollWidth + 'px';
        iframe.contentWindow.document.body.style.margin = '0';
        iframe.contentWindow.document.body.style.overflow = 'hidden';
    }, 6000);
};
</script>

<div class="live-reporting-twitter-card">
  <iframe srcdoc={Play.EmbedCode} on:load={handleLoad}></iframe>
  {#if Play.Text}
    <div class="title">{Play.Text}</div>
  {/if}
</div>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.live-reporting-twitter-card {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.live-reporting-twitter-card .title {
  margin: 6px 0 0 0;
  font-family: AeonikTRIAL;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #202020;
}

.live-reporting-twitter-card iframe {
  border: none;
  width: 600px;
}

.live-reporting-twitter-card iframe :global(blockquote) {
  margin: 0;
}

@media (max-width: 767px) {
  .live-reporting-twitter-card .title {
    margin: 2px 0 0 0;
    font-size: 14px;
  }

  .live-reporting-twitter-card iframe {
    width: unset;
  }
}</style>
