<CardBase {element}>
    <div class="breadcrumbs">
    {#each hyperlinks as item (item.id) }
        <div class="breadcrumbs-div" style="display:inline;position={item.position};width={item.width};">
            <a class="breadcrumbs-hyperlink" href={item.url} class:active-link={!isActive} on:click={isActive}>{item.itemName}</a>
        </div>
    {/each}
    </div>
</CardBase>

<style>.breadcrumbs {
  font-family: 'Core Sans C 45';
}

.breadcrumbs-div:before{
  content: "/";
  padding-right: 3px;
}

.breadcrumbs-div:first-child::before{
  content: "";
  padding-right: 0;
}

.breadcrumbs-hyperlink.active-link {
  color: #0B03FC;
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { t } from "../../../../../js/i18n";
import { getFirstSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { getContext, onMount } from "svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
export let element;
export let sources;
$: hyperlinks = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.links;
var isActive = function (element) {
    let breadCrumbs = document.getElementsByClassName("breadcrumbs-hyperlink");
    for (let i = 0; i < breadCrumbs.length; i++) {
        breadCrumbs[i].classList.remove("active-link");
    }
    element.currentTarget.classList.add("active-link");
};
</script>