<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore } from '../../../utilities';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { Readable } from 'svelte/store';
import { afterUpdate, getContext, onMount } from 'svelte';
import moment from 'moment';
import footballWhiteImg from '../image/football_icon_white.png';
import footballBlackImg from '../image/football_icon.png';
import headerBackground from '../image/header_background.png';
import VideoStreamPlayer from '../VideoStreamPlayer/VideoStreamPlayer.svelte';
import WatchTimer from '../WatchTimer/WatchTimer.svelte';
import { windowMetrics as wm } from '../../../../../js/stores';
import { _ } from 'svelte-i18n';
export let element;
export let sources;
let isPre;
let isCurrent;
let isLive;
let isFinal;
let footballImg = '';
let videoRef;
let showListenLive = false;
let title = "";
let clockTime = 0;
let endTime = 0;
const tabContext = getContext(CardsTabContextKey);
$: source = getFirstSourceStore($sources);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: isDesktop = $wm.width > 1024;
let sourceData = {
    HomeTeam: {},
    AwayTeam: {},
    ScoringPeriod: [],
    HomeScores: [],
    AwayScores: [],
    Clock: {},
    State: ""
};
let submittedGtm = false;
const playerGroupsHome = {};
const playerGroupsAway = {};
$: {
    if ($source) {
        if (![(_d = (_c = (_b = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _b === void 0 ? void 0 : _b._attributes) === null || _c === void 0 ? void 0 : _c.TriCode) === null || _d === void 0 ? void 0 : _d.toLowerCase(), (_g = (_f = (_e = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.TriCode) === null || _g === void 0 ? void 0 : _g.toLowerCase()].includes('tot')) {
            window.location.href = 'https://www.tottenhamhotspur.com/';
        }
        sourceData = {
            HomeTeam: {
                FullName: ((_j = (_h = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _h === void 0 ? void 0 : _h._attributes) === null || _j === void 0 ? void 0 : _j.FullName) || "",
                TriCode: ((_l = (_k = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _k === void 0 ? void 0 : _k._attributes) === null || _l === void 0 ? void 0 : _l.TriCode) || "",
                Logo: `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_p = (_o = (_m = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _m === void 0 ? void 0 : _m._attributes) === null || _o === void 0 ? void 0 : _o.LogoId) === null || _p === void 0 ? void 0 : _p.toLowerCase()}_${((_q = $source === null || $source === void 0 ? void 0 : $source.State) === null || _q === void 0 ? void 0 : _q._text) == "C" ? "light" : "dark"}.png`
            },
            AwayTeam: {
                FullName: ((_s = (_r = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _r === void 0 ? void 0 : _r._attributes) === null || _s === void 0 ? void 0 : _s.FullName) || "",
                TriCode: ((_u = (_t = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _t === void 0 ? void 0 : _t._attributes) === null || _u === void 0 ? void 0 : _u.TriCode) || "",
                Logo: `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_x = (_w = (_v = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _v === void 0 ? void 0 : _v._attributes) === null || _w === void 0 ? void 0 : _w.LogoId) === null || _x === void 0 ? void 0 : _x.toLowerCase()}_${((_y = $source === null || $source === void 0 ? void 0 : $source.State) === null || _y === void 0 ? void 0 : _y._text) == "C" ? "light" : "dark"}.png`
            },
            Result: (((_z = $source === null || $source === void 0 ? void 0 : $source.Result) === null || _z === void 0 ? void 0 : _z._text) || "").split(" ")[0],
            PenaltyResult: ((_0 = $source === null || $source === void 0 ? void 0 : $source.PenaltyResultLong) === null || _0 === void 0 ? void 0 : _0._text) || "",
            CompetitionName: ((_2 = (_1 = $source === null || $source === void 0 ? void 0 : $source.Competition) === null || _1 === void 0 ? void 0 : _1._attributes) === null || _2 === void 0 ? void 0 : _2.Name) || "",
            Stadium: ((_3 = $source === null || $source === void 0 ? void 0 : $source.Venue) === null || _3 === void 0 ? void 0 : _3._text) || "",
            ScoringPeriod: []
                .concat((_5 = []
                .concat((_4 = $source === null || $source === void 0 ? void 0 : $source.Scoring) === null || _4 === void 0 ? void 0 : _4.ScoringPeriod)
                .find((e) => { var _a; return ((_a = e === null || e === void 0 ? void 0 : e._attributes) === null || _a === void 0 ? void 0 : _a.MainHeading) === 'SCORING'; })) === null || _5 === void 0 ? void 0 : _5.ScoringPlay)
                .filter(e => e)
                .map((e) => {
                var _a, _b, _c, _d;
                return ({
                    IsHome: JSON.parse((_a = e === null || e === void 0 ? void 0 : e._attributes) === null || _a === void 0 ? void 0 : _a.IsHome),
                    Time: ((_b = e === null || e === void 0 ? void 0 : e._attributes) === null || _b === void 0 ? void 0 : _b.Time) || "",
                    Name: ((_d = (_c = [].concat(e === null || e === void 0 ? void 0 : e.Description)) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d._text) || "",
                });
            }),
            ClockTime: ((_6 = $source === null || $source === void 0 ? void 0 : $source.Clock) === null || _6 === void 0 ? void 0 : _6._text) || "0'",
            GameState: ((_7 = $source === null || $source === void 0 ? void 0 : $source.GameState) === null || _7 === void 0 ? void 0 : _7._text) || "",
            State: (_8 = $source === null || $source === void 0 ? void 0 : $source.State) === null || _8 === void 0 ? void 0 : _8._text,
            DateValue: new Date((_9 = $source === null || $source === void 0 ? void 0 : $source.Timestamp) === null || _9 === void 0 ? void 0 : _9._text)
        };
        isPre = ((_10 = $source === null || $source === void 0 ? void 0 : $source.State) === null || _10 === void 0 ? void 0 : _10._text) === 'P';
        isLive = ((_11 = $source === null || $source === void 0 ? void 0 : $source.State) === null || _11 === void 0 ? void 0 : _11._text) === 'C';
        isFinal = ((_12 = $source === null || $source === void 0 ? void 0 : $source.State) === null || _12 === void 0 ? void 0 : _12._text) === 'F';
        isCurrent = ((_13 = $source === null || $source === void 0 ? void 0 : $source.IsCurrent) === null || _13 === void 0 ? void 0 : _13._text) == true;
        footballImg = isLive ? footballBlackImg : footballWhiteImg;
        showListenLive = (_14 = $source === null || $source === void 0 ? void 0 : $source.ShowListenLive) === null || _14 === void 0 ? void 0 : _14._text;
        sourceData.HomeScores = sourceData.ScoringPeriod.filter(item => item.IsHome);
        sourceData.AwayScores = sourceData.ScoringPeriod.filter(item => !item.IsHome);
        Object.keys(playerGroupsHome).forEach(key => {
            delete playerGroupsHome[key];
        });
        Object.keys(playerGroupsAway).forEach(key => {
            delete playerGroupsAway[key];
        });
        sourceData.ScoringPeriod.forEach(player => {
            const playerName = player.Name || '';
            const playerTime = player.Time || '';
            if (player.IsHome) {
                if (!playerGroupsHome[playerName]) {
                    playerGroupsHome[playerName] = [];
                }
                playerGroupsHome[playerName].push(playerTime);
            }
            if (!player.IsHome) {
                if (!playerGroupsAway[playerName]) {
                    playerGroupsAway[playerName] = [];
                }
                playerGroupsAway[playerName].push(playerTime);
            }
        });
        title = `${sourceData.HomeTeam.TriCode} vs ${sourceData.AwayTeam.TriCode} - ${sourceData.DateValue.getDate().toString().padStart(2, "0")}/${(sourceData.DateValue.getMonth() + 1).toString().padStart(2, "0")}`;
    }
}
$: endTime = ((_15 = $source === null || $source === void 0 ? void 0 : $source.Timestamp) === null || _15 === void 0 ? void 0 : _15._text) ? (new Date((_16 = $source === null || $source === void 0 ? void 0 : $source.Timestamp) === null || _16 === void 0 ? void 0 : _16._text)).getTime() : 0;
$: clockTime = endTime ? (endTime - (new Date()).getTime()) / 1000 : 0;
const updateClockTime = () => {
    clockTime = calculateClockTime();
};
const calculateClockTime = () => {
    return endTime ? Math.max(0, (endTime - Date.now()) / 1000) : 0;
};
$: {
    // Update clockTime every 5 seconds
    const interval = setInterval(updateClockTime, 5000);
    // Calculate initial clockTime
    clockTime = calculateClockTime();
}
$: gameId = ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.gameId) || "";
$: {
    const prefix = "Laliga_Tot_";
    const competitionName = (_18 = (_17 = $source === null || $source === void 0 ? void 0 : $source.Competition) === null || _17 === void 0 ? void 0 : _17._attributes) === null || _18 === void 0 ? void 0 : _18.Name;
    const homeName = (_20 = (_19 = $source === null || $source === void 0 ? void 0 : $source.HomeTeam) === null || _19 === void 0 ? void 0 : _19._attributes) === null || _20 === void 0 ? void 0 : _20.Name;
    const awayName = (_22 = (_21 = $source === null || $source === void 0 ? void 0 : $source.AwayTeam) === null || _21 === void 0 ? void 0 : _21._attributes) === null || _22 === void 0 ? void 0 : _22.Name;
    const timeStamp = (_23 = $source === null || $source === void 0 ? void 0 : $source.Timestamp) === null || _23 === void 0 ? void 0 : _23._text;
    window.localStorage.setItem(prefix + "Competition_Name", competitionName);
    window.localStorage.setItem(prefix + "Home_Name", homeName);
    window.localStorage.setItem(prefix + "Away_Name", awayName);
    window.localStorage.setItem(prefix + "Timestamp", timeStamp);
    if (!submittedGtm && competitionName) {
        submittedGtm = true;
        setTimeout(() => {
            var _a;
            (_a = window === null || window === void 0 ? void 0 : window['dataLayer']) === null || _a === void 0 ? void 0 : _a.push({
                event: 'match_details_viewed',
                login_status: 'guest',
                crn: null,
                customer_id: null,
                topics_followed: null,
                scope: 'THFC Website',
                source: 'www.tottenhamhotspur.com',
                page_language: 'en',
                page_url: location.href,
                page_title: document.title,
                page_category: 'Match Centre',
                match_id: gameId,
                match_name: homeName + ' - ' + awayName,
                match_kickoff_datetime: moment(new Date(timeStamp)).format("YYYY-MM-DD HH:mm"),
                team_squad: 'men',
                competition_name: competitionName,
                match_host_team: homeName,
                match_opponent_team: awayName,
                details_type: 'commentary' // [ 'commentary', 'lineups', 'stats', 'live_results' ]
            });
        }, 1000);
    }
}
// Google Tag Manager
function initialize() {
    (function (wnd, doc, scriptTag, dataLayer, gtmId) {
        wnd[dataLayer] = wnd[dataLayer] || [];
        wnd[dataLayer].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const firstScript = doc.getElementsByTagName(scriptTag)[0];
        const newScript = doc.createElement(scriptTag);
        const dataLayerStr = dataLayer !== 'dataLayer' ? '&l=' + dataLayer : '';
        newScript.async = true;
        newScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dataLayerStr;
        firstScript.parentNode.insertBefore(newScript, firstScript);
    })(window, document, 'script', 'dataLayer', 'GTM-KFB6C9R');
}
// Google Tag Manaer
onMount(() => {
    initialize();
    window['dataLayer'].push({
        event: 'page_viewed',
        login_status: 'guest',
        crn: null,
        customer_id: null,
        topics_followed: null,
        scope: 'THFC Website',
        source: 'www.tottenhamhotspur.com',
        page_language: 'en',
        page_url: location.href,
        page_title: document.title,
        page_category: 'Match Centre'
    });
});
</script>

<CardBase {element}>
  <div class="match-panel" class:match-panel-dark={!isLive}>
    <div class="match-score-box" class:display-none={!$source}>
      <div class="goal-container">
        {#each Object.entries(playerGroupsHome) as [playerName, times]}
          <div class="goal-item">
		    <span class="player">{playerName} {times.join(', ')}</span>
            <img src={footballImg} alt="football" class="ball" />
          </div>
        {/each}
      </div>
      <div class="match-team">
        <div
          class="team"
          style={'margin-bottom: 0'}
        >
          <img src={sourceData.HomeTeam.Logo || ""} alt="logo" class="logo" />
          <div class="name">{sourceData.HomeTeam.FullName || ""}</div>
          <div class="short-name">{sourceData.HomeTeam.TriCode || ""}</div>
        </div>
        <div class="match-info">
          <h3>{sourceData.CompetitionName || ""}</h3>
          {#if isLive || isFinal}
            <div class="match-date">
              <!-- <span class="date">Wednewday, 7 April</span>
              <span class="time">18:00</span> -->
              <span class="date">{moment(sourceData.DateValue).format("DD MMM YYYY")}</span>
            </div>
          {/if}
          {#if isPre}
            <div class="match-date">
              <span class="date">{moment(sourceData.DateValue).format("DD MMM YYYY")}</span>
              <span class="time">{moment(sourceData.DateValue).format("HH:mm")}</span>
            </div>
          {:else}
            <div class="score-info">
              {sourceData.Result}
            </div>
            {#if isLive}
              <div class="progress-time">
                <div class="circle-mark" />
                <div class="info">{sourceData.GameState || ""}</div>
                <div class="time">{sourceData.ClockTime || ""}</div>
              </div>
            {:else}
              <div class="match-over-info">{sourceData.PenaltyResult === "" ? sourceData.GameState : sourceData.PenaltyResult}</div>
            {/if}
          {/if}
          <a href={encodeURI(`https://www.google.com/maps?q=${sourceData.Stadium}`)} target="_blank" class="external stadium">{sourceData.Stadium || ""}</a>
          {#if isCurrent && isDesktop && isPre}
            <div class="watch-timer">
              <WatchTimer resetTime={clockTime} />
            </div>
          {/if}
        </div>
        <div
          class="team"
          style={'margin-bottom: 0'}
        >
          <img src={sourceData.AwayTeam.Logo || ""} alt="logo" class="logo" />
          <div class="name">{sourceData.AwayTeam.FullName || ''}</div>
          <div class="short-name">{sourceData.AwayTeam.TriCode || ''}</div>
        </div>
      </div>
      <div class="goal-container away">
        {#each Object.entries(playerGroupsAway) as [playerName, times]}
          <div class="goal-item">
			<span class="player">{playerName} {times.join(', ')}</span>
            <img src={footballImg} alt="football" class="ball" />
          </div>
        {/each}
      </div>
    </div>

    {#if isCurrent && !isDesktop && isPre}
      <div class="watch-timer">
        <WatchTimer resetTime={clockTime} />
        <div class="description">until kick-off</div>
      </div>
    {/if}
    <!-- <GTM gtmId='GTM-KFB6C9R' /> -->

    {#if showListenLive}
      <div class="play-button">
        <VideoStreamPlayer bind:this={videoRef} />
      </div>
    {/if}
  </div>
</CardBase>

<svelte:head>
  <!-- // DNS Prefetch and preconnect -->
  <link rel="preconnect" href="//cdn.dynamicyield.com">
  <link rel="preconnect" href="//st.dynamicyield.com">
  <link rel="preconnect" href="//rcom.dynamicyield.com">
  <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
  <link rel="dns-prefetch" href="//st.dynamicyield.com" />
  <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />

  <!-- // DY context - for HP -->
  <script type="text/javascript">
     window.DY = window.DY || {};
     DY.recommendationContext = { type: "MATCHCENTRE" };
    </script>
  <!-- // DY scripts -->
  <script src="//cdn.dynamicyield.com/api/8769356/api_dynamic.js" type="text/javascript"></script>
  <script src="//cdn.dynamicyield.com/api/8769356/api_static.js" type="text/javascript"></script>
  <title>{title}</title>
</svelte:head>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --goal-players-margin: 0px;
}

@media (max-width: 991px) {
  :root {
    --goal-players-margin: 15px;
  }
}

.match-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 281px;
  position: relative;
  background-color: #3dc1f2;
  --primary-match-text-color: #0F204B;
}

.match-panel.match-panel-dark {
  background-color: #0F204B;
  background: url("../image/header_background.png");
  background-size: cover;
  --primary-match-text-color: #fff;
}

.match-score-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-match-text-color);
}

.match-score-box .match-team {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.match-score-box .team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 0 0;
}

.match-score-box .team .logo {
  height: 175px;
}

.match-score-box .team .name {
  height: 22px;
  margin: 6px 0 0 0;
  font-family: AeonikTRIAL;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  text-align: center;
  text-transform: uppercase;
}

.match-score-box .team .short-name {
  display: none;
}

.match-score-box .match-info {
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.match-score-box .match-info h3 {
  height: 21px;
  margin: 0 0 20px 0;
  font-family: AeonikTRIAL;
  font-size: 14.6px;
  font-weight: 500;
  line-height: 1.57;
  text-align: center;
}

.match-score-box .match-info .match-date {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px 0 8px 0;
}

.match-score-box .match-info .match-date .date {
  height: 15px;
  margin: 0;
  font-family: AeonikTRIAL;
  font-size: 14.6px;
  line-height: 0.94;
  text-align: center;
}

.match-score-box .match-info .match-date .time {
  height: 22px;
  margin: 8px 0 0 0;
  font-family: AeonikTRIAL;
  font-size: 28px;
  font-weight: 600;
  line-height: 0.79;
  letter-spacing: -0.64px;
  text-align: center;
}

.match-score-box .match-info .score-info {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 81px;
  margin: 0px;
  font-family: Arial;
  font-size: 72.4px;
  font-weight: bold;
  color: var(--primary-match-text-color);
  width: 170px;
}

.match-score-box .match-info .score-info .score1,
      .match-score-box .match-info .score-info .score2 {
  width: 80px;
  text-align: center;
  display: block;
}

.match-score-box .match-info .score-info .vs {
  line-height: 1;
  margin: 0 10px 10px 10px;
  display: block;
}

.match-score-box .match-info .match-over-info {
  text-transform: uppercase;
  height: 30px;
  font-family: AeonikTRIAL;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: var(--primary-match-text-color);
}

.match-score-box .match-info .stadium {
  height: 22px;
  margin: 4px 0 0 0;
  font-family: AeonikTRIAL;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: var(--primary-match-text-color);
  text-decoration: none;
}

.match-score-box .match-info .watch-timer {
  margin: 30px 0 0 0;
}

.match-score-box .progress-time {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  font-family: AeonikTRIAL;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-match-text-color);
  text-transform: uppercase;
  grid-gap: 10px;
  gap: 10px;
}

.match-score-box .progress-time .circle-mark {
  border-radius: 50%;
  border: 3px solid #D60101;
  width: 21px;
  height: 21px;
  position: relative;
}

.match-score-box .progress-time .circle-mark:after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  background: #D60101;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
}

.match-score-box .goal-container {
  margin: 0 var(--goal-players-margin) 0 0;
  width: 200px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.match-score-box .goal-container .goal-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}

.match-score-box .goal-container .goal-item:last-child {
  margin-bottom: 0;
}

.match-score-box .goal-container .goal-item * {
  display: block;
}

.match-score-box .goal-container .goal-item .player {
  margin: 0 13px 0 0;
  line-height: 22px;
  font-family: AeonikTRIAL;
  font-size: 14px;
  color: var(--primary-match-text-color);
}

.match-score-box .goal-container .goal-item .ball {
  width: 18px;
  height: 18px;
}

.match-score-box .goal-container.away {
  margin: 0 0 0 var(--goal-players-margin);
}

.match-score-box .goal-container.away .goal-item {
  flex-direction: row-reverse;
}

.match-score-box .goal-container.away .goal-item .player {
  margin: 0 0 0 13px;
}

.play-button {
  position: absolute;
  right: 20px;
  bottom: 0px;
}

@media (max-width: 767px) {
  .match-panel {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: unset;
    position: relative;
  }

  .match-panel .symbol {
    display: none;
  }

  .match-panel .watch-timer {
    margin: 0 0 0 0;
  }

  .match-panel .watch-timer .description {
    font-size: 10px;
    text-align: center;
    color: white;
    margin: 8px 0 16px 0;
  }

  .match-score-box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-match-text-color);
  }

  .match-score-box .match-team {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 12px 0 15.7px 0;
  }

  .match-score-box .match-team .team {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 0 0;
  }

  .match-score-box .match-team .team .logo {
    height: 50px;
  }

  .match-score-box .match-team .team .name {
    display: none;
    text-transform: uppercase;
  }

  .match-score-box .match-team .team .short-name {
    display: block;
    line-height: 16px;
    margin: 0;
    font-family: AeonikTRIAL;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: var(--primary-match-text-color);
    text-transform: uppercase;
  }

  .match-score-box .match-team .match-info {
    margin: 0 11.4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .match-score-box .match-team .match-info h3 {
    line-height: 15.1px;
    font-size: 12px;
    order: 1;
    margin: 0 -30px 10.9px -30px;
  }

  .match-score-box .match-team .match-info .match-date {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px 0 8px 0;
    order: 3;
  }

  .match-score-box .match-team .match-info .match-date .date {
    height: 15px;
    margin: 0;
    font-family: AeonikTRIAL;
    font-size: 12px;
    line-height: 0.94;
    text-align: center;
  }

  .match-score-box .match-team .match-info .match-date .time {
    height: 22px;
    margin: 8px 0 0 0;
    font-family: AeonikTRIAL;
    font-size: 20.3px;
    font-weight: 600;
    line-height: 0.79;
    letter-spacing: -0.64px;
    text-align: center;
  }

  .match-score-box .match-team .match-info .score-info {
    line-height: 43px;
    font-size: 39.1px;
    order: 5;
    width: unset;
  }

  .match-score-box .match-team .match-info .score-info .score1,
          .match-score-box .match-team .match-info .score-info .score2 {
    width: 45px;
    text-align: center;
    display: block;
  }

  .match-score-box .match-team .match-info .score-info .vs {
    line-height: 1;
    margin: 0 10px 5px 10px;
    display: block;
  }

  .match-score-box .match-team .match-info .match-over-info {
    text-transform: uppercase;
    height: 16.2px;
    font-family: AeonikTRIAL;
    font-size: 10.8px;
    line-height: 16.2px;
    text-align: center;
    color: var(--primary-match-text-color);
    margin: 0 0 2px 0;
    order: 3;
  }

  .match-score-box .match-team .match-info .stadium {
    height: 16px;
    margin: 0 -30px 0 -30px;
    font-size: 10.2px;
    line-height: 1.57;
    order: 7;
    visibility: hidden;
  }

  .match-score-box .match-team .match-info .progress-time {
    margin: 0 0 6px 0;
    line-height: 17px;
    font-size: 10.8px;
    order: 3;
    grid-gap: 4px;
    gap: 4px;
  }

  .match-score-box .match-team .match-info .progress-time .circle-mark {
    border-radius: 50%;
    border: 3px solid #3dc1f2;
    width: 11px;
    height: 11px;
    position: relative;
  }

  .match-score-box .match-team .match-info .progress-time .circle-mark:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background: #3dc1f2;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 50%;
  }

  .match-score-box .match-team .match-info .progress-time .info {
    margin: 0 8px 0 5px;
  }

  .match-score-box .goal-container {
    margin: 0;
    padding: 0;
    width: 90px;
  }

  .match-score-box .goal-container .goal-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
  }

  .match-score-box .goal-container .goal-item:last-child {
    margin-bottom: 0;
  }

  .match-score-box .goal-container .goal-item * {
    display: block;
  }

  .match-score-box .goal-container .goal-item .player {
    margin: 0 12px 0 0;
    line-height: 16px;
    font-family: AeonikTRIAL;
    font-size: 9.7px;
  }

  .match-score-box .goal-container .goal-item .ball {
    width: 12px;
    height: 12px;
    display: none;
  }

  .match-score-box .goal-container.away {
    margin: 0 0 0 -1px;
  }

  .match-score-box .goal-container.away .goal-item {
    flex-direction: row-reverse;
  }

  .match-score-box .goal-container.away .goal-item .player {
    margin: 0 0 0 11px;
  }

  .play-button {
    position: absolute;
    right: 10px;
    bottom: 0px;
  }
}</style>
