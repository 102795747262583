<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore } from '../../../utilities';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { t } from '../../../../../js/i18n';
import { Readable } from 'svelte/store';
import { getContext } from 'svelte';
import { windowMetrics as wm } from '../../../../../js/stores';
import { ConfigToken } from 'yinzcam-config';
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import GoalCard from './GoalCard/GoalCard.svelte';
import SubCard from './SubCard/SubCard.svelte';
import ImageCard from './ImageCard/ImageCard.svelte';
import NormalCard from './NormalCard/NormalCard.svelte';
import QuoteCard from './QuoteCard/QuoteCard.svelte';
import YoutubeCard from './YoutubeCard/YoutubeCard.svelte';
import TwitterCard from './TwitterCard/TwitterCard.svelte';
export let element;
export let sources;
export let sourceStore;
const appConfig = getToken(ROOT, ConfigToken);
const tabContext = getContext(CardsTabContextKey);
$: sourceStore = getFirstSourceStore($sources);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
let Play = {
    PlayerTeam: {
        TriCode: ""
    }
};
$: {
    Play = Object.assign({}, $sourceStore) || {
        PlayerTeam: {
            TriCode: ""
        }
    };
}
</script>

<CardBase {element}>
  <div
    class="live-reporting-card"
    style={Play.Type === "EMBED" && Play.EmbedCode.indexOf("blockquote") > -1 ? "background: white" : ""}
  >
    {#if Play.Type === 'QUOTE'}
      <QuoteCard {Play} />
    {:else if Play.Type === 'TWITTER'}
      <TwitterCard {Play} />
    {:else if Play.Type === 'YOUTUBE'}
      <YoutubeCard {Play} />
    {:else if Play.Type === 'EMBED'}
      <div style='margin: 0 auto;'>
        {@html Play.EmbedCode}
      </div>
    {:else if Play.Type === 'IMAGE'}
      <ImageCard {Play} />
    {:else if ['GOAL', 'PENALTY GOAL', 'OWN GOAL'].includes(Play.Type) && Play.PlayerTeam.TriCode.toLowerCase() === appConfig.tricode.toLowerCase()}
      <GoalCard {Play} />
    {:else if Play.Type === 'SUB' && Play.PlayerTeam.TriCode.toLowerCase() === appConfig.tricode.toLowerCase()}
      <SubCard {Play} />
    {:else}
      <NormalCard {Play} />
    {/if}
  </div>
</CardBase>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.live-reporting {
  padding: 10px 0;
}

.live-reporting-card {
  margin: 0 0 17px 0;
  display: flex;
  overflow: hidden;
}

.live-reporting-card:last-child {
  margin-bottom: 0;
}

.live-reporting-card :global(iframe) {
  margin: 0 auto;
  border-radius: 12px;
}

@media (max-width: 550px) {
  .live-reporting-card :global(iframe) {
    width: 100%;
    height: 100%;
  }
}</style>
