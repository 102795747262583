<CardBase {element}>
<div class="event-slider" class:display-none={hasPlays === false}>
	<div class="teams">
		<div class="team team-1">
			<span class="logo"><TeamLogo teamObject={homeTeam} /></span>
		</div>
		<div class="team team-2">
			<span class="logo"><TeamLogo teamObject={awayTeam} /></span>
		</div>
	</div>
	<ul class="events" style="max-width:{maxWidth+'px'};">
		{#each events as event (event.id)}
		<li class="event event-type-{event.eventType} {event.teamContext}" data-play-id="{event.id}" on:click={playIdFocus}>
			<div class="wrapper">
				<div class="detail">
					<h4>{event.title}</h4>
				</div>
				<div class="time-type">
					<div class="time">{event.timeLabel}</div>
					<div class="icon"></div>
				</div>
				<div class="detail">
					<h4>{event.title}</h4>
				</div>
			</div>
		</li>
		{/each}
	</ul>
</div>
</CardBase>

<style>*,*:before,*:after {
  box-sizing:border-box;
}

.event-slider {
  width:100%;
  font-family:var(--f7-font-family);
  display:flex;
  align-items:center;
}

.event-slider .teams {
  width:64px;
  display:block;
  flex-wrap:wrap;
}

.event-slider .teams .team {
  width:100%;
  padding:12px;
  display:block;
  overflow:hidden;
}

.event-slider .teams .team .logo {
  width:100%;
  -webkit-filter:grayscale(100%);
  filter:grayscale(100%);
  display:block;
  overflow:hidden;
}

.event-slider ul.events {
  width:90%;
  margin:0;
  padding:0;
  position:relative;
  flex-flow:row nowrap;
  display:flex;
  overflow:auto;
  flex:none;
  scroll-snap-type:x mandatory;
}

.event-slider ul.events::-webkit-scrollbar {
  display:none;
}

.event-slider ul.events:after {
  width:100%;
  height:2px;
  /*background-image: linear-gradient(to top, var(--neutrals-grey-lighter-20), rgba(120, 133, 145, 0));*/
  background-image:linear-gradient(45deg, var(--neutrals-grey-lighter-20),transparent);
  position:absolute;
  top:calc(50% + 1px);
  left:0;
  content:"";
  display:block;
  z-index:1;
}

.event-slider ul.events, .event-slider ul.events li {
  list-style:none;
  z-index:2;
}

.event-slider ul.events li {
  width:58px;
  margin:0px 24px;
  overflow:hidden;
  text-align:center;
  scroll-snap-align:center;
  flex:none;
  flex-wrap:wrap;
  overflow:visible;
}

.event-slider ul.events li .wrapper {
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
}

/*.event-slider ul.events li.opposing .time-type {
		order:2;
	}

	.event-slider ul.events li.opposing .detail {
		order:1;
	}*/

/*.event-slider ul.events li.event-type-goal.primary {
		background:var(--alav-s-primary);
	}

	.event-slider ul.events li.event-type-goal.opposing {
		background:var(--neutrals-grey-darker-20);
	}*/

.event-slider ul.events li .time-type {
  width:58px;
  height:58px;
  border-radius:29px;
  background:var(--neutrals-grey-darker-10);
  color:var(--neutrals-white-100);
  display:flex;
  flex-flow:column;
  justify-content:center;
  position:relative;
}

.event-slider ul.events li.event-type-goal .time-type {
  background-color: var(--neutrals-white-100);
}

.event-slider ul.events li.event-type-goal .time {
  color:var(--neutrals-grey-darker-60);
}

.event-slider ul.events li .time {
  width:100%;
  color:var(--neutrals-white-100);
  font-size:16px;
  font-weight:bold;
  text-align:center;
}

.event-slider ul.events li .icon {
  width:20px;
  height:20px;
  border-radius:50%;
  background-color:var(--neutrals-grey-darker-60);
  background-repeat:no-repeat;
  background-size:contain;
  position:absolute;
  bottom:-10px;
  left:calc(50% - 10px);
  z-index:2;
}

.event-slider ul.events li.opposing .icon {
  top:-10px;
}

.event-slider ul.events li.event-type-goal .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_goal.svg);
}

.event-slider ul.events li.event-type-yellow-card .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_yellow-card.svg);
}

.event-slider ul.events li.event-type-red-card .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_red-card.svg);
}

.event-slider ul.events li.event-type-substitution .icon {
  background-color:transparent !important;
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_substitution.png);
}

.event-slider ul.events li.event-type-expulsion .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_expulsion.svg);
}

.event-slider ul.events li.event-type-offsides .icon {
  background-image:url(https://yc-websites.s3.amazonaws.com/lfp/shared/icon_offsides.png);
}

.event-slider ul.events li .icon:after {
  width:100%;
  height:100%;
  margin:8px 0 0 0;
  background-repeat:no-repeat;
  background-size:contain;
  position:absolute;
  top:0;
  left:0;
  content:"";
  display:block;
}

.event-slider ul.events li .detail {
  width:100%;
  min-height:50px;
  display:flex;
  justify-content:center;
  overflow:visible;
}

.event-slider ul.events li.opposing .detail:first-child {
  align-items:flex-end;
}

.event-slider ul.events li.primary .detail:last-child {
  align-items:flex-start;
}

.event-slider ul.events li.opposing .detail:last-child {
  visibility:hidden;
}

.event-slider ul.events li.primary .detail:first-child {
  visibility:hidden;
}

.event-slider ul.events li .team {
  width:64px;
}

.event-slider ul.events li .team > span {
  width:40px;
  margin:0 auto;
}

.event-slider ul.events li .detail h3 {
  margin:0;
  color:var(--neutrals-grey-darker-60);
  font-size:20px;
  font-weight:800;
  line-height:1.2;
  text-align:center;
}

.event-slider ul.events li.event-type-goal .detail h3 {
  text-transform:uppercase;
}

.event-slider ul.events li .detail h4 {
  margin:12px 0;
  color:var(--neutrals-white-100);
  font-size:10px;
  font-weight:normal;
  line-height:1;
}

.event-slider ul.events li.primary .team.event-type-substitution .player:nth-child(1):after, .event-slider ul.events li.opponent .team.event-type-substitution .player:nth-child(1):before {
  width:11px;
  height:11px;
  background-color:#00ad00;
  border-radius:50%;
  content:"";
  display:inline-block;
}

.event-slider ul.events li.primary .team.event-type-substitution .player:nth-child(2):after, .event-slider ul.events li.opponent .team.event-type-substitution .player:nth-child(2):before {
  width:11px;
  height:11px;
  background-color:#e50000;
  border-radius:50%;
  content:"";
  display:inline-block;
}

.event-slider ul.events li.primary .team.event-type-goal .player:after, .event-slider ul.events li.opponent .team.event-type-goal .player:before {
  content:"\26BD";
}

/*show blank space on the second player listed (assister)*/

.event-slider ul.events li.primary .team.event-type-goal .player:nth-child(2):after, .event-slider ul.events li.opponent .team.event-type-goal .player:nth-child(2):before {
  width:13px;
  display:inline-block;
  content:"";
}

.event-slider ul.events li.primary .team.event-type-yellow-card .player:after, .event-slider ul.events li.opponent .team.event-type-yellow-card .player:before {
  width:11px;
  height:11px;
  background-color:#ffbc00;
  content:"";
  display:inline-block;
}

.event-slider ul.events li.primary .team.event-type-red-card .player:after, .event-slider ul.events li.opponent .team.event-type-red-card .player:before {
  width:11px;
  height:11px;
  background-color:#e50000;
  content:"";
  display:inline-block;
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import { onMount } from "svelte";
import { f7ready } from "framework7-svelte";
import CardBase from "../../../common/CardBase.svelte";
import { findSourceStore } from "../../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import TeamLogo from "../../../utilities/TeamLogo.svelte";
import { windowMetrics as wm } from "../../../../../js/stores.js";
export let element;
export let sources;
export let playIdFocus = function (e) {
    var playId = e.currentTarget.getAttribute('data-play-id');
    const playIDFocusEvent = new CustomEvent('playIDFocus', { detail: playId });
    console.log('playIdFocus', playId, e.currentTarget);
    window.dispatchEvent(playIDFocusEvent);
};
let hasPlays = false;
$: maxWidth = $wm.width - 64;
const eventMap = {
    'sub': 'substitution',
    'yellow': 'yellow-card',
    'red': 'red-card',
    'goal': 'goal'
};
const eventFiltersType = [
    'sub',
    'yellow',
    'red',
    'goal'
];
let eventTypeFilter = function (value) {
    if (eventFiltersType.indexOf(value._attributes.Type) !== -1) {
        return true;
    }
    else {
        return false;
    }
};
$: source = findSourceStore($sources, 'YinzCamAppServerGamePlays');
$: data = (source) ? $source : {};
$: plays = [].concat((_a = data === null || data === void 0 ? void 0 : data.Plays) === null || _a === void 0 ? void 0 : _a.Play).filter(play => { var _a; return (_a = play === null || play === void 0 ? void 0 : play._attributes) === null || _a === void 0 ? void 0 : _a.Id; });
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
// $: console.log('event data',data);
$: hasPlays = (plays.length > 0) ? true : false;
// $: console.log('another one',data);
$: events = plays.map(play => {
    let attrs = play === null || play === void 0 ? void 0 : play._attributes;
    let team = (((attrs === null || attrs === void 0 ? void 0 : attrs.IsHome) || 'true').toLowerCase() === 'true') ? data === null || data === void 0 ? void 0 : data.HomeTeam : data === null || data === void 0 ? void 0 : data.AwayTeam;
    return {
        id: attrs === null || attrs === void 0 ? void 0 : attrs.Id,
        eventType: eventMap[((attrs === null || attrs === void 0 ? void 0 : attrs.Type) || '')],
        real: (attrs === null || attrs === void 0 ? void 0 : attrs.Type) || '',
        timeInt: parseInt(((attrs === null || attrs === void 0 ? void 0 : attrs.Time) || "0'").slice(0, -1)),
        timeLabel: attrs === null || attrs === void 0 ? void 0 : attrs.Time,
        title: attrs === null || attrs === void 0 ? void 0 : attrs.Title.replace(':', ''),
        subTitle: [].concat((attrs === null || attrs === void 0 ? void 0 : attrs.Title) || []).concat((attrs === null || attrs === void 0 ? void 0 : attrs.SubTitle) || []),
        team: team,
        teamContext: ((team === null || team === void 0 ? void 0 : team._attributes.TriCode.toLowerCase()) === 'ala') ? 'primary' : 'opposing',
        players: [].concat((attrs === null || attrs === void 0 ? void 0 : attrs.Title) || []).concat((attrs === null || attrs === void 0 ? void 0 : attrs.SubTitle) || [])
    };
}).filter(item => eventFiltersType.indexOf(item.real) !== -1).sort((a, b) => b.timeInt - a.timeInt).reverse();
onMount(() => {
    f7ready(() => {
        // var 
    });
});
</script>