<CardBase {element}>
	<a href={getHrefForMatchCenter(gameId, state)}>
		<div class="schedule-card-d0" class:state-pregame={isPregame} class:state-live={isCurrent} class:state-final={isFinal}>
			<div class="date">
				{$tdate(gameTime, { month: 'short', day: 'numeric' })}, {$ttime(gameTime, { hour: 'numeric', minute: 'numeric' })}
			</div>
			<div class="game-detail">
				<div class="team team-1">
					<span class='logo'><TeamLogo teamObject={homeTeam} blankIfNil={true} /></span>
					<div class="team-name">{homeName || ''}</div>
				</div>
				<div class="team team-2">
					<span class='logo'><TeamLogo teamObject={awayTeam} blankIfNil={true} /></span>
					<div class="team-name">{awayName || ''}</div>
				</div>
			</div>
		</div>
	</a>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.schedule-card-d0 {
  width:100%;
  padding:8px;
  /*background-color:var(--f7-card-bg-color);*/
  font-family:var(--f7-font-family);
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  overflow:hidden;
  position:relative;
}

.schedule-card-d0:after {
  width:1px;
  height:70%;
  background-color:#FFF;
  position:absolute;
  top:15%;
  right:0;
  display:block;
  content:"";
}

.schedule-card-d0 .date {
  width:100%;
  padding:2px 12px;
  color:var(--f7-theme-color);
  text-align:left;
  text-transform:uppercase;
}

.schedule-card-d0 .team {
  width:100%;
  display:flex;
  align-items:center;
}

.schedule-card-d0 .team .logo {
  width:48px;
  height:auto;
  margin:0;
  padding:8px;
  display:block;
}

.schedule-card-d0 .team .team-name {
  padding:0 6px;
  font-weight:bold;
  font-size:0.8vw;
  color:var(--f7-theme-color);
}

/*pregame state*/

.schedule-card-d0.state-pregame .pregame {
  display:block;
}

.schedule-card-d0.state-pregame .score {
  visibility:hidden;
}

.schedule-card-d0.state-pregame .game-start {
  display:block;
}

/*live game state*/

.schedule-card-d0.state-live .clock {
  display:block;
}

.schedule-card-d0.state-live .live {
  display:block;
}

/*final game state*/

.schedule-card-d0.state-final .state {
  display:block;
}

.schedule-card-d0.state-final .final {
  display:block;
}

@media screen and (max-width:767px){
  .schedule-card-d0 .team .team-name {
    font-size:7vw;
  }
}</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { findSourceStore, getHrefForMatchCenter } from "../../utilities";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
import TeamLogo from '../../utilities/TeamLogo.svelte';
import { t } from "../../../../js/i18n";
import { resolveUrl } from "../../../../js/url";
import YCLink from "../../utilities/YCLink.svelte";
import { tdate, ttime } from "../../../../js/i18n";
export let element;
export let sources;
export let sourceStore;
$: {
    sourceStore = findSourceStore($sources, 'YinzCamAppServerGameScores');
}
;
$: data = (sourceStore && $sourceStore) ? $sourceStore : null;
$: gameId = (_a = data === null || data === void 0 ? void 0 : data._attributes) === null || _a === void 0 ? void 0 : _a.Id;
$: timestamp = (_b = data === null || data === void 0 ? void 0 : data.Timestamp) === null || _b === void 0 ? void 0 : _b._text;
$: gameTime = (timestamp) ? new Date(timestamp) : null;
$: venue = (_c = data === null || data === void 0 ? void 0 : data.Venue) === null || _c === void 0 ? void 0 : _c._text;
$: buttons = [].concat((_d = data === null || data === void 0 ? void 0 : data.Buttons) === null || _d === void 0 ? void 0 : _d.Button).map(i => {
    var _a, _b, _c;
    return {
        title: (_a = i === null || i === void 0 ? void 0 : i.Title) === null || _a === void 0 ? void 0 : _a._text,
        url: resolveUrl((_b = i === null || i === void 0 ? void 0 : i.URL) === null || _b === void 0 ? void 0 : _b._text),
        imageUrl: (_c = i === null || i === void 0 ? void 0 : i.ImageURL) === null || _c === void 0 ? void 0 : _c._text
    };
}).filter(i => i === null || i === void 0 ? void 0 : i.title);
$: homeTeam = data === null || data === void 0 ? void 0 : data.HomeTeam;
$: homeName = (_e = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam._attributes) === null || _e === void 0 ? void 0 : _e.Name;
$: homeScore = (_g = (_f = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _f === void 0 ? void 0 : _f._attributes) === null || _g === void 0 ? void 0 : _g.Score;
$: awayTeam = data === null || data === void 0 ? void 0 : data.AwayTeam;
$: awayName = (_h = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam._attributes) === null || _h === void 0 ? void 0 : _h.Name;
$: awayScore = (_k = (_j = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.Score;
$: state = (_l = data === null || data === void 0 ? void 0 : data.State) === null || _l === void 0 ? void 0 : _l._text;
$: isPregame = state === 'P';
$: isCurrent = state === 'C';
$: isFinal = state === 'F';
$: stateFmt = (_m = data === null || data === void 0 ? void 0 : data.GameState) === null || _m === void 0 ? void 0 : _m._text;
$: clock = (_o = data === null || data === void 0 ? void 0 : data.Clock) === null || _o === void 0 ? void 0 : _o._text;
</script>