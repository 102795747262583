import { YinzCamAPIRequestComponent, YinzCamAPIResponse } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent } from 'yinzcam-rma';
import { YinzCamCardsServiceFragment } from './YinzCamCardsServiceTypes';

export class YinzCamCardsServiceFragmentComponent extends AbstractReactiveMicrocomponent<YinzCamCardsServiceFragment, [YinzCamAPIResponse]> {
  public constructor(name: string, srvInput: YinzCamAPIRequestComponent) {
    super({ name }, srvInput);
  }

  protected async update($control: unknown, $srvrsp: YinzCamAPIResponse): Promise<YinzCamCardsServiceFragment> {
    if (!$srvrsp) {
      return null;
    }
    return $srvrsp.data as YinzCamCardsServiceFragment;
  }
}
