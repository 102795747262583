<CardBase {element}>
	<section class="site-links" class:display-none={isHidden}>
		{#if element.data.links}
		{#each element.data.links as column}
		<ul class="footer-links reg">
			{#if column.listHeading}
			<li class="list-heading">
				<a href="{column.listHeading.url}">{column.listHeading.text}</a>
			</li>
			{#each column.listItems as listItem}
			<li>
				<a href="{listItem.url}">{listItem.text}</a>
			</li>
			{/each}
			{/if}
		</ul>
		{/each}
		{/if}

		<ul style="display:none;" class="footer-links featured">
			<li>
				<a href="/cards/Home?round=current">Team Info</a>
			</li>
			<li>
				<a href="/cards/Home?round=current">Tickets</a>
			</li>
			<li>
				<a href="/cards/Home?round=current">Careers</a>
			</li>
			<li>
				<a href="/cards/Home?round=current">About</a>
			</li>
		</ul>
	</section>
	<section class="misc">
		<a class="branding" href="/cards/Home?round=current">
			<img src="https://resources-us.yinzcam.com/csf/logos/conmebol-logo_{languageValue}.png" alt="{$t("CONMEBOL Libertadores")}">
		</a>
		<div>
			<div class="policy-links">
				<a class="link" href="/cards/Terms/">Terms of Use</a>
				<a class="link" href="/cards/EUPrivacyPolicy">EU Privacy Policy</a>
				<a class="link" href="/cards/PrivacyPolicy">Privacy Policy</a>
				<a class="link" href="/cards/Cookies">Cookies</a>
				<a class="link external" href="mailto:notificaciones@conmebol.com">Contact</a>
			</div>
			<span class="copyright">Copyright &copy; {currentYear}.</span>
		</div>
	</section>
</CardBase>
<style>*, *:before, *:after {
  box-sizing:border-box;
}

:root {
  --nav-background-color:#000;
}

section {
  width:100%;
  margin:24px 0;
  padding:0 10%;
  background-color:var(--nav-background-color);
  color:#FFF;
  font-family:var(--f7-font-family);
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
}

.footer-links {
  width:14.28%;
  padding:0 8px;
  list-style:none;
}

.footer-links li {
  margin:8px 0;
  font-weight:400;
  text-align:left;
  text-transform:uppercase;
}

.footer-links li a {
  color:#FFF;
  font-size:1vw;
  text-decoration:none;
  opacity:0.75;
}

.footer-links li a:hover {
  opacity:1;
}

.footer-links li.list-heading {
  margin:4px 0 12px 0;
  font-weight:bold;
}

.footer-links li.list-heading a {
  opacity:1;
}

.footer-links.featured li {
  margin:4px 0 8px 0;
  font-weight:bold;
}

.misc {
  justify-content:flex-start;
  align-items:center;
}

.branding {
  width:14.28%;
  margin:12px 11.72% 12px 0;
  position:relative;
}

.branding img {
  width:100%;
  max-width:400px;
  margin:0 auto;
}

.branding + div {
  width:50%;
}

.policy-links {
  width:100%;
  margin:6px 0;
  padding:0;
  display:flex;
  justify-content:center;
  align-items:center;
}

.policy-links a {
  /*margin:0 8px;*/
  color:#FFF;
  font-size:1vw;
  text-align:center;
  text-decoration:none;
  opacity:0.75;
}

.policy-links a:hover {
  opacity:1;
}

.policy-links a:after {
  margin:0 4px;
  content:" | ";
}

.policy-links a:last-child:after {
  content:"";
}

.copyright {
  width:100%;
  padding:0;
  text-align:center;
  opacity:0.75;
  display:block;
}

@media screen and (max-width:1199px){
  section {
    padding:0 12px;
  }

  .site-links {
    justify-content:flex-start;
  }

  .footer-links {
    width:20%;
    margin:24px 2.5%;
  }

  .footer-links li {
    text-align:center;
  }

  .misc {
    justify-content:center;
  }

  .branding {
    width:150px;
  }
}

@media screen and (max-width:999px){
  .footer-links a {
    font-size:1.5vw;
  }
}

@media screen and (max-width:767px){
  .site-links {
    justify-content:center;
  }

  .footer-links {
    width:250px;
    margin:24px 0;
  }

  .footer-links a {
    font-size:1.75vw;
  }
}

@media screen and (max-width:500px){
  .branding {
    margin:12px auto;
  }

  .policy-links {
    flex-wrap:wrap;
  }

  .policy-links a {
    font-size:3.5vw;
  }

  .branding + div {
    width:100%;
  }

  .footer-links a {
    font-size:3.5vw;
  }
}</style>

<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { t } from "../../../../js/i18n";
import { JanusModeContextManagerToken } from "../../../../js/mode";
import ROOT from '../../../../inversify.config';
import { getToken } from 'inversify-token';
export let element;
const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const langStore = contextManager.getLanguageComponent().store;
$: languageValue = $langStore.toLowerCase();
let currentYear = new Date().getFullYear();
let isHidden = false;
$: if (element.data.hidden === true) {
    isHidden = true;
}
$: console.log('element.data', element.data);
</script>