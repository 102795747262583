<CardBase {element}>
	<div class='pagination-bar'>
		{#if parseInt(selectedValue) > 0}
		<Button on:click={goToPage.bind(this, 0)}>&lt;&lt;</Button>
		<Button on:click={goToPage.bind(this, Math.max(0, parseInt(selectedValue) - 1))}>&lt;</Button>
		{/if}
		{#each options as option (option.value) }
			<Button
				on:click={goToPage.bind(this, parseInt(option.value))}
				round={option.value === selectedValue}
				fill={option.value === selectedValue}>{option.title}</Button>
		{/each}
		{#if parseInt(selectedValue) < pageCount - 1}
		<Button on:click={goToPage.bind(this, Math.min(pageCount - 1, parseInt(selectedValue) + 1))}>&gt;</Button>
		<Button on:click={goToPage.bind(this, pageCount - 1)}>&gt;&gt;</Button>
		{/if}
		<div class="page-info">{parseInt(selectedValue) + 1} de {pageCount} Páginas</div>
  </div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.pagination-bar {
  --f7-button-border-color: #1f66ff;
  --f7-button-fill-text-color: #fff;
  --f7-button-fill-bg-color: #1f66ff;
  --f7-button-outline-border-color: #1f66ff;
  --f7-button-text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pagination-bar :global(.button) {
  margin-right: 2px;
  transition-duration: 0ms;
}

.pagination-bar .page-info {
  margin-left: auto;
}</style>

<script lang="ts">var _a, _b, _c, _d;
import { Button } from "framework7-svelte";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { getFirstSourceStore } from "../../../utilities";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
import { t } from "../../../../../js/i18n";
import { Readable } from "svelte/store";
import { afterUpdate, getContext, onMount } from "svelte";
export let element;
export let sources;
//const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const tabContext = getContext(CardsTabContextKey);
$: key = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.key;
// TODO: make these dynamic
$: pageLength = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.pageLength) || 10;
$: {
    tabContext.setParam('length', String(pageLength));
}
$: pageCount = ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.pageCount) || 1;
/*
$: source = getFirstSourceStore($sources);
$: sourceOptions = $source?.options?.map((option) => {
    return {
        title: $t(option.title),
        value: option.value
    };
});
$: elementOptions = element?.data?.options?.map((option) => {
    return {
        title: $t(option.title),
        value: option.value
    };
});
$: options = (sourceOptions || elementOptions || []) as { title: string, value: string }[];
*/
$: options = [...Array(pageCount).keys()].map((i) => {
    return {
        title: $t(String(i + 1)),
        value: String(i)
    };
});
$: mergedParams = (_d = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _d === void 0 ? void 0 : _d.store;
$: selectedValue = (key) ? ($mergedParams || {})[key] : null;
function goToPage(value) {
    tabContext.setParam(key, String(value));
}
afterUpdate(() => {
    if (!selectedValue && $mergedParams && options && options.length > 0) {
        tabContext.setParam(key, options[0].value);
    }
});
</script>
