<CardBase {element}>
<div class="classification">
	<ul class="classification-table">
		<li class="legend">
			<span class="pos">Pos</span>
			<span class="club">Club</span>
			<span class="ptos">Ptos</span>
			<span class="pj">PJ</span>
			<span class="dg">DG</span>
		</li>
		<li>
			<a href="">
				<span class="pos">13</span>
				<span class="club">
					<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/fc-barcelona.png" alt="">
					<span>FC Barcelona</span>
				</span>
				<span class="ptos">11</span>
				<span class="pj">8</span>
				<span class="dg">+6</span>
			</a>
		</li>
		<li>
			<a href="">
				<span class="pos">14</span>
				<span class="club">
					<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/osasuna.png" alt="">
					<span>CA Osasuna</span>
				</span>
				<span class="ptos">11</span>
				<span class="pj">9</span>
				<span class="dg">-1</span>
			</a>
		</li>
		<li class="feature-team">
			<a href="">
				<span class="pos">15</span>
				<span class="club">
					<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/alaves.png" alt="">
					<span>Deportivo Alavés</span>
				</span>
				<span class="ptos">10</span>
				<span class="pj">10</span>
				<span class="dg">-3</span>
			</a>
		</li>
		<li>
			<a href="">
				<span class="pos">16</span>
				<span class="club">
					<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/eibar.png" alt="">
					<span>SD Eibar</span>
				</span>
				<span class="ptos">10</span>
				<span class="pj">10</span>
				<span class="dg">-3</span>
			</a>
		</li>
		<li>
			<a href="">
				<span class="pos">17</span>
				<span class="club">
					<img src="https://yc-websites.s3.amazonaws.com/lfp/shared/valladolid.png" alt="">
					<span>Real Valladolid</span>
				</span>
				<span class="ptos">9</span>
				<span class="pj">10</span>
				<span class="dg">-5</span>
			</a>
		</li>
	</ul>
</div>
</CardBase>

<style>*, *:before, *:after {
  box-sizing:border-box;
}

.classification {
  width:100%;
  overflow:hidden;
}

ul.classification-table {
  width:100%;
  margin:0;
  padding:0;
  list-style:none;
  display:block;
}

ul.classification-table li {
  width:100%;
  margin:0;
  padding:4px 0;
  display:block;
}

ul.classification-table li.legend {
  padding:6px 0;
  background-color:var(--alav-s-primary-lighter);
  color:#FFF;
}

ul.classification-table li:not(.legend) {
  border-bottom:solid 1px rgba(0,0,0,0.1);
}

ul.classification-table li:not(.feature-team) {
  border-left:solid 4px transparent;
}

ul.classification-table li.feature-team {
  border-left:solid 4px var(--alav-s-primary-lighter);
  color:#000;
}

ul.classification-table li.legend, ul.classification-table li a {
  width:100%;
  text-decoration:none;
  display:flex;
  flex-wrap:nowrap;
  justify-content:flex-start;
  align-items:center;
  overflow:hidden;
}

ul.classification-table li.legend span {
  font-size:10px;
  font-weight:normal;
}

ul.classification-table li.legend .pos {
  padding-left:4px;
}

ul.classification-table li a span {
  margin:0;
  padding:0 6px;
  color:#888;
}

ul.classification-table li.feature-team a span {
  color:#000;
}

ul.classification-table li a span {
  font-size:12px;
}

ul.classification-table li .pos {
  width:10%;
}

ul.classification-table li .club {
  width:55%;
}

ul.classification-table li .club {
}

ul.classification-table li a .club {
  display:flex;
  flex-wrap:nowrap;
  align-items:center;
}

ul.classification-table li a .club img {
  width:32px;
}

ul.classification-table li .ptos {
  width:15%;
  font-weight:700;
  text-align:center;
}

ul.classification-table li .pj {
  width:12.5%;
  text-align:center;
}

ul.classification-table li .dg {
  width:12.5%;
  text-align:center;
}</style>

<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { t } from "../../../../../js/i18n";
import { getFirstSourceStore, expandTemplateParams } from "../../../utilities";
import YCLink from "../../../utilities/YCLink.svelte";
import { Readable } from "svelte/store";
import { getContext, onMount } from "svelte";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
export let element;
export let sources;
</script>